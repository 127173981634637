/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo, useEffect, useState } from "react";
import BaseView from "../BaseView";
import NavBar from "./NavBar";
import PurposeList from "./PurposeList";
import { useDisclosure } from "@chakra-ui/react";
import PurposesEditModal from "./PurposeEditModal";
import { usePurposeApi } from "./usePurpoeApi";
import { usePurposeUI } from "./usePurposeUI";
import { FormattedMessage } from "react-intl";
import NewItemButton from "src/components/NewItemButton";
import PurposeCreateModal from "./PurposeCreateModal";
import { useCreatePurpose } from "./useCreatePurpose";
import { useEditPurpose } from "./useEditPurpose";
import { valuesBg } from "src/assets/icons";
import { useAppContext } from "../../../Providers";
import SharingModal from "src/components/SharingModal/SharingModal";

const Purposes: React.FC = () => {
  const context = useAppContext();
  useEffect(() => {
    context.background?.set("#fff");
  });
  const {
    currentId,
    currentListTypeState,
    editDisclosureProps,
    createDisclosureProps,
  } = usePurposeUI();

  const { listQuery, mutations, currentPurposeQuery } =
    usePurposeApi(currentId);

  useEffect(() => {
    currentPurposeQuery.data?.selectedAspects?.sort((a, b) =>
      a.completed && !b.completed ? -1 : 1
    );
  }, [currentPurposeQuery]);

  const { createHandler } = useCreatePurpose({
    openEditModal: editDisclosureProps.onOpen,
    dismissCreateModal: createDisclosureProps.onClose,
    createMutation: mutations.createMutation,
  });
  const editProps = useEditPurpose({
    id: currentId,
    editMutation: mutations.editMutation,
    currentPurpose: currentPurposeQuery?.data,
    addAspectMutation: mutations.addAspectMutation,
    addTargetMutation: mutations.addTargetMutation,
    selectTargetMutation: mutations.selectTargetMutation,
    selectValuesMatrix: mutations.mutateValuesMatrix,
    saveNote: mutations.saveNoteMutation,
  });

  //state to indicate that the purpose had been reset
  const [isReset, setIsReset] = useState(0);
  const { isOpen: isSharingModalOpen, onClose: onSharingModalClose, onOpen: onSharingModalOpen } = useDisclosure();
  const [currentList, setCurrentList] = useState<any>({ id: 0, name: "" })
  const [sharingProps, setSharingProps] = useState<any>()
  const onShare: any = ({ id, name }) => {
    onSharingModalOpen()
    setCurrentList({ id, name })
  }

  const listActions = useMemo(() => {
    //TODO
    return {
      removePurposeHandler: async (id: number) => {
        mutations.deletePurposeMutation(id);
      },
      archive: (id: number) => mutations.archiveMutation(id),
      reset: async (id: number) => {
        await mutations.purposeResetMutation({ purposeId: id });
        setIsReset(id);
        return Promise.resolve();
      },
      unarchive: (id: number) => mutations.unarchiveMutation(id),
      incompleteItemOnClick: (id: number) => editDisclosureProps.onOpen(id),
      completeArchiveItemOnClick: (id: number) =>
        editDisclosureProps.onOpen(id),
    };
  }, [editDisclosureProps, mutations]);

  useEffect(() => {
    // open the edit dialog when resetting the message
    if (isReset) {
      editDisclosureProps.onOpen(isReset);
    }
  }, [isReset]);

  return (
    <>
      <BaseView imageSrc={valuesBg}>
        <NewItemButton onClick={createDisclosureProps.onOpen}>
          <FormattedMessage
            id="purpose.create"
            defaultMessage="إضافة رسالة جديدة"
          />
        </NewItemButton>
        <NavBar state={currentListTypeState} />
        <PurposeList
          {...listQuery}
          onShare={onShare}
          setSharingProps={setSharingProps}
          currentType={currentListTypeState[0]}
          listActions={listActions}
        />
      </BaseView>
      <SharingModal isOpen={isSharingModalOpen} onClose={onSharingModalClose} sharedEntity={{ name: "message", id: currentList.id }} showOptions={true} />
      <PurposeCreateModal
        disclosureProps={createDisclosureProps}
        createHandler={createHandler}
      />
      <PurposesEditModal
        disclosureProps={editDisclosureProps}
        purposeQuery={currentPurposeQuery}
        isReset={isReset}
        isSharedWithMe={sharingProps?.shared?.permission}
        canUpdate={sharingProps?.shared?.permission === "update"}
        {...editProps}
        restoreFn={listActions.reset}
      />
    </>
  );
};
export default Purposes;
