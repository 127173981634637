import { Box } from "@chakra-ui/react";
import React from "react";

const ImageList = ({ images, onClick, onClose }) => {
  return (
    <Box
      mt="4"
      d="flex"
      alignItems={"center"}
      justifyContent={["center"]}
      flexDir={"row"}
      w="100%"
      flexWrap={"wrap"}
      maxH="500"
      overflowY={"scroll"}
    >
      {images.map((image: any) => {
        return (
          <Box
            onClick={() => {
              onClick(image.urls.full);
              onClose();
            }}
            cursor="pointer"
            className="image-list-item"
          >
            <img
              style={{ width: "150px", margin: "5px" }}
              src={image.urls.regular}
              alt={image.alt_description}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default ImageList;
