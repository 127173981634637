import {
  Center,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
  Box,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import InputWithAdd from "src/components/InputWithAdd";
import NewItemButton from "src/components/NewItemButton";
import PrimaryButton from "src/components/PrimaryButton";
import SecondaryButton from "src/components/SecondaryButton";
import { Vision, Wish, wishItem } from "src/types";
import ValueItem from "../Values/ValueItem";
import * as mutation from "src/api/prepration/vision/mutation";
import { getList } from "src/api/prepration/vision/query";
import { scrollBarStyles } from "src/layout/ScrollBar";
import { useMutation, useQueryClient } from "react-query";
import AccessPermission from "src/components/AccessPermission/AccessPermission";

type VisionCreateProps = {
  disclosureProps: UseDisclosureReturn;
  onSubmit: (goToTopWishes?: boolean) => Promise<void>;
  vision: Vision;
  isEdit?: boolean;
  loading?: boolean;
  error: Error | null;
  addWish: (wish: Wish) => void;
  editWish: (idx: number, wish: Wish) => void;
  removeWish: (idx: number) => void;
  setVisionName: (name: string) => void;
  isSharedWithMe?: boolean;
  canUpdate?: boolean;
  owner?: any;
};
const VisionsCreate: React.FC<VisionCreateProps> = ({
  disclosureProps,
  vision,
  addWish,
  editWish,
  removeWish,
  setVisionName,
  onSubmit,
  isEdit,
  isSharedWithMe,
  canUpdate,
  owner
}) => {
  const client = useQueryClient();
  const { mutateAsync: editMutation } = useMutation(mutation.editWishItem, {
    onSuccess: () => client.refetchQueries(getList[0]),
  });
  const { mutateAsync: deleteMutation } = useMutation(mutation.deleteWishItem, {
    onSuccess: () => client.invalidateQueries("listVision"),
  });
  const [tempWish, setTempWish] = useState<Wish>({ name: "" });
  const [wishItemState, setWishItemState] = useState<wishItem>({
    id: 0,
    name: "",
  });

  const newSubmitFunc = () => {
    onSubmit(false);
    editMutation(wishItemState);
  };

  useEffect(() => {
    const container = document.getElementById("wishes-container-op");
    if (container) container.scrollTop = container?.scrollHeight;
  }, [vision.wishes.length]);
  const { formatMessage } = useIntl();
  return (
    <>
      <Modal {...disclosureProps}>
        <ModalOverlay />
        <ModalContent
          px={"72px"}
          maxH={"732px"}
          borderRadius="30px"
          maxW="50rem"
        >
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            {isSharedWithMe ? <Box mb="4">
              <Box>
                <div>
                  <p>
                    <span>{formatMessage({ id: "sharedBy" })}</span> <span>{owner?.name}</span>
                  </p>
                  <small>{owner?.email}</small>
                </div>
                <AccessPermission permission={canUpdate ? "update" : "read"} />
              </Box>
            </Box> : null}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const input = document.querySelector(
                  "#input-add-op"
                )! as HTMLInputElement;
                input.focus();
              }}
            >
              <Text
                d="inline-block"
                color="brand.900"
                fontSize="18px"
                fontWeight="bold"
                fontFamily="Calibri (Body)"
              >
                <FormattedMessage
                  id="vision.create.title"
                  defaultMessage="ادخل عنوان للقائمة"
                />
              </Text>
              <Input
                variant="flushed"
                mb={4}
                value={vision.name}
                onChange={(e) => setVisionName(e.target.value)}
                id="vision-name-input"
              />
            </form>
            <Text
              d="inline-block"
              color="brand.900"
              fontSize="18px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
            >
              <FormattedMessage
                id="vision.create.enter_wishes"
                defaultMessage="قم بإدخال الأمنيات"
              />
            </Text>
            <InputWithAdd
              id="input-add-op"
              mb={8}
              value={tempWish.name}
              onChange={(e: any) => setTempWish({ name: e.target.value })}
              onClick={() => {
                if (!tempWish || !tempWish.name) return;
                addWish(tempWish);
                setTempWish({ name: "" });
              }}
            />
            <Box
              overflowY={"scroll"}
              maxHeight={"200px"}
              css={scrollBarStyles}
              padding="0 20px"
              id="wishes-container-op"
            >
              {vision.wishes.map((wish, idx) => (
                <ValueItem
                  idx={idx}
                  value={wish.name}
                  key={isEdit ? wish.id : wish.id}
                  edit={(value) => {
                    editWish(idx, { ...wish, name: value });
                    setWishItemState(wish);
                  }}
                  remove={() => {
                    removeWish(idx);
                    deleteMutation(wish.id);
                  }}
                />
              ))}
            </Box>
            <Center mt={4} mb={6}>
              <PrimaryButton
                me="10px"
                minWidth="100px"
                disabled={vision.wishes.length <= 20 || !vision.name}
                onClick={() => onSubmit(true)}
              >
                <FormattedMessage
                  id="vision.create.add_vision"
                  defaultMessage="إضافة رؤية"
                />
              </PrimaryButton>
              <SecondaryButton
                w="168px"
                h="40px"
                borderRadius="0.375rem"
                onClick={() => newSubmitFunc()}
              >
                <FormattedMessage id="save" defaultMessage="حفظ" />
              </SecondaryButton>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <NewItemButton onClick={() => disclosureProps.onOpen()}>
        <FormattedMessage
          id="visions.create"
          defaultMessage="إضافة قائمة أمنيات جديدة"
        />
      </NewItemButton>
    </>
  );
};
export default VisionsCreate;
