import {
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Breadcrumb as ChakraBreadcrumb,
  Text,
  Flex,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, Link as ReactRouterLink } from "react-router-dom";
import SalMagicGate from "src/views/Frequency/SalMagic/components/SalMagicGate";

interface Props {
  smallHeader?: any;
  breadTitle?: string;
}

const Breadcrumb: React.FC<Props> = ({ smallHeader, breadTitle }) => {
  const location = useLocation();
  return (
    <ChakraBreadcrumb color="gray.900" textTransform="capitalize" separator=" ">
      <BreadcrumbItem w="100%">
        <BreadcrumbLink
          as={ReactRouterLink}
          to={`/${location.pathname.split("/")[1]}/${
            location.pathname.split("/")[2]
          }`}
        >
          <FormattedMessage id={location.pathname.split("/")[2]} />
        </BreadcrumbLink>
        {location.pathname.includes("waves") && (
          <>
            &nbsp;-&nbsp;
            <BreadcrumbLink
              as={ReactRouterLink}
              to={`/${location.pathname.split("/")[1]}/${
                location.pathname.split("/")[2]
              }/${location.pathname.split("/")[3]}`}
            >
              <FormattedMessage id={location.pathname.split("/")[3]} />
            </BreadcrumbLink>
          </>
        )}

        {location.pathname.split("/").length > 4 &&
          !location.pathname.includes("waves") && (
            <>
              &nbsp;-&nbsp;
              <BreadcrumbLink
                as={ReactRouterLink}
                to={`/${location.pathname.split("/")[1]}/${
                  location.pathname.split("/")[2]
                }/${location.pathname.split("/")[3]}`}
              >
                <FormattedMessage id={location.pathname.split("/")[3]} />
              </BreadcrumbLink>
            </>
          )}
      </BreadcrumbItem>
      <BreadcrumbItem
        textStyle="h2"
        isCurrentPage
        w="100%"
        justifyContent="space-between"
        alignItems="start"
      >
        {location.pathname.includes("waves") ? (
          location.pathname.includes("category") ? (
            <Heading as="h2" textStyle={"h2"}>
              <FormattedMessage id={breadTitle ? breadTitle : " "} />
            </Heading>
          ) : (
            <>
              <Heading as="h2" textStyle={"h2"}>
                <FormattedMessage id={breadTitle ? breadTitle : " "} />
              </Heading>
              <SalMagicGate radius={105} />
            </>
          )
        ) : (
          <Heading as="h2" textStyle={"h2"}>
            {breadTitle ? (
              <Text>{breadTitle}</Text>
            ) : location.pathname.split("/").length <= 4 ? (
              <FormattedMessage id={location.pathname.split("/")[3]} />
            ) : (
              isNaN(+location.pathname.split("/")[4]) && (
                <FormattedMessage
                  id={breadTitle ? breadTitle : location.pathname.split("/")[4]}
                />
              )
            )}
          </Heading>
        )}
        {smallHeader ? smallHeader : null}
      </BreadcrumbItem>
    </ChakraBreadcrumb>
  );
};
export default Breadcrumb;
