import { Input, InputGroup, InputProps } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import ReactDatePicker, { CalendarContainer } from "react-datepicker";
import { sameDateFormatter } from "src/utils/sameDateFormatter";
type DatePickerProps = {
  value: string;
  onChange: (
    date: Date | [Date, Date] | string | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void;
  inputProps?: InputProps;
  isDisabled?: Boolean;
};

const MyContainer = ({ className, children }) => {
  return (
    // <div style={{ padding: "16px", background: "#216ba5", color: "#fff", position:'relative',  }}>
    <CalendarContainer className={className}>
      {/* <div style={{ background: "#f0f0f0" }}>
            What is your favorite day?
          </div> */}
      <div style={{ direction: "ltr" }}>{children}</div>
    </CalendarContainer>
    // </div>
  );
};

const ActionDatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  inputProps,
  isDisabled,
}) => {
  const [inputValue, setInputValue] = useState(
    value ? sameDateFormatter(value) : "DD-MM-YYYY"
  );

  //effect to update input value when value prop changes
  useEffect(() => {
    setInputValue(value ? sameDateFormatter(value) : "DD-MM-YYYY");
  }, [value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return !isDisabled ? (
    <ReactDatePicker
      onSelect={(date) => {}}
      selected={undefined}
      onChange={(date, e) => {
        if (!date) return;
        date = new Date(date);
        date.setHours(6);
        onChange(date, e);
      }}
      calendarContainer={MyContainer}
      customInput={
        <InputGroup>
          <Input
            style={{ textAlign: "center", height: "31px" }}
            variant="unstyled"
            // minW="65px"
            color="black"
            w="110px"
            // dateFormat="MM-DD-YYYY"
            value={inputValue}
            onChange={handleInputChange}
            {...inputProps}
          />
        </InputGroup>
      }
    />
  ) : (
    <InputGroup>
      <Input
        disabled={Boolean(isDisabled)}
        style={{ textAlign: "center", height: "31px" }}
        variant="unstyled"
        // minW="65px"
        color="black"
        w="110px"
        // dateFormat="MM-DD-YYYY"
        value={inputValue}
        onChange={handleInputChange}
        {...inputProps}
      />
    </InputGroup>
  );
};

export default ActionDatePicker;
