import { deleteMethod, post, put } from "src/api";
import { Mission } from "src/types";

const create: (mission: Mission) => Promise<Mission> = (mission) =>
  post<Mission>("/mission", mission);

const edit: (mission: Mission) => Promise<Mission> = (mission) =>
  put<Mission>(`/mission/${mission.id!}`, mission);

const deleteMission: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/mission/${id!}`);
export { create, edit, deleteMission };
