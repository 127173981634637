import {
  Popover,
  PopoverTrigger,
  Tooltip,
  Box,
  HStack,
  Image,
  Link,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, Link as ReactRouterLink } from "react-router-dom";
import LightThanosBar from "src/components/ThanosBar/LightThanosBar";
import { home, settings, logout, earth, login } from "../assets/icons";
import {
  LEARN_ROUTE,
  TRAIN_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  SETTINGS_ROUTE,
  FAQ_ROUTE,
  DESCRIPTION_ROUTE,
  PLAYLISTS_ROUTE,
} from "../routes";
import { useLocaleContext } from "../providers/LocaleProvider";
import { useSelector } from "../store";
import useIsDev from "../helpers/useIsDev";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import { GiTeacher } from "react-icons/gi";

const DarkHeader = () => {
  const location = useLocation();
  const intl = useIntl();
  const isActive = location.pathname.includes("/");
  const [open, setOpen] = useState<boolean>(false);
  const { locale, setLocale } = useLocaleContext();
  const { toggleColorMode } = useColorMode();
  const isDev = useIsDev();
  //current user
  const user = useSelector((state) => state.auth.user);

  const endDate = user?.subscription?.endDate;
  const millisecondsDifference = endDate
    ? new Date(endDate).getTime() - new Date().getTime()
    : 0;

  const remainingDays = Math.floor(
    millisecondsDifference / (1000 * 60 * 60 * 24)
  );

  //   const {btn} = useContext(NavBtnsContext);
  // useEffect(()=>{},[btn?.length])
  return (
    <Box
      as="nav"
      px={["0px", "50px", "140px"]}
      d="flex"
      justifyContent="center"
      flexDirection={["column", "column", "column", "row", "row"]}
      position="relative"
      pb={{ base: "30px", lg: "0px" }}
      pt="30px"
    >
      {/* {isDev ? (
        <div
          style={{
            position: "fixed",
            top: "10px",
            left: "10px",
            zIndex: 9999,
          }}
        >
          <Button onClick={toggleColorMode} p={5}>
            Toggle Color mode <br /> (this is in dev only عشان عيني بتوجعني) :D
          </Button>
        </div>
      ) : null} */}
      <HStack
        gridGap={["2"]}
        h="22px"
        w="220px"
        mb={[".5rem", "auto", "auto", "auto"]}
        textAlign="end"
        justifyContent={["center"]}
        position={["static", "static", "static", "absolute"]}
        top="0"
        left={{ lg: "50%" }}
        marginX="auto"
        transform={{
          base: "translate(0, 0px)",
          lg:
            user?.subscriptionStatus === "approved"
              ? "translate(-370px, 50px)"
              : "translate(-480px, 50px)",
        }}
      >
        {user?.subscriptionStatus === "approved" ? (
          <Box
            w="100%"
            d="flex"
            alignItems={"center"}
            justifyContent={"space-evenly"}
          >
            <Tooltip
              hasArrow
              label={intl.formatMessage({ id: "learning" })}
              arrowSize={8}
              bg="#1f3f93"
              color="white"
            >
              <Link
                as={ReactRouterLink}
                to={LEARN_ROUTE}
                color="brand.900"
                textStyle="smallBold"
              >
                {/* <FormattedMessage id="learning" /> */}
                <LocalLibraryIcon fontSize="medium" />
              </Link>
            </Tooltip>
            <Tooltip
              hasArrow
              label={intl.formatMessage({ id: "training" })}
              arrowSize={8}
              bg="#1f3f93"
              color="white"
            >
              <Link
                as={ReactRouterLink}
                to={TRAIN_ROUTE}
                color="brand.900"
                textStyle="smallBold"
              >
                {/* <FormattedMessage id="training" /> */}
                {/* <TouchAppIcon /> */}
                <CastForEducationIcon />
                {/* <Image src="/learning2.svg" width={"30px"} /> */}

                {/* <GiTeacher /> */}
              </Link>
            </Tooltip>
            <Tooltip
              hasArrow
              label={intl.formatMessage({ id: "playlists" })}
              arrowSize={8}
              bg="#1f3f93"
              color="white"
            >
              <Link
                as={ReactRouterLink}
                to={PLAYLISTS_ROUTE}
                color="brand.900"
                textStyle="smallBold"
                // fontSize={28}
              >
                {/* <FormattedMessage id="playlists" /> */}
                <QueueMusicIcon style={{ fontSize: 28 }} />
              </Link>
            </Tooltip>
          </Box>
        ) : (
          <>
            {!user && (
              <Link
                as={ReactRouterLink}
                to={LOGIN_ROUTE}
                color="brand.900"
                textStyle="smallBold"
                px="3"
              >
                <FormattedMessage id="login" />
              </Link>
            )}
            <Link
              as={ReactRouterLink}
              to={FAQ_ROUTE}
              color="brand.900"
              textStyle="smallBold"
              px="3"
            >
              <FormattedMessage id="faq" />
            </Link>
            <Link
              as={ReactRouterLink}
              to={DESCRIPTION_ROUTE}
              color="brand.900"
              textStyle="smallBold"
              px="3"
              dir="ltr"
            >
              {intl.formatMessage({ id: "description" })}
            </Link>
          </>
        )}
      </HStack>
      <Box d="flex" flexDir="column" alignItems="center" margin="0 20px">
        <ReactRouterLink to="/portal">
          <Image
            loading="lazy"
            alt="SAL Logo"
            src="/logo-dark.png"
            me="auto"
            marginTop="10px"
            height="50px"
            display={["none", "none", "none", "flex"]}
          />
        </ReactRouterLink>
        {user?.subscriptionStatus === "approved" && (
          <Box d="flex" justifyContent="center" margin={"30px auto"}>
            <LightThanosBar />
          </Box>
        )}
      </Box>
      <HStack
        gridGap={["9"]}
        h="22px"
        mb={[".5rem", "auto", "auto", "auto"]}
        textAlign="end"
        justifyContent={["center"]}
        position={["static", "static", "static", "absolute"]}
        top="0"
        right={{ lg: "50%" }}
        transform={{ base: "translate(0, 10px)", lg: "translate(400px, 50px)" }}
      >
        {user?.subscriptionStatus === "approved" && (
          <>
            <Tooltip
              hasArrow
              label={intl.formatMessage({ id: "homebutton" })}
              arrowSize={8}
              bg="#1f3f93"
              color="white"
            >
              <Link
                transition="none"
                as={ReactRouterLink}
                to="/portal"
                _activeLink={{ color: "white" }}
                _focus={{ outline: "none", border: "none" }}
                _hover={{ textDecoration: "none" }}
                aria-current={isActive ? "page" : "false"}
              >
                <Image src={home} maxW="unset" color="#2895ff" />
              </Link>
            </Tooltip>
            <Tooltip
              hasArrow
              label={intl.formatMessage({ id: "settings" })}
              arrowSize={8}
              bg="#1f3f93"
              color="white"
            >
              <Link as={ReactRouterLink} to={SETTINGS_ROUTE}>
                {remainingDays && remainingDays <= 10 && (
                  <Box position="relative">
                    <Image src={settings} maxW="unset" />
                    <Box
                      position="absolute"
                      top="-5px"
                      right="-5px"
                      bg="red.500"
                      borderRadius="50%"
                      w="10px"
                      h="10px"
                    />
                  </Box>
                )}
                {(!remainingDays || remainingDays > 10) && (
                  <Image src={settings} maxW="unset" />
                )}
              </Link>
            </Tooltip>
          </>
        )}
        <Tooltip
          hasArrow
          label={intl.formatMessage({ id: "changelanguage" })}
          isDisabled={open}
          arrowSize={8}
          bg="#1f3f93"
          color="white"
        >
          <Link>
            <Popover
              onOpen={() => setOpen(true)}
              isOpen={open}
              onClose={() => setOpen(false)}
              isLazy
              variant="responsive"
            >
              <PopoverTrigger>
                <Image src={earth} maxW="unset" />
              </PopoverTrigger>
              <PopoverContent borderRadius="15px">
                <PopoverArrow />
                <PopoverBody
                  p="1"
                  color="gray.900"
                  fontSize="18px"
                  fontFamily="Calibri (Body)"
                >
                  <HStack justifyContent="center">
                    <Button
                      backgroundColor={locale === "ar" ? "#1f3f93" : ""}
                      textColor={locale === "ar" ? "white" : ""}
                      onClick={() => {
                        setOpen(false);
                        setLocale("ar");
                      }}
                    >
                      <FormattedMessage id="ar" />
                    </Button>
                    <Button
                      backgroundColor={locale === "en" ? "#1f3f93" : ""}
                      textColor={locale === "en" ? "white" : ""}
                      onClick={() => {
                        setOpen(false);
                        setLocale("en");
                      }}
                    >
                      <FormattedMessage id="en" />
                    </Button>
                  </HStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Link>
        </Tooltip>
        {user && (
          <Tooltip
            hasArrow
            label={
              user
                ? intl.formatMessage({ id: "logout" })
                : intl.formatMessage({ id: "login" })
            }
            arrowSize={8}
            bg="#1f3f93"
            color="white"
          >
            <Link as={ReactRouterLink} to={user ? LOGOUT_ROUTE : LOGIN_ROUTE}>
              <Image src={user ? logout : login} maxW="unset" />
            </Link>
          </Tooltip>
        )}
      </HStack>
    </Box>
  );
};
export default DarkHeader;
