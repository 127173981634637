import React from "react";
import { Box, BoxProps, Progress, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

const ProgressBar: React.FC<
  BoxProps & { magic?: boolean; step: number; setStep: (step: number) => void }
> = ({ step, setStep, magic, ...props }) => {
  return (
    <Box
      maxW={magic ? "444px" : "100%"}
      position="relative"
      px="32px"
      pb="29px"
      pt="10px"
      display={["none", "block"]}
      {...props}
    >
      <Progress
        mt={magic ? "3px" : "0"}
        size={magic ? "sm" : "md"}
        background={magic ? "#e6e6e6" : "#f9f9f9"}
        max={magic ? 120 : 100}
        value={magic ? (120 / 4) * step : (100 / 3) * step}
        colorScheme="brand"
      />
      <Box
        display="flex"
        top="0px"
        left="16px"
        position="absolute"
        justifyContent="space-between"
        h={"51px"}
        w={"calc(100% - 32px)"}
        // flex="1"
      >
        <Box>
          <Box
            backgroundColor="brand.800"
            h={"32px"}
            w={"32px"}
            borderRadius={"16px"}
            cursor={"pointer"}
            onClick={() => step > 0 && setStep(0)}
          >
            {magic && step >= 0 ? (
              <Text textAlign={"center"} color={"white"} pt={"2px"}>
                1
              </Text>
            ) : (
              ""
            )}
          </Box>
          {step >= 0 ? (
            <Text
              position="absolute"
              color="brand.800"
              fontFamily="Calibri (Body)"
              fontWeight="normal"
              fontSize="14px"
              lineHeight="46px"
            >
              {!magic && (
                <FormattedMessage
                  defaultMessage="عنوان المهمة"
                  id="missions.progressbar.name"
                />
              )}
            </Text>
          ) : null}
        </Box>
        <Box>
          <Box
            backgroundColor={
              step >= 1 ? "brand.800" : magic ? "#e6e6e6" : "gray.100"
            }
            h={"32px"}
            w={"32px"}
            borderRadius={"16px"}
            cursor={"pointer"}
            onClick={() => step > 1 && setStep(1)}
          >
            {magic && step >= 1 ? (
              <Text textAlign={"center"} color={"white"} pt={"2px"}>
                2
              </Text>
            ) : (
              ""
            )}
          </Box>
          {step >= 1 ? (
            <Text
              position="absolute"
              color="brand.800"
              fontWeight="normal"
              fontFamily="Calibri (Body)"
              fontSize="14px"
              lineHeight="46px"
            >
              {!magic && (
                <FormattedMessage
                  defaultMessage="الصفات"
                  id="missions.progressbar.target"
                />
              )}
            </Text>
          ) : null}
        </Box>
        <Box>
          <Box
            backgroundColor={
              step >= 2 ? "brand.800" : magic ? "#e6e6e6" : "gray.100"
            }
            h={"32px"}
            w={"32px"}
            borderRadius={"16px"}
            cursor={"pointer"}
            onClick={() => step > 2 && setStep(2)}
          >
            {magic && step >= 2 ? (
              <Text textAlign={"center"} color={"white"} pt={"2px"}>
                3
              </Text>
            ) : (
              ""
            )}
          </Box>
          {step >= 2 ? (
            <Text
              position="absolute"
              color="brand.800"
              fontWeight="normal"
              fontFamily="Calibri (Body)"
              fontSize="14px"
              lineHeight="46px"
            >
              {!magic && (
                <FormattedMessage
                  defaultMessage="الأهداف"
                  id="purposes.accomplish"
                />
              )}
            </Text>
          ) : null}
        </Box>
        <Box>
          <Box
            backgroundColor={
              step >= 3 ? "brand.800" : magic ? "#e6e6e6" : "gray.100"
            }
            h={"32px"}
            w={"32px"}
            borderRadius={"16px"}
            cursor={"pointer"}
            onClick={() => step > 3 && setStep(3)}
          >
            {magic && step >= 3 ? (
              <Text textAlign={"center"} color={"white"} pt={"2px"}>
                4
              </Text>
            ) : (
              ""
            )}
          </Box>
          {step >= 3 ? (
            <Text
              position="absolute"
              color="brand.800"
              fontWeight="normal"
              fontFamily="Calibri (Body)"
              fontSize="14px"
              lineHeight="46px"
            >
              {!magic && (
                <FormattedMessage defaultMessage="القيم" id="values" />
              )}
            </Text>
          ) : null}
        </Box>

        {magic ? (
          <Box>
            <Box
              backgroundColor={
                step >= 4 ? "brand.800" : magic ? "#e6e6e6" : "gray.100"
              }
              h={"32px"}
              w={"32px"}
              borderRadius={"16px"}
              cursor={"pointer"}
              onClick={() => step > 4 && setStep(4)}
            >
              {magic && step >= 4 ? (
                <Text textAlign={"center"} color={"white"} pt={"2px"}>
                  5
                </Text>
              ) : (
                ""
              )}
            </Box>
            {step >= 4 ? (
              <Text
                position="absolute"
                color="brand.800"
                fontWeight="normal"
                fontFamily="Calibri (Body)"
                fontSize="14px"
                lineHeight="46px"
              />
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
export default ProgressBar;
