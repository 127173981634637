import React, { useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Box,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import InputWithAdd from "src/components/InputWithAdd";
import Link from "src/components/Link/Link";
const EditObstacleModal = (props: any) => {
  const { open, closeModal, obstacle, submitModal, solution, onChangeProp } =
    props;
  useEffect(() => {}, [obstacle, solution]);
  return (
    <Modal isCentered isOpen={open} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent
        px={"10px"}
        maxWidth={"720px"}
        width={"100%"}
        borderRadius={"30px"}
      >
        <ModalCloseButton />
        <ModalBody>
          <Box textAlign={"center"} width={"100%"}>
            <Box p={"1rm"} width={"100%"}>
              <InputWithAdd
                id={"magicObstaclesInputModal"}
                onKeyDown={(event: any) => {
                  if (event.keyCode === 13) {
                    document.getElementById("inputMagicSolutionModal")?.focus();
                  }
                }}
                magic={true}
                value={obstacle}
                noAdd
                mt={"20px"}
                mb={"20px"}
                onChange={(e: any) => onChangeProp(e, "o")}
              />
              <InputWithAdd
                noAdd
                id={"inputMagicSolutionModal"}
                onClick={() => {}}
                mt={"20px"}
                mb={"20px"}
                value={solution}
                onChange={(e: any) => onChangeProp(e, "s")}
              />
            </Box>
            <Box width={"100%"} textAlign={"center"}>
              <Link mb={"33px"} onClick={() => submitModal()} asBtn={true}>
                <FormattedMessage id="edit" defaultMessage={"تعديل"} />
              </Link>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditObstacleModal;
