import React, { useState, useEffect } from "react";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Description } from "src/types";
import Waves from "./Waves/Waves";
import Solfeggio from "./Solfeggio/Solfeggio";
import Chakras from "./Chakras/Chakras";
import { WAVES_ROUTE, SOLFEGGIO_ROUTE, CHAKRAS_ROUTE } from "src/routes";
import Link from "src/components/Link/Link";
import { get, titleSize, descSize } from "src/api";
import { FormattedMessage, useIntl } from "react-intl";
import useLocale from "src/providers/useLocale";
import BaseView from "./BaseView";

const Frequency = () => {
  let locale = useLocale()[0];
  const [content, setContent] = useState("Loading ...");
  const intl = useIntl();
  useEffect(() => {
    get<Description>("/description/frequencies").then((obj) => {
      setContent(obj?.content || intl.formatMessage({ id: "failFetch" }));
    });
  }, [intl, locale]);
  return (
    <Box>
      <BaseView noBread />
      <VStack alignItems="start">
        <Box>
          <Text fontSize={titleSize} fontWeight="bold" color="#1f3f93">
            <FormattedMessage id="frequency" />
          </Text>
        </Box>
        <Box>
          <Text color="#8b8b8b" fontSize={descSize}>
            {content}
          </Text>
        </Box>
        <VStack w="100%" marginTop="30px !important">
          <HStack flexWrap="wrap" alignItems="center">
            <Link
              to={WAVES_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="waves" />
            </Link>
            <Link
              to={SOLFEGGIO_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="solfeggio" />
            </Link>
            <Link
              to={CHAKRAS_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="chakras" />
            </Link>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};

Frequency.Waves = Waves;
Frequency.Solfeggio = Solfeggio;
Frequency.Chakras = Chakras;

export default Frequency;
