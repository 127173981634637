import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  VStack,
  ModalOverlay,
  Checkbox,
  useDisclosure,
  Box,
  HStack,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import NewItemButton from "src/components/NewItemButton";
import InputWithAdd from "src/components/InputWithAdd";
import SubHeader from "src/components/SubHeader";
import Link from "src/components/Link/Link";
import DatePicker from "react-datepicker";
import * as mutation from "src/api/affirmation/problem/mutation";
import { useMutation, useQueryClient } from "react-query";

type goalList = {
  Goal?: string;
  Prevent?: string;
}[];

const CreateProblem = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [goal, setGoal] = useState<string>("");
  const [prevent, setPrevent] = useState<string>("");
  const [checkedNothing, setCheckedNothing] = useState<boolean>(false);
  const [goalList, setGoalList] = useState<goalList>([]);
  const [showPrevent, setShowPrevent] = useState<boolean>(false);
  const [problemName, setProblemName] = useState<string>("");
  const [showGoal, setShowGoal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const client = useQueryClient();

  useEffect(() => {
    //add required func
  }, [startDate, prevent]);

  //React-Query Mutation
  const { mutateAsync: createMutation } = useMutation(
    mutation.createProblemItem,
    {
      onSuccess: () => client.invalidateQueries("listProblem"),
    }
  );

  const handleKeyPress = (event: any) => {
    if (event.keyCode === 13 && !showGoal) {
      if (problemName !== "") {
        setShowGoal(true);
        return setTimeout(() => {
          var objDiv = document.getElementById("problemGoalInput");
          if (objDiv) objDiv.focus();
        }, 0);
      }
      return setShowGoal(false);
    }
    if (event.keyCode === 13 && !showPrevent) {
      if (goal !== "") {
        setShowPrevent(true);
        setTimeout(() => {
          var objDiv = document.getElementById("problemPreventInput");
          if (objDiv) objDiv.focus();
        }, 0);
        return;
      }
      return setShowPrevent(false);
    }
    if (
      showGoal &&
      showPrevent &&
      goal !== "" &&
      prevent !== "" &&
      event.keyCode === 13
    ) {
      setGoalList([...goalList, { Goal: goal, Prevent: prevent }]);
      setPrevent("");
      setGoal("");
      setTimeout(() => {
        var objDiv = document.getElementById("problemScrollDiv");
        document.getElementById("problemGoalInput")?.focus();
        if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
      }, 0);
      setShowPrevent(false);
    }
  };
  const modalClose = () => {
    setShowPrevent(false);
    setShowGoal(false);
    setStartDate(new Date());
    setPrevent("");
    setGoal("");
    setProblemName("");
    setGoalList([]);
    setCheckedNothing(false);
    onClose();
  };
  const submitProblem = () => {
    createMutation({
      name: problemName,
      goal: goalList[0].Goal,
      firstStep: goalList[goalList.length - 1].Goal,
      startDate: `${startDate.toISOString()}`,
    });
    modalClose();
  };
  return (
    <Box>
      <Modal isCentered isOpen={isOpen} onClose={modalClose}>
        <ModalOverlay />
        <ModalContent
          p={"10px"}
          height={"100%"}
          minH={"660px"}
          maxWidth={"720px"}
          width={"100%"}
          borderRadius={"30px"}
        >
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Box w={"100%"}>
                <SubHeader
                  fontSize={"24px"}
                  mt={"20px"}
                  width={"100%"}
                  textAlign={"left"}
                  formatId={"problem.query"}
                />
                <Box w={"100%"}>
                  <InputWithAdd
                    style={{ width: "90%" }}
                    pl={"10px"}
                    inputDisabled={showGoal}
                    backgroundColor={showGoal ? "#f9f9f9" : "transparent"}
                    onKeyDown={handleKeyPress}
                    value={problemName}
                    noAdd
                    mt={"-5px"}
                    mb={"5px"}
                    onChange={(e: any) => setProblemName(e.target.value)}
                  />
                </Box>
              </Box>
              <Box
                w={"100%"}
                id={"problemScrollDiv"}
                maxH={"380px"}
                overflowY={"auto"}
              >
                {goalList.length > 0 &&
                  goalList.map((e) => {
                    return (
                      <>
                        <Box w={"100%"}>
                          <SubHeader
                            fontSize={"24px"}
                            mt={"10px"}
                            width={"100%"}
                            textAlign={"left"}
                            formatId={"problem.option"}
                          />
                          <Box w={"100%"}>
                            <InputWithAdd
                              inputDisabled={true}
                              variant={"flushed"}
                              value={e.Goal}
                              backgroundColor={"#f9f9f9"}
                              style={{ width: "90%" }}
                              noAdd
                              mt={"-5px"}
                              mb={"20px"}
                              onChange={(e: any) => setGoal(e.target.value)}
                            />
                          </Box>
                        </Box>
                        <Box w={"100%"}>
                          <SubHeader
                            fontSize={"24px"}
                            mt={"10px"}
                            width={"100%"}
                            textAlign={"left"}
                            formatId={"problem.prevent"}
                          />
                          <Box w={"100%"}>
                            <InputWithAdd
                              inputDisabled={true}
                              variant={"flushed"}
                              style={{ width: "90%" }}
                              backgroundColor={"#f9f9f9"}
                              value={e.Prevent}
                              noAdd
                              mt={"-5px"}
                              mb={"20px"}
                              onChange={(e: any) => setPrevent(e.target.value)}
                            />
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                {showGoal && (
                  <Box w={"100%"}>
                    <SubHeader
                      fontSize={"24px"}
                      mt={"20px"}
                      width={"100%"}
                      textAlign={"left"}
                      formatId={"problem.option"}
                    />
                    <Box w={"100%"}>
                      <InputWithAdd
                        id={"problemGoalInput"}
                        inputDisabled={showPrevent}
                        value={goal}
                        onKeyDown={handleKeyPress}
                        backgroundColor={
                          showPrevent ? "#f9f9f9" : "transparent"
                        }
                        style={{ width: "90%" }}
                        noAdd
                        mt={"-5px"}
                        mb={"20px"}
                        onChange={(e: any) => setGoal(e.target.value)}
                      />
                    </Box>
                  </Box>
                )}
                {showPrevent && (
                  <Box w={"100%"}>
                    <HStack justify={"start"} align={"center"}>
                      <SubHeader
                        fontSize={"24px"}
                        textAlign={"left"}
                        formatId={"problem.prevent"}
                      />
                      {prevent === "" && (
                        <Checkbox
                          dir={"rtl"}
                          mx={"10px"}
                          isChecked={checkedNothing}
                          onChange={() => {
                            setCheckedNothing(!checkedNothing);
                            setGoalList([
                              ...goalList,
                              { Goal: goal, Prevent: "" },
                            ]);
                            setTimeout(() => {
                              var objDiv =
                                document.getElementById("problemScrollDiv");
                              if (objDiv)
                                objDiv.scrollTop = objDiv.scrollHeight;
                            }, 0);
                          }}
                          isDisabled={prevent !== ""}
                          colorScheme="blue"
                        >
                          {" "}
                          <FormattedMessage
                            id={"nothing"}
                            defaultMessage={"لا شيء"}
                          />
                        </Checkbox>
                      )}
                    </HStack>
                    <Box w={"100%"}>
                      <InputWithAdd
                        id={"problemPreventInput"}
                        inputDisabled={checkedNothing}
                        style={{ width: "90%" }}
                        onKeyDown={handleKeyPress}
                        value={prevent}
                        noAdd
                        mt={"-5px"}
                        mb={"20px"}
                        onChange={(e: any) => setPrevent(e.target.value)}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
              {checkedNothing && (
                <>
                  <Box
                    spacing={0}
                    borderBottom={"1px solid #c8c8c8"}
                    w={"100%"}
                  >
                    <SubHeader
                      fontSize={"24px"}
                      mt={"10px"}
                      width={"100%"}
                      textAlign={"left"}
                      formatId={"problem.starttime"}
                    />
                    <Box
                      pr={"6rem"}
                      fontFamily={"tajwal"}
                      color={"#1f3f93"}
                      fontSize={"24px"}
                      border={"1px Solid #c8c8c8"}
                      cursor={"pointer"}
                      w={"fit-contnet"}
                    >
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={(date: any) => {
                          setStartDate(date);
                        }}
                      />
                    </Box>
                  </Box>
                  <div>
                    <Link
                      disabled={prevent !== ""}
                      mb={"10px"}
                      onClick={submitProblem}
                      asBtn={true}
                    >
                      <FormattedMessage id="send" defaultMessage={"إرسال"} />
                    </Link>
                  </div>
                </>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <NewItemButton onClick={onOpen}>
        <FormattedMessage id="problem.create" defaultMessage="حل مشكلة جديدة" />
      </NewItemButton>
    </Box>
  );
};

export default CreateProblem;
