import React, { useState, useEffect, FormEvent } from 'react';
import { get, post } from 'src/api/index';
import { FormControl, Input, Select, VStack, Box, Text, Button } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import DarkHeader from 'src/layout/DarkHeader';
import { LOGIN_ROUTE } from 'src/routes';
import { Link } from 'react-router-dom';
import useLocale from 'src/providers/useLocale';
import { Label } from '@material-ui/icons';

type Country = {
  id: number;
  name: string;
}

type RegData = {
  id?: number;
  name?: string;
  phone?: string;
  countryId?: number;
  email?: string;
  password?: string;
  conPw?: string;
  error?: string;
  message?: string[];
  CC?: string;
}

const Register = () => {

  //tracking changes for inputs
  const [registerData, setRegisterData] = useState<RegData>({});

  //list of countries
  const [countries, setCountries] = useState<Array<Country>>([]);

  //validation errors
  const [errors, setErrors] = useState<string[]>([]);

  //success message
  const [success, setSuccess] = useState("");

  //current locale
  const [locale] = useLocale();

  //state
  const [processing, setProcessing] = useState(false);

  //method for fetching countries
  const fetchCountries = async () => {
    setCountries([{ id: 7, name: 'Egypt' }])
    const response = await get<Array<Country>>('/country', locale);
    if (Array.isArray(response)) setCountries(response);
  }

  //for formatting messages
  const { formatMessage } = useIntl();

  useEffect(() => {
    fetchCountries();
  }, [])

  //method for handling inputs
  const handleChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    if (target.name === 'phone' && target.value.length > 11) {
      target.value = target.value.slice(0, 11);
      return;
    }
    if (target.name === 'CC' && target.value.length > 3) {
      target.value = target.value.slice(0, 3);
      return;
    }
    if (target.name === 'countryId') setRegisterData({ ...registerData, [target.name]: +target.value });
    else setRegisterData({ ...registerData, [target.name]: target.value });
  }

  //handling submit
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (processing) return;
    setProcessing(true);
    if (!registerData.password) {
      setErrors([formatMessage({ id: 'passwordRequired' })]);
      document.querySelector('form')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setProcessing(false);
      return;
    }
    if ((registerData.conPw && registerData.password) && (registerData.conPw !== registerData.password)) {
      setErrors([formatMessage({ id: 'conPwMisMatch' })]);
      document.querySelector('form')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setProcessing(false);
      return;
    }
    const data = { ...registerData, phone: `+${registerData.CC}${registerData.phone}` };
    console.log(data)
    delete data.CC;
    delete data.conPw;
    post('/auth/register', data, locale)
      .then((res: any) => {
        setProcessing(false);
        if (res.id) {
          setSuccess('registerComplete');
          setErrors([]);
        }
        else {
          setErrors([formatMessage({ id: 'wentWrong' })]);
          document.querySelector('form')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (res.message) {
          if (Array.isArray(res.message)) {
            setErrors(res.message);
          }
          else {
            setErrors([res.message]);
          }
        }
        else {
          setErrors([formatMessage({ id: 'wentWrong' })]);
        }
      })
      .catch(err => {
        setProcessing(false);
        console.log(err);
      })
  }

  return (
    <>
      <DarkHeader />
      <VStack width='100%' padding='50px 0' display='flex' flexDir='column' alignItems='center'>
        <Box margin="40px 0">
          <Text fontWeight="bold" fontSize="42px" color='brand.900'>
            {formatMessage({ id: 'register' })}
          </Text>
        </Box>
        {
          success ?
            <Box color='green' padding="50px 0" textAlign='center'>
              <Text>{formatMessage({ id: success })}</Text>
            </Box>
            :
            <FormControl boxShadow='0 0 10px 2px silver' padding={{ base: "20px", xl: "30px 80px" }} borderRadius="15px" maxWidth={{ base: "90%", md: "700px" }} onSubmit={handleSubmit} as='form'>
              {
                errors.length > 0 &&
                <Box display='flex' flexDir='column' alignItems='flex-start' color='crimson'>
                  {
                    errors.map((err, idx) => (
                      <Text margin="5px 0" key={idx}>-{err}</Text>
                    ))
                  }
                </Box>
              }
              <Box margin="30px 0">
                <Input type='text' placeholder={formatMessage({ id: 'name' })} name="name" padding="25px 20px" onChange={handleChange} />
              </Box>
              <Box margin="30px 0">
                <Input type='email' placeholder={formatMessage({ id: 'email' })} name="email" padding="25px 20px" onChange={handleChange} />
              </Box>
              <Box margin="30px 0" display='flex' justifyContent='space-between'>
                <Box d="flex" alignItems="center" width={{ base: "25%", sm: "15%" }}>
                  <Text fontSize={'28px'} color='gray.500'>+</Text>
                  <Input textAlign='center' margin="0 5px" type='number' flexGrow={1} name="CC" padding="25px 10px" onChange={handleChange} />
                </Box>
                <Input width={{ base: "75%", sm: "85%" }} type='number' placeholder={formatMessage({ id: 'phone' })} name="phone" padding="25px 20px" onChange={handleChange} />
              </Box>
              <Box margin="30px 0">
                <Select type='text' name="countryId" height="50px" onChange={handleChange}>
                  <optgroup defaultValue={-1}>
                    <option value={-1}>{formatMessage({ id: 'country' })}</option>
                    {
                      countries.map(country => (
                        <option key={country.id} value={country.id}>{country.name}</option>
                      ))
                    }
                  </optgroup>
                </Select>
              </Box>
              <Box margin="30px 0">
                <Input type='password' placeholder={formatMessage({ id: 'password' })} name="password" padding="25px 20px" onChange={handleChange} />
              </Box>
              <Box margin="30px 0">
                <Input type='password' placeholder={formatMessage({ id: 'confirmPassword' })} name="conPw" padding="25px 20px" onChange={handleChange} />
              </Box>
              <Box margin="60px 0 30px 0" textAlign='center'>
                <Button height="50px" minWidth='80px' type='submit' background='brand.900' color='white' _hover={{ filter: 'brightness(120%)' }} disabled={processing}>{formatMessage({ id: 'submit' })}</Button>
              </Box>
              <Box margin="40px 0 30px 0" textAlign='start' fontSize="14px" color='#6fbff7' _hover={{ textDecoration: 'underline' }}>
                <Link to={LOGIN_ROUTE}>{formatMessage({ id: 'needLogin' })}</Link>
              </Box>
            </FormControl>
        }
      </VStack>
    </>
  )
}

export default Register;