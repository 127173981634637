import WaveSurfer from "wavesurfer.js";

class WaveSurferCache {
  private static instance: WaveSurferCache;
  private cache: Map<string, WaveSurfer> = new Map<string, WaveSurfer>();

  private constructor() {}

  public static getInstance(): WaveSurferCache {
    if (!WaveSurferCache.instance) {
      WaveSurferCache.instance = new WaveSurferCache();
    }
    return WaveSurferCache.instance;
  }

  public get(url: string): WaveSurfer | undefined {
    return this.cache.get(url);
  }

  public set(url: string, waveSurfer: WaveSurfer): void {
    this.cache.set(url, waveSurfer);
  }
}

export default WaveSurferCache;
