import { UseMutateAsyncFunction } from "react-query";

type UseCreatePurposeParams = {
  openEditModal: (id: number) => void;
  dismissCreateModal: () => void;
  createMutation: UseMutateAsyncFunction<
    { id: number },
    unknown,
    string,
    unknown
  >;
};

type ReturnUseCreatePurpose = {
  createHandler: (name: string) => Promise<void>;
};

export const useCreatePurpose: (
  params: UseCreatePurposeParams
) => ReturnUseCreatePurpose = ({
  openEditModal,
  dismissCreateModal,
  createMutation,
}) => {
  const createHandler = async (name: string) => {
    if (!name) throw new Error("Name must exist");
    const { id } = await createMutation(name);
    dismissCreateModal();
    openEditModal(id);
  };
  return { createHandler };
};
