import React, { CSSProperties, useReducer, useRef } from "react";
import ReactPlayer from "react-player";
import {
  Button,
  Grid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
} from "@chakra-ui/react";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
// @ts-ignore
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import getReadableTime from "src/helpers/getReadableTime";

const IndepMediaPlayer = ({
  src,
  loop = false,
  disableSeeking = false,
  muted = false,
  styles = {},
  noControls = false,
  onEnded,
  onPlay,
  onPause,
  autoPlay,
}: {
  src: string;
  loop?: boolean;
  disableSeeking?: boolean;
  muted?: boolean;
  styles?: CSSProperties;
  noControls?: boolean;
  onEnded?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  autoPlay?: boolean;
}) => {
  const [isPlay, togglePlay] = useReducer(
    (s, next) => (next == null ? !s : next),
    autoPlay ?? false
  );
  const [volume, setVolume] = React.useState(muted ? 0 : 1);
  const type: "audio" | "video" =
    src.endsWith(".mp3") || src.endsWith(".ogg") ? "audio" : "video";

  const ref = useRef<ReactPlayer>(null);
  const handle = useFullScreenHandle();
  const [progress, setProgress] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  return (
    <div style={styles} dir="ltr">
      <FullScreen handle={handle}>
        <ReactPlayer
          onPlay={() => {
            if (!isPlay) togglePlay(true);
            onPlay && onPlay();
          }}
          onPause={() => {
            if (isPlay) togglePlay(false);
            onPause && onPause();
          }}
          onDuration={(duration) => setDuration(duration)}
          onProgress={(progress) => setProgress(progress.playedSeconds)}
          height={
            handle.active && noControls
              ? "100%"
              : handle.active
              ? "90%"
              : "360px"
          }
          width={handle.active ? "100%" : "640px"}
          ref={ref}
          style={{
            height: "100%",
            width: "100%",
            minWidth: "200px",
            minHeight: "100px",
            maxWidth: "100%",
            maxHeight: type === "audio" ? "80px" : "100%",
            display: type === "audio" ? "none" : "block",
            margin: "auto",
            pointerEvents: disableSeeking ? "none" : "auto",
          }}
          volume={volume}
          url={`${src}${type === "video" ? `?loop=${loop ? 1 : 0}` : ""}`}
          loop={loop}
          playing={isPlay}
          controls={false}
          onEnded={() => {
            onEnded && onEnded();
            togglePlay(false);
            setTimeout(() => {
              setProgress(duration);
            }, 1000);
          }}
        />
        {noControls ? null : (
          <Grid
            marginTop="15px"
            width={"100%"}
            sx={{ gap: "10px" }}
            height={handle.active ? "10%" : "auto"}
            alignItems="center"
            justifyItems="center"
            justifyContent={"center"}
            templateColumns={[
              "50px 9ch auto",
              null,
              "50px 9ch minmax(50px,150px) 50px minmax(20px,100px) 50px",
            ]}
          >
            <Button onClick={() => togglePlay(!isPlay)}>
              {isPlay ? <PauseIcon /> : <PlayArrowIcon />}
            </Button>
            <Text color={handle.active ? "white" : "black"}>
              {getReadableTime(progress)} / {getReadableTime(duration)}
            </Text>
            <Slider
              aria-label="audio"
              defaultValue={100}
              value={Math.round((progress / duration) * 100)}
              pointerEvents={disableSeeking ? "none" : "auto"}
              onChange={(e) => {
                ref.current?.seekTo(e / 100);
                setProgress((e / 100) * duration);
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb
                backgroundColor={handle.active ? "white" : "red"}
              />
            </Slider>
            {/* <Button
              color={handle.active ? "white" : "black"}
              variant="link"
              onClick={() => {
                if (volume === 0) {
                  setVolume(1);
                } else {
                  setVolume(0);
                }
              }}
            >
              {volume > 0 ? <VolumeUpIcon /> : <VolumeOffIcon />}
            </Button> */}
            <Slider
              aria-label="audio"
              defaultValue={100}
              value={volume * 100}
              onChange={(n) => setVolume(n / 100)}
              marginEnd="10px"
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb
                backgroundColor={handle.active ? "white" : "black"}
              />
            </Slider>
            {type === "video" ? (
              <div>
                <Button
                  alignSelf="start"
                  onClick={() => {
                    if (handle.active) {
                      handle.exit().catch(() => null);
                    } else {
                      handle.enter().catch(() => null);
                    }
                  }}
                >
                  {handle.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </Button>
              </div>
            ) : null}
          </Grid>
        )}
      </FullScreen>
    </div>
  );
};
export default IndepMediaPlayer;
