/* eslint-disable eqeqeq, react-hooks/exhaustive-deps */

import { Box, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import InputWithAdd from "src/components/InputWithAdd";
import PrimaryButton from "src/components/PrimaryButton";
import SecondaryButton from "src/components/SecondaryButton";
import { Aspect } from "src/types";
import PurposeAspectModal from "./PurposeAspectsModal";

export type StepOneProps = {
  nextStep?: (step?: number) => Promise<void>;
  id?: number;
  addTarget: (name: string) => Promise<void>;
  addAspectToPurpose: (aspect: Aspect) => Promise<void>;
  editTarget: (selectedTarget: any, name: string) => void;
};

const StepOne: React.FC<
  StepOneProps & {
    aspects: Aspect[];
    setEditModalVisiblity: (aspectModalOpen: boolean) => void;
    nextLocalStep: () => void;
    closeModal: () => void;
  }
> = ({
  nextStep,
  aspects,
  id,
  addTarget,
  addAspectToPurpose,
  setEditModalVisiblity,
  nextLocalStep,
  editTarget,
  closeModal,
}) => {
  const [submittingTarget, setSubmittingTarget] = useState(false);
  const [tempList, setTempList] = useState<any>([]);
  const [tempTarget, setTempTarget] = useState("");
  const aspectsModalDiscslosure = useDisclosure();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const initialAspect = aspects.length > 0 ? aspects[aspects.length - 1] : null;
  const [aspect, setAspect] = useState(initialAspect);
  useEffect(() => {
    setEditModalVisiblity(aspectsModalDiscslosure.isOpen);
  }, [
    aspectsModalDiscslosure.isOpen,
    aspects,
    setEditModalVisiblity,
    tempTarget,
  ]);

  useEffect(() => {
    aspectsModalDiscslosure.onOpen();
  }, []);



  const addBtnClick = async () => {
    if (!tempTarget || !aspect || submittingTarget) return;

    setSubmittingTarget(true);
    // await addTarget(tempTarget);
    setTempList([...tempList, tempTarget]);
    setTempTarget("");
    setSubmittingTarget(false);
  };
  const EditTargetLocal = (index: number, value?: string) => {
    if (!aspect || submittingTarget) return;
    let localTempList = tempList;
    localTempList.splice(index, 1, value);
    setTempList(localTempList);
  };

  //function to handle submitting aspects
  const submitAspect = (close: boolean) => {
    tempList.map(async (e: any) => {
      await addTarget(e);
    });
    setTempList([]);
    if (close) {
      nextStep && nextStep(0);
      closeModal();
    } else {
      nextStep && nextStep(1)
      .then((data)=>{
        aspectsModalDiscslosure.onClose();
      })
      //This lets the function execute after the aspects modal is closed
      // setTimeout(() => {
      //   // nextLocalStep();
      // }, 0);
    }
  };

  const scrollableBox = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!submittingTarget)
      scrollableBox.current!.scrollTop =
        scrollableBox.current!.scrollHeight -
        scrollableBox.current!.offsetHeight;
  }, [submittingTarget, tempTarget, aspect?.targets]);

  return (
    <>
      <Box display={aspectsModalDiscslosure.isOpen ? "none" : "unset"}>
        <Box>
          <Text
            d="inline-block"
            color="brand.900"
            fontSize="18px"
            fontWeight="bold"
            fontFamily="Calibri (Body)"
          >
            <FormattedMessage
              id="purpose.create.aspect"
              defaultMessage="الجانب"
            />
          </Text>{" "}
          <Text
            d="inline-block"
            color="gray.900"
            fontSize="16px"
            fontWeight="500"
            fontFamily="Calibri (Body)"
          >
            <FormattedMessage
              id="purpose.create.at_least"
              defaultMessage="(على الأقل)"
            />
            {`${aspects.length}/3`}
          </Text>
        </Box>

        <Text
          fontSize="30px"
          fontWeight="bold"
          fontFamily="Calibri (Body)"
          color="brand.900"
          textTransform="capitalize"
        >
          {aspect?.name}
        </Text>

        <hr style={{ marginBottom: "15px" }} />

        <Box maxHeight={"200px"} overflowY={"auto"} ref={scrollableBox}>
          {tempList.map((target: any, index: any, arr: any) => {
            return (
              <Box key={index} margin={"10px 0"} padding={"0 20px"}>
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  color="brand.900"
                  fontFamily="Calibri (Body)"
                >
                  <FormattedMessage
                    id={
                      index >= 1
                        ? "purposes.create_target_message_dynamic"
                        : "purposes.create.target_message"
                    }
                    defaultMessage={
                      index >= 1
                        ? `إستشعر أنك حققت ${
                            arr[index - 1]
                          } بالدرجة التي تتمنى وتريد، ما الذي يحققه لك ذلك حتى أكثر أهمية؟`
                        : "أدخل حصيلة تود تحقيقها خلال الأيام القادمة فى هذا الجانب"
                    }
                    values={index >= 1 ? { asc: arr[index - 1] } : {}}
                  />
                </Text>
                <InputWithAdd
                  onKeyDown={(event: any) => {
                    if (event.which == 13 || event.keyCode == 13) {
                      EditTargetLocal(
                        index,
                        (
                          document.getElementsByName(
                            `${target}/${index}`
                          )[0] as HTMLInputElement
                        ).value
                      );
                    }
                  }}
                  DevRef={inputRef}
                  id={`input${index}`}
                  placeholder={tempList[index]}
                  name={`${target}/${index}`}
                  background={target == tempTarget ? "transparent" : "gray.100"}
                  disabled={
                    document.getElementById(`input${index}`) !==
                    document.activeElement
                  }
                  inputDisabled={!aspect || target != tempTarget}
                  onClick={() => {
                    EditTargetLocal(
                      index,
                      (
                        document.getElementsByName(
                          `${target}/${index}`
                        )[0] as HTMLInputElement
                      ).value
                    );
                  }}
                />
              </Box>
            );
          })}

          {aspects?.length !== 0 && (
            <Box margin={"10px 0"} padding={"0 20px"}>
              <Text
                fontSize="18px"
                fontWeight="bold"
                color="brand.900"
                fontFamily="Calibri (Body)"
              >
                <FormattedMessage
                  id={
                    aspect && tempList.length > 0
                      ? "purposes.create_target_message_dynamic"
                      : "purposes.create.target_message"
                  }
                  defaultMessage={
                    tempList.length > 0
                      ? `إستشعر أنك حققت ${
                          tempList[tempList.length - 1]
                        } بالدرجة التي تتمنى وتريد، ما الذي يحققه لك ذلك حتى أكثر أهمية؟`
                      : "أدخل حصيلة تود تحقيقها خلال الأيام القادمة فى هذا الجانب"
                  }
                  values={
                    aspect && tempList.length > 0
                      ? { asc: tempList[tempList.length - 1] }
                      : {}
                  }
                />
              </Text>
              <InputWithAdd
                id={`inputFirst${tempList.length}`}
                onKeyDown={(event: any) => {
                  if (event.which == 13 || event.keyCode == 13) {
                    addBtnClick();
                  }
                }}
                value={tempTarget}
                onChange={(e: any) => setTempTarget(e.target.value)}
                disabled={!aspect || !tempTarget}
                inputDisabled={!aspect}
                onClick={addBtnClick}
              />
            </Box>
          )}
        </Box>
        <Box mt={5} d="flex" justifyContent="center">
          <Box d="inline-block">
            <Box>
              <PrimaryButton
                me={3}
                isDisabled={
                  (aspects.length !== 0 && tempList.length < 4) ||
                  submittingTarget
                }
                onClick={async () => {
                  setSubmittingTarget(true);
                  for (let i = 0; i < tempList.length; i++) {
                    await addTarget(tempList[i]);
                  }
                  setSubmittingTarget(false);
                  setTempList([]);
                  aspectsModalDiscslosure.onOpen();
                  if (!tempTarget || !aspect || submittingTarget) return;
                  setTempTarget("");
                }}
              >
                <FormattedMessage
                  id="finishedAspect"
                  defaultMessage="إضافة جانب"
                />
              </PrimaryButton>
              <SecondaryButton
                borderRadius="5px"
                h="40px"
                w="168px"
                margin="10px 0"
                onClick={() => submitAspect(true)}
              >
                <FormattedMessage
                  id="purposes.create.later"
                  defaultMessage="استكمال لاحقا"
                />
              </SecondaryButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <PurposeAspectModal
        id={id!}
        disclousureProps={aspectsModalDiscslosure}
        addAspectToPurpose={addAspectToPurpose}
        btnDisabled={aspects.length < 3}
        submitAspect={() => submitAspect(false)}
        setCurrent={setAspect}
      />
    </>
  );
};
export default StepOne;
