import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addToPlaylist,
  create,
  deletePlaylist,
  edit,
  deleteTrack,
} from "src/api/prepration/playlists/mutation";

import { getList, getOne } from "src/api/prepration/playlists/query";

const usePlaylists = (playlistId?: number) => {
  const queryClient = useQueryClient();

  const playlistsQuery = useQuery(...getList);

  const playlistQuery = useQuery(...getOne(playlistId));

  const { mutateAsync: createMutation } = useMutation(create, {
    onSuccess: () => queryClient.invalidateQueries(getList[0]),
  });

  const { mutateAsync: editMutation } = useMutation(edit, {
    onSuccess: () => queryClient.invalidateQueries(getList[0]),
  });

  const { mutateAsync: addToPlaylistMutation } = useMutation(addToPlaylist, {
    onSuccess: () => queryClient.invalidateQueries(getList[0]),
  });

  const { mutateAsync: deleteMutation } = useMutation(deletePlaylist, {
    onSuccess: () => queryClient.invalidateQueries(getList[0]),
  });

  const { mutateAsync: deleteTrackMutation } = useMutation(deleteTrack, {
    onSuccess: () => queryClient.invalidateQueries(getList[0]),
  });

  return {
    playlistsQuery,
    playlistQuery,
    createMutation,
    editMutation,
    deleteMutation,
    addToPlaylistMutation,
    deleteTrackMutation,
  };
};

export default usePlaylists;
