import {
  LANDING_PAGE_ROUTE,
  LOGIN_ROUTE,
  SUBSCRIPTION_ROUTE,
} from "src/routes";
import {
  BASE_URL,
  BASE_MEDIA as baseMedia,
  MEDIA_URL as mediaUrl,
} from "../configs/config";

// ===============================================

export const BASE_MEDIA = baseMedia;
export const MEDIA_URL = mediaUrl;

// ===============================================

const currentOrigin = window.location.origin;

export const get = <T>(
  endpoint: string,
  locale: any = localStorage.getItem("locale"),
  authToken = ""
): Promise<T> =>
  fetch(BASE_URL + endpoint, {
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("__sal_auth")}`,
      "x-localization": locale,
      origin: currentOrigin,
    },
  }).then(handleRes);

// ===============================================

export const post = <T>(
  endpoint: string,
  body: T,
  locale: any = localStorage.getItem("locale"),
  authToken = ""
): Promise<T> =>
  fetch(BASE_URL + endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${window.localStorage.getItem("__sal_auth")}`,
      "x-localization": locale,
      origin: currentOrigin,
    },

    body: JSON.stringify(body),
  }).then(handleRes);

// ===============================================

export const put = <T>(
  endpoint: string,
  body: T,
  locale: any = localStorage.getItem("locale"),
  authToken = ""
): Promise<any & T> =>
  fetch(BASE_URL + endpoint, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${window.localStorage.getItem("__sal_auth")}`,
      "x-localization": locale,
      origin: currentOrigin,
    },
    body: JSON.stringify(body),
  }).then(handleRes);

// ===============================================

export const deleteMethod = (
  endpoint: string,
  locale: any = localStorage.getItem("locale"),
  authToken = ""
): Promise<void> =>
  fetch(BASE_URL + endpoint, {
    method: "delete",
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("__sal_auth")}`,
      "x-localization": locale,
      origin: currentOrigin,
    },
  }).then(handleRes);

// ===============================================

const handleRes = async (res: Response) => {
  if ([500, 503].includes(res.status)) return undefined;

  // if unauthorized user (error 401 ) redirect the user to the login page
  // if (res.status === 401) { }

  //  if unauthorized user (error 403 ) redirect the user to the subscription page if cause is subscription
  if (res.status === 403) {
    const { cause } = await res.json();
    if (cause === "subscription") {
      if (window.location.pathname !== SUBSCRIPTION_ROUTE) {
        window.location.replace(SUBSCRIPTION_ROUTE);
      }
    }
  }

  try {
    const jsonRes = await res.json();
    if (jsonRes.affected) {
      return Promise.resolve({ success: true });
    }
    return jsonRes;
  } catch {}
};

// ===============================================

export const uploadFile = (
  file: File,
  progCallback: (prog: number) => void,
  successCallback: (url: string) => void,
  failureCallback?: () => void,
  authToken = ""
) => {
  //data to send with the request
  const data = new FormData();
  data.append("file", file);
  const xhr = new XMLHttpRequest();
  xhr.open("POST", `${MEDIA_URL}/media`);
  xhr.setRequestHeader(
    "Authorization",
    `Bearer ${window.localStorage.getItem("__sal_auth")}`
  );
  xhr.upload.onprogress = (e: ProgressEvent) => {
    progCallback((e.loaded / e.total) * 100);
  };
  xhr.onload = (e: any) => {
    const target = e.target;
    if (target.status === 201) successCallback(JSON.parse(target.response).url);
    progCallback(0);
  };
  xhr.onerror = () => failureCallback && failureCallback();
  xhr.send(data);
};

// ===============================================

//function to email
export const emailMePlan = async (planId, filters = {}) => {
  return post(`/plan/${planId}/email`, filters);
};

// ===============================================

//function to email
export const emailMe = async (body: string, subject: string) => {
  return await post("/email/me", { body, subject });
};

// ===============================================

//stop seeking for a video
export const DisableSeeking = (
  media: HTMLMediaElement,
  isAudio: boolean = false,
  loop = true
) => {
  let supposedCurrentTime = 0;

  media?.addEventListener("timeupdate", function () {
    //Set supposed time to times that is not being seeked only
    if (!media.seeking) {
      supposedCurrentTime = media.currentTime;
    }
  });

  // prevent user from seeking
  media?.addEventListener("seeking", function () {
    const delta = media.currentTime - supposedCurrentTime;
    if (Math.abs(delta) > 0.01) {
      media.currentTime = supposedCurrentTime;
    }
  });

  media?.addEventListener("ended", function () {
    if (loop) {
      // reset state in order to allow for video replay
      supposedCurrentTime = 0;
      media.currentTime = supposedCurrentTime;
      // inside Play
      media.play();
    }
  });
};

// ===============================================

//Being changed a lot, just keep it here
export const titleSize = 28;
export const descSize = 18;
