import React, { useState, ChangeEvent, FormEvent } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  UseDisclosureReturn,
  Input,
  FormControl,
  Box,
  useToast,
  Button,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { put } from "src/api/index";
import useLocale from "src/providers/useLocale";
import { UserType as userType } from "src/types";

type PasswordChangeProps = {
  disclosures: UseDisclosureReturn;
  user: userType | undefined;
};
const PasswordChange: React.FC<PasswordChangeProps> = ({
  disclosures,
  user,
}) => {
  //passwords state
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  //current locale
  const [locale] = useLocale();

  //formatting message
  const { formatMessage } = useIntl();

  //toasting error and success messages
  const toast = useToast();

  //function to handle change
  const handleChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setData({ ...data, [target.name]: target.value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (
      data.newPassword.length < 6 ||
      data.confirmPassword.length !== data.newPassword.length ||
      !data.oldPassword
    )
      return;
    const response = await put(
      "/user/me/password",
      data,
      locale,
      user.accessToken
    );
    if (response?.id) {
      toast({
        title: response?.message || formatMessage({ id: "saved" }),
        status: "success",
        duration: 4000,
      });
      disclosures.onClose();
      setData({ newPassword: "", oldPassword: "", confirmPassword: "" });
    } else {
      toast({
        title: response?.message || formatMessage({ id: "wentWrong" }),
        status: "error",
        duration: 4000,
      });
    }
  };
  if (!user) return null;
  return (
    <Modal
      {...disclosures}
      onClose={disclosures.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent width="90%" maxW="650px">
        <ModalHeader padding="20px">
          <ModalCloseButton onClick={disclosures.onClose} />
        </ModalHeader>
        <ModalBody padding="30px">
          <FormControl as="form" onSubmit={handleSubmit}>
            <Box my="15px">
              <Input
                name="oldPassword"
                value={data.oldPassword}
                type="password"
                onChange={handleChange}
                isInvalid={!data.oldPassword}
                placeholder={formatMessage({ id: "oldPassword" })}
              />
            </Box>
            <Box my="15px">
              <Input
                name="newPassword"
                value={data.newPassword}
                type="password"
                onChange={handleChange}
                isInvalid={data.newPassword.length < 6}
                placeholder={formatMessage({ id: "newPassword" })}
              />
            </Box>
            <Box my="15px">
              <Input
                name="confirmPassword"
                value={data.confirmPassword}
                type="password"
                onChange={handleChange}
                isInvalid={
                  data.confirmPassword.length !== data.newPassword.length
                }
                placeholder={formatMessage({ id: "confirmPassword" })}
              />
            </Box>
            <Box textAlign="center" my="15px">
              <Button colorScheme="messenger" type="submit" minWidth="80px">
                {formatMessage({ id: "save" })}
              </Button>
            </Box>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PasswordChange;
