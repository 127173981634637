/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Text } from "@chakra-ui/react";
import ItemCard from "src/components/ItemCard";
import { get } from "src/api/index";
import { Value } from "src/types";
import { useSelector } from "src/store";

export type StepThreeProps = {
  selectMatrix: (matrixId: number) => Promise<void>;
  completePurpose: () => void;
};
const StepThree: React.FC<
  StepThreeProps & {
    finishPurpose: () => void;
  }
> = ({ selectMatrix, finishPurpose, completePurpose }) => {
  const [valuesListsList, setValuesListsList] = useState<Value[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  //current user
  const user = useSelector((state) => state.auth.user);

  const getMessage = async () => {
    const response = await get("/value", undefined, user?.accessToken);
    setLoading(false);
    if (Array.isArray(response)) setValuesListsList(response);
    else setError("Error");
  };
  useEffect(() => {
    getMessage();
  }, []);

  return (
    <>
      <Text
        d="inline-block"
        color="brand.900"
        fontSize="18px"
        fontWeight="bold"
        fontFamily="Calibri (Body)"
      >
        <FormattedMessage
          id="purpose.create.step3_title"
          defaultMessage="حدد مصفوفة القيم"
        />
      </Text>
      <Box my={5}>
        {isLoading || error ? (
          <Box padding={"50px 20px"} color="grey">
            {isLoading ? (
              <FormattedMessage id="loading" defaultMessage="جارى التحميل" />
            ) : (
              error
            )}
          </Box>
        ) : (
          valuesListsList!.map((item, idx) => (
            <ItemCard
              cursor="pointer"
              mb={4}
              onClick={() => {
                selectMatrix(item.id)
                completePurpose();
                finishPurpose();
              }}
              key={idx}
            >
              {item.name}
            </ItemCard>
          ))
        )}
      </Box>
    </>
  );
};
export default StepThree;
