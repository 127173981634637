import {
  QueryFunction,
  UseQueryOptions,
  QueryFunctionContext,
} from "react-query";
import { get } from "src/api";
import { Plan } from "src/types";

const getList: [string, QueryFunction<Plan[]>] = [
  "listPlans",
  () => get<Plan[]>("/plan"),
];

const getSharedList: [string, QueryFunction<any[]>] = [
  "listSharedPlans",
  () => get<Plan[]>("/shared/?entityName=salplan"),
];

const getPlanGroups = () => {
  return get<any[]>("/entity-groups/all");
};

const plan = (id: number | undefined) =>
  [
    ["plan", id],
    () => get<Plan>(`/plan/${id}`),
    {
      enabled: !!id,
      // refetchInterval:60000
    },
  ] as [[string, number], QueryFunction<Plan>, UseQueryOptions<Plan, Error>];

const getPlanGroup = (id: number) => {
  return get<{ plans: Plan[] }>(`/plan/group/${id}`);
};

export { getList, plan, getSharedList, getPlanGroups, getPlanGroup };
