/* eslint-disable eqeqeq */

import React from "react";
import { UseMutateAsyncFunction } from "react-query";
import { Aspect, Purpose } from "src/types";
import { StepFourProps } from "./StepFour";
import { StepOneProps } from "./StepOne";
import { StepThreeProps } from "./StepThree";
import { StepTwoProps } from "./StepTwo";

type UseEditPurposeProps = {
  id: number | undefined;
  editMutation: UseMutateAsyncFunction<
    Partial<Purpose>,
    unknown,
    Partial<Purpose>,
    unknown
  >;
  currentPurpose: Purpose | undefined;
  addAspectMutation: UseMutateAsyncFunction<
    void,
    unknown,
    {
      name: string;
      messageId: number;
    },
    unknown
  >;
  addTargetMutation: UseMutateAsyncFunction<
    void,
    unknown,
    {
      name: string;
      order: number;
      aspectId: number;
    },
    unknown
  >;
  selectTargetMutation: UseMutateAsyncFunction<
    void,
    unknown,
    {
      targetId: number;
    },
    unknown
  >;
  selectValuesMatrix: UseMutateAsyncFunction<
    void,
    unknown,
    {
      id: number;
      matrixId: number;
    },
    unknown
  >;
  saveNote: (note: string) => Promise<Partial<Purpose>>;
};

type ReturnUseEditPurpose = {
  step1Props: StepOneProps;
  step2Props: StepTwoProps;
  step3Props: StepThreeProps;
  step4Props: StepFourProps;
};

export const useEditPurpose: (
  props: UseEditPurposeProps
) => ReturnUseEditPurpose = ({
  id,
  editMutation,
  currentPurpose,
  addTargetMutation,
  addAspectMutation,
  selectTargetMutation,
  selectValuesMatrix,
  saveNote,
}) => {
  const addTarget = async (name: string) => {
    if (!currentPurpose)
      throw new Error(
        "Current purpose must be there when calling this function"
      );
    const aspectToAddTargetTo =
      currentPurpose.selectedAspects[currentPurpose.selectedAspects.length - 1];
    await addTargetMutation({
      aspectId: aspectToAddTargetTo.id,
      order: aspectToAddTargetTo.targets?.length || 0,
      name: name,
    });
  };
  const editTarget = async (selectedTarget: any, name: string) => {
    let dummyPurpose: any = [];
    if (currentPurpose) {
      const aspectToAddTargetTo: Aspect =
        currentPurpose.selectedAspects[
          currentPurpose.selectedAspects.length - 1
        ];
      if (aspectToAddTargetTo.targets) {
        // eslint-disable-next-line array-callback-return
        aspectToAddTargetTo.targets.map((target: any, index: number) => {
          if (selectedTarget.id == target.id) {
            dummyPurpose = aspectToAddTargetTo.targets?.splice(index, 1, {
              ...selectedTarget,
              name: name,
            });
          }
        });
      }
      await editMutation({
        ...currentPurpose,
        aspects: [
          ...currentPurpose.aspects,
          { ...aspectToAddTargetTo, targets: dummyPurpose },
        ],
      });
    }
  };

  const addAspectToPurpose = async (aspect: Aspect) => {
    if (!currentPurpose)
      throw new Error(
        "Current purpose must be there when calling this function"
      );
    await editMutation({
      ...currentPurpose,
      aspects: [...currentPurpose.aspects, { ...aspect, targets: [] }],
    });
  };
  const nextStep = async (step?: number) => {
    if (!currentPurpose) return;
    if (step !== undefined) {
      await editMutation({
        ...currentPurpose,
        step,
      });
    } else {
      await editMutation({
        ...currentPurpose,
        step: currentPurpose.step + 1,
      });
    }
  };
  const setPurposeStep = async (step: number) => {
    if (!currentPurpose) return;
    if (step !== undefined) {
      await editMutation({
        ...currentPurpose,
        step,
      });
    } else {
      await editMutation({
        ...currentPurpose,
        step
      });
    }
  };
  const completePurpose = async () => {
    if (!currentPurpose)
      throw new Error(
        "Current purpose must be there when calling this function"
      );
    await editMutation({
      ...currentPurpose,
      step: 3,
      status: "completed",
    });
  };

  const step2Targets = React.useMemo(
    () =>
      currentPurpose
        ? currentPurpose.selectedAspects
            ?.map((aspect) =>
              aspect.targets!.slice(1, aspect.targets!.length - 1)
            )
            .flat()
        : undefined,
    [currentPurpose]
  );

  const selectTarget = async (targetId: number) => {
    await selectTargetMutation({ targetId });
  };

  const selectMatrix = async (valuesMatrixId: number) => {
    await selectValuesMatrix({
      id: currentPurpose!.id,
      matrixId: valuesMatrixId,
    });
  };

  return {
    setPurposeStep,
    step1Props: {
      id,
      addTarget,
      addAspectToPurpose,
      nextStep,
      selectTarget,
      editTarget,
    },
    step2Props: {
      nextStep,
      targets: step2Targets,
      selectTarget,
    },
    step3Props: {
      selectMatrix: (matrixId: number) => selectMatrix(matrixId),
      completePurpose: completePurpose,
    },
    step4Props: {
      purpose: currentPurpose!,
      saveNote,
      completePurpose: completePurpose,
    },
  };
};
