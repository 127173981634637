import React, { useEffect, useRef, useState } from "react";
import { Box, Link } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import { franc } from "franc";
import "../styles/AssistantMessage.css";
import "../styles/Messages.css";

export default function AssistantMessage({
  eventSource,
  messageId,
  text,
}: {
  eventSource?: EventSource;
  messageId?: string;
  text?: string;
}) {
  const [currentMessage, setCurrentMessage] = useState<string>(text ?? "");

  const detectedLanguageCode = franc(currentMessage);
  const isRTL = ["arb"].includes(detectedLanguageCode);
  const direction = isRTL ? "rtl" : "ltr";

  // Custom component for rendering list items
  const CustomListItem = ({ children }: { children: React.ReactNode }) => (
    <Box as="li" dir={direction}>
      {children}
    </Box>
  );

  // Custom link component for styling hyperlinks
  const CustomLink = ({
    href,
    children,
  }: {
    href: string;
    children: React.ReactNode;
  }) => {
    // Get the current host URL
    const currentHost = window.location.origin;

    // Determine if the link is internal or external
    const isInternalLink = href.startsWith(currentHost);

    // Set the target based on whether the link is internal or external
    const linkTarget = isInternalLink ? "_self" : "_blank";

    return (
      <Link
        href={href}
        color="#3572EF"
        target={linkTarget}
        textDecoration="underline"
        _hover={{ color: "#5C2FC2" }}
      >
        {children}
      </Link>
    );
  };

  // Custom components for rendering Markdown elements
  const renderers = {
    list: ({
      children,
      ordered,
    }: {
      children: React.ReactNode;
      ordered?: boolean;
    }) =>
      ordered ? (
        <Box as="ol" padding={4}>
          {children}
        </Box>
      ) : (
        <Box as="ul" listStyleType="disc">
          {children}
        </Box>
      ),
    listItem: CustomListItem,
    text: ({ children }: { children: React.ReactNode }) => (
      <Box as="text">{children}</Box>
    ),
    link: CustomLink,
  };

  useEffect(() => {
    if (eventSource) {
      eventSource.onmessage = (event) => {
        const { name, text } = JSON.parse(event.data);

        if (name === "textDelta") {
          setCurrentMessage((prevMessage) => prevMessage + text);
        }

        if (name === "textDone") {
          eventSource.close();
        }
      };
    }
  }, [messageId]);

  return (
    <Box
      className="message-container"
      style={{
        width: "100%",
        display: "flex",
        alignItems: "end",
        justifyContent: "flex-start",
        direction: "ltr",
      }}
    >
      <Box
        background="#1F3F93"
        padding="8px"
        borderRadius="50%"
        cursor="pointer"
        marginBottom={2}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 800 800"
          height="28px"
          width="28px"
          role="img"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="#ffffff"
            d="M400 26.2c-193.3 0-350 156.7-350 350 0 136.2 77.9 254.3 191.5 312.1 15.4 8.1 31.4 15.1 48.1 20.8l-16.5 63.5c-2 7.8 5.4 14.7 13 12.1l229.8-77.6c14.6-5.3 28.8-11.6 42.4-18.7C672 630.6 750 512.5 750 376.2c0-193.3-156.7-350-350-350zm211.1 510.7c-10.8 26.5-41.9 77.2-121.5 77.2-79.9 0-110.9-51-121.6-77.4-2.8-6.8 5-13.4 13.8-11.8 76.2 13.7 147.7 13 215.3.3 8.9-1.8 16.8 4.8 14 11.7z"
          ></path>
        </svg>
      </Box>
      <Box className={"assistant-message"} dir={direction}>
        {/* Render Markdown content with custom renderers */}
        <ReactMarkdown renderers={renderers}>{currentMessage}</ReactMarkdown>
      </Box>
    </Box>
  );
}
