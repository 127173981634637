import React, { useState } from "react";
import { Box, BoxProps, Text } from "@chakra-ui/react";
import VideoModal from "src/views/Affirmation/ClearAndProtect/Area/VideoModal";
import Footer from "src/components/Footer/Footer";
import { useSelector } from "src/store";
import SalAssistant from "src/views/SalAssistant/SalAssistant";
import "../views/SalAssistant/styles/AssistantContainer.css";
import "../views/SalAssistant/styles/AssistantFAB.css";

const Container: React.FC<BoxProps> = ({ children }) => {
  const { value, color, user } = useSelector((state) => ({
    value: state.media.value,
    color: state.media.color,
    user: state.auth.user,
  }));
  const [showSalAssistant, setShowSalAssistant] = useState(false);
  const isLoggedIn = localStorage.getItem("__sal_auth") ? true : false;
  const validSubscription =
    user?.subscription?.endDate &&
    new Date(user.subscription.endDate) > new Date();

  const toggleSalAssistant = () => {
    setShowSalAssistant(!showSalAssistant);
  };

  return (
    <Box
      width={"100%"}
      style={{
        fontFamily: "Calibri (Body)",
        position: "relative",
        maxWidth: "100%",
        overflowX: "hidden",
      }}
    >
      {/* Area Video Modal */}
      <Box width={"100%"} poistion={"absolute"}>
        <VideoModal />
      </Box>
      {/* subliminal Text */}
      <Text
        id={"subliminalTextToFlash"}
        fontFamily="Calibri (Body)"
        opacity={0}
        fontWeight={"bold"}
        fontSize={"24px"}
        zIndex={10000000}
        position={"absolute"}
        color={color}
      >
        {value}
      </Text>
      {isLoggedIn && user && validSubscription && (
        <Box className="assistant-container">
          <Box
            className="assistant-fab"
            display={showSalAssistant ? "none" : "block"}
            onClick={toggleSalAssistant}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 800 800"
              height="32px"
              width="32px"
              role="img"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                fill="#ffffff"
                d="M400 26.2c-193.3 0-350 156.7-350 350 0 136.2 77.9 254.3 191.5 312.1 15.4 8.1 31.4 15.1 48.1 20.8l-16.5 63.5c-2 7.8 5.4 14.7 13 12.1l229.8-77.6c14.6-5.3 28.8-11.6 42.4-18.7C672 630.6 750 512.5 750 376.2c0-193.3-156.7-350-350-350zm211.1 510.7c-10.8 26.5-41.9 77.2-121.5 77.2-79.9 0-110.9-51-121.6-77.4-2.8-6.8 5-13.4 13.8-11.8 76.2 13.7 147.7 13 215.3.3 8.9-1.8 16.8 4.8 14 11.7z"
              ></path>
            </svg>
          </Box>
          <SalAssistant
            onClose={toggleSalAssistant}
            isOpen={showSalAssistant}
            style={{
              position: "absolute",
              bottom: "14px",
              right: "15px",
            }}
          />
        </Box>
      )}
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        {children}
        <Footer />
      </div>
    </Box>
  );
};

export default Container;
