import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { FaStar } from "react-icons/fa";
import useLocale from "src/providers/useLocale";
import "./Rater.css";

const iconValue = 5;

const StaticRatingStars = ({ rating = 0, starSize = 27 }) => {
  const [locale] = useLocale();
  const starsRef = useRef<any>(null);

  useEffect(() => {
    if (starsRef.current) {
      let svgDom: any = starsRef.current?.querySelectorAll(".singleStar");

      svgDom.forEach((item, idx) => {
        if (Math.floor(rating)) {
          item.style.fill = "";
        }
      });

      if (Math.floor(rating) !== Math.round(rating)) {
        let pathDom: any = svgDom[Math.floor(rating)]; // 3.6 => 3
        pathDom.style.fill = "url(#orange_red)";
      }

      svgDom.forEach((item, idx) => {
        if (idx > Math.floor(rating)) {
          item.style.fill = "";
        }
      });
    }
  }, [rating]);

  return (
    <div id="start-wrap">
      <svg className="linear-gradient-template">
        <linearGradient
          id="orange_red"
          x1={locale === "ar" ? "100%" : "0"}
          y1="0%"
          x2={locale === "ar" ? "0%" : "100%"}
          y2="0%"
        >
          <stop offset="50%" style={{ stopColor: "rgb(255, 215, 0)" }}></stop>
          <stop offset="50%" style={{ stopColor: "rgb(228, 229, 233)" }}></stop>
        </linearGradient>
      </svg>
      <Box d="flex" ref={starsRef}>
        {[...Array(iconValue)].map((icon, i) => {
          const value = i + 1;
          return (
            <label key={value}>
              <FaStar
                className="singleStar"
                color={value <= rating ? "rgb(255, 215, 0)" : "#e4e5e9"}
                size={starSize}
              />
            </label>
          );
        })}
      </Box>
    </div>
  );
};

export default StaticRatingStars;
