import React from "react";
import Section from "./Section";
import styles from "../styles/videos.module.css";
import { TrainingCategory } from "src/types";

const TrainingVideos = ({ videos }: { videos: TrainingCategory[] }) => {
  console.log("video", videos);
  return (
    <div className={styles.sections}>
      {videos
        .filter((category) => category.trainings?.length > 0)
        .map((section, index) => {
          return (
            <React.Fragment key={section.name}>
              <Section
                title={section?.name}
                videos={section.trainings.filter((vid) => vid.url)}
                categoryIndex={index}
              />
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default TrainingVideos;
