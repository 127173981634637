// import axios, { AxiosResponse } from "axios";
import { Solfeggio, Chakra, Training, TrainingCategory } from "src/types";
// import { LOGIN_ROUTE } from "../routes";
// import { baseURL } from "../configs/config";
import { get } from "src/api";

// -------------------------------------------------------

// export const api = axios.create({ baseURL });

// -------------------------------------------------------

// const get = async <T>(url: string, token?: string, locale?: string) => {
//   const local = locale
//     ? locale
//     : localStorage.getItem("locale")
//     ? localStorage.getItem("locale")
//     : "ar";
//   const authorization = token
//     ? `Bearer ${token}`
//     : `Bearer ${window.localStorage.getItem("__sal_auth")}`;
//   return api
//     .get(url, {
//       headers: {
//         authorization,
//         "x-localization": local,
//       },
//     })
//     .then((res: AxiosResponse<T>) => {
//       if (res.status === 401 && window.location.pathname !== LOGIN_ROUTE) {
//         window.location.href = LOGIN_ROUTE;
//       }
//       return res.data;
//     })
//     .catch(() => null);
// };

// -------------------------------------------------------

export const getSolfeggio = async (token?: string, locale?: string) => {
  // return get<Solfeggio[]>("/solfeggio", token, locale);
  return get<Solfeggio[]>("/solfeggio", locale);
};

// -------------------------------------------------------

export const getChakras = async (token?: string, locale?: string) => {
  // return get<Chakra[]>("/chakra", token, locale);
  return get<Chakra[]>("/chakra", locale);
};

// -------------------------------------------------------

export const getAllWaves = async (token?: string, locale?: string) => {
  // return get<any>("/wave/allCategories", token, locale);
  return get<any>("/wave/allCategories", locale);
};

// -------------------------------------------------------

export const getSearchWaves = async (
  searchValue: string,
  token?: string,
  locale?: string
) => {
  if (!searchValue) return;
  // return get<any>(`/wave/search?query=${searchValue}`, token, locale);
  return get<any>(`/wave/search?query=${searchValue}`, locale);
};

// -------------------------------------------------------

export const getTraining = async (token?: string, locale?: string) => {
  // return get<Training[]>("/training", token, locale);
  return get<TrainingCategory[]>("/training/allCategories", locale);
};

// -------------------------------------------------------

export const getProtection = async (token?: string, locale?: string) => {
  // return get<Training[]>("/protection", token, locale);
  return get<Training[]>("/protection", locale);
};
