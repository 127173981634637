import {
  Box,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FormEvent, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from "src/components/Modal";
import PrimaryButton from "src/components/PrimaryButton";
import { FormattedMessage, useIntl } from "react-intl";
import { Skill } from "src/types";
import DeleteModel from "src/components/DeleteModel";

type SkillItemProps = {
  text: string;
  skill: Skill;
  remove?: () => void;
  edit?: (skill: Skill) => void;
};
const SkillItem: React.FC<SkillItemProps> = ({ text, remove, edit, skill }) => {
  const [name, setName] = useState(skill.name);
  const [property, setProperty] = useState(skill.property);

  const editDiscloureProps = useDisclosure();
  const { formatMessage } = useIntl();

  const submit = () => {
    edit && edit({ name, property });
    editDiscloureProps.onClose();
  };

  return (
    <Box my='4px'>
      <Text fontFamily='Calibri (Body)' color='#8b8b8b' d='block'>
        {text}
      </Text>
      <Box d='inline-block' float='right' mt={-5}>
        <HStack>
          {edit ? (
            <Modal
              disclosureProps={editDiscloureProps}
              btnProps={{
                rightIcon: <BiEdit />,
                variant: "link",
                fontSize: "20px",
                color: "brand.800",
                minW: 1,
              }}
              bodyProps={{
                children: (
                  <Box mt={5}>
                    <form
                      style={{ marginBottom: "25px" }}
                      onSubmit={(e: FormEvent) => {
                        e.preventDefault();
                        name && submit();
                      }}>
                      <FormLabel fontWeight='bold' fontFamily='Calibri (Body)' color='brand.900'>
                        <FormattedMessage id='mission.create.step2.skill' defaultMessage='الصفة' />
                      </FormLabel>
                      <Input
                        variant='flushed'
                        value={name}
                        placeholder={formatMessage({
                          id: "mission.create.step2.skill",
                          defaultMessage: "الصفة",
                        })}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </form>
                    <form
                      style={{ marginBottom: "25px" }}
                      onSubmit={(e: FormEvent) => {
                        e.preventDefault();
                        property && submit();
                      }}>
                      <FormLabel fontWeight='bold' fontFamily='Calibri (Body)' color='brand.900'>
                        <FormattedMessage
                          id='mission.create.step2.property'
                          defaultMessage='العمل'
                        />
                      </FormLabel>
                      <Input
                        variant='flushed'
                        value={property}
                        placeholder={formatMessage({
                          id: "mission.create.step2.property",
                          defaultMessage: "العمل",
                        })}
                        onChange={(e) => setProperty(e.target.value)}
                      />
                    </form>

                    <Box d='flex' justifyContent='center' mt={5}>
                      <PrimaryButton
                        disabled={property === "" || name === ""}
                        onClick={() => {
                          edit({ name, property });
                          editDiscloureProps.onClose();
                        }}>
                        <FormattedMessage id='edit' defaultMessage='تعديل' />
                      </PrimaryButton>
                    </Box>
                  </Box>
                ),
              }}
            />
          ) : null}
          {remove ? <DeleteModel remove={remove} /> : null}
        </HStack>
      </Box>
    </Box>
  );
};
export default SkillItem;
