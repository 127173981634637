import { Flex, Box, useMediaQuery } from "@chakra-ui/react";
import React, { useMemo } from "react";

const Waveform = () => {
  // Correctly use useMediaQuery to get boolean values
  const [isSmallScreen] = useMediaQuery("(max-width: 480px)");
  const [isMediumScreen] = useMediaQuery(
    "(min-width: 481px) and (max-width: 768px)"
  );
  const [isLargeScreen] = useMediaQuery(
    "(min-width: 769px) and (max-width: 1024px)"
  );
  const [isExtraLargeScreen] = useMediaQuery("(min-width: 1025px)");

  // Determine the number of bars based on media queries
  let numBars = 100; // Default number of bars

  if (isSmallScreen) {
    numBars = 10; // Small screens
  } else if (isMediumScreen) {
    numBars = 30; // Medium screens
  } else if (isLargeScreen) {
    numBars = 60; // Large screens
  } else if (isExtraLargeScreen) {
    numBars = 90; // Extra large screens
  }

  const bars = useMemo(() => {
    return Array.from({ length: numBars }, (_, index) => {
      const height = Math.random() * 80 + 20;
      const marginLeft = index === 0 ? 0 : 2;
      return (
        <Box
          key={index}
          height={`${height}px`}
          flex="1"
          marginLeft={`${marginLeft}px`}
          borderRadius="5px"
          bg="#ccc"
          _last={{ marginRight: "2px" }}
        />
      );
    });
  }, [numBars]);

  return (
    <Flex alignItems="center" height="100px" overflow="hidden" mx={2}>
      {bars}
    </Flex>
  );
};

export default Waveform;
