import { BASE_URL } from "src/configs/config";
import { LOGIN_ROUTE, REGISTER_ROUTE } from "src/routes";

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  let [resource, config] = args;

  // Ensure resource is parsed to a string url
  const url = getURL(resource);

  // Handle auth-related fetch requests
  if (isPublicEndpoint(url)) {
    const response = await originalFetch(resource, config);

    // Handle token refresh failure
    if (isRefreshEndpoint(url) && response.status === 401) {
      handleRefreshTokenExpiry();
      return Promise.reject(new Error("Refresh Token expired"));
    }

    return response;
  }

  // Get tokens from local storage
  const refreshToken = localStorage.getItem("__sal_auth_refresh");
  const accessToken = localStorage.getItem("__sal_auth");

  // Check if the refresh token is expired
  if (isTokenExpired(refreshToken)) {
    handleRefreshTokenExpiry();
    return Promise.reject(new Error("Refresh Token expired"));
  }

  // Check if the access token is expired
  if (isTokenExpired(accessToken)) {
    console.log("Access token expired, refreshing...");
    try {
      const newAccessToken = await refreshAccessToken(refreshToken as string);

      if (config?.headers) {
        // Convert headers to Headers if it's not already
        const headers: any = config.headers;

        headers["authorization"] = `Bearer ${newAccessToken}`;
        headers["Origin"] = window.location.origin;

        config.headers = headers;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      handleRefreshTokenExpiry();
      return Promise.reject(error);
    }
  }

  // Perform the original fetch with potentially updated config
  return originalFetch(resource, config);
};

/**
 * Get the string representation of the resource.
 * @param resource - The resource to convert to a string.
 * @returns The string representation of the resource url.
 */
const getURL = (resource: Request | URL | string): string => {
  if (typeof resource === "string") {
    return resource;
  } else if (resource instanceof Request) {
    return resource.url;
  } else if (resource instanceof URL) {
    return resource.href;
  } else {
    throw new Error("Unsupported resource type");
  }
};

/**
 * Determine if the given url is an auth endpoint.
 * @param url - The resource URL to check.
 * @returns True if the url is an auth endpoint, otherwise false.
 */
const isPublicEndpoint = (url: string): boolean => {
  return url.includes("/auth/") || url.endsWith("/country");
};

/**
 * Determine if the given url is a refresh endpoint.
 * @param url - The resource URL to check.
 * @returns True if the url is a refresh endpoint, otherwise false.
 */
const isRefreshEndpoint = (url: string): boolean => {
  return url.includes("/auth/refresh");
};

/**
 * Handle the expiry of refresh token by clearing local storage and redirecting to login.
 */
const handleRefreshTokenExpiry = () => {
  localStorage.removeItem("__sal_auth_refresh");
  localStorage.removeItem("__sal_auth");
  if (
    window.location.pathname !== LOGIN_ROUTE &&
    window.location.pathname !== REGISTER_ROUTE
  ) {
    window.location.replace(LOGIN_ROUTE);
  }
};

export const isTokenExpired = (token: string | null): boolean => {
  if (!token) {
    // Token is not provided
    return true;
  }

  try {
    // Decode the token (assuming it's a JWT)
    const tokenPayload = JSON.parse(atob(token.split(".")[1]));

    // Extract the expiration time from the token payload
    const expirationTime = tokenPayload.exp * 1000; // Convert from seconds to milliseconds

    // Check if the expiration time is in the past
    return Date.now() >= expirationTime;
  } catch (error) {
    // Error parsing or decoding the token
    console.error("Error decoding token:", error);
    return true; // Treat as expired in case of error
  }
};

/**
 * Fetch a new access token using the refresh token.
 * @param refreshToken - The refresh token to use.
 * @returns The new access token.
 */
const refreshAccessToken = async (refreshToken: string): Promise<string> => {
  const response = await fetch(`${BASE_URL}/auth/refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const { accessToken } = await response.json();

  if (accessToken) {
    localStorage.setItem("__sal_auth", accessToken);
    return accessToken;
  } else {
    throw new Error("No access token returned");
  }
};
