import {
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";

const StoppedIntentionModel = (props: any) => {
  const { isOpen, onClose, title, content, endDate, startDate } = props;
  const [minutes, setMinutes] = useState<number>();
  const [seconds, setSeconds] = useState<number>();
  const [hours, setHours] = useState<number>();
  const [days, setDays] = useState<number>();
  const intl = useIntl();
  const calcPeriod = useCallback(() => {
    const timeDifference = endDate.getTime() - startDate.getTime();
    setSeconds(Math.floor((timeDifference % (1000 * 60)) / 1000));
    setMinutes(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)));
    setHours(
      Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    );
    setDays(Math.floor(timeDifference / (1000 * 3600 * 24)));
  }, [endDate, startDate]);
  React.useEffect(() => {
    calcPeriod();
    return () => {
      setMinutes(0);
      setSeconds(0);
      setHours(0);
      setDays(0);
    };
  }, [calcPeriod, endDate, startDate]);

  return (
    <Modal size={"xl"} isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="25px">
        <ModalHeader
          fontFamily={"Calibri (Body)"}
          fontSize="30px"
          fontWeight="bold"
          color="brand.900"
        >
          {title}
          {/* <FormattedMessage id={title} /> */}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p="6"
          color="gray.900"
          fontSize="18px"
          fontFamily="Calibri (Body)"
        >
          <VStack spacing={3} align={"start"}>
            <Text
              fontFamily={"Calibri (Body)"}
              fontSize="18px"
              fontWeight="bold"
              color="#8b8b8b"
              lineHeight="1.44"
              mt={"-2rem"}
              mb={".5rem"}
            >
              {content}
            </Text>
            <HStack>
              <Text textStyle={"intentionModalTime"}>
                {intl.formatMessage({ id: "day" })} ({days})
              </Text>
              <Text textStyle={"intentionModalTime"}>
                {intl.formatMessage({ id: "hour" })} ({hours})
              </Text>
              <Text textStyle={"intentionModalTime"}>
                {intl.formatMessage({ id: "minute" })} ({minutes})
              </Text>
              <Text textStyle={"intentionModalTime"}>
                {intl.formatMessage({ id: "second" })} ({seconds}){" "}
              </Text>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StoppedIntentionModel;
