import { AddIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import React from "react";

function EditButton({ onClick }) {
  return (
    <IconButton
      className="remove-on-print"
      onClick={onClick}
      size="lg"
      border="2px solid #E2E8F0"
      aria-label="add"
      icon={<AddIcon />}
    />
  );
}

export default EditButton;
