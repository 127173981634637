import { AddIcon } from "@chakra-ui/icons";
import {
  Accordion,
  Box,
  Center,
  Heading,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ModalSpinner from "src/components/modalSpinner";
import AddPlaylistModal from "./AddPlaylistModal";
import DeletePlaylistModal from "./DeletePlaylistModal";
import Playlist from "./Playlist";
import usePlaylists from "./usePlaylists";
import useLocale from "src/providers/useLocale";
const Playlists = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onClose: onCloseDelete,
    onOpen: onOpenDelete,
  } = useDisclosure();
  const {
    playlistsQuery: { data: playlists, isLoading, error, refetch },
  } = usePlaylists();
  const [currentPlaylist, setCurrentPlaylist] = useState<any>(null);
  const [locale] = useLocale();
  useEffect(() => {
    refetch();
  }, [locale, refetch]);
  if (error) return;
  if (isLoading) return ModalSpinner;
  return (
    <Box>
      <Heading as="h2" textStyle={"h2"} my="2">
        <FormattedMessage id={"playlists"} />
      </Heading>
      <Box mb="4">
        <Text color="grey" paddingTop={"10px"} fontSize="18px">
          <FormattedMessage id={"playlistsDescription"} />
        </Text>
      </Box>
      {playlists?.length ? (
        <IconButton
          className="remove-on-print"
          onClick={onOpen}
          size="lg"
          border="2px solid #E2E8F0"
          aria-label="add"
          icon={<AddIcon />}
        />
      ) : null}
      <AddPlaylistModal isOpen={isOpen} onClose={onClose} />
      {playlists ? (
        playlists.length ? (
          <Accordion allowToggle my="3">
            {playlists.map((playlist) => {
              return (
                <Playlist
                  playlistId={playlist.id}
                  key={playlist.id}
                  id={playlist.id}
                  name={playlist.name}
                  tracks={playlist.tracks}
                  setCurrentPlaylist={setCurrentPlaylist}
                  onDelete={onOpenDelete}
                />
              );
            })}
          </Accordion>
        ) : (
          <Center h="300px" d="flex" flexDir={"column"}>
            <Text textAlign={"center"}>
              <FormattedMessage id={"noPlaylists"} />
            </Text>
            <IconButton
              className="remove-on-print"
              onClick={onOpen}
              size="lg"
              border="2px solid #E2E8F0"
              aria-label="add"
              m="5"
              icon={<AddIcon />}
            />
          </Center>
        )
      ) : null}
      <DeletePlaylistModal
        isOpen={isDeleteOpen}
        onClose={onCloseDelete}
        itemId={currentPlaylist?.id}
        itemName={currentPlaylist?.name}
      />
    </Box>
  );
};

export default Playlists;
