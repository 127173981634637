import React, { useEffect, useContext } from "react";
import { Box, BoxProps, IconButton, Text } from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { LocaleContext } from "../../../providers/LocaleProvider";

type WishItemProps = {
  addHandler?: () => void;
  removeHandler?: () => void;
  done?: boolean;
} & BoxProps;
const WishItem: React.FC<WishItemProps> = ({
  addHandler,
  removeHandler,
  children,
  done,
  ...props
}) => {
  const { locale } = useContext(LocaleContext);
  useEffect(() => {}, [done, addHandler]);
  return (
    <Box d="flex" flexDir="row" justifyContent="space-between" {...props}>
      <Text color="gray.900" fontFamily="Calibri (Body)" lineHeight="22px">
        {children}
      </Text>
      <IconButton
        h="22px"
        variant="link"
        onClick={addHandler ? addHandler : removeHandler}
        color={addHandler ? "brand.800" : "#e97575"}
        aria-label="arrow"
        icon={
          addHandler ? (
            locale === "ar" ? (
              <FaArrowLeft
                color={done ? "gray" : "brand.800"}
                fontSize="20px"
                fontWeight="bold"
              />
            ) : (
              <FaArrowRight
                color={done ? "gray" : "brand.800"}
                fontSize="20px"
                fontWeight="bold"
              />
            )
          ) : (
            <MdClose fontSize="20px" fontWeight="bold" />
          )
        }
      />
    </Box>
  );
};
export default WishItem;
