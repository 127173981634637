import React, { useCallback, useEffect, useState } from "react";
import ItemCard from "src/components/ItemCard";
import BaseView from "../BaseView";
import VisionItem from "./VisionItem";
import VisionsCreateEdit from "./VisionsCreate";
import VisionDetailsModal from "./VisionDetailsModal";
import TopWishesModal from "./TopWishesModal";
import { useVisionsApi } from "./useVisionsApi";
import { useUIState } from "./useUIState";
import { useVision } from "./useVision";
import WishListModal from "./WishListModal";
import { valuesBg } from "src/assets/icons";
import { useAppContext } from "../../../Providers";
import SharingModal from "src/components/SharingModal/SharingModal";
import { useDisclosure } from "@chakra-ui/react";

const Visions: React.FC = () => {
  const context = useAppContext();
  useEffect(() => {
    context.background?.set("#fff");
  });

  const {
    currentId,
    createEditProps,
    topWishesProps,
    detailsProps,
    allWishesProps,
    isEdit,
  } = useUIState();

  const {
    visionList,
    completedWishList,
    notCompletedWishList,
    listLoading,
    listError,
    createMutation,
    editMutation,
    removeMutation,
    duplicateMutation,
    currentVision,
    visionLoading,
    visionError,
    sharedList
  } = useVisionsApi(currentId);
  const {
    vision,
    submitEditCreate,
    addWish,
    editWish,
    removeWish,
    setVisionName,
    submitTopTen,
  } = useVision({
    createMutation,
    editMutation,
    dismissCreateEditModal: createEditProps.onClose,
    openTopWishesModal: topWishesProps.onOpen,
    currentVisionFromApi: currentVision,
    dismissTopWishesModal: topWishesProps.onClose,
    isEdit,
  });
  const removeHandler = useCallback(
    (id: number) => {
      removeMutation(id);
    },
    [removeMutation]
  );
  const duplicateHandler = useCallback(
    (id: number) => {
      duplicateMutation(id);
    },
    [duplicateMutation]
  );

  const { isOpen: isSharingModalOpen, onClose: onSharingModalClose, onOpen: onSharingModalOpen } = useDisclosure();
  const [currentList, setCurrentList] = useState<any>({ id: 0, name: "" })
  const onShare: any = (item) => {

    onSharingModalOpen()
    setCurrentList(item)
  }
  return (
    <BaseView imageSrc={valuesBg}>
      {listLoading ? (
        "Loading..."
      ) : listError ? (
        listError.message
      ) : (
        <>
          <VisionsCreateEdit
            vision={vision}
            loading={visionLoading}
            error={visionError}
            disclosureProps={createEditProps}
            onSubmit={submitEditCreate}
            addWish={addWish}
            editWish={editWish}
            removeWish={removeWish}
            setVisionName={setVisionName}
            isSharedWithMe={currentList.shared}
            canUpdate={currentList.shared?.permission === "update"}
            owner={currentList.shared?.owner}
          />

          {visionList?.sort((item1, item2) => Number(item1.createdAt) - Number(item2.createdAt))?.map((vision) => (
            <ItemCard
              resort={() => { setCurrentList(vision); topWishesProps.onOpen(vision.id) }}
              key={vision.id}
              mb={5}
              onClick={() => { setCurrentList(vision); detailsProps.onOpen(vision.id!) }}
              edit={() => { setCurrentList(vision); createEditProps.onOpen(true, vision.id) }}
              remove={() => removeHandler(vision.id)}
              douplicate={() => duplicateHandler(vision.id!)}
              share={() => onShare(vision)}
              isSharedWithMe={Boolean(vision.shared)}
              permission={vision.shared?.permission}
            >
              {vision.name}
            </ItemCard>
          )).reverse()}
          <hr style={{ marginBottom: "16px" }} />
          {completedWishList?.sort((item1, item2) => Number(item1.createdAt) - Number(item2.createdAt))?.map((wish) => (
            <VisionItem
              id={wish.id}
              openTopWishes={() => { setCurrentList(wish); topWishesProps.onOpen(wish.id) }}
              key={wish!.id}
              edit={() => {
                setCurrentList(wish);
                createEditProps.onOpen(true, wish.id);
              }}
              remove={() => { removeHandler(wish.id!) }}
              isComplete
              share={() => onShare(wish)}
              isSharedWithMe={Boolean(wish.shared)}
              permission={wish.shared?.permission}
            >
              {wish.name}
            </VisionItem>
          ))}
          {notCompletedWishList?.sort((item1, item2) => Number(item1.createdAt) - Number(item2.createdAt))?.map((wish) => (
            <VisionItem
              id={wish.id}
              duplicate={() => duplicateHandler(wish.id!)}
              openTopWishes={topWishesProps.onOpen}
              count={wish.wishesCount}
              key={wish.id + wish.name}
              onClick={() => {
                setCurrentList(wish);
                allWishesProps.onOpen(wish.id!);
              }}
              edit={() => {
                setCurrentList(wish);
                createEditProps.onOpen(true, wish.id);
              }}
              remove={() => removeHandler(wish.id!)}
              share={() => onShare(wish)}
              isSharedWithMe={Boolean(wish.shared)}
              permission={wish.shared?.permission}
            >
              {wish.name}
            </VisionItem>
          )).reverse()}
        </>
      )}

      <TopWishesModal
        vision={vision}
        disclosureProps={topWishesProps}
        submitTopTen={submitTopTen}
        openPreview={detailsProps.onOpen}
        isSharedWithMe={currentList.shared}
        canUpdate={currentList.shared?.permission === "update"}
        owner={currentList.shared?.owner}
      />
      <VisionDetailsModal
        disclosureProps={detailsProps}
        vision={vision}
        isLoading={visionLoading}
        error={visionError}
        remove={removeHandler}
        openWishesModal={topWishesProps.onOpen}
        isSharedWithMe={currentList.shared}
        canUpdate={currentList.shared?.permission === "update"}
        owner={currentList.shared?.owner}
      />
      <WishListModal
        disclosureProps={allWishesProps}
        vision={vision}
        isLoading={visionLoading}
        error={visionError}
        isSharedWithMe={currentList.shared}
        canUpdate={currentList.shared?.permission === "update"}
        owner={currentList.shared?.owner}
      />
      <SharingModal isOpen={isSharingModalOpen} onClose={onSharingModalClose} sharedEntity={{ name: "wish", id: currentList.id }} showOptions={true} />
    </BaseView>
  );
};

export default Visions;
