import React from "react";
import { Button } from "@chakra-ui/react";

function Colors(props: any) {
  return (
    <Button
      borderRadius="4px"
      w="24px"
      h="24px"
      minW={"24px"}
      p={"0"}
      border={"3px solid transparent"}
      backgroundColor={props.color}
      _hover={{ border: "2px solid rgba(0,0,0,0.7)" }}
      _active={{ border: "3px dotted white" }}
      isActive={props.isActive}
      onClick={() => {
        props.OnChangeColor(props.color);
      }}
    />
  );
}
export default Colors;
