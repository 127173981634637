import { Box, Image } from "@chakra-ui/react";
import React from "react";
import { useIntl } from "react-intl";
import { portalBg } from "src/assets/icons";
import Breadcrumb from "src/components/Breadcrumb/Breadcrumb";
interface Props {
  children?: React.ReactNode;
  noBread?: any;
}
const BaseView: React.FC<Props> = ({ children, noBread }) => {
  const { locale } = useIntl();

  return (
    <Box>
      {!noBread && <Breadcrumb />}
      {children}
      <Box
        overflow={"hidden"}
        width={"767px"}
        height={"767px"}
        position={"absolute"}
        bottom={0}
        right={0}
        zIndex={-1}
      >
        <Image
          overflow={"hidden"}
          position={"absolute"}
          right={"-57px"}
          bottom={"-193px"}
          width={"347.7px"}
          height={"473px"}
          src={portalBg}
          transform={locale === "en" ? "scaleX(-1)" : undefined}
        />
      </Box>
    </Box>
  );
};

export default BaseView;
