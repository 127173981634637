import React, { useState, useEffect } from "react";
import Colors from "../Colors/Colors";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { VStack, HStack, Text, Box } from "@chakra-ui/react";
import InputColor from "react-input-color";
import { SketchPicker } from "react-color";
import { IconButton } from "@chakra-ui/react";
import SliderChakra from "./ٍSliderChakra";
import { useSelector } from "src/store";

function SliderData(props: any) {
  const [isPreviewShown, setPreviewShown] = useState<boolean>(false);
  const [activeOne, setActiveOne] = useState<boolean>(false);
  const [activeTwo, setActiveTwo] = useState<boolean>(false);
  const [activeThree, setActiveThree] = useState<boolean>(false);
  const [pickerColor, setPickerColor] = useState();
  const color = useSelector((state) => state.media.color);

  useEffect(() => {
    const span: any = document.getElementsByClassName(
      "css-1yn2e29-InputColor"
    )[0];
    if (span) {
      span.style.width = "24px";
      span.style.height = "24px";
      span.style.borderRaduis = "50%";
      span.style.top = "1.1rem";
      span.style.left = "-5rem";
      span.style.background = "transparent";
      span.style.border = "none";
    }
  }, [isPreviewShown]);
  const { noSlider } = props;

  useEffect(() => {
    //once the user shows the color picker
    //click the input to show the color picker automatically
    if (isPreviewShown)
      document.getElementById(`op-color-${props.name}`)?.click();
  }, [isPreviewShown, props.name]);

  return (
    <VStack>
      <VStack
        fontFamily="Calibri (Body)"
        alignItems="right"
        style={{
          position: "relative",
          // right: "-1em",
          // padding: "0.6em"
        }}
      >
        <Text
          fontFamily="Calibri (Body)"
          color="#1f3f93"
          fontSize="18px"
          fontWeight="bold"
          lineHeight="1.44"
        >
          {props.title}
        </Text>
        {!noSlider && (
          <SliderChakra
            onChangeSize={(newSize: any) => {
              props.onChangeSize(newSize);
            }}
          />
        )}
        <HStack textAlign="right">
          <Text
            fontFamily="Calibri (Body)"
            color="#1f3f93"
            fontSize="18px"
            fontWeight="bold"
            lineHeight="1.44"
            align="right"
          >
            {props.content}
          </Text>
          <Colors
            color="#e95151"
            isActive={activeOne || color === "#e95151"}
            OnChangeColor={(newColor: any) => {
              setActiveOne(!activeOne);
              setActiveTwo(false);
              setActiveThree(false);
              props.onChangeColor(newColor);
            }}
          />
          <Colors
            color="#515be9"
            isActive={activeTwo || color === "#515be9"}
            OnChangeColor={(newColor: any) => {
              setActiveOne(false);
              setActiveTwo(!activeTwo);
              setActiveThree(false);
              props.onChangeColor(newColor);
            }}
          />
          <Colors
            color="#51e965"
            isActive={activeThree || color === "#51e965"}
            OnChangeColor={(newColor: any) => {
              setActiveOne(false);
              setActiveTwo(false);
              setActiveThree(!activeThree);
              props.onChangeColor(newColor);
            }}
          />
          <IconButton
            aria-label="Search database"
            bg="transparent"
            icon={<AddCircleIcon style={{ color: "#2895ff" }} />}
            onClick={() => {
              setPreviewShown(!isPreviewShown);
            }}
          />
        </HStack>
      </VStack>
      {isPreviewShown ? (
        // <input type='color'
        // id={`op-color-${props.name}`}
        // name='color'
        // defaultValue='#fff'
        // style={{position:'relative', top:0, left:0, zIndex:-1}}
        // onChange={(e: any) =>{
        //  props.onChangeColor(e.target.value)
        //  setActiveOne(false);
        //  setActiveTwo(false);
        //  setActiveThree(false);
        // }}
        // />
        <SketchPicker
          color={pickerColor}
          onChangeComplete={(color) => {
            props.onChangeColor(color.hex);
            setPickerColor(color);
            setActiveOne(false);
            setActiveTwo(false);
            setActiveThree(false);
          }}
        />
      ) : null}{" "}
      :
      {props.subliminal && (
        <Box style={{ position: "relative", top: 0, left: 0, zIndex: -1 }}>
          <InputColor
            initialValue="transparent"
            placement="top"
            onChange={(newColor: any) => {
              props.onChangeColor(newColor.hex);
              setActiveOne(false);
              setActiveTwo(false);
              setActiveThree(false);
            }}
          />
        </Box>
      )}
    </VStack>
  );
}
export default SliderData;
