import React, { useState, useContext, useEffect } from "react"
import { Box, Button, HStack, IconButton, Text, Tooltip, VStack } from "@chakra-ui/react";
import { get } from "src/api";
import { LocaleContext } from "src/providers/LocaleProvider";
import { useParams } from 'react-router-dom';
import BaseView from "../BaseView";
import { FaStop, FaPlay } from "react-icons/fa";
import { MdPlaylistAdd } from "react-icons/md";
import playImage from "./play.svg";
import stopImage from "./stop.svg";
import { useDispatch, useSelector } from "src/store";
import { playMedia, stopMedia } from "src/store/media";
import { FormattedMessage } from "react-intl";
import DOMPurify from "dompurify";

const AudioDetail = () => {
  const [audio, setAudio] = useState(null)
  const [loading, Setloading] = useState(true)
  
  const { stateId, isPlay } = useSelector((state) => ({
    stateId: state.media.id,
    isPlay: state.media.playing,
  }));

  const { locale } = useContext(LocaleContext);

  const params = useParams();
  const id = params.id;
  


  useEffect(() => {
    get(`/subliminal/${id}`, locale).then((data: any) => {
      setAudio(data);
      Setloading(false);
      });
    }, [id, locale]);
    

    // const id = useSelector((state) => state.media.id);
    const dispatch = useDispatch();
    const closeMedia = () => dispatch(stopMedia());
    const handlePlay = (source: string, id: number) => {
      dispatch(
        playMedia({
          source: source,
          name: "subliminal",
          type: "audio",
          id: `subliminal-${id}`,
          loop: false,
        })
      );
    };
    const handleStop = () => {
      closeMedia();
    };

    function createMarkup(audio) {
      // Since DOMPurify removes all target="_blank" from the markup we put it back
      // to allow user go to an external link in another tap
      return {
        __html:
          DOMPurify.sanitize(audio.html).replace(
            /href/g,
            `target="_blank" href`
          ) || " ",
      };
    }

  return (
    <>
      {loading || !audio ? (
        <Box padding={"20px"}>{loading && "Loading..."}</Box>
      ) : (
        <>
        <VStack
            dir="ltr"
            display={"flex"}
            flexDir={["column", "column", "column", "row"]}
            alignItems={["center", "center", "center", "flex-start"]}
            marginTop={"50px"}
            justifyContent={{ lg: "space-between" }}
            width={{ lg: "750px", xl: "1000px" }}
            >
            <Box marginTop={{ lg: "20px" }}>
              <img
                alt={"wave"}
                src={audio.imageURL}
                style={{
                  minWidth: "250px",
                  maxWidth: "250px",
                  height: "250px",
                  borderRadius: "10px",
                  marginBottom: "20px"
                }}
              />
            </Box>
            <VStack
              paddingTop={["30px", "30px", "30px", "0"]}
              paddingInline="10px"
              display="flex"
              flexDir="column"
              alignItems={{ base: "center", lg: "flex-start" }}
              dir={locale === "ar" ? "rtl" : "ltr"}
            >
              <VStack display={"flex"} flexDir={"column"}>
               <Box
                bg={"#f9f9f9"}
                boxShadow={"0 3px 6px 0 rgb(0 0 0 / 16%)"}
                borderRadius={"10px"}
                p={"0px 16px"}
                // maxH={'46px'}
                my={"1rem"}
                style={{ minHeight: 46 }}
                >
                <HStack
                  align={"center"}
                  justify={"space-between"}
                  style={{ minHeight: 44 }}
                >
                  <Text>{audio.name}</Text>
                    <Box  width={"40px"} height={"40px"} display={"flex"} alignItems={"center"} justifyContent={"center"} position={"relative"}>
                      {stateId === `subliminal-${audio.id}` && isPlay ? 
                        <Button
                          _hover={{ bg: "transparent" }}
                          _focus={{ bg: "transparent" }}
                          _active={{ bg: "transparent" }}
                          maxH={"17px"}
                          bg={"transparent"}
                          position={"absolute"}
                          height={"30px"}
                          width={"40px"}
                          p={"0"}
                          onClick={handleStop}
                        >
                        <img width={"20px"} height={"20px"} src={stopImage} alt={"Stop audio"} />
                        </Button>
                        : 
                        <Button
                        _hover={{ bg: "transparent" }}
                        _focus={{ bg: "transparent" }}
                        _active={{ bg: "transparent" }}
                        maxH={"17px"}
                        bg={"transparent"}
                        position={"absolute"}
                        height={"30px"}
                        width={"40px"}
                        p={"0"}
                        onClick={() => handlePlay(audio.url, audio.id)}
                        >
                        <img width={"20px"} height={"20px"} src={playImage} alt={"Play audio"} />
                      </Button>}
                    </Box>
                </HStack>
              </Box>
              </VStack>
              <HStack
                display={"flex"}
                flexDir={"column"}
                width={"100%"}
                textAlign={["center", "center", "center", "start"]}
                dir="auto"
              >
                <Box paddingTop={"10px"} width={"100%"} maxW="400px">
                  <Box
                    margin={{ base: "10px auto", lg: "10px 0" }}
                    wordBreak="break-word"
                  >
                  </Box>
                </Box>
              </HStack>
            </VStack>
          </VStack>
          <Box dangerouslySetInnerHTML={createMarkup(audio)}
            style={{ padding: "20px" }}
            className="description">
            {/* {audio.html||  "There is no description for this audio"} */}
          </Box>
        </>
      )}
    </>
  )
}

const AudioContainer = () => {
  return(
    <BaseView>
      <AudioDetail />
    </BaseView>
  )
}

export default AudioContainer