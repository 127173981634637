import { Box, VStack, HStack, Center, Flex } from "@chakra-ui/react";
import React from "react";
import BaseView from "src/views/Prepration/BaseView";
import { affirmationBg } from "src/assets/icons";
import Link from "src/components/Link/Link";
import { FormattedMessage, useIntl } from "react-intl";
import SubHeader from "src/components/SubHeader";
import InputWithAdd from "src/components/InputWithAdd";
import { useSelector, useDispatch } from "src/store";
import {
  toggleAnotherPlace,
  setHereValue,
  toggleArea,
  toggleClearArea,
} from "src/store/media";
const Area = () => {
  const [thereValue, setThereValue] = React.useState<string>("0");
  const dispatch = useDispatch();
  const { hereValue, anotherPlace, clear } = useSelector((state) => ({
    hereValue: state.media.hereValue,
    anotherPlace: state.media.anotherPlace,
    clear: state.media.clear,
  }));

  const intl = useIntl();

  const onModalMaximize = () => {
    dispatch(toggleArea({ value: true }));
  };
  return (
    <Box>
      <BaseView imageSrc={affirmationBg} />

      <HStack
        my={"2rem"}
        maxWidth={"900px"}
        flexWrap={["wrap", "wrap", "nowrap", "nowrap"]}
        alignItems={"start"}
        justifyContent={["space-between"]}
      >
        <VStack>
          <HStack>
            <Link
              asBtn
              fontWeight={"normal"}
              fontSize={"16px"}
              maxWidth={"112px"}
              height={"32px"}
              onClick={() => {
                dispatch(toggleAnotherPlace({ value: false }));
              }}
              borderRadius={15}
              py={1}
              isSecondary={anotherPlace}
            >
              <FormattedMessage id={"area.here"} />
            </Link>
            <Link
              asBtn
              fontWeight={"normal"}
              fontSize={"16px"}
              height={"32px"}
              onClick={() => {
                dispatch(toggleAnotherPlace({ value: true }));
              }}
              maxWidth={"112px"}
              borderRadius={15}
              py={1}
              isSecondary={!anotherPlace}
            >
              <FormattedMessage id={"area.there"} />
            </Link>
          </HStack>
        </VStack>
        <VStack>
          <SubHeader
            fontSize={"24px"}
            marginTop={".3rem"}
            width={"100%"}
            textAlign={"left"}
            formatId={"area.specifyareawithmeter"}
          />
          <InputWithAdd
            value={thereValue}
            onChange={(e: any) =>
              setThereValue(e.target.value.replace(/\D/, ""))
            }
            placeholder={intl.formatMessage({ id: "numbersonly" })}
            id="numbers-input"
            inputType="text"
            pattern={"[0-9.]*"}
            noAdd
            width={"352px"}
          />
          {anotherPlace ? (
            <>
              <SubHeader
                fontSize={"24px"}
                width={"100%"}
                textAlign={"left"}
                formatId={"area.specifyplace"}
              />
              <InputWithAdd
                placeholder={intl.formatMessage({ id: "area.place" })}
                value={hereValue}
                onChange={(e: any) =>
                  dispatch(setHereValue({ value: e.target.value }))
                }
                noAdd
                marginBottom={"32px"}
                width={"352px"}
                onKeyDown={(e) => e.key === "Enter" && onModalMaximize()}
              />
            </>
          ) : null}
        </VStack>
      </HStack>
      <Flex
        textAlign="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          gap: "10px",
        }}
      >
        <Center
          sx={{
            gap: "10px",
          }}
        >
          <Link
            asBtn
            fontWeight={"normal"}
            fontSize={"16px"}
            maxWidth={"112px"}
            height={"32px"}
            onClick={() => {
              dispatch(
                toggleClearArea({
                  value: true,
                })
              );
            }}
            borderRadius={15}
            py={1}
            isSecondary={!clear}
          >
            <FormattedMessage id="clear" defaultMessage={"clear"} />
          </Link>
          <Link
            asBtn
            fontWeight={"normal"}
            fontSize={"16px"}
            height={"32px"}
            onClick={() => {
              dispatch(
                toggleClearArea({
                  value: false,
                })
              );
            }}
            maxWidth={"112px"}
            borderRadius={15}
            py={1}
            isSecondary={clear}
          >
            <FormattedMessage id="protect" defaultMessage={"protect"} />
          </Link>
        </Center>
        <Link
          disabled={!!(anotherPlace && hereValue === "")}
          onClick={onModalMaximize}
          asBtn={true}
        >
          <FormattedMessage id="click" defaultMessage={"إضغط هنا"} />
        </Link>
      </Flex>
    </Box>
  );
};

export default Area;
