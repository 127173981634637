import { deleteMethod, post } from "src/api";
import { Intention } from "src/types";

const createIntention: (intention: Intention) => Promise<Intention> = (
  intention
) => post<Intention>("/intention/saved", intention);
const createSubliminal: (intention: Intention) => Promise<Intention> = (
  intention
) => post<Intention>("/subliminal/saved", intention);

const deleteIntention: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/intention/saved/${id!}`);
const deleteSubliminal: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/subliminal/saved/${id!}`);

export { createIntention, createSubliminal, deleteIntention, deleteSubliminal };
