import { Center, Spinner } from "@chakra-ui/react";
import React from "react";

const modalSpinner = (
  <Center h="300px">
    <Spinner size="lg" bgSize="lg" />
  </Center>
);

export default modalSpinner;
