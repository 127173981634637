import { deleteMethod, post, put } from "src/api";
import { MagicGoal } from "src/types";

//takes name
export const createGoal: (goal: MagicGoal) => Promise<MagicGoal> = (goal) =>
  post<MagicGoal>("/goal", goal);

export const deleteGoal: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/goal/${id!}`);

//takes name and endDate as strings
export const editGoal: (goal: MagicGoal) => Promise<MagicGoal> = (goal) =>
  put<MagicGoal>(`/goal/${goal.id!}`, goal);
