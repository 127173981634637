import React from "react";
import { Box, Text, Button } from "@chakra-ui/react";

type CookieCheckerProps = {
  accept: () => void;
};

const CookieChecker: React.FC<CookieCheckerProps> = ({ accept }) => {
  //message to display
  const message = navigator.cookieEnabled
    ? "We are using cookies. This website needs cookies to work correctly, Please don't disable cookies otherwise the website will not function as expected."
    : "Cookies are disabled. The website needs cookies to work correctly. Please enable cookies before using the website.";

  //handle accepting cookies, ca stands for cookies accepted
  const acceptCookies = () => {
    window.localStorage.setItem("__sal_ca", "1");
    accept();
  };

  return (
    <Box
      boxShadow="0 0 5px 1px silver"
      padding="20px"
      position="fixed"
      bottom={0}
      left={0}
      width="100%"
      dir="ltr"
      backgroundColor="white"
      zIndex={1000}
    >
      <Text display="inline" margin="10px 0">
        {message}
      </Text>
      <Button
        backgroundColor="#1f3f93"
        onClick={acceptCookies}
        color="white"
        padding="5px 10px"
        borderRadius="10px"
        margin="10px"
        minWidth="60px"
        transition="250ms ease-out"
        _hover={{ backgroundColor: "#2895ff" }}
      >
        Ok
      </Button>
    </Box>
  );
};

export default CookieChecker;
