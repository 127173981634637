import React, { useState, useEffect, useContext } from "react";
import { defineMessages, IntlProvider } from "react-intl";
import { CacheProvider } from "@emotion/react";
import createCache, { Options, StylisPlugin } from "@emotion/cache";
import arTranslation from "src/translations/ar.json";
import enTranslation from "src/translations/en.json";
import rtl from "stylis-plugin-rtl";

if (process.env.NODE_ENV === "development")
  defineMessages({
    prepration: {
      id: "prepration",
      defaultMessage: "التجهيزات",
    },
    values: {
      id: "values",
      defaultMessage: "القيم",
    },
    purposes: {
      id: "purposes",
      defaultMessage: "الرسالة",
    },
    missions: {
      id: "missions",
      defaultMessage: "المهمة",
    },
    visions: {
      id: "visions",
      defaultMessage: "الرؤية",
    },
    focusPoster: {
      id: "focus",
      defaultMessage: "Focus Poster",
    },
  });

type Locale = "en" | "ar";
type LocaleContextProps = {
  locale: Locale;
  setLocale: (locale: Locale) => void;
};
export const LocaleContext = React.createContext<LocaleContextProps>({
  locale: "ar",
  setLocale: (locale: Locale) => {},
});
export const useLocaleContext = () => useContext(LocaleContext);

const options: {
  [key: string]: Options;
} = {
  rtl: { key: "css-ar", stylisPlugins: [rtl as StylisPlugin] },
  ltr: { key: "css-en" },
};
let preferredLanguage: Locale = "ar";
if (window !== undefined) {
  const locales = ["ar", "en"];
  const navigatorLanguage = navigator.languages
    ? navigator.languages[0] //@ts-ignore
    : navigator.language || navigator.userLanguage;
  const localStorageLanguage = localStorage.getItem("locale");
  if (localStorageLanguage && locales.includes(localStorageLanguage)) {
    preferredLanguage = localStorageLanguage as Locale;
  } else {
    if (navigatorLanguage.startsWith("en-")) {
      preferredLanguage = "en";
    } else if (navigatorLanguage.startsWith("ar-")) {
      preferredLanguage = "ar";
    } else {
      if (locales.includes(navigatorLanguage)) {
        preferredLanguage = navigatorLanguage as Locale;
      }
    }
  }
}
export const LocaleProvider: React.FC = ({ children }) => {
  const [locale, setLocaleState] = useState<Locale>(preferredLanguage);
  const dir = locale === "ar" ? "rtl" : "ltr";
  const cache = createCache(options[dir]);
  const setLocale = (local: Locale) => {
    localStorage.setItem("locale", local);
    setLocaleState(local);
    const direction = local === "ar" ? "rtl" : "ltr";
    document.getElementsByTagName("html")[0].setAttribute("dir", direction);
    document.getElementsByTagName("html")[0].setAttribute("lang", local);
  };
  //remove deps from array to only invoke effect after initial render
  useEffect(() => {
    const dir = preferredLanguage === "ar" ? "rtl" : "ltr";
    document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    document
      .getElementsByTagName("html")[0]
      .setAttribute("lang", preferredLanguage);
  }, []);
  useEffect(() => {
    const eventHandler = () => {
      const localStorageLanguage = localStorage.getItem("locale");
      if (localStorageLanguage) return;
      const navigatorLanguage = navigator.languages
        ? navigator.languages[0] //@ts-ignore
        : navigator.language || navigator.userLanguage;
      if (navigatorLanguage.startsWith("en-")) {
        setLocaleState("en");
      } else if (navigatorLanguage.startsWith("ar-")) {
        setLocaleState("ar");
      } else {
        if (["ar", "en"].includes(navigatorLanguage)) {
          setLocaleState(navigatorLanguage);
        }
      }
    };
    window.addEventListener("languagechange", eventHandler);
    return () => window.removeEventListener("languagechange", eventHandler);
  });

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider
        locale={locale}
        messages={locale === "ar" ? arTranslation : enTranslation}
      >
        <CacheProvider value={cache} children={children} />
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
