import { Box, HStack } from "@chakra-ui/react";
import React from "react";
import LightGem from "./LightGem";
import { useSelector } from "src/store";
const names = ["manifestation", "BalanceGate", "subliminal", "new-story", ""];
const LightThanosBar = () => {
  const { isBtn, PeBtn, frBtn, baBtn, smBtn, name } = useSelector((state) => ({
    isBtn: state.media.isBtn,
    PeBtn: state.media.PeBtn,
    frBtn: state.media.frBtn,
    baBtn: state.media.baBtn,
    smBtn: state.media.smBtn,
    name: state.media.name,
  }));
  // const FrBtn = !names.includes(name);
  return (
    <Box display="inline-block">
      <HStack gridGap="2">
        <LightGem
          color={baBtn ? "purple" : "gray.100"}
          textColor={baBtn ? "white" : "gray.900"}
          text="BA"
        />

        <LightGem
          color={frBtn ? "yellow" : "gray.100"}
          textColor={frBtn ? "black" : "gray.900"}
          text="Fr"
        />
        <LightGem
          color={name === "subliminal" ? "indigo" : "gray.100"}
          textColor={name === "subliminal" ? "white" : "gray.900"}
          text="Su"
        />
        <LightGem
          color={isBtn ? "red" : "gray.100"}
          textColor={isBtn ? "white" : "gray.900"}
          text="is"
        />
        <LightGem
          color={smBtn ? "yellow" : "gray.100"}
          textColor={smBtn ? "black" : "gray.900"}
          text="SM"
        />
        <LightGem
          color={PeBtn ? "green" : "gray.100"}
          textColor={PeBtn ? "white" : "gray.900"}
          text="PE"
        />
      </HStack>
    </Box>
  );
};
export default LightThanosBar;
