import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  UseDisclosureReturn,
  Wrap,
  WrapItem,
  useDisclosure,
  CloseButton,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState, FormEvent } from "react";
import PrimaryButton from "src/components/PrimaryButton";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  selectAspect,
  createAspect,
  deselectAspect,
} from "src/api/prepration/purpose/mutation";
import { getOne } from "src/api/prepration/purpose/query";
import SecondaryButton from "src/components/SecondaryButton";
import { Aspect } from "src/types";
type PurposeAspectModalProps = {
  disclousureProps: UseDisclosureReturn;
  id: number;
  addAspectToPurpose: (aspect: Aspect) => Promise<void>;
  btnDisabled: boolean;
  submitAspect: () => void;
  setCurrent: (aspect: Aspect) => void;
};
const PurposeAspectModal: React.FC<PurposeAspectModalProps> = ({
  disclousureProps,
  id,
  submitAspect,
  btnDisabled,
  setCurrent,
}) => {
  const client = useQueryClient();
  const { data, isLoading, error } = useQuery(...getOne(id));
  const { mutateAsync: selectAspectMutation } = useMutation(selectAspect, {
    onSuccess: () => client.invalidateQueries(getOne(id)[0]),
  });
  const { mutateAsync: createAspectMutation } = useMutation(createAspect, {
    onSuccess: () => client.invalidateQueries(getOne(id)[0]),
  });

  const { mutateAsync: deselectAspectMutation } = useMutation(deselectAspect, {
    onSuccess: () => client.invalidateQueries(getOne(id)[0]),
  });
  const [addingAspect, setAddingAspect] = useState(false);
  const customDisclosure = useDisclosure();
  const [newAspect, setNewAspect] = useState("");
  const { formatMessage } = useIntl();

  const toast = useToast();

  return (
    <>
      <Modal
        {...disclousureProps}
        size="2xl"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalContent borderRadius="25px" paddingBottom={"0"}>
          {/* <ModalCloseButton /> */}
          <ModalHeader
            fontFamily="Calibri (Body)"
            fontSize="18px"
            fontWeight="bold"
            color="brand.900"
          >
            <FormattedMessage
              id="purposes.choose_aspect"
              defaultMessage="اختار احد الجوانب"
            />
          </ModalHeader>
          <ModalBody
            p="6"
            color="gray.900"
            fontSize="18px"
            fontFamily="Calibri (Body)"
          >
            <Wrap spacing="16px">
              {isLoading
                ? "Loading..."
                : error
                ? error.message
                : data?.aspects!.map((aspect) => (
                    <WrapItem key={aspect.id}>
                      <SecondaryButton
                        textTransform={"capitalize"}
                        onClick={async () => {
                          if (addingAspect) return;
                          setAddingAspect(true);
                          await selectAspectMutation({ aspectId: aspect.id });
                          setAddingAspect(false);
                          setCurrent(aspect);
                          disclousureProps.onClose();
                        }}
                        disabled={addingAspect}
                      >
                        {aspect.name}
                      </SecondaryButton>
                    </WrapItem>
                  ))}

              <WrapItem>
                <SecondaryButton onClick={customDisclosure.onOpen}>
                  <FormattedMessage
                    id="purposes.add_aspect"
                    defaultMessage="جانب اخر  +"
                  />
                </SecondaryButton>
              </WrapItem>
            </Wrap>
            <Text
              fontFamily="Calibri (Body)"
              fontSize="18px"
              fontWeight="bold"
              padding="40px 0"
              color="brand.900"
            >
              <FormattedMessage id="alreadySelected" />
            </Text>
            <Wrap spacing="16px">
              {isLoading
                ? "Loading..."
                : error
                ? error.message
                : data?.selectedAspects!.map((aspect) => (
                    <WrapItem key={aspect.id}>
                      <SecondaryButton
                        textTransform={"capitalize"}
                        onClick={async () => {
                          if (addingAspect) return;
                          setAddingAspect(true);
                          await deselectAspectMutation({ aspectId: aspect.id });
                          setAddingAspect(false);
                        }}
                        disabled={addingAspect}
                      >
                        {aspect.name}
                      </SecondaryButton>
                    </WrapItem>
                  ))}

              <WrapItem>
                <SecondaryButton onClick={customDisclosure.onOpen}>
                  <FormattedMessage
                    id="purposes.add_aspect"
                    defaultMessage="جانب اخر  +"
                  />
                </SecondaryButton>
              </WrapItem>
            </Wrap>
            <PrimaryButton
              disabled={btnDisabled}
              mt={10}
              w="fit-content"
              mx="auto"
              flex={1}
              d="block"
              onClick={() => {
                submitAspect();
              }}
            >
              <FormattedMessage
                id="finishedAspectAll"
                defaultMessage="التالي"
              />
            </PrimaryButton>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal {...customDisclosure} onClose={customDisclosure.onClose}>
        <ModalContent>
          <ModalHeader>
            <CloseButton onClick={customDisclosure.onClose} />
          </ModalHeader>
          <ModalBody padding="20px 20px 40px 20px">
            <form
              onSubmit={async (e: FormEvent) => {
                e.preventDefault();
                if (!newAspect) return;
                if (data?.aspects.find((aspect) => aspect.name === newAspect)) {
                  toast({
                    title: formatMessage({ id: "aspects.same" }),
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                  return;
                }
                await createAspectMutation({ name: newAspect, messageId: id });
                customDisclosure.onClose();
              }}
            >
              <Input
                type="text"
                placeholder={formatMessage({ id: "aspectName" })}
                onChange={(e: any) => setNewAspect(e.target.value)}
              />
              <div style={{ marginTop: "30px", textAlign: "center" }}>
                <PrimaryButton type="submit" disabled={!newAspect}>
                  {formatMessage({ id: "next" })}
                </PrimaryButton>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default PurposeAspectModal;
