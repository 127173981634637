import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import "../styles/Input.css";
import { BarLoader } from "react-spinners";
import {
  Box,
  FormControl,
  Input,
  Button,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

export default function MessageInput({ onSend, isDisabled }) {
  const [text, setText] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const intl = useIntl();

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    onSend(text);
    setText("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent line break in input
      handleSend(e);
    }
  };

  // Keep the input focused after sending the message
  useEffect(() => {
    if (!isDisabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isDisabled]);

  return (
    <Box mt="auto" pt={2}>
      {isDisabled && <BarLoader color="#2895FF" width="100%" height={2} />}
      <FormControl onSubmit={handleSend}>
        <InputGroup>
          <Input
            ref={inputRef}
            type="text"
            onChange={handleInputChange}
            value={text}
            placeholder={intl.formatMessage({ id: "sal_assistant.input" })}
            disabled={isDisabled}
            onKeyDown={handleKeyDown}
          />
          <InputRightElement width="3rem">
            <Button
              bgColor="transparent"
              _hover={{ bgColor: "transparent" }}
              _active={{ bgColor: "transparent" }}
              disabled={isDisabled}
              onClick={handleSend}
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 500 500"
              >
                <g>
                  <g
                    transform={
                      intl.locale === "ar"
                        ? "translate(250, 250) rotate(180) translate(-250, -250)"
                        : undefined
                    }
                  >
                    <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
                  </g>
                </g>
              </svg>
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </Box>
  );
}
