import React, { useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { MdCategory, MdOutlineTimelapse } from "react-icons/md";
import DOMPurify from "dompurify";
import { FormattedMessage } from "react-intl";

interface DescriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: number;
  title: string;
  category: string;
  duration: string;
  description: string;
}

const OtherSourcesInfoModal: React.FC<DescriptionModalProps> = ({
  isOpen,
  onClose,
  id,
  title,
  category,
  duration,
  description,
}) => {
  const createMarkup = useCallback((text: string) => {
    return {
      __html:
        DOMPurify.sanitize(text).replace(/href/g, `target="_blank" href`) ||
        " ",
    };
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box>
            <Text>{title}</Text>
            <Flex gap={2} alignItems="center">
              <MdCategory size="18px" />
              <Text fontSize={14} fontWeight="semibold">
                {category}
              </Text>
              <MdOutlineTimelapse size="18px" />
              <Text fontSize={14} fontWeight="semibold">
                {duration === "00:00" ? (
                  <FormattedMessage id={"loading"} />
                ) : (
                  duration
                )}
              </Text>
            </Flex>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            dangerouslySetInnerHTML={createMarkup(
              description?.length > 0
                ? description
                : "No description available."
            )}
            style={{ padding: "20px", whiteSpace: "pre-wrap" }}
            boxSize="fit-content"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OtherSourcesInfoModal;
