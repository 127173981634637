import { Avatar, Box, IconButton, Text } from '@chakra-ui/react'
import React from 'react'
import { BASE_MEDIA } from 'src/api'
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import RatingStars from './RatingStars'

const UserRating = ({ id, user, rating, description, date, canEdit = true, canDelete = true, openModal, deleteRating }) => {
    return (
        <>
            {id ? (<Box mb="3">
            <Box d="flex" alignItems={"center"} mb="2" >
                <Box d="flex"  >
                    <Avatar
                        src={`${BASE_MEDIA}/${user?.picture}`}
                        size={"sm"}
                        mr="3"
                        mt="2"
                    />
                    <Box>
                        <Box d="flex" alignItems={"center"}>
                            <h3>{user?.name}</h3>
                            <Box display={"inline"} alignSelf={"start"}>
                                {canEdit ? <IconButton
                                    color="brand.900"
                                    margin="0 5px"
                                    icon={<FaEdit />}
                                    size={"sm"}
                                    variant="ghost"
                                    aria-label="icon-button"
                                    // d="inline-flex"
                                    // alignItems={"start"}
                                    onClick={() => { openModal() }}
                                /> : null}
                                {canDelete ? <IconButton
                                    color="#e97575"
                                    margin="0 5px"
                                    icon={<RiDeleteBin6Line />}
                                    size={"sm"}
                                    // d="inline-flex"
                                    // alignItems={"start"}
                                    variant="ghost"
                                    aria-label="icon-button"
                                    onClick={() => { deleteRating(id) }}
                                /> : null}
                            </Box>
                        </Box>
                        <Text fontSize={"small"} color={"#b3b1ad"}>{date.split("T")[0].split("-").reverse().join("-")}</Text>
                        <RatingStars
                            icon="star"
                            scale={5}
                            fillColor="gold"
                            isDisabled
                            initialRating={rating}
                            size={3}
                        />
                        <Box mt="2">
                            <p>
                                {description}
                            </p>
                        </Box>
                    </Box>
                </Box>

            </Box>
            {/* 
            <Box mt="2">
                <p>
                    {description}
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, commodi.
                </p>
            </Box> */}
        </Box>): null}
        </>
    )
}

export default UserRating