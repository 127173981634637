import { QueryFunction } from "react-query";
import { get } from "src/api";
import { ProblemList } from "src/types";

const getList: [string, QueryFunction<ProblemList>] = [
  "listProblem",
  () => get<ProblemList>("/problem"),
];

export { getList };
