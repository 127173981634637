import { Box, Text, Icon, AspectRatio } from "@chakra-ui/react";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import Paper from "@material-ui/core/Paper";

const PosterContainer = ({ poster, onClick, isSelected }) => {
  return (
    <AspectRatio boxSize={120} ratio={1.4142 / 1} marginRight={2}>
      <Box
        onClick={() => onClick(poster)}
        cursor="pointer"
        className={`image-list-item ${isSelected ? "selected" : ""}`}
        backgroundImage={poster.coverUrl}
        backgroundPosition="center"
        borderRadius="10px"
        backgroundRepeat="no-repeat"
        objectFit={"contain"}
        backgroundSize="cover"
        marginBottom="8px"
      >
        <Paper
          elevation={4}
          style={{
            background: "rgba(0, 0, 0, .0)",
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            borderRadius: "10px",
            display: "flex",
            alignItems: poster.valign,
          }}
        >
          <Text
            color={poster.nameColor}
            fontFamily="Calibri (Body)"
            fontSize={16}
            style={{
              fontFamily: "Droid",
              textAlign: poster.halign,
              width: "100%",
              WebkitUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            }}
          >
            {poster.name}
          </Text>
          {isSelected && (
            <Box
              position="absolute"
              top="2"
              right="2"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgColor="blue.500"
              color="white"
              p="2"
              borderRadius="md"
              boxShadow="md"
              cursor="pointer"
            >
              <Icon as={FaCheckCircle} />
            </Box>
          )}
        </Paper>
      </Box>
    </AspectRatio>
  );
};

export default PosterContainer;
