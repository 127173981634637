import React, { useState, FormEvent } from "react";
import {
  FormControl,
  Box,
  Input,
  VStack,
  Text,
  Button,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import DarkHeader from "src/layout/DarkHeader";
import { post } from "src/api/index";
import ForgetReset from "./ForgetReset";

const Forget = () => {
  //for localization
  const { formatMessage } = useIntl();

  //email to send in request
  const [email, setEmail] = useState("");

  //processing state
  const [processing, setProcessing] = useState(false);

  //error state
  const [error, setError] = useState("");

  //success state
  const [success, setSuccess] = useState("");

  //function to handle change
  const handleChange = (e: FormEvent) => {
    setEmail((e.target as HTMLInputElement).value);
  };

  //function to handle submittion
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (processing) return;
    //make the call with the email
    setProcessing(true);
    const response: any = await post("/auth/forget", { email }).catch(() => {
      setError("wentWrong");
      setSuccess("");
      setProcessing(false);
    });
    setProcessing(false);
    if (response?.message === "success") {
      setError("");
      setSuccess("checkEmail");
    } else {
      setSuccess("");
      setError("emailNotFound");
    }
  };

  return (
    <>
      <DarkHeader />
      <VStack width="100%" padding="50px 0">
        <Box margin="40px 0">
          <Text
            fontSize="42px"
            color="brand.900"
            fontWeight="bold"
            textAlign="center"
          >
            {formatMessage({ id: "resetPass" })}
          </Text>
        </Box>
        {success ? (
          <Box padding="60px 0">
            <Text color="brand.900">{formatMessage({ id: success })}</Text>
          </Box>
        ) : (
          <FormControl
            as="form"
            padding="30px"
            maxWidth="500px"
            onSubmit={handleSubmit}
          >
            {error && (
              <Box margin="20px 0">
                <Text color="crimson">{formatMessage({ id: error })}</Text>
              </Box>
            )}
            <Box margin="10px 0">
              <Input
                name="email"
                id="email"
                placeholder={formatMessage({ id: "email" })}
                value={email}
                required
                onChange={handleChange}
                height="50px"
              />
            </Box>
            <Box margin="60px 0 30px 0" textAlign="center">
              <Button
                height="50px"
                minWidth="80px"
                type="submit"
                background="brand.900"
                color="white"
                _hover={{ filter: "brightness(120%)" }}
                disabled={processing}
              >
                {formatMessage({ id: "submit" })}
              </Button>
            </Box>
          </FormControl>
        )}
      </VStack>
    </>
  );
};

Forget.ForgetReset = ForgetReset;

export default Forget;
