import { QueryFunction } from "react-query";
import { get } from "src/api";
import { IntentionsSavedList } from "src/types";

export const getList: [string, QueryFunction<IntentionsSavedList>] = [
  "listIntention",
  () => get<IntentionsSavedList>("/intention/saved"),
];

export const getSharedList: [string, QueryFunction<IntentionsSavedList>] = [
  "sharedListIntention",
  () => get<IntentionsSavedList>("/shared/details?entityName=intention"),
];

export const getListSubliminal: [string, QueryFunction<IntentionsSavedList>] = [
  "listSubliminal",
  () => get<IntentionsSavedList>("/subliminal/saved"),
];
