import React, { useState, FormEvent } from "react";
import { FormattedMessage } from "react-intl";
import ItemCard from "src/components/ItemCard";
import BaseView from "../BaseView";
import {
  Box,
  VStack,
  HStack,
  useDisclosure,
  Input,
  Flex,
  useToast,
  useMediaQuery,
  Center,
  Spinner,
  Divider,
  Spacer,
  BreadcrumbItem,
  Breadcrumb,
  Heading,
  Text,
} from "@chakra-ui/react";
import Modal from "src/components/Modal/Modal";
import { FiPlus } from "react-icons/fi";
import PrimaryButton from "src/components/PrimaryButton";
import { useIntl } from "react-intl";
import { getList, getSharedList } from "src/api/prepration/focusPoster/query";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { focusListItems } from "src/types";
import * as mutation from "src/api/prepration/focusPoster/mutation";
import PosterDetails from "./PosterDetails";
import { useHistory } from "react-router-dom";
import { FOCUS_POSTER_ROUTE } from "src/routes";
import SharingModal from "src/components/SharingModal/SharingModal";
import FilmPlayer from "./FocusFilm/components/FilmPlayer";
import { BASE_MEDIA, MEDIA_URL, get, post } from "src/api";
import FilmList from "./FocusFilm/components/FilmList";
import CreateFilmModal from "./FocusFilm/components/CreateFilmModal";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { BASE_URL } from "src/configs/config";
import { FocusFilm } from "src/types";

// Utility function to format duration
const formatDuration = (durationInSeconds: number): string => {
  const hours = Math.floor(durationInSeconds / 3600);
  const remainingMinutes = durationInSeconds % 3600;
  const minutes = Math.floor(remainingMinutes / 60);
  const seconds = Math.floor(remainingMinutes % 60);

  const formattedHours = hours > 0 ? `${hours}:` : "";
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
};

const transformPosterData = (posterData) => {
  return posterData?.map((poster) => ({
    id: poster.id,
    coverUrl: poster.coverUrl?.startsWith("media/")
      ? `${BASE_MEDIA}/${poster.coverUrl}`
      : poster.coverUrl,
    halign: poster.halign,
    valign: poster.valign,
    name: poster.name,
    nameSize: poster.nameSize,
    nameColor: poster.nameColor,
  }));
};

const FocusPoster = () => {
  const client = useQueryClient();

  const posters = useQuery<focusListItems, Error, focusListItems>(...getList);
  const sharedPosters = useQuery<focusListItems, Error, focusListItems>(
    ...getSharedList
  );

  const filmPosters = [
    ...(posters.data ? transformPosterData(posters.data) : []),
    ...(sharedPosters.data ? transformPosterData(sharedPosters.data) : []),
  ];

  // Fetch Films
  const {
    data: ownedFilms,
    isLoading: isOwnedFilmsLoading,
    isError: isOwnedFilmsError,
  } = useQuery<FocusFilm[]>(
    "ownedFilms",
    async () => {
      return await get("/film/all");
    },
    {
      onError: (error) => console.error("Error fetching films:", error),
    }
  );

  const {
    data: sharedFilms,
    isLoading: isSharedFilmsLoading,
    isError: isSharedFilmsError,
  } = useQuery<FocusFilm[]>(
    "sharedFilms",
    async () => {
      const response: any = await get("/shared/details?entityName=film");

      const mappedFilms = response.map((sharedFilm) => ({
        id: sharedFilm.id,
        title: sharedFilm.title,
        description: sharedFilm.description,
        audioName: sharedFilm.audioName,
        audioUrl: sharedFilm.audioUrl,
        duration: sharedFilm.duration,
        posters: sharedFilm.posters,
        isShared: true,
        createdAt: new Date(Number(sharedFilm.createdAt)),
      }));

      return mappedFilms as FocusFilm[];
    },
    {
      onError: (error) => console.error("Error fetching films:", error),
    }
  );

  const { mutateAsync: createMutation } = useMutation(mutation.create, {
    onSuccess: () => client.invalidateQueries("listPoster"),
  });

  const { mutateAsync: deletePosterMutation } = useMutation(
    mutation.deletePoster,
    {
      onSuccess: () => {
        client.invalidateQueries("listPoster");
      },
    }
  );

  const { mutateAsync: duplicatePosterMutation } = useMutation(
    mutation.duplicatePoster,
    {
      onSuccess: () => client.invalidateQueries("listPoster"),
    }
  );

  const createPosterDisclosureProps = useDisclosure();
  const createFilmDisclosureProps = useDisclosure();

  const { formatMessage, locale } = useIntl();
  const [editValue, setEditValue] = useState("");
  const {
    isOpen: isPosterSharingModalOpen,
    onClose: onPosterSharingModalClose,
    onOpen: onPosterSharingModalOpen,
  } = useDisclosure();

  const [currentList, setCurrentList] = useState<any>({ id: 0, name: "" });
  const onPosterShare: any = ({ id, name }) => {
    onPosterSharingModalOpen();
    setCurrentList({ id, name });
  };

  const getAudioDuration = (audioUrl) => {
    return new Promise((resolve) => {
      const audio = new Audio(audioUrl);
      audio.addEventListener("loadedmetadata", () => {
        const audioDuration = formatDuration(audio.duration);
        resolve(audioDuration);
      });
    });
  };

  const [selectedFilm, setSelectedFilm] = useState<FocusFilm | null>(null);

  const handleCreateFilm = async (film) => {
    try {
      const audioDuration = await getAudioDuration(film.audioUrl);

      await post("/film", {
        title: film.title,
        description: film.description,
        audioName: film.audioName,
        audioUrl: film.audioUrl,
        posters: film.posters,
        duration: audioDuration,
      });

      await client.invalidateQueries("ownedFilms");
      client.refetchQueries("ownedFilms");

      toast({
        title: formatMessage({ id: "focusFilm.create.success" }),
        status: "success",
        isClosable: false,
      });
    } catch (error) {
      console.error(error);

      toast({
        title: formatMessage({ id: "focusFilm.create.failure" }),
        status: "error",
        isClosable: false,
      });
    }
  };

  const history = useHistory();

  async function deletePoster(id) {
    try {
      const response = await fetch(`${BASE_URL}/poster/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("__sal_auth")}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error("Failed to delete poster.");
      }

      client.invalidateQueries("listPoster");
      toast({
        title: formatMessage({ id: "focusPoster.delete.success" }),
        status: "success",
        isClosable: false,
      });
    } catch (error) {
      toast({
        title: formatMessage({ id: "focusPoster.delete.error" }),
        status: "error",
        isClosable: false,
      });
      console.error("Error deleting poster: ", error);
    }
  }

  function duplicatePoster(id) {
    duplicatePosterMutation(id);
  }

  const [showFilmPlayer, setShowFilmPlayer] = useState(false);

  const toast = useToast();

  const handlePlayButtonClick = (selectedFilm) => {
    setSelectedFilm(selectedFilm);
    setShowFilmPlayer(true);
  };

  const handleCloseFilmPlayer = () => {
    setShowFilmPlayer(false);
  };

  const handleFilmDelete = async (filmId: number) => {
    try {
      await fetch(`${BASE_URL}/film/${filmId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("__sal_auth")}`,
          "x-localization": locale,
        },
      });

      await client.invalidateQueries("ownedFilms");
      client.refetchQueries("ownedFilms");

      toast({
        title: formatMessage({ id: "focusFilm.delete.success" }),
        status: "success",
        isClosable: false,
      });
    } catch (error) {
      console.error("Error deleting Film:", error);

      toast({
        title: formatMessage({ id: "focusFilm.delete.failure" }),
        status: "error",
        isClosable: false,
      });
    }
  };

  const [isTabletOrSmaller] = useMediaQuery("(max-width: 59em)");

  if (isOwnedFilmsError || isSharedFilmsError)
    return <p>Error fetching focus posters or films</p>;
  if (isOwnedFilmsLoading || isSharedFilmsLoading)
    return (
      <Center w="100%" h="80vh">
        <Spinner />
      </Center>
    );

  return isTabletOrSmaller ? (
    <Tabs isFitted variant="enclosed">
      <TabList mb="1em">
        <Tab>Focus Poster</Tab>
        <Tab>Focus Film</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <BaseView>
            <Modal
              disclosureProps={createPosterDisclosureProps}
              btnProps={{
                mt: "2",
                mb: "4",
                children: formatMessage({
                  id: "focusPoster.create",
                }),
                color: "brand.800",
                variant: "link",
                rightIcon: <FiPlus />,
              }}
              bodyProps={{
                children: (
                  <Box
                    as="form"
                    mt={5}
                    onSubmit={(e: FormEvent) => {
                      e.preventDefault();
                      createMutation(editValue);
                      createPosterDisclosureProps.onClose();
                      setEditValue("");
                    }}
                  >
                    <Input
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                    />
                    <Box d="flex" justifyContent="center" mt={5}>
                      <PrimaryButton
                        type="submit"
                        style={{
                          width: "14em",
                        }}
                      >
                        <FormattedMessage
                          id="focusPoster.create.name_label"
                          defaultMessage="ادخل عنوان للFocus Poster"
                        />
                      </PrimaryButton>
                    </Box>
                  </Box>
                ),
              }}
            />
            <HStack
              maxW="100vw"
              flexDir={["column", "column", "column", "column", "row"]}
              alignItems={[
                "center",
                "center",
                "center",
                "center",
                "flex-start",
              ]}
              justifyContent={{ xl: "space-between" }}
            >
              <Box w={["100%", "100%", "100%", "100%", "30%"]}>
                <VStack gridRowGap="2" flexDir={"column-reverse"}>
                  {posters?.data
                    ?.concat(sharedPosters?.data || [])
                    .sort(
                      (item1, item2) =>
                        Number(item1.createdAt) - Number(item2.createdAt)
                    )
                    .map((card: any, index: any) => (
                      <ItemCard
                        key={`${index}_card`}
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(
                            `${FOCUS_POSTER_ROUTE}/${card.id}`,
                            card
                          );
                        }}
                        remove={() => deletePoster(card.id)}
                        share={() =>
                          onPosterShare({
                            id: card.id,
                            name: card.slug ?? card.name,
                          })
                        }
                        isSharedWithMe={Boolean(card.shared)}
                        permission={card.shared?.permission}
                        // douplicate={() => douplicatePoster(card.id)}
                      >
                        {" "}
                        <Box
                          as="span"
                          overflow="hidden"
                          maxWidth="200px"
                          display="block"
                          whiteSpace="pre"
                          textOverflow="ellipsis"
                        >
                          {card.slug ?? card.name}
                        </Box>
                      </ItemCard>
                    ))}
                </VStack>
              </Box>
            </HStack>
            <SharingModal
              isOpen={isPosterSharingModalOpen}
              onClose={onPosterSharingModalClose}
              sharedEntity={{ name: "poster", id: currentList.id }}
              showOptions={true}
            />
          </BaseView>
        </TabPanel>
        <TabPanel>
          <BaseView breadTitle="Focus Film">
            <CreateFilmModal
              disclosureProps={createFilmDisclosureProps}
              posters={filmPosters}
              onCreate={handleCreateFilm}
            />
            <FilmList
              ownedFilms={ownedFilms}
              sharedFilms={sharedFilms}
              locale={locale}
              onFilmPlay={handlePlayButtonClick}
              onDelete={handleFilmDelete}
            />
            {showFilmPlayer && selectedFilm && (
              <FilmPlayer
                posters={selectedFilm.posters}
                audioUrl={selectedFilm.audioUrl}
                onClose={() => {
                  handleCloseFilmPlayer();
                  setSelectedFilm(null);
                }}
              />
            )}
          </BaseView>
        </TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <Flex
      justify="space-between"
      align="start"
      h="100vh"
      style={{ margin: "0 auto" }}
    >
      <Flex flex={1} flexDirection="column">
        <BaseView>
          <Modal
            disclosureProps={createPosterDisclosureProps}
            btnProps={{
              mt: "2",
              mb: "4",
              children: formatMessage({
                id: "focusPoster.create",
              }),
              color: "brand.800",
              variant: "link",
              rightIcon: <FiPlus />,
            }}
            bodyProps={{
              children: (
                <Box
                  as="form"
                  mt={5}
                  onSubmit={(e: FormEvent) => {
                    e.preventDefault();
                    createMutation(editValue);
                    createPosterDisclosureProps.onClose();
                    setEditValue("");
                  }}
                >
                  <Input
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                  />
                  <Box d="flex" justifyContent="center" mt={5}>
                    <PrimaryButton
                      type="submit"
                      style={{
                        width: "14em",
                      }}
                    >
                      <FormattedMessage
                        id="focusPoster.create.name_label"
                        defaultMessage="ادخل عنوان للFocus Poster"
                      />
                    </PrimaryButton>
                  </Box>
                </Box>
              ),
            }}
          />
          <HStack
            maxW="100vw"
            flexDir={["column", "column", "column", "column", "row"]}
            alignItems={["center", "center", "center", "center", "flex-start"]}
            justifyContent={{ xl: "space-between" }}
          >
            <Box w={["100%", "100%", "100%", "100%", "100%"]}>
              <VStack gridRowGap="2" flexDir={"column-reverse"}>
                {posters?.data
                  ?.concat(sharedPosters?.data || [])
                  .sort(
                    (item1, item2) =>
                      Number(item1.createdAt) - Number(item2.createdAt)
                  )
                  .map((card: any, index: any) => (
                    <ItemCard
                      key={`${index}_card`}
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`${FOCUS_POSTER_ROUTE}/${card.id}`, card);
                      }}
                      remove={() => deletePoster(card.id)}
                      share={() =>
                        onPosterShare({
                          id: card.id,
                          name: card.slug ?? card.name,
                        })
                      }
                      isSharedWithMe={Boolean(card.shared)}
                      permission={card.shared?.permission}
                      // douplicate={() => douplicatePoster(card.id)}
                    >
                      {" "}
                      <Box
                        as="span"
                        overflow="hidden"
                        maxWidth="200px"
                        display="block"
                        whiteSpace="pre"
                        textOverflow="ellipsis"
                      >
                        {card.slug ?? card.name}
                      </Box>
                    </ItemCard>
                  ))}
              </VStack>
            </Box>
          </HStack>
          <SharingModal
            isOpen={isPosterSharingModalOpen}
            onClose={onPosterSharingModalClose}
            sharedEntity={{ name: "poster", id: currentList.id }}
            showOptions={true}
          />
        </BaseView>
      </Flex>

      <Spacer />
      <Divider orientation="vertical" bgColor="#808080" mx="20" />

      <Flex flex={1} flexDirection="column" alignItems="start" mt="24px">
        <Breadcrumb>
          <BreadcrumbItem textStyle="h2" isCurrentPage>
            <Heading as="h2" textStyle={"h2"}>
              <Text>Focus Film</Text>
            </Heading>
          </BreadcrumbItem>
        </Breadcrumb>
        {/* <BaseView breadTitle="Focus Film" showPath={false}> */}
        <CreateFilmModal
          disclosureProps={createFilmDisclosureProps}
          posters={filmPosters}
          onCreate={handleCreateFilm}
        />
        <FilmList
          ownedFilms={ownedFilms}
          sharedFilms={sharedFilms}
          locale={locale}
          onFilmPlay={handlePlayButtonClick}
          onDelete={handleFilmDelete}
        />
        {showFilmPlayer && selectedFilm && (
          <FilmPlayer
            posters={selectedFilm.posters}
            audioUrl={selectedFilm.audioUrl}
            onClose={() => {
              handleCloseFilmPlayer();
              setSelectedFilm(null);
            }}
          />
        )}
        {/* </BaseView> */}
      </Flex>
      <SharingModal
        isOpen={isPosterSharingModalOpen}
        onClose={onPosterSharingModalClose}
        sharedEntity={{ name: "poster", id: currentList.id }}
        showOptions={true}
      />
    </Flex>
  );
};

FocusPoster.PosterDetails = PosterDetails;
export default FocusPoster;
