import React, { useState, useEffect } from "react";
import {
  VStack,
  HStack,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { get } from "src/api/index";
import useLocale from "src/providers/useLocale";
import { useHistory } from "react-router-dom";
import { FAQuestion } from "./types";

const FAQ = () => {
  const [locale] = useLocale();
  const history = useHistory();
  const [initialContent, setInitialContent] = useState<any>([]);

  useEffect(() => {
    //method for fetching data
    const fetchData = async () => {
      const response = await get<FAQuestion[]>("/faq", locale);
      if (Array.isArray(response)) {
        //filter the only complete entries
        // @ts-ignore
        const allTranslations = response
          .filter((q) => !q.isSalMagicFAQ)
          .map((q) => q.translations)
          .flat(); // this variable holds the real data in different languages
        // const renderData = allTranslations.filter((q) => q.locale === locale);
        setInitialContent(allTranslations);
        return;
      }
      history.push("/");
    };
    fetchData();
  }, [history, locale]);

  return (
    <VStack
      width={{ base: "100%", sm: "80vw", md: "60vw" }}
      display="flex"
      flexDir="column"
      alignItems="center"
      overflow="hidden"
      paddingTop="50px"
      margin="0 auto"
      paddingBottom="50px"
    >
      <HStack paddingBottom="20px" minW="100%">
        {/* <Input type='text' placeholder={intl.formatMessage({ id: 'search' })} width="100%" onChange={search} /> */}
        <Accordion defaultIndex={[0]} allowMultiple w="100%">
          {initialContent.map((item) => {
            return (
              <AccordionItem key={item.id}>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                      {item.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>{item.answer}</AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </HStack>
    </VStack>
  );
};

export default FAQ;
