import { useDispatch, useSelector } from "src/store";
import { Box, Flex, Tag, TagLabel, Text } from "@chakra-ui/react";
import React from "react";
import { BsInfoCircle } from "react-icons/bs";
import { setFocusProgram } from "src/store/assistant";
import { useIntl } from "react-intl";

export const SAL125Programs = [
  "preparation",
  "affirmation",
  "gate",
  "communication",
  "frequency",
];

const ProgramSelector = () => {
  const dispatch = useDispatch();
  const { focusProgram: currentFocusProgram } = useSelector((state) => ({
    focusProgram: state.assistant.focusProgram,
  }));

  const { formatMessage } = useIntl();

  const handleProgramSelect = (chip: string) => {
    dispatch(setFocusProgram(chip));
  };

  return (
    <Box
      p={4}
      mt="auto"
      bgColor="#fff"
      display={currentFocusProgram ? "none" : "flex"}
      flexDirection="column"
    >
      <Flex
        alignItems="center"
        justifyContent="start"
        gap={3}
        borderTop="1px solid"
        borderColor="gray.200"
        pt={2}
      >
        <BsInfoCircle />
        <Text fontSize={"smaller"} flex={1}>
          {formatMessage({ id: "sal_assistant.section.selection.hint" })}
        </Text>
      </Flex>
      {
        <Flex gap={2} wrap="wrap" mt={2}>
          {SAL125Programs.map((program) => (
            <Tag
              key={program}
              size="lg"
              cursor="pointer"
              onClick={() => handleProgramSelect(program)}
              aria-label={`Select ${program}`}
              bgColor="#F2F2F3"
              textColor="#454555"
              _hover={{ bgColor: "#1F3F93", textColor: "white" }}
              transition="background-color 0.3s ease, color 0.3s ease"
            >
              <TagLabel fontSize="sm">
                {formatMessage({ id: program })}
              </TagLabel>
            </Tag>
          ))}
        </Flex>
      }
    </Box>
  );
};

export default ProgramSelector;
