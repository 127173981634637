import { Center, Input, Text } from "@chakra-ui/react";
import React from "react";
import { FormattedMessage } from "react-intl";
import PrimaryButton from "src/components/PrimaryButton";
import { ReturnUseMission } from "./useMission";
type Step1Props = {
  nextStep: () => void;
} & ReturnUseMission;
const Step1: React.FC<Step1Props> = ({ nextStep, setName, mission }) => {
  const onClick = () => {
    nextStep();
  };
  return (
    <>
      <Text
        d="inline-block"
        color="brand.900"
        fontSize="18px"
        fontWeight="bold"
        fontFamily="Calibri (Body)"
        padding={"20px 0"}
      >
        <FormattedMessage
          id="missions.create.step1.title"
          defaultMessage="ادخل عنوان للمهمة"
        />
      </Text>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!mission.name) return;
          onClick();
        }}
      >
        <Input
          variant="flushed"
          value={mission.name}
          onChange={(e) => setName(e.target.value)}
        />
        <Center my={"32px"}>
          <PrimaryButton onClick={onClick} disabled={mission.name === ""}>
            <FormattedMessage id="next" defaultMessage="التالي" />
          </PrimaryButton>
        </Center>
      </form>
    </>
  );
};
export default Step1;
