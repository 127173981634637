import { IconButton, Radio, RadioGroup, Stack, useToast } from '@chakra-ui/react'
import React from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useIntl } from 'react-intl'
import { put, deleteMethod } from 'src/api'



const SharedUserItem = ({ sharedItem, setUsersList, showOptions }) => {
    const toast = useToast();
    const { formatMessage } = useIntl();

    const onRemove = (id) => {
        deleteMethod(`/shared/${id}`).then((res: any) => {
            if (res.success) {
                toast({
                    title: formatMessage({ id: "saved" }),
                    status: "success",
                    duration: 4000,
                });
                setUsersList(users => users.filter(user => user.id !== id))
            } else {
                toast({
                    title: formatMessage({ id: "errorOnSaving" }),
                    status: "error",
                    duration: 4000,
                });
            }
        })
    }

    const changePermission = (permission: string, entityId: number) => {
        put(`/shared/${entityId}`, { permission }).then(res => {
            if (res.accessor.id) {
                toast({
                    title: formatMessage({ id: "saved" }),
                    status: "success",
                    duration: 4000,
                });
                setUsersList(entitys => {
                    return entitys.map(entity => {
                        if (entity.id === entityId) {
                            return { ...entity, permission }
                        }
                        return entity
                    })
                })
            }
        })
    }

    return (
        <Stack direction="row" my="3" py={"1"} px={"3"} bg={"blackAlpha.50"} borderRadius="5" justifyContent={"space-between"}>
            <div>
                <div>
                    <p>{sharedItem.accessor.email}</p>
                    <small>{sharedItem.accessor.name}</small>
                </div>
                {showOptions ? <RadioGroup
                    onChange={(e: any) => {
                        changePermission(e, sharedItem.id)
                    }}
                    defaultValue={sharedItem.permission}
                >
                    <Stack direction="row">
                        <Radio value="read" >
                            {formatMessage({ id: "readOnly" })}
                        </Radio>
                        <Radio value="update">
                            {formatMessage({ id: "readAndUpdate" })}
                        </Radio>
                    </Stack>
                </RadioGroup> : null}
            </div>
            <IconButton
                color="#e97575"
                onClick={() => onRemove(sharedItem.id)}
                fontSize="20px"
                variant="link"
                aria-label="delete"
                mx="10px"
                minW={1}
                icon={<RiDeleteBin6Line />}
            />
        </Stack>
    )
}

export default SharedUserItem