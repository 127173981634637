import React, { KeyboardEvent } from "react";
import {
  Box,
  Input,
  Text,
  Textarea,
  FormLabel,
  Button,
} from "@chakra-ui/react";
import { ProcessType, Case } from "./Session";
import { useIntl } from "react-intl";

type ProcessTypes = {
  process: ProcessType;
  added: Array<{ name: string; value: string }>;
  currentCase?: Case;
  handler: (e: KeyboardEvent) => void;
  show: boolean;
  createSession: (redirect?: boolean) => Promise<void>;
  addNote: (txt: string) => void;
};

const Process: React.FC<ProcessTypes> = ({
  process,
  added,
  currentCase,
  handler,
  show,
  createSession,
  addNote,
}) => {
  const { formatMessage } = useIntl();

  //function to obtain date string
  const getDateString = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date();
    return `${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  };

  return (
    <Box paddingTop="0px" >
      <Box display="flex" flexDir="column" alignItems="center">
        {added.map(({ name, value }) => (
          <Box key={name} textAlign="center" width="100%" whiteSpace="pre">
            <Text padding="5px 0 0 0" fontWeight="bold">
              {name && formatMessage({ id: `iterations.${name}` })}
            </Text>
            <Text padding="5px 0" fontWeight="bold">
              {name && currentCase?.sequence}
            </Text>
            <Input
              textAlign="center"
              background="white"
              disabled
              value={value}
              css={{ "&:disabled": { opacity: 1, background: "#e8ebe9" } }}
            />
          </Box>
        ))}
      </Box>
      {process.current < process.iterations.length && !show && (
        <Box paddingTop="10px" textAlign="center" whiteSpace="pre">
          {process.iterations[process.current] && (
            <Text fontWeight="bold" fontSize={22} padding="5px 0 0 0" mb="20px">
              {formatMessage({
                id: `iterations.${process.iterations[process.current]}`,
              })}
            </Text>
          )}
          <Text fontWeight="bold" fontSize={22} padding="5px 0 10px 0">
            {currentCase?.sequence}
          </Text>
          <Input
            id="process-input"
            background="white"
            min={process.min}
            max={process.max}
            onKeyDown={handler}
            placeholder={`${process.min} - ${process.max}`}
            textAlign="center"
            dir="ltr"
            type="number"
          />
        </Box>
      )}
      {show && (
        <Box paddingTop="10px" textAlign="center">
          <Text fontWeight="bold" fontSize={22} padding="10px 0">
            Eye Roll
          </Text>
          <Box my={3}>
            <FormLabel fontWeight="bold">
              {formatMessage({ id: "date" })}:{" "}
            </FormLabel>
            <Text
              dir="ltr"
              color="gray.500"
              fontSize={16}
              padding="7px 0"
              background="#e8ebe9"
              cursor="blocked"
            >
              {getDateString()}
            </Text>
          </Box>
          <Box my={3}>
            <FormLabel fontWeight="bold">
              {formatMessage({ id: "magic.notes" })}:{" "}
            </FormLabel>
            <Textarea
              height="150px"
              onChange={(e) => addNote(e.target.value)}
            ></Textarea>
          </Box>
          <Box my={3} textAlign="center">
            <Button
              type="submit"
              colorScheme="messenger"
              onClick={() => createSession(true)}
            >
              {formatMessage({ id: "submit" })}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Process;
