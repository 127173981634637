import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  create,
  deleteWish as deleteMission,
  edit,
  duplicateWish,
} from "src/api/prepration/vision/mutation";
import {
  getList,
  vision,
  getSharedList,
} from "src/api/prepration/vision/query";
import { VisionListItem } from "src/types";

export type UseVisionsApiReturn = {};

export const useVisionsApi = (currentId?: number) => {
  const client = useQueryClient();
  //List API

  //  storing state locally instead of
  //  using api's state directly
  //  to place them in different lists
  const [completedWishList, setCompletedWishList] =
    useState<VisionListItem[]>();
  const [notCompletedWishList, setNotCompletedWishList] =
    useState<VisionListItem[]>();
  const [visionList, setVisionList] = useState<VisionListItem[]>();
  const [listLoading, setListLoading] = useState(true);

  const { data, error: listError } = useQuery<VisionListItem[], Error>(
    ...getList
  );

  const { data: sharedList, error: sharedListError } = useQuery<
    VisionListItem[],
    Error
  >(...getSharedList);
  const splitApiData = useCallback(() => {
    if (!data) return;
    // if (!sharedList) return;
    const sharedCompleted = sharedList?.filter(
      (d) => d.state === "completed-vision"
    );
    if (sharedCompleted) {
      setVisionList(
        data
          .filter((d) => d.state === "completed-vision")
          .concat(sharedCompleted)
      );
    } else {
      setVisionList(data.filter((d) => d.state === "completed-vision"));
    }
    const sharedInCompleted = sharedList?.filter(
      (d) => d.state === "incompleted-vision"
    );
    if (sharedInCompleted) {
      setCompletedWishList(
        data
          .filter((d) => d.state === "incompleted-vision")
          .concat(sharedInCompleted)
      );
    } else {
      setCompletedWishList(
        data.filter((d) => d.state === "incompleted-vision")
      );
    }
    const sharedNotCompletedWishes = sharedList?.filter(
      (d) => d.state === "incompleted-wishes"
    );
    if (sharedNotCompletedWishes) {
      setNotCompletedWishList(
        data
          .filter((d) => d.state === "incompleted-wishes")
          .concat(sharedNotCompletedWishes)
      );
    } else {
      setNotCompletedWishList(
        data.filter((d) => d.state === "incompleted-wishes")
      );
    }

    setListLoading(false);
  }, [data, sharedList]);

  useEffect(() => {
    if (data) {
      splitApiData();
    }
  }, [data, splitApiData, sharedList]);

  //Create/Edit/Delete API
  const { mutateAsync: createMutation } = useMutation(create, {
    onSuccess: () => {
      client.refetchQueries([getList[0], getSharedList[0]]);
      client.invalidateQueries();
      // client.refetchQueries({ queryKey: [getList[0], getSharedList[0]] });
    },
  });
  const { mutateAsync: editMutation } = useMutation(edit, {
    onSuccess: () => {
      client.refetchQueries([getList[0], getSharedList[0]]);
      client.invalidateQueries();
    },
  });
  const { mutateAsync: removeMutation } = useMutation(deleteMission, {
    onSuccess: () => {
      client.refetchQueries([getList[0], getSharedList[0]]);
      client.invalidateQueries();
    },
  });

  const { mutateAsync: duplicateMutation } = useMutation(duplicateWish, {
    onSuccess: () => {
      client.refetchQueries([getList[0], getSharedList[0]]);
      client.invalidateQueries();
    },
  });

  //Get One
  const {
    isLoading: visionLoading,
    data: currentVision,
    error: visionError,
  } = useQuery(...vision(currentId));

  return {
    completedWishList,
    notCompletedWishList,
    visionList,
    listLoading,
    listError,
    editMutation,
    createMutation,
    removeMutation,
    visionLoading,
    currentVision,
    visionError,
    duplicateMutation,
    sharedList,
  };
};
