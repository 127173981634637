import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React from "react";

type MainModalProps = {
  modalHeader?: any;
  modalFooter?: any;
  modalDisclosure: UseDisclosureReturn;
  closeModal?: () => void;
  maxH?: any;
};

const MainModal: React.FC<MainModalProps> = ({
  modalFooter,
  modalHeader,
  modalDisclosure,
  closeModal,
  maxH,
  children,
}) => {
  return (
    <Modal
      isOpen={modalDisclosure.isOpen}
      onClose={closeModal || modalDisclosure.onClose}
    >
      <ModalOverlay />
      <ModalContent
        maxH={maxH}
        px={["12px", "24px", "72px"]}
        py="20px"
        borderRadius="30px"
        maxW={{ base: "90%", lg: "900px" }}
      >
        <ModalHeader>
          <ModalCloseButton />
          {modalHeader}
        </ModalHeader>
        <ModalBody overflowY={"auto"}>{children}</ModalBody>
        <ModalFooter>{modalFooter}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MainModal;
