import { Box, HStack, Link } from "@chakra-ui/react";
import React from "react";
import { BASE_MEDIA } from "src/api";
import { currentEnvTemplates } from "./templates";
import { useIntl } from "react-intl";

const PosterTemplates = ({ poster, update, resetTitle }: any) => {
  const { locale } = useIntl();
  return (
    <HStack mt="5" w="100%" overflowX={"scroll"}>
      {currentEnvTemplates
        .filter((template, idx) => template.name.includes(locale))
        .map((card, idx) => {
          return (
            <Box
              key={"op-download" + idx}
              minW="200px"
              style={{ borderRadius: "4px !important" }}
            >
              <Link
                as={"button"}
                onClick={() => {
                  update({
                    ...poster,
                    coverUrl: card.coverUrl,
                    name: card.title,
                  });
                  resetTitle("");
                }}
              >
                <img
                  src={`${BASE_MEDIA}/${card.thumbnailImageUrl}`}
                  alt={card.name}
                />
              </Link>
            </Box>
          );
        })}
    </HStack>
  );
};
export default PosterTemplates;
