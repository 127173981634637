import React, { useEffect } from "react";
import { Box, Image } from "@chakra-ui/react";
import "./RedirectLoading.css";
import { LANDING_PAGE_ROUTE, PORTAL_ROUTE } from "src/routes";

const RedirectLoading: React.FC<{}> = () => {
  
  useEffect(() => {
    const token = localStorage.getItem("__sal_auth");
    if (!token) {
      window.location.href = LANDING_PAGE_ROUTE;
      return;
    }

    window.location.pathname = PORTAL_ROUTE;
  }, []);


  return (
    <Box className="fadeInOut" boxSize="sm" h="100%" m="auto">
      <Image src="logo-dark.png" alt="SAL125" />
    </Box>
  );
};

export default RedirectLoading;
