// a function to check if the plan have at least one action

import { Plan } from "src/types";

export const checkAnyActions = (plan: Plan) => {
  if (!plan) return false;
  if (!plan.aspects) return false;

  for (const aspect of plan.aspects) {
    for (const goal of aspect.goals) {
      if (!goal.actions) return false;
      if (goal.actions.length > 0) {
        return true;
      }
    }
  }

  return false;
};
