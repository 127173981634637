import {
  QueryObserverResult,
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  addAspect,
  create,
  douplicate,
  deletePlan,
  addGoalAction,
  deleteAction,
  editAction,
  deletePlanGoal,
  deletePlanAspect,
  addGoal,
  editPlanName,
  editAspect,
  editGoal,
  reorderAspects,
  reorderAspectGoals,
  reorderGoalActions,
  addNewGroup,
  deleteGroup,
  addWeight,
  douplicateShared,
} from "src/api/prepration/salPlan/mutation";
import {
  getList,
  plan,
  getSharedList,
  getPlanGroups,
} from "src/api/prepration/salPlan/query";
import { Plan, PlanAspect, PlanAspectGoal, PlanGoalAction } from "src/types";
export type usePlanApiReturn = {
  planListQuery: QueryObserverResult<Plan[], Error>;
  sharedListQuery: QueryObserverResult<any[], Error>;
  createPlan: (plan: Plan) => Promise<Plan>;
  deletePlanMutation: (id: number) => Promise<void>;
  planQuery: QueryObserverResult<Plan, Error>;
  addPlanAspectMutation: (aspect: PlanAspect, planId: number) => Promise<Plan>;
  addActionMutation: (goalId: number, action: PlanGoalAction) => Promise<Plan>;
  deletePlanAction: (actionId: number) => Promise<void>;
  editActionMutation: UseMutateAsyncFunction<
    Plan,
    unknown,
    {
      action: PlanGoalAction;
    },
    unknown
  >;
  editGoalMutation: UseMutateAsyncFunction<
    Plan,
    unknown,
    {
      goal: PlanAspectGoal;
    },
    unknown
  >;
  deleteGoalMutation: UseMutateAsyncFunction<void, unknown, number, unknown>;
  deleteAspectMutation: UseMutateAsyncFunction<void, unknown, number, unknown>;
  addGoalMutation: UseMutateAsyncFunction<
    PlanAspectGoal,
    unknown,
    {
      goal: PlanAspectGoal;
      aspectId: number;
    },
    unknown
  >;
  editPlanNameMutation: UseMutateAsyncFunction<
    Plan,
    unknown,
    {
      planId: number;
      name: string;
    },
    unknown
  >;
  editAspectMutation: UseMutateAsyncFunction<
    Plan,
    unknown,
    {
      aspect: PlanAspect;
    },
    unknown
  >;

  reorderAspectsMutation: UseMutateAsyncFunction<
    Plan,
    unknown,
    {
      aspects: number[];
      planId: number;
    },
    unknown
  >;

  reorderAspectGoalsMutation: UseMutateAsyncFunction<
    Plan,
    unknown,
    {
      goals: number[];
      aspectId: number;
    },
    unknown
  >;

  reorderGoalActionsMutation: UseMutateAsyncFunction<
    Plan,
    unknown,
    {
      actions: number[];
      goalId: number;
    },
    unknown
  >;

  planGroupsQuery: QueryObserverResult<any[], Error>;

  addNewGroupMutation: UseMutateAsyncFunction<
    Plan,
    unknown,
    {
      name: string;
    },
    unknown
  >;

  addWeightMutation: UseMutateAsyncFunction<
    void,
    unknown,
    {
      weight: number;
      id: number;
    },
    unknown
  >;

  douplicateMutation: UseMutateAsyncFunction<
    void,
    unknown,
    {
      planId: string;
      groupId?: string;
    },
    unknown
  >;

  douplicateSharedMutation: UseMutateAsyncFunction<
    void,
    unknown,
    {
      planId: string;
      groupId?: string;
    },
    unknown
  >;
};

const usePlanApi = (planId?: number): usePlanApiReturn => {
  const queryClient = useQueryClient();

  const planListQuery = useQuery<Plan[], Error>(...getList);

  const sharedListQuery = useQuery<Plan[], Error>(...getSharedList);

  const planGroupsQuery = useQuery(["planGroups"], getPlanGroups);

  const planQuery = useQuery(...plan(planId));

  const { mutateAsync: createMutation } = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries(getList[0]);
      queryClient.invalidateQueries("planGroups");
    },
  });

  const { mutateAsync: douplicateMutation } = useMutation(douplicate, {
    onSuccess: () => {
      queryClient.invalidateQueries(getList[0]);
      queryClient.invalidateQueries("planGroups");
    },
  });

  const { mutateAsync: douplicateSharedMutation } = useMutation(
    douplicateShared,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getList[0]);
        queryClient.invalidateQueries("planGroups");
      },
    }
  );

  const { mutateAsync: addGoalActionMutation } = useMutation(addGoalAction, {
    onSuccess: (params) => {
      queryClient.invalidateQueries(["plan", planId]);
    },
  });

  const { mutateAsync: deleteMutation } = useMutation(deletePlan, {
    onSuccess: () => {
      queryClient.invalidateQueries(getList[0]);
      queryClient.invalidateQueries("planGroups");
    },
  });

  const { mutateAsync: addAspectMutation } = useMutation(addAspect, {
    onSuccess: ({ id }) => queryClient.invalidateQueries(["plan", planId]),
  });

  const { mutateAsync: deleteAspectMutation } = useMutation(deletePlanAspect, {
    onSuccess: () => queryClient.invalidateQueries(["plan", planId]),
  });

  const { mutateAsync: deleteActionMutation } = useMutation(deleteAction, {
    onSuccess: () => queryClient.invalidateQueries(["plan", planId]),
  });

  const { mutateAsync: deleteGoalMutation } = useMutation(deletePlanGoal, {
    onSuccess: () => queryClient.invalidateQueries(["plan", planId]),
  });

  const addPlanAspectMutation = (aspect: PlanAspect, planId: number) =>
    addAspectMutation({ aspect, planId });

  const deletePlanMutation = (id: number) => deleteMutation(id);

  const { mutateAsync: editActionMutation } = useMutation(editAction, {
    onSuccess: () => queryClient.invalidateQueries(["plan", planId]),
  });

  const { mutateAsync: editGoalMutation } = useMutation(editGoal, {
    onSuccess: () => queryClient.invalidateQueries(["plan", planId]),
  });

  const { mutateAsync: editAspectMutation } = useMutation(editAspect, {
    onSuccess: () => queryClient.invalidateQueries(["plan", planId]),
  });

  const { mutateAsync: addGoalMutation } = useMutation(addGoal, {
    onSuccess: () => queryClient.invalidateQueries(["plan", planId]),
  });

  const { mutateAsync: editPlanNameMutation } = useMutation(editPlanName, {
    onSuccess: () => queryClient.invalidateQueries(getList[0]),
  });

  const { mutateAsync: addNewGroupMutation } = useMutation(addNewGroup, {
    onSuccess: () => queryClient.invalidateQueries("planGroups"),
  });

  const { mutateAsync: reorderAspectsMutation } = useMutation(reorderAspects);
  const { mutateAsync: reorderAspectGoalsMutation } =
    useMutation(reorderAspectGoals);

  const { mutateAsync: reorderGoalActionsMutation } =
    useMutation(reorderGoalActions);

  const { mutateAsync: addWeightMutation } = useMutation(addWeight);

  const addActionMutation = (goalId: number, action: PlanGoalAction) =>
    addGoalActionMutation({ action, goalId });

  const deletePlanAction = (actionId: number) => deleteActionMutation(actionId);

  const createPlan = (plan: Plan) => createMutation(plan);

  return {
    planListQuery,
    sharedListQuery,
    createPlan,
    douplicateMutation,
    deletePlanMutation,
    addPlanAspectMutation,
    planQuery,
    addActionMutation,
    deletePlanAction,
    editActionMutation,
    deleteGoalMutation,
    deleteAspectMutation,
    addGoalMutation,
    editGoalMutation,
    editPlanNameMutation,
    editAspectMutation,
    reorderAspectsMutation,
    reorderAspectGoalsMutation,
    reorderGoalActionsMutation,
    planGroupsQuery,
    addNewGroupMutation,
    addWeightMutation,
    douplicateSharedMutation,
  };
};

export default usePlanApi;
