import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Input,
} from "@chakra-ui/react";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ValueItem from "./ValueItem";
import { ValuesList } from "src/types";
import PrimaryButton from "src/components/PrimaryButton";
import { FieldArray, Formik, FormikProps } from "formik";
import { arabicList, englishList } from "./autoCompleteList";
import { IoMdAddCircle } from "react-icons/io";
import classes from "./ValueCreate.module.css";
import CreatableSelect from "react-select/creatable";
const initialValues: ValuesList = {
  id: -1,
  name: "",
  values: [],
  isComplete: false,
};

type ValueCreateProps = {
  onSubmit: (valuesList: ValuesList) => void;
  isComplete?: boolean;
};

const ValueCreate: React.FC<ValueCreateProps> = ({ onSubmit, isComplete }) => {
  const [newValue, setNewValue] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const { locale, formatMessage } = useIntl();
  const [counter, setCounter] = useState(0);
  const items = locale === "ar" ? arabicList : englishList;

  //formik ref
  const formikRef = useRef<FormikProps<ValuesList>>(null);

  //submit listener for values
  const submitListener = useCallback((e: KeyboardEvent) => {
    if (e.key === "Enter" && formikRef?.current?.values.values.length === 10) {
      formikRef?.current?.submitForm();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", submitListener);

    return () => {
      window.removeEventListener("keydown", submitListener);
    };
  }, [submitListener]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Box>
          <FormControl mb="4">
            <FormLabel as="h5" textStyle="h5">
              <FormattedMessage
                id="values.create.name_label"
                defaultMessage="ادخل عنوان للمصفوفة"
              />
            </FormLabel>
            <Input
              onKeyDown={(event: any) => {
                if (event.which === 13 || event.keyCode === 13) {
                  document.getElementById("inputValues")?.focus();
                  return false;
                }
                return true;
              }}
              variant="flushed"
              name="name"
              value={values.name}
              onChange={(e) => setFieldValue("name", e.target.value)}
              required
            />
          </FormControl>
          <FieldArray name="values">
            {(arrayHelpers) => {
              return (
                <>
                  <FormControl>
                    <FormLabel as="h5" textStyle="h5">
                      <FormattedMessage
                        id="values.create.values_label"
                        defaultMessage="قم بادخال أهم عشر قيم في حياتك بدون ترتيب"
                      />
                    </FormLabel>
                    <Box display={"flex"}>
                      <CreatableSelect
                        onInputChange={(e, meta) => {
                          if (meta.action === "input-change") {
                            setInputValue(e);
                          }
                        }}
                        placeholder={formatMessage({
                          id: "chooseValue",
                        })}
                        options={items}
                        onKeyDown={(event: any) => {
                          if (event.which === 13 || event.keyCode === 13) {
                            if (
                              !newValue ||
                              !newValue?.value?.replace(/\s/g, "").length
                            ) {
                              return;
                            }
                            arrayHelpers.push({
                              name: newValue.value,
                              id: counter,
                              isComplete: false,
                            });
                            setCounter((old) => old + 1);
                            setNewValue(null);
                            return false;
                          }
                          return true;
                        }}
                        id={"inputValues"}
                        onChange={setNewValue}
                        value={newValue}
                        isDisabled={values.values.length === 10}
                        className={classes.select}
                      />
                      <IconButton
                        variant="link"
                        disabled={values.values.length === 10}
                        color="brand.800"
                        fontSize="28px"
                        aria-label="add"
                        onClick={() => {
                          if (newValue) {
                            arrayHelpers.push({
                              name: newValue.value,
                              id: counter,
                              isComplete: false,
                            });
                            setInputValue(null);
                            setNewValue(null);
                            return;
                          }
                          if (inputValue) {
                            arrayHelpers.push({
                              name: inputValue,
                              id: counter,
                              isComplete: false,
                            });
                            setInputValue(null);
                            setNewValue(null);
                            return;
                          }
                        }}
                        style={{
                          width: "5%",
                        }}
                        icon={<IoMdAddCircle />}
                      />
                    </Box>
                  </FormControl>
                  {values?.values?.map((value, idx) => (
                    <ValueItem
                      idx={idx}
                      key={idx}
                      value={value.name}
                      edit={(v) => {
                        setFieldValue(`values[${idx}].value`, v);
                        arrayHelpers.replace(idx, { ...value, name: v });
                      }}
                      remove={() => arrayHelpers.remove(idx)}
                    />
                  ))}
                </>
              );
            }}
          </FieldArray>
          <Box d="flex" justifyContent="center" mt={5}>
            <PrimaryButton
              disabled={values.values.length !== 10 || values.name === ""}
              title={
                values.values.length !== 10
                  ? formatMessage({
                      id: "choose10Value",
                    })
                  : values.name === ""
                  ? formatMessage({
                      id: "enterName",
                    })
                  : "submit"
              }
              onClick={() => handleSubmit()}
            >
              <FormattedMessage id="next" defaultMessage="التالي" />
            </PrimaryButton>
          </Box>
        </Box>
      )}
    </Formik>
  );
};
export default ValueCreate;
