import { DownloadIcon } from "@chakra-ui/icons";
import { Box, IconButton, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import ReactExport from "react-export-excel";
import { useIntl } from "react-intl";
import useLocale from "src/providers/useLocale";
import { PlanGoalAction } from "src/types";
import usePlanApi from "../usePlanApi";
import { FiltersContext } from "./FiltersContext";
import { useSelector } from "src/store";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// TODO: 1- FETCH DATA

// 2- MAP TO NEW STRUCTURE
//  PASS IT TO THE COMPONENT

const ExcelPlan = () => {
  const planId = +window.location.pathname.split("/")[4] || 0;

  const { planQuery } = usePlanApi(planId);

  const { endDate, excutor, startDate } = useContext(FiltersContext);

  const user = useSelector((state) => state.auth.user);
  // console.log(user?.name)
  const { formatMessage } = useIntl();
  const [locale] = useLocale();
  // console.log(locale)
  const dataSet1: any = [];

  let row: any = {};
  let allActions: PlanGoalAction[] = [];

  const data = planQuery.data?.aspects
    ?.map((aspect) => {
      let validGoals = aspect.goals
        .map((goal) => {
          let validActions = goal.actions?.filter((action) => {
            let isValid: boolean[] = []; // no filters applied
            // console.log('no Filter', !excutor&&!startDate&&!endDate )
            if (!excutor && !startDate && !endDate) {
              return true;
            } else {
              if (excutor) {
                isValid.push(
                  action.executor
                    .trim()
                    .toLocaleLowerCase()
                    .includes(excutor.trim().toLocaleLowerCase())
                );
              }

              if (startDate) {
                // return start date
                isValid.push(
                  new Date(action.startDate)
                    .toLocaleDateString("en-GB")
                    .slice(0, 10) ===
                    startDate?.toLocaleDateString("en-GB").slice(0, 10)
                );
                // console.log(startDate?.toLocaleDateString('en-GB').slice(0,10),new Date( action.startDate).toLocaleDateString('en-GB').slice(0,10))
              }

              if (endDate) {
                // return end date
                isValid.push(
                  new Date(action.endDate)
                    .toLocaleDateString("en-GB")
                    .slice(0, 10) ===
                    endDate?.toLocaleDateString("en-GB").slice(0, 10)
                );
                // console.log(new Date().getTimezoneOffset()) // is it ??
              }
              return isValid.every((value) => value) && action;
            }
          });
          return validActions?.length
            ? { ...goal, actions: validActions }
            : { ...goal, actions: [] };
        })
        .filter((goal) => {
          if (!excutor && !startDate && !endDate) {
            return goal;
          }
          return goal.actions?.length;
        });
      // console.log('valid',validGoals)
      return { ...aspect, goals: validGoals };
    })
    .filter((aspect) => {
      if (!excutor && !startDate && !endDate) {
        return aspect;
      }
      return aspect.goals.length;
    });

  // valuesString.push(`${planQuery.data?.name}:-`);
  // let isHeaderRenderdOnce = false // to prevent printing the header for each table
  dataSet1.push({
    action: "",
    quantity: "",
    executor: "", // @ts-ignore
    startDate: data?.name,
    endDate: "",
    counter: "",
    isCommpleted: "",
  }); // we put it in start date field just to center it
  dataSet1.push({});
  dataSet1.push({
    action: "",
    quantity: "",
    executor: "",
    startDate: user?.name,
    endDate: "",
    counter: "",
    isCommpleted: "",
  }); // we put it in start date field just to center it
  dataSet1.push({});
  dataSet1.push({});
  data &&
    // eslint-disable-next-line array-callback-return
    data.map((aspect, idx, arr) => {
      dataSet1.push({
        ...row,
        action: `${formatMessage({ id: "aspect" })}${idx + 1}: ${aspect.name}`,
      });
      dataSet1.push({});
      // plan.goal.action.create.excutor
      // eslint-disable-next-line array-callback-return
      aspect.goals.map((goal, idx, arr) => {
        dataSet1.push({
          ...row,
          action: `${formatMessage({ id: "goal" })}${idx + 1}: ${goal.name}`,
        });
        dataSet1.push({});
        // valuesString.push(``);
        // plan.goal.action.create.excutor
        // 'plan.goal.action.create.quantity'
        goal.actions?.length &&
          dataSet1.push({
            action: formatMessage({ id: "measure" }).toUpperCase(),
            quantity: formatMessage({
              id: "plan.goal.action.create.quantity",
            }).toUpperCase(),
            executor: formatMessage({
              id: "plan.goal.action.create.excutor",
            }).toUpperCase(),
            startDate: formatMessage({ id: "action.startdate" }).toUpperCase(),
            endDate: formatMessage({ id: "action.endDate" }).toUpperCase(),
            counter: formatMessage({ id: "done" }).toUpperCase(),
            isCommpleted: formatMessage({ id: "done" }).toUpperCase(),
          });
        // eslint-disable-next-line array-callback-return
        goal.actions?.map((action) => {
          allActions.push(action);
          dataSet1.push({
            ...row,
            action: action.name,
            quantity: action.quantity,
            executor: action.executor,
            startDate: new Date(action.startDate)
              .toLocaleDateString("en-GB")
              .slice(0, 10),
            endDate: new Date(action.endDate)
              .toLocaleDateString("en-GB")
              .slice(0, 10),
            counter: action.counter,
            isCommpleted: action.complete
              ? formatMessage({ id: "completed" })
              : formatMessage({ id: "notCompleted" }),
          });
        });
        dataSet1.push({});
      });
      dataSet1.push({}); // considier this line an empty line in the copied text
      dataSet1.push({}); // considier this line an empty line in the copied text
    });

  // let row:any = {}
  // planQuery?.data?.aspects&&planQuery.data.aspects.map((aspect,idx) => {
  //         row.aspect = aspect.name
  //         if (aspect.goals.length>0){
  //             aspect.goals.map((goal,idx) => {
  //               row.goal = goal.name

  //                 if (goal.actions && goal.actions.length > 0){
  //                     goal.actions.map((action) => {
  //                         row.action = action.name
  //                         row.quantity = action.quantity
  //                         row.executor = action.executor
  //                         row.startDate = action.startDate
  //                         row.endDate = action.endDate
  //                         row.counter = action.counter
  //                         row.isCommpleted = action.complete
  //                       })
  //                       dataSet1.push(row)
  //                 }
  //                 row = {}
  //                 dataSet1.push(row)
  //             })
  //         }
  //   row = {}
  //   dataSet1.push(row)
  // })
  // console.log(dataSet1)

  const downloadButton = (
    <Box
      d="flex"
      flexDir="column"
      m="0"
      p="0"
      alignItems="center"
      py={1}
      position="relative"
      right="3.5"
    >
      <IconButton
        bg={"#e6e6e6"}
        fontSize="30px"
        w="70px"
        h="70px"
        aria-label="print"
        color="brand.800"
        icon={<DownloadIcon />}
        text="Download as excelsheet"
      />
      <Text
        mt={"10px"}
        fontSize={"18px"}
        color="gray.900"
        maxW="100px"
        textAlign="center"
      >
        Download as excelsheet
      </Text>
    </Box>
  );
  const cols = [
    <ExcelColumn value="action" />,
    <ExcelColumn value="quantity" />,
    <ExcelColumn value={"executor"} />,
    <ExcelColumn value={"startDate"} />,
    <ExcelColumn value={"endDate"} />,
    <ExcelColumn value={"counter"} />,
    <ExcelColumn value={"isCommpleted"} />,
  ];

  return (
    <ExcelFile element={downloadButton} filename={planQuery.data?.name}>
      <ExcelSheet data={dataSet1} name={planQuery.data?.name}>
        {/* <ExcelColumn  value="aspect"/>
                <ExcelColumn  value="goal"/> */}
        {locale === "ar" ? cols.reverse() : cols}
      </ExcelSheet>
    </ExcelFile>
  );
};
export default ExcelPlan;
