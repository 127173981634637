import React, { useState } from "react";
import BaseView from "../BaseView";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Chakra } from "src/types";
import { useIntl } from "react-intl";
import { useGetChakras } from "src/newApi/hooks";
import { useDispatch } from "src/store";
import { playMedia } from "src/store/media";
import { MdPlaylistAdd } from "react-icons/md";
import AddToPlaylistModal from "src/components/AddToPlaylistModal";
import { CHAKRAS_ROUTE } from "src/routes";

const names = [
  "Root Chakra-001",
  "Sacral Chakra-002",
  "Solar Plexus-003",
  "Heart Chakra-004",
  "Throat Chakra-005",
  "Third Eye-006",
  "Crown Chakra-007",
  "All Chakras",
];

const Chakras = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [currentChakra, setCurrentChakra] = useState<Chakra>();
  const { isLoading, error, data: chakras } = useGetChakras();
  const {
    isOpen: isChakraOpen,
    onOpen: onChakraOpen,
    onClose: onChakraClose,
  } = useDisclosure();
  const {
    isOpen: isAddToPlaylistOpen,
    onOpen: onAddToPlaylistOpen,
    onClose: onAddToPlaylistClose,
  } = useDisclosure();
  //background media context
  return (
    <BaseView route={CHAKRAS_ROUTE}>
      <HStack
        display={"flex"}
        flexDir={{ base: "column", xl: "row" }}
        alignItems={{ base: "center", xl: "flex-start" }}
        paddingTop={"50px"}
      >
        {isLoading ||
        error ||
        chakras === undefined ||
        chakras?.length === 0 ? (
          <div style={{ padding: "30px", textAlign: "center" }}>
            {isLoading && "Loading..."}
            {error && error}
          </div>
        ) : (
          <>
            <Box
              display={"flex"}
              flexWrap="wrap"
              justifyContent="center"
              maxWidth={{ xl: "800px" }}
              cursor="pointer"
            >
              {chakras
                ?.filter((c) => c.name !== "All" && c.name !== "الكل")
                ?.map((chakra) => {
                  return (
                    <Box
                      width={{ base: "130px" }}
                      margin={{
                        base: "10px",
                        lg: "10px 60px",
                        xl: "30px 30px",
                      }}
                      alignItems="center"
                      onClick={() => {
                        setCurrentChakra(chakra);
                        onChakraOpen();
                      }}
                    >
                      <img
                        width="100%"
                        src={chakra?.imageUrl}
                        alt={chakra?.name}
                      />
                      <Text textAlign="center">{chakra?.name}</Text>
                    </Box>
                  );
                })}
            </Box>
            {chakras
              ?.filter((c) => c.name === "All" || c.name === "الكل")
              ?.map((chakra) => {
                return (
                  <Box
                    width={{ base: "140px" }}
                    paddingTop={{ base: "40px", xl: "120px" }}
                    onClick={() => {
                      setCurrentChakra(chakra);
                      onChakraOpen();
                    }}
                    cursor="pointer"
                  >
                    <img
                      width="100%"
                      src={chakra?.imageUrl}
                      alt={chakra?.name}
                    />
                    <Text textAlign="center">{chakra?.name}</Text>
                  </Box>
                );
              })}
            <Modal
              isOpen={isChakraOpen}
              onClose={onChakraClose}
              isCentered
              allowPinchZoom
            >
              <ModalOverlay />
              <ModalContent paddingBottom="20px" width={"fit-content"}>
                <ModalHeader>
                  <ModalCloseButton color="black" bg="white" />
                </ModalHeader>
                <ModalBody>
                  <Button
                    width={"fit-content"}
                    onClick={() => {
                      if (currentChakra) {
                        const index = chakras.indexOf(currentChakra);
                        dispatch(
                          playMedia({
                            source: currentChakra?.audioUrl!,
                            name: names[index]!,
                            type: "audio",
                            id: `chakra-00${index + 1}`,
                            loop: false,
                            disableSeeking: true,
                            gif: currentChakra?.gifUrl,
                            frBtn: true,
                          })
                        );
                      }
                      onChakraClose();
                    }}
                  >
                    {formatMessage({
                      id: "audio",
                    })}
                  </Button>
                  <Button
                    marginStart={"10px"}
                    width={"fit-content"}
                    onClick={() => {
                      if (currentChakra) {
                        const index = chakras.indexOf(currentChakra);
                        dispatch(
                          playMedia({
                            source: currentChakra?.videoUrl!,
                            name: names[index]!,
                            type: "video",
                            id: `chakra-00${index + 1}`,
                            loop: false,
                            disableSeeking: true,
                            frBtn: true,
                          })
                        );
                      }
                      onChakraClose();
                    }}
                  >
                    {formatMessage({
                      id: "video",
                    })}
                  </Button>
                  <IconButton
                    className="remove-on-print"
                    onClick={onAddToPlaylistOpen}
                    marginStart={"10px"}
                    size="lg"
                    border="2px solid #E2E8F0"
                    aria-label="add"
                    icon={<MdPlaylistAdd />}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
            <AddToPlaylistModal
              isOpen={isAddToPlaylistOpen}
              onClose={onAddToPlaylistClose}
              track={makeTrackFromChakra(currentChakra)}
              entityName={ENTITY_NAME}
            />
          </>
        )}
      </HStack>
    </BaseView>
  );
};

export default Chakras;
const ENTITY_NAME = "chakras";
const makeTrackFromChakra = (chackra: Chakra | undefined) => {
  if (!chackra) return;
  return {
    ...chackra,
    entityName: ENTITY_NAME,
    entityId: chackra.id,
  };
};
