import React, { useState, useEffect } from "react";
import { Box, Text, CircularProgress } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { FiCheckCircle, FiAlertCircle } from "react-icons/fi";
import { useHistory } from "react-router-dom";

const PaymentInfo: React.FC = () => {
  //payment status
  //currently this always shows success
  const [status, setStatus] = useState<"green" | "red">("green");

  //info message
  const [message, setMessage] = useState<string>();

  //history object
  const history = useHistory();

  //fetching status
  const [fetching, setFetching] = useState<boolean>(true);

  useEffect(() => {
    const success = true;
    if (success) {
      setStatus("green");
      setMessage("payment.done");
      //redirect the user to home page after displaying the success message
      setTimeout(() => {
        history.push("/");
      }, 2000);
    } else {
      setStatus("red");
      setMessage("payment.fail");
    }
    setFetching(false);
  }, [history]);

  return (
    <Box paddingTop="200px" textAlign="center">
      {fetching ? (
        <Box
          d="flex"
          justifyContent="center"
          alignItems="center"
          paddingBottom="300px"
        >
          <CircularProgress isIndeterminate color="brand.900" />
          <Text color="gray" margin="0 10px" fontSize="18px">
            <FormattedMessage id="processing" />
          </Text>
        </Box>
      ) : (
        <Box
          d="flex"
          justifyContent="center"
          alignItems="center"
          paddingBottom="300px"
        >
          {status === "green" && <FiCheckCircle color={status} size={52} />}
          {status === "red" && <FiAlertCircle color={status} size={52} />}
          <Text color={status} margin="0 10px" fontWeight="bold">
            <FormattedMessage id={message} />
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default PaymentInfo;
