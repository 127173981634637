import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import React from "react";

export default function Category(props: {
  category: any;
  filter: (wave) => any;
  element: (wave) => JSX.Element;
}) {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left" fontWeight="bold">
            {props.category[0].name}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} px={"auto"}>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={["center","start","start"]}
          flexDir={"row"}
          p="0"
          pb="10px"
          w="100%"
          flexWrap={"wrap"}
        >
          {props.category?.filter(props.filter)?.map(props.element)}
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
}
