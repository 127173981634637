/* eslint-disable eqeqeq, react-hooks/exhaustive-deps */

import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Input,
  Center,
  Select,
  useToast,
  HStack,
  Box,
  InputGroup,
} from "@chakra-ui/react";
import PrimaryButton from "src/components/PrimaryButton";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import usePlanApi from "../usePlanApi";
import { PlanAspect, PlanGoalAction } from "src/types";
import { DropdownDate } from "react-dropdown-date";

const formatDate = (date, addedYears = 0, subtractedDays = 0) => {
  // formats a JS date to 'yyyy-mm-dd'
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + (d.getDate() - subtractedDays),
    year = addedYears + d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const initialFormData: PlanGoalAction = {
  name: "",
  complete: false,
  endDate: new Date(),
  note: "",
  quantity: "",
  startDate: new Date(),
  executor: "",
  counter: "",
};
function EditPlanModal({ isModalOpen, onClose, reload }) {
  const [addNewAspectName, setAddNewAspectName] = useState<string>("");
  const toast = useToast();
  const [selectedStartDate, setSelectedStartDate] = useState(
    formatDate(initialFormData.startDate)
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    formatDate(initialFormData.startDate)
  );
  const [selectedAspectId, setSelectedAspectId] = useState<any>();
  const [selectedGoalId, setSelectedGoalId] = useState<any>();
  const { formatMessage } = useIntl();
  const [addNewGoalName, setAddNewGoaltName] = useState<any>("");
  const planId = +window.location.pathname.split("/")[4] || 0;

  const {
    planQuery,
    addActionMutation,
    addGoalMutation,
    addPlanAspectMutation,
  } = usePlanApi(planId);
  const [formData, setFormData] = useState<PlanGoalAction>(initialFormData);

  useEffect(() => {
    if (isModalOpen) {
      planQuery.refetch();
    }
  }, [isModalOpen]);

  const addAction = async (goalId: number, action: PlanGoalAction) => {
    if (!goalId) return;
    try {
      await addActionMutation(goalId, formData as any);
      resetForm();
      // onClose && onClose();
      toast({
        title: formatMessage({ id: "plan.goal.action.create" }),
        status: "success",
      });
      reload();
    } catch (error: any) {
      if (error.message[0])
        toast({
          title: formatMessage({ id: "plan.goal.action.create" }),
          description: error.message[0],
          status: "error",
        });
    }
  };

  const addNewAspect = async (aspect: PlanAspect, planId: number) => {
    try {
      await addPlanAspectMutation(aspect, planId);
      toast({
        title: formatMessage({ id: "aspect.add.success" }),
        status: "success",
        isClosable: true,
      });
      reload();
    } catch (error: any) {
      toast({
        title: formatMessage({ id: "aspect.add.failed" }),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const addGoal = async (name: string, aspectId) => {
    try {
      await addGoalMutation({ goal: { name }, aspectId: aspectId });
      toast({
        status: "success",
        title: formatMessage({ id: "goal.add.success" }),
      });
      reload();
    } catch (error) {
      toast({
        status: "success",
        title: formatMessage({ id: "goal.add.failed" }),
      });
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setSelectedStartDate(formatDate(new Date()));
    setSelectedEndDate(formatDate(new Date()));
  };

  const getGoals = () => {
    const selectedAspect: any = planQuery.data?.aspects?.filter(
      (aspect) => aspect.id == selectedAspectId
    );
    const options =
      selectedAspect &&
      selectedAspect[0]?.goals?.map((goal) => {
        return <option value={goal.id}>{goal.name}</option>;
      });
    return options;
  };

  const onChange = (fieldName: string, value: any) => {
    setFormData((oldFormData) => ({ ...oldFormData, [fieldName]: value }));
  };
  return (
    <Modal isOpen={isModalOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="editplan" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>
                <FormattedMessage id="purposes.create.add_aspect" />
              </Tab>
              <Tab>
                <FormattedMessage id="plan.aspect.goal.create" />
              </Tab>
              <Tab>
                <FormattedMessage id="plan.aspect.measure.create" />
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    addNewAspect({ name: addNewAspectName, goals: [] }, planId);
                    setAddNewAspectName("");
                  }}
                >
                  <Text
                    d="inline-block"
                    color="brand.900"
                    fontSize="18px"
                    fontWeight="bold"
                    fontFamily="Calibri (Body)"
                  >
                    <FormattedMessage id="purposes.create.add_aspect" />
                  </Text>
                  <Input
                    variant="flushed"
                    value={addNewAspectName}
                    onChange={(e) => setAddNewAspectName(e.target.value)}
                    id="addNewAspectInput"
                  />
                  <Center my={"32px"}>
                    <PrimaryButton
                      type="submit"
                      disabled={addNewAspectName.trim() == ""}
                    >
                      <FormattedMessage id="save" defaultMessage="حفظ" />
                    </PrimaryButton>
                  </Center>
                </form>
              </TabPanel>
              <TabPanel>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // addNewAspect({name:addNewAspectName,goals:[]},currentPlan.id!)
                    addGoal(addNewGoalName, selectedAspectId);
                    setAddNewGoaltName("");
                  }}
                >
                  <Select
                    onChange={(e) => {
                      setSelectedAspectId(e.target.value);
                    }}
                    value={selectedAspectId}
                    mb="5"
                    placeholder={formatMessage({ id: "selectAspect" })}
                  >
                    {planQuery.data?.aspects?.map((aspect) => {
                      return <option value={aspect.id}>{aspect.name}</option>;
                    })}
                  </Select>
                  <Text
                    d="inline-block"
                    color="brand.900"
                    fontSize="18px"
                    fontWeight="bold"
                    fontFamily="Calibri (Body)"
                  >
                    <FormattedMessage id="plan.aspect.goal.create" />
                  </Text>
                  <Input
                    variant="flushed"
                    value={addNewGoalName}
                    onChange={(e) => setAddNewGoaltName(e.target.value)}
                    id="addNewAspectInput"
                  />
                  <Center my={"32px"}>
                    <PrimaryButton
                      type="submit"
                      disabled={
                        addNewGoalName.trim() == "" || !selectedAspectId
                      }
                    >
                      <FormattedMessage id="save" defaultMessage="حفظ" />
                    </PrimaryButton>
                  </Center>
                </form>
              </TabPanel>
              <TabPanel px="0">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // addNewAspect({name:addNewAspectName,goals:[]},currentPlan.id!)
                    // addGoal(newGoalName,aspect)
                    addAction(selectedGoalId, formData);
                  }}
                >
                  <Select
                    onChange={(e) => {
                      setSelectedAspectId(e.target.value);
                    }}
                    value={selectedAspectId}
                    mb="5"
                    placeholder={formatMessage({ id: "selectAspect" })}
                  >
                    {planQuery.data?.aspects?.map((aspect) => {
                      return <option value={aspect.id}>{aspect.name}</option>;
                    })}
                  </Select>
                  <Select
                    isDisabled={!selectedAspectId}
                    onChange={(e) => {
                      setSelectedGoalId(e.target.value);
                    }}
                    value={selectedGoalId}
                    mb="5"
                    placeholder={formatMessage({ id: "selectGoal" })}
                  >
                    {getGoals()}
                  </Select>
                  <Text
                    d="inline-block"
                    color="brand.900"
                    fontSize="18px"
                    fontWeight="bold"
                    fontFamily="Calibri (Body)"
                  >
                    <FormattedMessage id="plan.aspect.measure.create" />
                  </Text>
                  <HStack w="100%" zIndex="1000">
                    <Input
                      onChange={(e) => onChange("name", e.target.value)}
                      value={formData.name}
                      variant="flushed"
                      placeholder={formatMessage({ id: "measure" })}
                      //   id={`plan-${planId}-goal-${goalId}`}
                    />
                  </HStack>
                  <HStack w="100%" zIndex="1000">
                    <Input
                      value={formData.quantity}
                      onChange={(e) => onChange("quantity", e.target.value)}
                      variant="flushed"
                      placeholder={formatMessage({ id: "amount" })}
                    />
                  </HStack>
                  <HStack py={3} w="100%">
                    <InputGroup>
                      <Input
                        value={formData.executor}
                        onChange={(e: any) =>
                          onChange("executor", e.target.value)
                        }
                        resize="none"
                        width="100%"
                        variant="flushed"
                        placeholder={formatMessage({
                          id: "plan.goal.action.create.excutor",
                          defaultMessage: "المنفذ",
                        })}
                      />
                    </InputGroup>
                    {/* <Text>{formatMessage({id: 'done'})}</Text>
            <input
              type="checkbox"
              defaultChecked={formData.complete}
              onChange={(e) => onChange('complete', e.target.checked)}
            /> */}
                  </HStack>
                  <Box
                    d="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    flexWrap="wrap"
                  >
                    <Box>
                      <Text>{formatMessage({ id: "startDate" })}</Text>
                      <DropdownDate
                        startDate={
                          // optional, if not provided 1900-01-01 is startDate
                          formatDate(new Date(), 0, 0) // 'yyyy-mm-dd' format only
                        }
                        endDate={
                          // optional, if not provided current date is endDate
                          formatDate(new Date(), 10) // 'yyyy-mm-dd' format only
                        }
                        selectedDate={
                          // optional
                          selectedStartDate // 'yyyy-mm-dd' format only
                        }
                        onDateChange={(date) => {
                          // optional
                          onChange("startDate", date.toLocaleString());
                          // console.log(new Date(selectedDate))
                          // console.log()
                          setSelectedStartDate(date.toLocaleString());
                        }}
                        defaultValues={
                          // optional
                          {
                            year: "select year",
                            month: "select month",
                            day: "select day",
                          }
                        }
                        classes={
                          // optional
                          {
                            dateContainer: `date-picker-container flex-row-reverse`,
                            yearContainer: "year-container",
                            monthContainer: "month-container",
                            dayContainer: "day-container",

                            year: "date-picker-selector",
                            month: "date-picker-selector",
                            day: "date-picker-selector",
                            // yearOptions: 'classes',
                            // monthOptions: 'classes',
                            // dayOptions: 'classes'
                          }
                        }
                      />
                    </Box>
                    <Box>
                      <Text>{formatMessage({ id: "endDate" })}</Text>
                      <Box overflow="scroll">
                        <DropdownDate
                          startDate={
                            // optional, if not provided 1900-01-01 is startDate
                            formatDate(new Date(), 0, 0) // 'yyyy-mm-dd' format only
                          }
                          endDate={
                            // optional, if not provided current date is endDate
                            formatDate(new Date(), 10) // 'yyyy-mm-dd' format only
                          }
                          selectedDate={
                            // optional
                            selectedEndDate // 'yyyy-mm-dd' format only
                          }
                          onDateChange={(date) => {
                            // optional
                            onChange("endDate", date.toLocaleString());
                            // console.log(new Date(selectedDate))
                            // console.log()
                            setSelectedEndDate(date.toLocaleString());
                          }}
                          defaultValues={
                            // optional
                            {
                              year: "select year",
                              month: "select month",
                              day: "select day",
                            }
                          }
                          classes={
                            // optional
                            {
                              dateContainer: `date-picker-container flex-row-reverse`,
                              yearContainer: "year-container",
                              monthContainer: "month-container",
                              dayContainer: "day-container",

                              year: "date-picker-selector",
                              month: "date-picker-selector",
                              day: "date-picker-selector",
                              // yearOptions: 'classes',
                              // monthOptions: 'classes',
                              // dayOptions: 'classes'
                            }
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* </HStack> */}

                  <HStack d="flex" alignItems="center" justifyContent="center">
                    {
                      <PrimaryButton
                        type="submit"
                        disabled={
                          !selectedGoalId || formData.name.trim() === ""
                        }
                      >
                        {formatMessage({
                          id: "save",
                        })}
                      </PrimaryButton>
                    }
                  </HStack>
                </form>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EditPlanModal;
