import { Image, Tooltip, Link, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { useIntl } from "react-intl";
import { Link as ReactRouterLink } from "react-router-dom";

interface Props {
  id: string;
  name: string;
  src: string;
  route?: string | null;
  coordinates: { x: number; y: number };
  onClick?: () => void;
}

const SalMagicFigure: React.FC<Props> = ({
  id,
  name,
  src,
  route,
  onClick,
  coordinates: { x, y },
}) => {
  const { formatMessage } = useIntl();
  const [isMobile] = useMediaQuery("(max-width: 465px)");

  const imageElement = (
    <Tooltip
      hasArrow
      label={formatMessage({ id: name })}
      arrowSize={8}
      bg="#1f3f93"
      color="white"
    >
      <Image
        key={id}
        boxSize={30}
        src={src}
        position={isMobile ? "" : "absolute"}
        left={`calc(50% + ${-x}px - 15px)`} // 15px to center the image
        top={`calc(50% - ${y}px - 15px)`} // 15px to center the image
        cursor="pointer"
        onClick={onClick}
      />
    </Tooltip>
  );

  return route ? (
    <Link as={ReactRouterLink} to={route}>
      {imageElement}
    </Link>
  ) : (
    imageElement
  );
};

export default SalMagicFigure;
