import {
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import React from "react";
import { FormattedMessage } from "react-intl";

const DescriptionModal = ({
  isOpen,
  onClose,
  title,
  finalRef,
  content,
}: any) => {
  return (
    <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="25px">
        <ModalHeader
          fontFamily="Calibri (Body)"
          fontSize="18px"
          fontWeight="bold"
          color="brand.900"
        >
          <FormattedMessage id={title} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p="6"
          color="gray.900"
          fontSize="18px"
          fontFamily="Calibri (Body)"
        >
          <Text>{content}</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DescriptionModal;
