import { useState } from "react";
import {
  useQueryClient,
  useMutation,
  useQuery,
  QueryObserverResult,
} from "react-query";
import {
  create,
  deleteMission as deleteMissionApi,
  edit,
} from "src/api/prepration/mission/mutation";
import {
  getList,
  mission,
  getSharedList,
} from "src/api/prepration/mission/query";
import { Mission } from "src/types";

export type MissionQuery = QueryObserverResult<Mission, Error>;
export type UseMissionApiReturn = {
  createMission: (mission: Mission) => Promise<Mission>;
  editMission: (misssion: Mission) => Promise<Mission>;
  deleteMission: (id: number) => Promise<void>;
  setCurrentMission: (id: number | undefined) => void;
  currentMissionQuery: MissionQuery;
  missionListQuery: QueryObserverResult<Mission[], Error>;
  sharedMissionListQuery: QueryObserverResult<Mission[], Error>;
};
export const useMissionApi: () => UseMissionApiReturn = () => {
  const queryClient = useQueryClient();

  const [currentMissionId, setCurrentMissionId] = useState<number>();
  const currentMissionQuery = useQuery(...mission(currentMissionId));

  const missionListQuery = useQuery<Mission[], Error>(...getList);
  const sharedMissionListQuery = useQuery<Mission[], Error>(...getSharedList);

  const { mutateAsync: createMutation } = useMutation(create, {
    onSuccess: () => queryClient.invalidateQueries(getList[0]),
  });

  const createMission = (mission: Mission) => createMutation(mission);

  const { mutateAsync: editMutation } = useMutation(edit, {
    onSuccess: ({ id }) => queryClient.invalidateQueries(mission(id)[0]),
  });
  const editMission = (mission: Mission) => editMutation(mission);

  const { mutateAsync: deleteMutation } = useMutation<void, Error, number>(
    deleteMissionApi,
    {
      onSuccess: () => queryClient.invalidateQueries(getList[0]),
    }
  );
  const deleteMission = (id: number) => deleteMutation(id);
  return {
    createMission,
    editMission,
    deleteMission,
    currentMissionQuery,
    missionListQuery,
    setCurrentMission: setCurrentMissionId,
    sharedMissionListQuery,
  };
};
