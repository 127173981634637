import { deleteMethod, post, put } from "src/api";
import { ValuesList } from "src/types";

const create: (value: ValuesList) => Promise<ValuesList> = (value) =>
  post<ValuesList>("/value", value);

const edit: (value: ValuesList) => Promise<ValuesList> = (value) =>
  put<ValuesList>(`/value/${value.id!}`, value);

const deleteValue: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/value/${id!}`);

const douplicateValue: (id: string) => Promise<ValuesList> = (id) =>
  post<any>(`/value/duplicate/${id}`, { valueId: id });

export { create, edit, deleteValue, douplicateValue };
