import React, { useState, useEffect } from "react";
import Missions from "./Missions";
import Purposes from "./Purposes";
import Values from "./Values";
import Visions from "./Visions";
import SalPlan from "./SalPlan";
import FocusPoster from "./FocusPoster";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Description } from "../../types";
import {
  MISSIONS_ROUTE,
  PURPOSES_ROUTE,
  SALPLAN_ROUTE,
  VALUES_ROUTE,
  VISIONS_ROUTE,
  FOCUS_POSTER_ROUTE,
} from "src/routes";
import Link from "src/components/Link/Link";
import { get, titleSize, descSize } from "src/api";
import { FormattedMessage, useIntl } from "react-intl";
import useLocale from "src/providers/useLocale";
import { valuesBg } from "src/assets/icons";
import BaseView from "src/views/Prepration/BaseView";

const Prepration = () => {
  let locale = useLocale()[0];
  const [content, setContent] = useState("Loading ...");

  const { formatMessage } = useIntl();

  useEffect(() => {
    get<Description>("/description/prepration").then((obj) => {
      setContent(obj?.content || formatMessage({ id: "failFetch" }));
    });
  }, [formatMessage, locale]);

  return (
    <Box>
      <BaseView noBread imageSrc={valuesBg} />
      <VStack alignItems="start" paddingBottom="30px">
        <Box>
          <Text fontSize={titleSize} fontWeight="bold" color="#1f3f93">
            <FormattedMessage id="prepration" />
          </Text>
        </Box>
        <Box>
          <Text color="#8b8b8b" fontSize={descSize}>
            {content}
          </Text>
        </Box>
        <VStack w="100%" marginTop="30px !important">
          <HStack flexWrap="wrap" alignItems="center">
            <Link
              to={VALUES_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="values" />
            </Link>
            <Link
              to={PURPOSES_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="purposes" />
            </Link>
            <Link
              to={VISIONS_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="visions" />
            </Link>
            <Link
              to={MISSIONS_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="missions" />
            </Link>
          </HStack>
          <HStack marginTop="15px !important">
            <Link
              to={SALPLAN_ROUTE}
              my={15}
              margin={["0 2px !important", "0 5px !important"]}
            >
              <FormattedMessage id="salplan" />
            </Link>
            <Link
              to={FOCUS_POSTER_ROUTE}
              isSecondary
              margin={["0 2px !important", "0 5px !important"]}
            >
              <FormattedMessage id="focusposter" />
            </Link>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};
Prepration.Values = Values;
Prepration.Purposes = Purposes;
Prepration.Visions = Visions;
Prepration.Missions = Missions;
Prepration.SalPlan = SalPlan;
Prepration.FocusPoster = FocusPoster;

export default Prepration;
