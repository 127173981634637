import { Box, Text, Checkbox, Flex, Spinner, Center } from "@chakra-ui/react";
import React from "react";
import { FaPlay, FaStop } from "react-icons/fa";
import { useSelector } from "src/store";

export const AudioItem = ({
  audio,
  onPlay,
  onStop,
  onSelect,
  isSelected,
  isLoading,
}) => {
  const { isPlay } = useSelector((state) => ({
    isPlay: state.media.playing && state.media.id === `${audio.type}-${audio.id}`,
  }));

  const handleClick = (event) => {
    // Prevent the selection when clicking on play or stop buttons
    if (
      event.target.tagName === "BUTTON" ||
      event.target.tagName === "svg" ||
      event.target.tagName === "path" ||
      event.target.tagName === "IMG"
    ) {
      return;
    }

    onSelect(audio);
  };

  const handleStopClick = () => {
    onStop();
  };

  if (isLoading) {
    return (
      <Center w="100%">
        <Spinner size="sm" thickness="2px" color="blue.500" />
      </Center>
    );
  }

  return (
    audio.audioUrl && (
      <Box
        borderWidth="1px"
        borderRadius="md"
        p="2"
        marginBottom="3"
        onClick={handleClick}
        cursor="pointer"
        borderColor={isSelected ? "blue.500" : "gray.300"}
        backgroundColor={isSelected ? "blue.100" : "white"}
      >
        <Flex justifyContent="space-between" alignItems="center">
          {isPlay ? (
            <FaStop
              color={"#2895ff"}
              onClick={handleStopClick}
              style={{ margin: "0 5px" }}
              cursor="pointer"
            />
          ) : (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaPlay
                color={"#2895ff"}
                onClick={() => onPlay(audio.audioUrl, audio.id)}
                style={{ margin: "0 5px" }}
                cursor="pointer"
              />
            </span>
          )}
          <Text
            flex="1" // Allow the text to grow to fill available space
            fontSize="md"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            marginRight={2}
          >
            {audio.name}
          </Text>
          <Checkbox
            isChecked={isSelected}
            onChange={handleClick}
            marginRight="2"
          />
        </Flex>
      </Box>
    )
  );
};
