import React, { useState, useCallback, useEffect } from "react";
import {
    Center,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Radio,
    RadioGroup,
    Stack,
    Text,
    useToast,
} from "@chakra-ui/react";
import PrimaryButton from "src/components/PrimaryButton";
import { get, post } from "src/api";
import { FormattedMessage, useIntl } from "react-intl";
import SharedWithUsersList from "./SharedWithUsersList";

function SharingModal({ isOpen, onClose, sharedEntity, showOptions = true }) {
    const intl = useIntl();
    const toast = useToast();
    const [email, setEmail] = useState("")
    const [usersList, setUsersList] = useState<any[]>([])

    const fetchItems = useCallback(
        () => {
            get(`/shared/shared-with/${sharedEntity.name}/${sharedEntity.id}`)
                .then((res: any) => {
                    if (res.length) {
                        setUsersList(res)
                    }
                })
        }, [sharedEntity.id, sharedEntity.name])

    useEffect(() => {
        fetchItems()
        return () => {
            setEmail("")
            setUsersList([])
        }
    }, [fetchItems, sharedEntity.id, sharedEntity.name, isOpen])



    const [permission, setPermission] = useState<
        "read" | "update"
    >("read");


    return (
        <Modal isCentered size={"xs"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                p={"10px"}
                maxWidth={"550px"}
                width={"100%"}
                borderRadius={"30px"}
            >
                <ModalCloseButton />
                <ModalBody>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            post('/shared', {
                                accessorEmail: email,
                                entityName: sharedEntity?.name,
                                entityId: Number(sharedEntity?.id),
                                permission
                            }).then((res: any) => {
                                if (res?.accessor) {
                                    toast({
                                        title: `${intl.formatMessage({ id: 'successfullySharedWith' })} ${res.accessor.email}`,
                                        status: "success",
                                        isClosable: true,
                                    });
                                    fetchItems()
                                    setEmail("")
                                }
                                if (res?.error) {
                                    toast({
                                        title: intl.formatMessage({ id: res.message }),
                                        status: "error",
                                        isClosable: true,
                                    });
                                }
                                onClose();
                            }).catch(() => {
                                toast({
                                    title: intl.formatMessage({ id: "wentWrong" }),
                                    status: "error",
                                    isClosable: true,
                                });
                            });
                        }}
                    >
                        <Text
                            d="inline-block"
                            color="brand.900"
                            fontSize="18px"
                            fontWeight="bold"
                            fontFamily="Calibri (Body)"
                            mb="4"
                        >
                            <FormattedMessage id="shareWith" />
                        </Text>
                        <Input
                            mt="1.5"
                            mb="1.5"
                            type="email"
                            value={email}
                            placeholder={intl.formatMessage({ id: "email" })}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                        {showOptions && <RadioGroup
                            onChange={(e: any) => {
                                setPermission(e);
                            }}
                            defaultValue={permission}
                        >
                            <Stack direction="row">
                                <Radio value="read" >
                                    {intl.formatMessage({ id: "readOnly" })}
                                    {/* <FormattedMessage id="thisSection" /> */}
                                </Radio>
                                <Radio value="update">
                                    {intl.formatMessage({ id: "readAndUpdate" })}
                                </Radio>
                            </Stack>
                        </RadioGroup>}
                        {/* <Text d="inline-block"> /270</Text> */}
                        <Center my={"32px"}>
                            <PrimaryButton
                                type="submit"
                                disabled={!Boolean(email.trim())}
                            >
                                <FormattedMessage id="share" />
                            </PrimaryButton>
                        </Center>
                    </form>
                    {usersList.length ? <SharedWithUsersList sharedList={usersList} setUsersList={setUsersList} showOptions={showOptions}/> : null}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}



export default SharingModal;



