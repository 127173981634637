import React, {
  useState,
  useEffect,
  FormEvent,
  useContext,
  useLayoutEffect,
} from "react";
import {
  VStack,
  Box,
  Modal,
  FormControl,
  Input,
  HStack,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  useDisclosure,
  Progress,
} from "@chakra-ui/react";
import { get, post, uploadFile } from "../../api";
import { FaCcVisa, FaUniversity, FaDonate } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import DarkHeader from "src/layout/DarkHeader";
import useLocale from "src/providers/useLocale";
import { LOGIN_ROUTE, SUBSCRIPTION_ROUTE } from "src/routes";
import { PaymentIntent } from "src/types";
import { useToast } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import GoSellPayment from "src/views/Payment/GoSellPayment";
import { Link, Redirect, useLocation } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useSelector } from "src/store";
import { validateToken } from "src/index";

type Quotation = {
  id?: number;
  duration?: number;
  price?: number;
  currency?: "USD";
  region?: string;
  selected?: string;
};

const Subscription = () => {
  //map for currency symbols
  const currencyMap = {
    USD: "$",
  };
  // to indicate heigh prices (245 or 375)
  const [heighPrices, setHeighPrices] = useState(false);

  //function for formatting messages
  const { formatMessage } = useIntl();

  //current locale
  const [locale] = useLocale();

  //disclosures for modal visibility
  const disclosureProps = useDisclosure();

  //subscribtion data
  const [subscriptionData, setSubscriptionData] = useState<{
    quotationId?: number;
    paymentMethod?: string;
    proveUrl?: string;
    nationalityProofUrl?: string;
  }>();

  //message to display after order creation attempt
  const [message, setMessage] = useState({ content: "", color: "" });

  //success status
  const [success, setSuccess] = useState<boolean>(false);

  //toast for errors while fetching gateway configurations
  const toast = useToast();

  //uploading files progress
  const [progress, setProgress] = useState(0);

  //processing the order
  const [processing, setProcessing] = useState(false);

  //configurations for payment gateway
  const [gatewayConfig, setGatewayConfig] = useState<PaymentIntent>();

  //current name for file upload proveUrl | nationalityProofUrl
  const [fileName, setFileName] = useState<string>();

  //current user
  const user = useSelector((state) => state.auth.user);

  //method for opening modal
  const openModal = (id: number, method: string) => {
    if (!method) return;
    setSubscriptionData({ quotationId: id, paymentMethod: method });
    disclosureProps.onOpen();
  };

  //quotations for subscriptions
  const [quotations, setQuotations] = useState<Quotation[]>([]);

  // is file uploaded (used to enable submit button)

  const [isfileUploaded, setIsFileUploaded] = useState<boolean>(false);

  const { state } = useLocation();
  const renew = state?.renew;

  //method for fetching quotations
  const fetchQuotations = async () => {
    const response = await get(
      "/subscription/quotation",
      undefined,
      user?.accessToken
    );
    if (response && Array.isArray(response)) setQuotations(response);
  };

  //function to fetch gate way configurations
  // const fetchGatewayConfig = async () => {
  //   const response: PaymentIntent = await get(
  //     "/payment/info",
  //     undefined,
  //     user?.accessToken
  //   );
  //   if (response?.gateway) {
  //     setGatewayConfig(response);
  //   } else {
  //     toast({
  //       title: formatMessage({ id: "wentWrong" }),
  //       status: "error",
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //   }
  // };

  const [
    changeQuotationSectionVisibility,
    setChangeQuotationSectionVisibility,
  ] = useState<boolean>(false);

  const toggleChangeQuotations = () => {
    setFileName("proveUrl");
    setChangeQuotationSectionVisibility((currentState: boolean) => {
      return !currentState;
    });
  };
  //available payment methods
  const paymentMethods = [
    { name: "card", Icon: FaCcVisa, color: "#6c5fe3" },
    { name: "purchase", Icon: FaDonate, color: "#0abf25" },
    { name: "bank", Icon: FaUniversity, color: "#f5b464" },
  ];

  //method for selecting payment method for quotation
  const selectMethod = (qId: number, name: string) => {
    const updatedQ = [...quotations];
    updatedQ.find((q) => q.id === qId)!.selected = name;
    setQuotations(updatedQ);
  };

  //method for handling media change
  const handleMedia = (e: FormEvent) => {
    setIsFileUploaded(false);
    if (progress) return;
    const target = e.target as HTMLInputElement;
    const file = target.files![0];
    const selectedQuotation = quotations.find((q) => q.selected);
    const successCb = (url: string) => {
      if (fileName) {
        setSubscriptionData({ ...subscriptionData, [fileName]: url });
      }
      //If that was the proof nationality step, go the the next step
      if (
        fileName === "nationalityProofUrl" ||
        selectedQuotation?.price === 245 ||
        selectedQuotation?.price === 375
      )
        setFileName("proveUrl");
    };
    const failureCb = () => {
      toast({
        title: formatMessage({ id: "upload.fail" }),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    };
    if (file) {
      uploadFile(file, setProgress, successCb, failureCb, user?.accessToken);
      setIsFileUploaded(true);
    }
  };

  //save the subscribtion
  const subscribe = () => {
    console.log(
      "proof",
      subscriptionData?.proveUrl,
      "method",
      subscriptionData?.paymentMethod,
      "processing",
      processing,
      "nationalityproofurl",
      subscriptionData?.nationalityProofUrl,
      "heighPrices",
      heighPrices
    );
    // if ((!subscribtionData?.proveUrl && subscribtionData?.paymentMethod !== 'card') || processing || (!heighPrices)) return;
    setProcessing(true);
    post("/subscription/order", subscriptionData, locale, user?.accessToken)
      .then((res: any) => {
        setProcessing(false);
        if (res && res.id) {
          if (res.url) {
            //if url is in response, redirect them to it since it is the payment API url
            disclosureProps.onClose();
            window.open(res.url, "_blank");
          }
          // setMessage({content: "orderSuccess", color: 'green'});
          setSuccess(true);
          setChangeQuotationSectionVisibility(false);
        } else setMessage({ content: "wentWrong", color: "crimson" });
      })
      .catch((err) => {
        setProcessing(false);
        setMessage({ content: err.message || "wentWrong", color: "crimson" });
      });
  };

  //save the subscribtion (heigh price case)
  const subscribeHeighPrice = (subData: {}) => {
    setSubscriptionData({ ...subData });
    if (processing) return;
    setProcessing(true);
    post("/subscription/order", subData, locale, user?.accessToken)
      .then((res: any) => {
        setProcessing(false);
        if (res && res.id) {
          if (res.url) {
            //if url is in response, redirect them to it since it is the payment API url
            window.open(res.url, "_blank");
          }
          // setMessage({content: "orderSuccess", color: 'green'});
          console.log(res && res.id);
          setSuccess(true);
          setChangeQuotationSectionVisibility(false);
        } else setMessage({ content: "wentWrong", color: "crimson" });
      })
      .catch((err) => {
        setProcessing(false);
        setMessage({ content: err.message || "wentWrong", color: "crimson" });
      });
  };

  //function to handle modal opening
  const handleOpening = (quotation: Quotation) => {
    // if (quotation.selected === 'card' && gatewayConfig) {
    //     setGatewayConfig({...gatewayConfig, order: {amount: quotation.price!, currency: quotation.currency!}});
    // }
    // else {
    //   openModal(quotation.id!, quotation.selected!);
    // }
    openModal(quotation.id!, quotation.selected!);
  };

  // if subscribtion failed
  const [repurchase, setRepurchase] = useState<any>();
  useLayoutEffect(() => {
    validateToken();
    console.log("layout");
  }, [validateToken]);

  useEffect(() => {
    console.log("effect");
    validateToken();
    fetchQuotations();
    disclosureProps.onClose();
    // if subscribtion failed
    post(
      "/subscription/order/repurchase",
      subscriptionData,
      locale,
      user?.accessToken
    )
      .then((res) => {
        setRepurchase(res);
      })
      .catch((err) => {
        console.log("repurchase error", err);
        setRepurchase(null);
      });
    // fetchGatewayConfig();
  }, [locale, processing]);

  if (!user) return <Redirect to={LOGIN_ROUTE} />;

  if (user.subscriptionStatus === "approved" && !renew)
    return <Redirect to="/portal" />;

  if (user?.subscriptionStatus === "pending" || success)
    return (
      <>
        <DarkHeader />
        <Box padding="50px 20px 50px 20px">
          <Text color="green.500" textAlign="center">
            <FormattedMessage id="offerPending" />
            <br />
            <a href="https://t.me/SmartsWay125">https://t.me/SmartsWay125</a>
            {repurchase && repurchase?.order?.paymentMethod === "card" ? (
              <>
                <p>
                  <a
                    style={{
                      borderRadius: "5px",
                      margin: " 30px auto",
                      textAlign: "center",
                      display: "block",
                      width: "200px",
                      padding: "5px 10px",
                      color: "white",
                      background: "#1f3f93",
                    }}
                    href={repurchase.payment_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="completeProcess" />
                  </a>
                </p>
                <Link
                  to={{ pathname: SUBSCRIPTION_ROUTE, state: { renew } }}
                  onClick={() => {
                    toggleChangeQuotations();
                  }}
                  style={{
                    borderRadius: "5px",
                    margin: " 10px auto",
                    padding: "5px 10px",
                    textAlign: "center",
                    display: "block",
                    width: "200px",
                    color: "white",
                    background: "#1f3f93",
                  }}
                >
                  <FormattedMessage id="changePaymentMethod" />
                  {changeQuotationSectionVisibility ? (
                    <ChevronUpIcon />
                  ) : (
                    <ChevronDownIcon />
                  )}
                </Link>
              </>
            ) : (
              <Link
                to={{ pathname: SUBSCRIPTION_ROUTE, state: { renew } }}
                onClick={() => {
                  toggleChangeQuotations();
                }}
                style={{
                  borderRadius: "5px",
                  margin: " 10px auto",
                  padding: "5px 10px",
                  textAlign: "center",
                  display: "block",
                  width: "200px",
                  color: "white",
                  background: "#1f3f93",
                }}
              >
                <FormattedMessage id="changePaymentMethod" />
                {changeQuotationSectionVisibility ? (
                  <ChevronUpIcon />
                ) : (
                  <ChevronDownIcon />
                )}
              </Link>
            )}
          </Text>
        </Box>
        {changeQuotationSectionVisibility && (
          <VStack display="flex" flexDir="column" alignItems="center">
            <HStack
              display="flex"
              flexDir={{ base: "column", lg: "row" }}
              alignItems={{ base: "center" }}
            >
              {quotations.map((quotation) => {
                return (
                  <Box
                    key={quotation.id}
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    padding="30px 0"
                    borderRadius="15px"
                    backgroundColor="white"
                    boxShadow="0 2px 10px 2px silver"
                    margin={{
                      base: "30px 0 !important",
                      lg: "30px !important",
                    }}
                    width={{ base: "300px", md: "350px" }}
                    position="relative"
                    overflow="hidden"
                  >
                    <Box padding="0 30px">
                      <Text fontSize="36px" fontWeight="bold" color="brand.900">
                        {quotation.duration} {formatMessage({ id: "year" })}
                      </Text>
                    </Box>
                    <Box
                      backgroundColor="brand.900"
                      padding="30px 0"
                      width="100%"
                      marginTop="30px"
                    >
                      <Text
                        textAlign="center"
                        color="white"
                        fontSize="28px"
                        fontWeight="bold"
                      >
                        {quotation.price?.toFixed(2)}
                        <sup>{currencyMap[quotation.currency!]}</sup>
                      </Text>
                    </Box>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                      marginTop="30px"
                    >
                      {paymentMethods.map(({ name, Icon, color }) => (
                        <Box
                          title={name}
                          key={name}
                          margin="15px"
                          width="80px"
                          paddingTop="10px"
                          display="flex"
                          flexDir="column"
                          alignItems="center"
                          borderRadius="10px"
                          background={
                            name === quotation.selected ? "#b2caf7" : "white"
                          }
                          onClick={() => {
                            selectMethod(quotation.id!, name);
                          }}
                          cursor="pointer"
                          transition="150ms ease-out"
                          _hover={{ background: "#b2caf7" }}
                        >
                          <Icon
                            size={46}
                            height="70px"
                            width="70px"
                            color={color}
                          />
                          <Text my={3} fontWeight="600">
                            <FormattedMessage id={name} />
                          </Text>
                        </Box>
                      ))}
                    </Box>
                    <Box marginTop="30px">
                      {processing && (
                        <Box margin="30px 0">
                          <Text color="grey">
                            <FormattedMessage id="wait" />
                          </Text>
                        </Box>
                      )}
                      {/* setFileName('proveUrl') */}
                      <Button
                        backgroundColor="brand.900"
                        color="white"
                        height="50px"
                        _hover={{ filter: "brightness(120%)" }}
                        onClick={() => {
                          const isValidPrice =
                            quotation.price === 245 || quotation.price === 375;

                          if (isValidPrice) setHeighPrices(true);

                          if (isValidPrice && quotation.selected === "bank")
                            setFileName("proveUrl");

                          if (isValidPrice && quotation.selected !== "bank") {
                            // let selectedQoutation = quotations.find(
                            //   (q) => q.selected
                            // );
                            // quotations.forEach(q => {
                            //   if(q.selected){
                            //     selectedPaymentMethod = q.selected
                            //   }
                            // })
                            // console.log(selectedPaymentMethod)

                            let subData = {
                              quotationId: quotation.id,
                              paymentMethod: quotation.selected,
                            };
                            setSubscriptionData({
                              quotationId: quotation.id,
                              paymentMethod: quotation.selected,
                            });

                            subscribeHeighPrice(subData);
                            setChangeQuotationSectionVisibility(false);
                            return;
                          }
                          handleOpening(quotation);
                        }}
                        disabled={!quotation.selected || processing}
                      >
                        <FormattedMessage id="subscribe" />
                      </Button>
                    </Box>
                  </Box>
                );
              })}
            </HStack>
            <Modal {...disclosureProps} onClose={disclosureProps.onClose}>
              <ModalOverlay />
              <ModalContent
                width={{ base: "300px", sm: "400px", md: "500px" }}
                minWidth={{ lg: "600px" }}
                marginTop="150px"
              >
                <ModalHeader>
                  <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                  <FormControl as="form">
                    {message.content && (
                      <Box margin="30px 0">
                        <Text color={message.color}>
                          <FormattedMessage id={message.content} />
                        </Text>
                      </Box>
                    )}
                    {processing && (
                      <Box margin="30px 0">
                        <Text color="grey">
                          <FormattedMessage id="wait" />
                        </Text>
                      </Box>
                    )}
                    {fileName === "proveUrl" ? (
                      <>
                        <Text color="grey" fontSize={18} fontWeight="bold">
                          <FormattedMessage id={fileName} />
                        </Text>
                        <Box margin="30px 0">
                          <Input
                            type="file"
                            required
                            name={fileName}
                            onChange={handleMedia}
                            key={fileName}
                          />
                          {progress > 0 && <Progress value={progress} />}
                        </Box>
                      </>
                    ) : null}
                    {
                      // fileName === 'proveUrl' &&
                      <Box textAlign="center" margin="30px 0">
                        <Button
                          backgroundColor="brand.900"
                          color="white"
                          height="50px"
                          _hover={{ filter: "brightness(120%)" }}
                          onClick={() => {
                            subscribe();
                            disclosureProps.onClose();
                          }}
                          disabled={processing || !isfileUploaded}
                        >
                          <FormattedMessage id="subscribe" />
                        </Button>
                      </Box>
                    }
                  </FormControl>
                </ModalBody>
              </ModalContent>
            </Modal>
          </VStack>
        )}
      </>
    );

    console.log(quotations);

    return (
      <>
        <DarkHeader />
        {gatewayConfig && gatewayConfig.order && (
          <GoSellPayment gatewayConfig={gatewayConfig} />
        )}
        <VStack
          padding="80px 0"
          display="flex"
          flexDir="column"
          alignItems="center"
        >
          <HStack
            display="flex"
            flexDir={{ base: "column", lg: "row" }}
            alignItems={{ base: "center" }}
          >
            {quotations.map((quotation) => (
              <Box
                key={quotation.id}
                display="flex"
                flexDir="column"
                alignItems="center"
                padding="30px 0"
                borderRadius="15px"
                backgroundColor="white"
                boxShadow="0 2px 10px 2px silver"
                margin={{ base: "30px 0 !important", lg: "30px !important" }}
                width={{ base: "300px", md: "350px" }}
                position="relative"
                overflow="hidden"
              >
                <Box padding="0 30px">
                  <Text fontSize="36px" fontWeight="bold" color="brand.900">
                    {quotation.duration} {formatMessage({ id: "year" })}
                  </Text>
                </Box>
                <Box
                  backgroundColor="brand.900"
                  padding="30px 0"
                  width="100%"
                  marginTop="30px"
                >
                  <Text
                    textAlign="center"
                    color="white"
                    fontSize="28px"
                    fontWeight="bold"
                  >
                    {quotation.price?.toFixed(2)}
                    <sup>{currencyMap[quotation.currency!]}</sup>
                  </Text>
                </Box>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="center"
                  marginTop="30px"
                >
                  {paymentMethods.map(({ name, Icon, color }) => (
                    <Box
                      title={name}
                      key={name}
                      margin="15px"
                      width="80px"
                      paddingTop="10px"
                      display="flex"
                      flexDir="column"
                      alignItems="center"
                      borderRadius="10px"
                      background={
                        name === quotation.selected ? "#b2caf7" : "white"
                      }
                      onClick={() => {
                        selectMethod(quotation.id!, name);
                        console.log(quotations);
                      }}
                      cursor="pointer"
                      transition="150ms ease-out"
                      _hover={{ background: "#b2caf7" }}
                    >
                      <Icon
                        size={46}
                        height="70px"
                        width="70px"
                        color={color}
                      />
                      <Text my={3} fontWeight="600">
                        <FormattedMessage id={name} />
                      </Text>
                    </Box>
                  ))}
                </Box>
                <Box marginTop="30px">
                  {processing && (
                    <Box margin="30px 0">
                      <Text color="grey">
                        <FormattedMessage id="wait" />
                      </Text>
                    </Box>
                  )}
                  {/* setFileName('proveUrl') */}
                  <Button
                    backgroundColor="brand.900"
                    color="white"
                    height="50px"
                    _hover={{ filter: "brightness(120%)" }}
                    onClick={() => {
                      const isValidPrice =
                        quotation.price === 245 || quotation.price === 375;

                      if (isValidPrice) setHeighPrices(true);

                      if (isValidPrice && quotation.selected === "bank")
                        setFileName("proveUrl");

                      if (isValidPrice && quotation.selected !== "bank") {
                        
                        // let selectedQuotation = quotations.find(
                        //   (q) => q.selected
                        // );
                        // quotations.forEach(q => {
                        //   if(q.selected){
                        //     selectedPaymentMethod = q.selected
                        //   }
                        // })
                        // console.log(selectedPaymentMethod)

                        let subData = {
                          quotationId: quotation.id,
                          paymentMethod: quotation.selected,
                        };
                        setSubscriptionData({
                          quotationId: quotation.id,
                          paymentMethod: quotation.selected,
                        });
                        subscribeHeighPrice(subData);
                        return;
                      }
                      handleOpening(quotation);
                    }}
                    disabled={!quotation.selected || processing}
                  >
                    <FormattedMessage id="subscribe" />
                  </Button>
                </Box>
              </Box>
            ))}
          </HStack>
          <Modal {...disclosureProps} onClose={disclosureProps.onClose}>
            <ModalOverlay />
            <ModalContent
              width={{ base: "300px", sm: "400px", md: "500px" }}
              minWidth={{ lg: "600px" }}
              marginTop="150px"
            >
              <ModalHeader>
                <ModalCloseButton />
              </ModalHeader>
              <ModalBody>
                <FormControl as="form">
                  {message.content && (
                    <Box margin="30px 0">
                      <Text color={message.color}>
                        <FormattedMessage id={message.content} />
                      </Text>
                    </Box>
                  )}
                  {processing && (
                    <Box margin="30px 0">
                      <Text color="grey">
                        <FormattedMessage id="wait" />
                      </Text>
                    </Box>
                  )}
                  {fileName === "proveUrl" &&
                  subscriptionData?.paymentMethod !== "bank" ? null : (
                    <>
                      <Text color="grey" fontSize={18} fontWeight="bold">
                        <FormattedMessage id={fileName} />
                      </Text>
                      <Box margin="30px 0">
                        <Input
                          type="file"
                          required
                          name={fileName}
                          onChange={handleMedia}
                          key={fileName}
                        />
                        {progress > 0 && <Progress value={progress} />}
                      </Box>
                    </>
                  )}
                  {fileName === "proveUrl" && (
                    <Box textAlign="center" margin="30px 0">
                      <Button
                        backgroundColor="brand.900"
                        color="white"
                        height="50px"
                        _hover={{ filter: "brightness(120%)" }}
                        onClick={subscribe}
                        disabled={
                          (!subscriptionData?.proveUrl &&
                            subscriptionData?.paymentMethod === "bank") ||
                          processing ||
                          (!subscriptionData.nationalityProofUrl &&
                            !heighPrices)
                        }
                      >
                        <FormattedMessage id="subscribe" />
                      </Button>
                    </Box>
                  )}
                </FormControl>
              </ModalBody>
            </ModalContent>
          </Modal>
        </VStack>
      </>
    );
};

export default Subscription;
