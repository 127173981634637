import React, { useState, useEffect } from "react";
import BaseView from "../BaseView";
import { Box, VStack, HStack, Text } from "@chakra-ui/react";
import { get, DisableSeeking } from "src/api/index";
import useLocale from "src/providers/useLocale";
import { NineG as NineGType } from "src/types";
import ReactPlayer from "react-player";

const NineG = () => {
  //current locale
  const [locale] = useLocale();

  const [content, setContent] = useState<NineGType>();

  useEffect(() => {
    const fetchContent = async () => {
      const response = await get<NineGType>("/tft/9g", locale);
      //Since the media is appended, we want to index by 0 the latest media added
      setContent(response);
    };
    fetchContent();
  }, [locale]);

  useEffect(() => {
    const video = document.getElementById("9g-vid") as HTMLVideoElement;
    if (!video) return;
    DisableSeeking(video);
  }, [content]);
  return (
    <BaseView>
      <VStack width="100%" paddingTop="50px" paddingBottom="80px">
        <Box
          display="flex"
          flexDir={{ base: "column", lg: "row" }}
          alignItems={{ base: "center", xl: "flex-start" }}
          width={{ lg: "820px" }}
          justifyContent={{ base: "space-between" }}
        >
          <Box
            background="#f6f7f2"
            width="300px"
            borderRadius="15px"
            boxShadow="-1px 2px 5px 1px silver"
            padding="20px 40px"
          >
            {content?.article?.map((item, index) => (
              <Box key={index}>
                <Text color="#bebfbb" margin={"15px 0"}>
                  {`${index + 1} ${item}`}
                </Text>
              </Box>
            ))}
          </Box>
          <Box
            width={{ base: "400px", lg: "480px" }}
            paddingTop={{ base: "40px", xl: "0" }}
            margin={"10px"}
          >
            {content?.media &&
              (content.media
                .find((m) => m.type === "video")
                ?.url.includes("youtube") ||
              content.media
                .find((m) => m.type === "video")
                ?.url.includes("youtu.be") ? (
                <ReactPlayer
                  preload="none"
                  className="react-player fixed-bottom"
                  url={content.media.find((m) => m.type === "video")?.url}
                  controls
                  width={"490px"}
                  height={"275px"}
                  style={{
                    borderRadius: "25px",
                    marginBottom: "0.7em",
                    overflow: "hidden",
                  }}
                />
              ) : (
                <video
                  preload="none"
                  onContextMenu={(e) => e.preventDefault()}
                  controlsList="nodownload"
                  id="9g-vid"
                  src={
                    content?.media &&
                    content.media.find((m) => m.type === "video")?.url
                  }
                  style={{ borderRadius: "25px", marginBottom: "0.7em" }}
                  controls
                />
              ))}
          </Box>
        </Box>
        <HStack
          display="flex"
          flexDir={{ base: "column" }}
          alignItems="center"
          paddingTop={{ base: "40px", xl: "80px" }}
          paddingBottom="80px"
        >
          {/* {content?.media.find(obj => obj.type === 'image')?.url ?
            <Box width={{ base: "100%", xl: "800px" }} border={content?.media.find(obj => obj.type === 'image')?.url! ? "1px solid silver" : "none"}>
              <img src={content?.media.find(obj => obj.type === 'image')?.url!} style={{ width: '100%' }} />
            </Box>
            : <Box></Box>
          } */}
        </HStack>
      </VStack>
    </BaseView>
  );
};

export default NineG;
