import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react'
import React, { useState, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import AddPlaylist from 'src/views/Playlists/AddPlaylist'
import usePlaylists from 'src/views/Playlists/usePlaylists'
import Card from '../Card'

// import ItemCard from '../ItemCard'
import PlaylistsList from './PlaylistsList'

const AddToPlaylistModal = ({ isOpen, onClose, track, entityName }) => {
    const [tabIndex, setTabIndex] = useState(0)
    const handleTabsChange = useCallback((index: number) => {
        setTabIndex(index)
    }, [])
    const { playlistsQuery: { data: playlists, isLoading, error }, addToPlaylistMutation } = usePlaylists()
    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody mt="5">
                    {playlists && playlists.length ? <Tabs isFitted variant='line' index={tabIndex} onChange={(index) => { handleTabsChange(index) }}>
                        <TabList mb='1em'>
                            <Tab><FormattedMessage id="addToPlaylist" /></Tab>
                            <Tab><FormattedMessage id="createPlaylist" /></Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <PlaylistsList entityName={entityName} track={track} onAdd={() => handleTabsChange(1)} />
                            </TabPanel>
                            <TabPanel>
                                <AddPlaylist isOpen={isOpen} onClose={onClose} entityName={entityName} track={track} />
                                <Card color="gray.500" mb="4" >{track?.name || track?.slug || track?.frequency}</Card>
                            </TabPanel>
                        </TabPanels>
                    </Tabs> : <Tabs isFitted variant='line' index={tabIndex} onChange={(index) => { handleTabsChange(index) }}>
                        <TabList mb='1em'>
                            <Tab><FormattedMessage id="createPlaylist" /></Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <AddPlaylist isOpen={isOpen} onClose={onClose} entityName={entityName} track={track} />
                                <Card color="gray.500" mb="4" >{track?.name || track?.slug || track?.frequency}</Card>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default AddToPlaylistModal