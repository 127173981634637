import React, { useState } from "react";
import Video from "./Video";
import styles from "../styles/videos.module.css";
import { FormattedMessage } from "react-intl";
import { Training } from "src/types";

interface SectionProps {
  title: string;
  videos: Training[];
  categoryIndex: number;
}

const Section = ({ title, videos, categoryIndex }: SectionProps) => {
  const [isSeeMore, setIsSeeMore] = useState<boolean>(false);

  return (
    <>
      {videos.length > 0 && (
        <>
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <h3 className={styles.sectionTitle}>{title}</h3>
              {videos.length > 3 && (
                <div className={styles.seeMoreBtnContainer}>
                  <button className={styles.seeMoreBtn} onClick={() => setIsSeeMore(!isSeeMore)}>
                    <FormattedMessage id={isSeeMore ? "seeLess" : "seeMore"} />
                  </button>
                </div>
              )}
            </div>
            <div className={styles.videosContainer}>
              {(isSeeMore ? videos : videos.slice(0, 3)).map((vid) => (
                <Video key={vid.id} video={{ ...vid }} />
              ))}
            </div>
          </div>
          {categoryIndex !== videos.length - 1 && (
            <div className={styles.lineSeparatorContainer}>
              <span className={styles.lineSeparator}></span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Section;
