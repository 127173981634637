import {
  Box,
  Link,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import UserRating from "./UserRating";
import RatingModal from "./RatingModal";
import { useSelector } from "src/store";
import { deleteMethod, get } from "src/api";
import StaticRatingStars from "./StaticRatingStars";
import InfiniteScroll from "react-infinite-scroll-component";
import RatingModalV2 from "./RatingModalV2";
import { useGetAllWaves } from "src/newApi/hooks";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

const Rating = ({ entityId, entityName }) => {
  const intl = useIntl();

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [allRatings, setAllRatings] = useState<any>();

  const [ratingsPage, setRatingsPage] = useState<number>(1);

  const [myRating, setMyRating] = useState<any>();

  const [totalRating, setTotalRating] = useState<any>();

  const currentUser: any = useSelector((state) => state.auth.user);

  const [showReviews, setShowReviews] = useState(false);

  const userIsAdmin = currentUser?.role?.name === "Admin";

  const { refetch: refetchWaves } = useGetAllWaves();

  const fetchRatings = useCallback(async () => {
    const initialAllRatings = await get(
      `/rating/allRating/${entityName}/${entityId}`
    );
    const initialMyRating = await get(
      `/rating/myRating/${entityName}/${entityId}`
    );
    const initialTotalRating = await get(
      `/rating/totalRating/${entityName}/${entityId}`
    );

    initialAllRatings && setAllRatings(initialAllRatings);
    initialMyRating && setMyRating(initialMyRating);
    initialTotalRating && setTotalRating(initialTotalRating);
  }, [entityId, entityName]);

  const fetchMoreRatings = useCallback(async () => {
    const nextLoadOfRating: any = await get(
      `/rating/allRating/${entityName}/${entityId}?page=${ratingsPage}`
    );
    if (nextLoadOfRating?.length) {
      setAllRatings((currentRatings) =>
        currentRatings.concat(nextLoadOfRating)
      );
      setRatingsPage((ratingsPage) => ratingsPage + 1);
    } else {
    }
  }, [entityId, entityName, ratingsPage]);

  useEffect(() => {
    fetchRatings();
  }, [fetchRatings]);

  const deleteMyRating = useCallback(() => {
    deleteMethod(`/rating/myRating/${entityName}/${entityId}`)
      .then((res: any) => {
        if (res?.success) {
          toast({
            title: intl.formatMessage({ id: "rating.success" }),
            status: "success",
            isClosable: true,
          });
          refetchWaves();
          fetchRatings();
        } else {
          toast({
            title: intl.formatMessage({ id: "wentWrong" }),
            status: "error",
            isClosable: true,
          });
        }
        onClose();
      })
      .catch((err) => {
        toast({
          title: intl.formatMessage({ id: "wentWrong" }),
          status: "error",
          isClosable: true,
        });
      });
  }, [entityId, entityName, fetchRatings, refetchWaves, intl, onClose, toast]);

  const deleteRating = useCallback(
    (id) => {
      deleteMethod(`/rating/${id}`)
        .then((res: any) => {
          if (res?.success) {
            toast({
              title: intl.formatMessage({ id: "deleted" }),
              status: "success",
              isClosable: true,
            });
            refetchWaves();
            fetchRatings();
          } else {
            toast({
              title: intl.formatMessage({ id: "wentWrong" }),
              status: "error",
              isClosable: true,
            });
          }
          onClose();
        })
        .catch((err) => {
          toast({
            title: intl.formatMessage({ id: "wentWrong" }),
            status: "error",
            isClosable: true,
          });
        });
    },
    [fetchRatings, refetchWaves, intl, onClose, toast]
  );

  return (
    <Box>
      <Text color="#b3b1ad" fontWeight="bold" fontSize={"20px"} width={"100%"}>
        <FormattedMessage id="rating" />
      </Text>
      <Box d={["block", "block", "flex"]} mt="2">
        <Box w="150px" mr="5">
          <Box>
            <StaticRatingStars rating={totalRating?.totalRating} />
            {/* <RatingStars
                            icon="star"
                            scale={5}
                            fillColor="gold"
                            size={5}
                            initialRating={totalRating?.totalRating}
                            isDisabled
                        /> */}
          </Box>
          <Box d="block" justifyContent={"space-between"}>
            {totalRating?.totalRating ? (
              <Text color="#b3b1ad">
                {`${Number(totalRating?.totalRating).toPrecision(2)}`}{" "}
                <FormattedMessage id="outof" /> 5
              </Text>
            ) : null}
            {totalRating?.totalRating ? (
              <Link
                as="button"
                onClick={() => {
                  setShowReviews((showReviews) => !showReviews);
                }}
                color="brand.900"
                textStyle="smallBold"
              >
                {`${totalRating?.totalCount}`}{" "}
                <FormattedMessage id="ratedThis" />{" "}
              </Link>
            ) : null}
            {/* <br /> */}
            {myRating && !Object.keys(myRating).length ? (
              <Link
                mx="2"
                as="button"
                onClick={() => {
                  onOpen();
                }}
                color="brand.900"
                textStyle="smallBold"
              >
                <FormattedMessage id="addRating" />
              </Link>
            ) : null}
          </Box>
        </Box>
        {showReviews ? (
          <Box w="100%">
            {myRating && Object.keys(myRating).length !== 0 ? (
              <UserRating
                id={myRating.id}
                user={myRating.user}
                rating={myRating.rating}
                description={myRating.description}
                date={myRating.updatedAt}
                canEdit={true}
                canDelete={true}
                openModal={onOpen}
                deleteRating={deleteRating}
              />
            ) : null}
            <Box d="flex" flexDir={"column-reverse"}>
              {allRatings?.length ? (
                <InfiniteScroll
                  dataLength={allRatings.length}
                  next={fetchMoreRatings}
                  hasMore={totalRating?.totalCount - 1 > allRatings?.length} // -1 because allRatings has all ratings except current user's rating
                  loader={<Spinner alignSelf={"center"} />}
                >
                  {allRatings.map(
                    ({ id, user, rating, description, updatedAt }) => (
                      <UserRating
                        key={id}
                        id={id}
                        user={user}
                        rating={rating}
                        description={description}
                        date={updatedAt}
                        canEdit={false}
                        canDelete={userIsAdmin}
                        openModal={null}
                        deleteRating={deleteRating}
                      />
                    )
                  )}
                </InfiniteScroll>
              ) : null}
            </Box>
          </Box>
        ) : null}
      </Box>
      <RatingModalV2
        isOpen={isOpen}
        onClose={onClose}
        entityId={entityId}
        entityName={entityName}
        refetchRating={refetchWaves}
        currentUserRating={myRating}
      />
    </Box>
  );
};

export default Rating;
