import { createSlice } from "@reduxjs/toolkit";

const initialState = { openVideo: false, done: false };
type State = typeof initialState;
const authSlice = createSlice({
  name: "calibration",
  initialState,
  reducers: {
    onEnd(state: State) {
      state.openVideo = false;
      state.done = true;
    },
    calibrationFunc(state) {
      if (!state.done) {
        state.openVideo = true;
      }
    },
    load(state) {
      const initialCalibration = window.localStorage.getItem("_sal-Calibra");
      if (initialCalibration && initialCalibration === "done") {
        state.openVideo = false;
        state.done = true;
      }
      else {
        state.openVideo = false;
        state.done = false;
      }
    },
  },
});
export const { onEnd, calibrationFunc, load } = authSlice.actions;
export default authSlice.reducer;
