import { deleteMethod, post, put } from "src/api";
import { Vision, wishItem } from "src/types";

export const create: (vision: Partial<Vision>) => Promise<Partial<Vision>> = (
  vision
) => post<Partial<Vision>>("/wish", vision);

export const edit: (vision: Vision) => Promise<Vision> = (vision) => {
  return put<Vision>(`/wish/${vision.id!}`, vision);
};

export const editWishItem: (wish: wishItem) => Promise<wishItem> = (wish) =>
  put<wishItem>(`/wish/item/${wish.id!}`, wish);

export const deleteWish: (id: any) => Promise<void> = (id) =>
  deleteMethod(`/wish/${id!}`);

export const duplicateWish: (id: any) => Promise<void> = (id) =>
  post<any>(`/wish/duplicate/${id}`, { wishId: id });
export const deleteWishItem: (id: any) => Promise<void> = (id) =>
  deleteMethod(`/wish/item/${id!}`);
