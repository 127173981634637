import React from "react";
import BaseView from "../BaseView";
import Link from "src/components/Link/Link";
import Disease from "../Disease/Disease";
import { VStack, HStack } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import {
  DISEASE_ROUTE,
  TAPPING_ROUTE,
  NINE_G_ROUTE,
  SESSION_ROUTE,
} from "src/routes";
import Parts from "../Disease/Parts";
import Detail from "../Disease/Detail";
import Tapping from "../Therapy/Tapping";
import NineG from "../Therapy/NineG";
import Inner from "../Disease/Inner";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Box,
  Text,
} from "@chakra-ui/react";
import { FaHandPointUp, FaSortNumericDown, FaPlusCircle } from "react-icons/fa";
import { Link as DOMLink } from "react-router-dom";

const Healing = () => {
  //disclosure props for controlling modal visibility
  const disclosure = useDisclosure();

  //icons to include in the modal
  const icons = [
    { Icon: FaHandPointUp, title: "tapChart", path: TAPPING_ROUTE },
    { Icon: FaSortNumericDown, title: "9ggamut", path: NINE_G_ROUTE },
    { Icon: FaPlusCircle, title: "startSession", path: SESSION_ROUTE },
  ];

  return (
    <BaseView>
      <VStack w="100%" spacing="10" paddingTop={"30px"}>
        <HStack flexWrap="wrap">
          <Link
            to={DISEASE_ROUTE}
            borderRadius={15}
            py={1}
            isSecondary
            maxW="none"
            width="fit-content"
            padding={"5px 10px"}
          >
            <FormattedMessage id="symptoms" />
          </Link>
          <Link
            onClick={disclosure.onOpen}
            to={""}
            borderRadius={15}
            py={1}
            isSecondary
            maxW="none"
            width="fit-content"
            padding={"5px 10px"}
          >
            <FormattedMessage id="thoughtTherapy" />
          </Link>
        </HStack>
        <Modal {...disclosure} onClose={disclosure.onClose}>
          <ModalOverlay />
          <ModalContent
            background="white"
            padding="30px"
            width={{ base: "90%" }}
            marginTop="150px"
            borderRadius="25px"
            minWidth={{ md: "600px" }}
          >
            <ModalHeader p="0">
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody p="0">
              <Box textAlign="start">
                <Text fontSize={20} fontWeight="bolder" color="#1f3f93">
                  <FormattedMessage id="thoughtTherapy" />
                </Text>
                <Link
                  to=""
                  background="transparent"
                  textAlign="start"
                  padding="5px 0 0 0"
                >
                  <Text color="#58b6f5">
                    <FormattedMessage id="archiveSessions" />
                  </Text>
                </Link>
              </Box>
              <Box
                display={"flex"}
                flexDir={{ base: "column", md: "row" }}
                justifyContent="center"
                alignItems="center"
                marginTop="50px"
              >
                {icons.map(({ Icon, path, title }) => (
                  <Box
                    key={path}
                    as={DOMLink}
                    to={path}
                    textAlign="center"
                    width="100px"
                    display="flex"
                    alignItems="center"
                    flexDir="column"
                    margin="20px"
                  >
                    <Box
                      padding="15px"
                      background="#e3e1dc"
                      borderRadius="10px"
                      transition="250ms ease-out"
                      _hover={{ background: "#c4c2be" }}
                    >
                      <Icon size={60} color="#2895ff" />
                    </Box>
                    <Text
                      fontSize="12px"
                      whiteSpace="pre"
                      color="#d6d4d0"
                      marginTop="5px"
                    >
                      <FormattedMessage id={title} />
                    </Text>
                  </Box>
                ))}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </VStack>
    </BaseView>
  );
};

Healing.Detail = Detail;
Healing.Parts = Parts;
Healing.Disease = Disease;
Healing.Tapping = Tapping;
Healing.NineG = NineG;
Healing.Inner = Inner;

export default Healing;
