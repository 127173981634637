import { Button, HStack, Input } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';


const SearchBar = ({ onSubmit }) => {

    const {formatMessage} = useIntl();
    const [value, setValue] = useState()
    const formSubmit = (e: any) => {
        e.preventDefault()
        onSubmit(value)

    }


    return (
        <div>
            <form onSubmit={formSubmit}>
                <HStack>
                    <Input
                        placeholder="search ..."
                        className="inputStyle"
                        type="text"
                        value={value}
                        onChange={(e: any) => setValue(e.target.value)}
                        variant="flushed"
                    />
                    <Button variant='outlined' type='submit'>
                        {formatMessage({ id: 'SearchUnsplash' })}
                    </Button>
                </HStack>
            </form>
        </div>
    )

}

export default SearchBar;