import { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import WaveSurferCache from "./WaveSurferCache";
import { usePlayback } from "./PlaybackContext";

const useWaveSurfer = (url: string) => {
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const { currentTrack } = usePlayback();

  const waveSurferCache = WaveSurferCache.getInstance();

  useEffect(() => {
    // If the WaveSurfer instance is already cached, use it
    const cachedWaveSurfer = waveSurferCache.get(url);

    if (cachedWaveSurfer && containerRef.current) {
      waveSurferRef.current = cachedWaveSurfer;

      cachedWaveSurfer.setOptions({
        container: containerRef.current,
        height: 100,
        waveColor: "#ccc",
        progressColor: "#2895ff",
        cursorColor: "#fff",
        barWidth: 2,
        interact: false,
        // url: url,
      });

      setDuration(cachedWaveSurfer.getDuration());
      setIsReady(true);

      cachedWaveSurfer.on("audioprocess", () =>
        setCurrentTime(cachedWaveSurfer.getCurrentTime())
      );
      cachedWaveSurfer.on("finish", () => {
        setCurrentTime(0); // Reset time on finish
        cachedWaveSurfer.play(); // Play again to loop
      });

      cachedWaveSurfer.setMuted(true);
    } else {
      // Otherwise, create a new instance and cache it
      if (containerRef.current && !waveSurferRef.current) {
        const ws = WaveSurfer.create({
          container: containerRef.current,
          height: 100,
          waveColor: "#ccc",
          progressColor: "#2895ff",
          cursorColor: "#fff",
          barWidth: 2,
          interact: false,
          // url: url,
        });

        ws.on("ready", () => {
          setDuration(ws.getDuration());
          setIsReady(true);
          // if (currentTrack?.url === url) {
          //   ws.seekTo(
          //     currentTrack.waveSurfer.getCurrentTime() /
          //       currentTrack.waveSurfer.getDuration()
          //   );
          // }
        });

        ws.setMuted(true);

        ws.on("error", (e) => {
          console.error("WaveSurfer error:", e);
        });

        ws.on("audioprocess", () => setCurrentTime(ws.getCurrentTime()));
        ws.on("finish", () => {
          setCurrentTime(0); // Reset time on finish
          ws.play(); // Play again to loop
        });

        waveSurferRef.current = ws;
        // waveSurferCache.set(url, ws); // Cache the WaveSurfer instance
      }
    }
  }, [currentTrack?.url, currentTrack?.waveSurfer, url, waveSurferCache]);

  return {
    waveSurferRef,
    containerRef,
    isReady,
    duration,
    currentTime,
  };
};

export default useWaveSurfer;
