import { Box, BoxProps, IconButton, Text, useBoolean } from "@chakra-ui/react";
import React from "react";
import { BiCopy } from "react-icons/bi";
import { RiDeleteBin6Line, RiShareBoxLine } from "react-icons/ri";
import { useIntl } from "react-intl";
import AccessPermission from "src/components/AccessPermission/AccessPermission";
import DeleteButton from "src/components/DeleteModel";

type PurposeItemProps = {
  remove: () => void;
  douplicate?: () => void;
  share?: () => void;
  isSharedWithMe?: boolean;
  id: number;
  permission?: "read" | "update";
} & Omit<BoxProps, "id">;

const PurposeItem: React.FC<PurposeItemProps> = ({
  children,
  remove,
  onClick,
  douplicate,
  id,
  share,
  isSharedWithMe,
  permission,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const canUpdate = permission === "update";

  return (
    <>
      <Box
        mb={2}
        {...props}
        cursor={isSharedWithMe ? (canUpdate ? "pointer" : "not-allowed") : "pointer"}>
        <Text
          onClick={isSharedWithMe ? (canUpdate ? onClick : () => {}) : onClick}
          d='inline-block'
          fontFamily='Calibri (Body)'
          lineHeight='22px'
          color={isSharedWithMe ? (canUpdate ? "black" : "GrayText") : "black"}>
          {children}
        </Text>
        <Box
          d='inline-block'
          float='right'
          color={isSharedWithMe ? (canUpdate ? "black" : "GrayText") : "black"}>
          {isSharedWithMe && (
            <Box d='flex'>
              {" "}
              <span style={{ padding: "0 5px" }}>{formatMessage({ id: "shared" })}</span>
              <AccessPermission permission={permission} />
            </Box>
          )}
          {douplicate ? (
            <IconButton
              mx={2}
              onClick={douplicate}
              me={1}
              minW='1rem'
              fontSize='20px'
              variant='link'
              aria-label='delete'
              icon={<BiCopy />}
            />
          ) : null}
          {!isSharedWithMe && share && (
            <IconButton
              // visibility={shareIconVisibility}
              // color="#e97575"
              onClick={(e: any) => {
                e.stopPropagation();
                share();
              }}
              variant='link'
              aria-label='delete'
              fontSize='23px'
              minW={1}
              icon={<RiShareBoxLine className='remove-on-print' />}
              h='5'
            />
          )}
          {!isSharedWithMe && <DeleteButton remove={remove} />}
        </Box>
      </Box>
    </>
  );
};
export default PurposeItem;
