import React from "react";
import { Textarea, TextareaProps, Box } from "@chakra-ui/react";

const TextArea: React.FC<TextareaProps> = ({
  onChange,
  value,
  placeholder,
  isDisabled,
  bg,
  borderRadius,
  ...props
}) => {
  return (
    <Box mt={"40px"} width={"100%"}>
      <Textarea
        isDisabled={isDisabled}
        pl={"10px"}
        borderRadius={borderRadius}
        background={bg}
        fontSize={"18px"}
        placeholder={placeholder}
        color={"black"}
        variant="flushed"
        h={"130px"}
        fontFamily={"tajwal"}
        onChange={onChange}
        value={value}
      />
    </Box>
  );
};

export default TextArea;
