import React, { useState, ChangeEvent, useEffect } from "react";
import {
  VStack,
  HStack,
  Table,
  Th,
  Thead,
  Tbody,
  Tr,
  Td,
  Input,
} from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import VideoIcon from "src/assets/icons/Video.jpeg";
import AudioIcon from "src/assets/icons/Audio.jpeg";
import { get } from "src/api/index";
import useLocale from "src/providers/useLocale";

//type for learning object
type LearningType = {
  id: number;
  title: string;
  description: string;
  speciality: string;
  type: "video" | "audio";
  url: string;
};

const Learning = () => {
  //heads for the table
  const heads = ["title", "desc", "domain", "type", "link"];

  //intl object
  const intl = useIntl();

  //current locale
  const [locale] = useLocale();

  //content displayed
  const [content, setContent] = useState<LearningType[]>();
  const [initialContent, setInitialContent] = useState<LearningType[]>();

  //map for types
  const typeMap = {
    video: VideoIcon,
    audio: AudioIcon,
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await get<LearningType[]>("/learning", locale);
      if (Array.isArray(response)) {
        //filter the only complete entries
        const filteredResponse = response.filter(
          (entry: LearningType) =>
            entry.speciality && entry.title && entry.description
        );
        filteredResponse.reverse();
        setInitialContent(filteredResponse);
        setContent(filteredResponse);
      }
    };
    fetchData();
  }, [locale]);

  //method for handling search
  const search = (e: ChangeEvent) => {
    if (!initialContent) return;
    const value = (e.target as HTMLInputElement).value;
    const filteredContent = initialContent.filter(
      (item) =>
        item.title.includes(value) ||
        item.description.includes(value) ||
        item.speciality.includes(value)
    );
    setContent(filteredContent);
  };

  return (
    <VStack
      width={{ base: "100%", sm: "80vw", md: "60vw" }}
      display="flex"
      flexDir="column"
      alignItems="center"
      overflow="hidden"
      paddingTop="50px"
      margin="0 auto"
      paddingBottom="50px"
    >
      <HStack paddingBottom="20px" width="100%">
        <Input
          type="text"
          placeholder={intl.formatMessage({ id: "search" })}
          width="100%"
          onChange={search}
        />
      </HStack>
      <Table
        display={
          content?.length === 0 || !content
            ? "table"
            : { base: "block", xl: "table" }
        }
        maxWidth="90vw"
        overflowX={{ base: "auto" }}
        borderRadius="10px"
        overflowY="hidden"
        css={{ borderCollapse: "collapse", border: "1px solid gray" }}
      >
        <Thead>
          <Tr>
            {heads.map((head, idx) => (
              <Th
                key={idx}
                border="1px solid silver"
                color="white"
                padding="10px"
                background="#2895ff"
                textAlign="center"
                fontSize="20"
                fontFamily="Calibri (Body)"
              >
                <FormattedMessage id={head} />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody color="grey">
          {content?.map((item, index) => (
            <Tr key={index} fontSize="16px">
              <Td minWidth="200px" textAlign="center" border="1px solid silver">
                {item.title}
              </Td>
              <Td minWidth="250px" textAlign="center" border="1px solid silver">
                {item.description}
              </Td>
              <Td minWidth="100px" textAlign="center" border="1px solid silver">
                {item.speciality}
              </Td>
              <Td minWidth="80px" textAlign="center" border="1px solid silver">
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={typeMap[item.type]}
                  alt={"learning type"}
                />
              </Td>
              <Td
                minWidth="120px"
                textAlign="center"
                border="1px solid silver"
                color="#2895ff"
              >
                <a href={item.url} target="_blank" rel={"noreferrer"}>
                  <FormattedMessage id="click" />
                </a>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  );
};

export default Learning;
