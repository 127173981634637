/* eslint-disable eqeqeq */

import {
  Tr,
  Td,
  Text,
  IconButton,
  HStack,
  Editable,
  EditableInput,
  EditablePreview,
  Checkbox,
  useToast,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useRef, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { MdEditNote } from "react-icons/md";
import { MdDragIndicator } from "react-icons/md";

import { RiDeleteBin6Line } from "react-icons/ri";
import ActionDatePicker from "./ActionDatePicker";
import { LocaleContext } from "src/providers/LocaleProvider";
import { PlanGoalAction } from "src/types";
import usePlanApi from "../usePlanApi";
import ViewAction from "../modals/ViewAction";
import { useIntl } from "react-intl";
import DeleteButton from "src/components/DeleteModel";

type ActionTableRowProps = {
  action: PlanGoalAction;
  onEdit: any;
  onRemove: () => void;
  planId: number;
  index?: number;
  reload: any;
  isDisabled?: boolean;
  provided: any;
  openWeightModal?: () => void;
  setActiveActionId?: () => void;
};

const ActionTableRow: React.FC<ActionTableRowProps> = ({
  action,
  planId,
  onEdit,
  index,
  onRemove,
  reload,
  isDisabled,
  //@ts-ignore
  provided,
  openWeightModal,
  setActiveActionId,
  ...props
}) => {
  const passed = new Date().getTime() > new Date(action.endDate).getTime();

  const diffInDays = Math.floor(
    (new Date(action.endDate).getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24)
  );
  const diffInDaysStart = Math.floor(
    (new Date(action.startDate).getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24)
  );
  const isTodayInRange = diffInDaysStart <= -1 && diffInDays >= -1;
  const isthreeDaysLeft = diffInDays >= 0 && diffInDays <= 2;
  const { locale } = useContext(LocaleContext);
  const { editActionMutation } = usePlanApi(planId);
  const toast = useToast();
  const { formatMessage } = useIntl();
  const createActionDisclosure = useDisclosure();

  const initialFormData: PlanGoalAction = {
    name: "",
    complete: false,
    endDate: new Date(),
    note: "",
    // @ts-ignore
    quantity: 0,
    startDate: new Date(),
    executor: "",
    counter: "",
    weight: 0,
  };

  const [formData, setFormData] = useState<PlanGoalAction>(
    action || initialFormData
  );

  const onChange = (fieldName: string, value: any) => {
    // console.log(fieldName, value)
    setFormData((oldFormData) => ({ ...oldFormData, [fieldName]: value }));
  };

  const completeCheckbox = useRef<HTMLInputElement>(null);

  const submitEditAction = async (fieldName?: string, value?: any) => {
    // if ((formData[`${fieldName}`] === value) && fieldName !=="complete") {
    //   return
    // }

    try {
      await editActionMutation({
        action: {
          id: formData.id,
          [fieldName ? fieldName : ""]: value,
          complete: completeCheckbox!.current!.checked,
        },
      });
      toast({
        title: formatMessage({ id: "plan.goal.action.edit.success" }),
        status: "success",
      });
    } catch (error) {
      toast({
        title: formatMessage({ id: "plan.goal.action.edit.failed" }),
        status: "error",
      });
    }
  };

  const getBackgroundColor = (action: PlanGoalAction) => {
    if (action.complete) return "#68D391"; //If task done ----- Dark Green
    if (new Date(action.startDate).getTime() > new Date().getTime())
      return "#f0f0f0"; //If Start Date > today ----- no color (planned)
    if (passed) return "rgba(233, 117, 117, 0.6)"; //If End Date <= today ----- Red
    if (isthreeDaysLeft) return "#EDED25"; //If End Date <= today+3days ---- Yellow
    if (isTodayInRange) return "#C6F6D5"; // today is in range but there are more thane 3 days to the end date of the task
    return "";
  };

  return (
    <Tr
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...provided.draggableProps.style,
        backgroundColor: getBackgroundColor(action),
      }}
    >
      <Td
        style={{
          borderRadius: locale === "ar" ? "0px 5px 5px 0px" : "5px 0px 0px 5px",
          padding: "0 10px",
        }}
      >
        <HStack>
          <IconButton
            {...provided.dragHandleProps}
            isDisabled={isDisabled}
            me="1"
            color="black"
            variant="link"
            fontSize="23px"
            minW={1}
            icon={<MdDragIndicator className="remove-on-print" />}
            // visibility={iconVisibility}
            // className="print-remove"
          />
          <Text
            textAlign="center"
            color="black"
            className="action-index-number"
          >
            {index ?? ""}
          </Text>
        </HStack>
      </Td>
      <Td p={[1, 2, 0]}>
        <Editable
          pl="1.5"
          defaultValue={action.name}
          isDisabled={isDisabled}
          onChange={(currentValue) => onChange("name", currentValue.trim())}
          onSubmit={(e) => {
            if (e === action.name) return;
            submitEditAction("name", formData.name);
          }}
          color="black"
          placeholder={formatMessage({ id: "Add action name" })}
          w={["auto", "auto", "300px"]}
        >
          <EditablePreview />
          <EditableInput disabled={isDisabled} />
        </Editable>
      </Td>

      <Td p={[2, 2, 2]}>
        <Box
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          onClick={() => {
            setActiveActionId!();
            openWeightModal!();
          }}
        >
          {action.weight ? action.weight : formatMessage({ id: "add" })}
        </Box>
      </Td>

      <Td p={[1, 2, 0]} textAlign="center">
        <Editable
          defaultValue={action.quantity + ""}
          onChange={(currentValue) => onChange("quantity", currentValue.trim())}
          isDisabled={isDisabled}
          onSubmit={(e) => {
            if (e == action.quantity + "") return;
            submitEditAction("quantity", formData.quantity);
          }}
          color="black"
          placeholder={formatMessage({ id: "add" })}
        >
          <EditablePreview />
          <EditableInput />
        </Editable>
      </Td>
      <Td p={[1, 2, 0]} textAlign="center">
        <Editable
          defaultValue={action.executor}
          color="black"
          onChange={(currentValue) => onChange("executor", currentValue.trim())}
          isDisabled={isDisabled}
          onSubmit={(e) => {
            if (e === action.executor) return;
            submitEditAction("executor", formData.executor);
          }}
          placeholder={formatMessage({ id: "Add executer" })}
          className={action.executor.trim() === "" ? "empty-field" : ""}
        >
          <EditablePreview />
          <EditableInput />
        </Editable>
      </Td>
      <Td p={[1, 2, 0]} textAlign="center">
        {/* <Box position="relative"> */}
        <ActionDatePicker
          // onSelect={submitEditAction}
          isDisabled={isDisabled}
          value={formData.startDate}
          onChange={(date) => {
            onChange("startDate", date);
            submitEditAction("startDate", date);
          }}
          inputProps={{
            placeholder: "تاريخ البدء",
          }}
        />
        {/* </Box> */}
      </Td>
      <Td p={[1, 2, 0]} textAlign="center">
        {/* <Box position="relative"> */}
        {/* {console.log(formData.endDate)} */}
        <ActionDatePicker
          isDisabled={isDisabled}
          value={formData.endDate}
          onChange={(date) => {
            onChange("endDate", date);
            submitEditAction("endDate", date);
          }}
          inputProps={{
            placeholder: "تاريخ الانتهاء",
          }}
        />
        {/* </Box> */}
      </Td>
      <Td p={[1, 2, 0]} textAlign="center">
        <Editable
          display="inline"
          isDisabled={isDisabled}
          defaultValue={action.counter ? action.counter : undefined}
          onChange={(currentValue) => onChange("counter", currentValue.trim())}
          onSubmit={(e) => {
            if (e === action.counter) return;
            submitEditAction("counter", formData.counter);
          }}
          color="black"
          placeholder={formatMessage({ id: "add" })}
        >
          <EditablePreview />
          <EditableInput />
        </Editable>
      </Td>
      {/* <Td p={[5]} textAlign="center">

        <input

            type="checkbox"
            defaultChecked={action.complete}
            name="complete"
            ref={completeCheckbox}
            onChange={() => {
            submitEditAction()
          }
          }
        />
      </Td>  */}
      <Td
        textAlign="end"
        style={{
          borderRadius: locale === "ar" ? "5px 0px 0px 5px" : "0px 5px 5px 0px",
          padding: "0px 20px",
        }}
      >
        <HStack h="8px" justifyContent="flex-end">
          <Checkbox
            //@ts-ignore
            isNative={true}
            isDisabled={isDisabled}
            // visibility={iconVisibility}
            type="checkbox"
            defaultIsChecked={action.complete}
            ref={completeCheckbox}
            name="complete"
            borderColor="blue.400"
            onChange={(e) => {
              submitEditAction("complete", e.target.checked);
            }}
          />
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation();
              createActionDisclosure.onOpen();
            }}
            isDisabled={isDisabled}
            me="1"
            color="brand.800"
            variant="link"
            aria-label="re-answer"
            fontSize="23px"
            minW={1}
            icon={
              action.note ? (
                <MdEditNote className="remove-on-print" />
              ) : (
                <BiEdit className="remove-on-print" />
              )
            }
            // visibility={iconVisibility}
            // className="print-remove"
          />

          {!isDisabled ? (
            <DeleteButton
              //@ts-ignore
              remove={onRemove}
            />
          ) : null}
        </HStack>
      </Td>
      <ViewAction
        modalDisclosure={createActionDisclosure}
        action={action}
        onSubmit={submitEditAction}
      />
    </Tr>
  );
};

export default ActionTableRow;
