/* eslint-disable react-hooks/exhaustive-deps */

import { Box, IconButton, Text, Textarea } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "src/components/Card";
import ActionBar from "../ActionBar";
import { MdSave } from "react-icons/md";
import { Purpose } from "src/types";
import { scrollBarStyles } from "src/layout/ScrollBar";
import { usePurposeApi } from "./usePurpoeApi";

export type StepFourProps = {
  purpose: Purpose;
  saveNote: (note: string) => Promise<Partial<Purpose>>;
  completePurpose: () => void;
  isSharedWithMe?: boolean;
  canUpdate?: boolean;
};
const StepFour: React.FC<
  StepFourProps & {
    closeModal: () => void;
    restoreModal: () => void;
    isSharedWithMe?: boolean;
    canUpdate?: boolean;
  }
> = ({ purpose, saveNote, closeModal, restoreModal, completePurpose, isSharedWithMe, canUpdate }) => {
  const [note, setNote] = useState<string>(
    purpose.content !== null ? purpose.content : ""
  );
  const [saved, setSaved] = useState(false);
  const [changed, setChanged] = useState(false);

  const { mutations } = usePurposeApi(purpose.id);

  const onDelete = () => {
    mutations.deletePurposeMutation(purpose.id);
    closeModal();
  };

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (purpose.content && purpose.status !== "completed") completePurpose();
    // console.log(purpose)
  }, [purpose]);

  return (
    <>
      <Box d="flex" flexDir="row" mb={4}>
        <Card marginEnd={6} py={4} ps={12}>
          <Box
            flexDir="row"
            justifyContent="space-between"
            display="flex"
            flex="1"
          >
            <Text
              d="inline-block"
              color="brand.900"
              fontSize="24px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
            >
              <FormattedMessage
                id="purposes.desires"
                defaultMessage="الغايات"
              />
            </Text>
          </Box>
          <Box overflowY="auto" maxHeight={"150px"} css={scrollBarStyles}>
            {purpose.selectedTargets.map((item, idx) => (
              <Text key={idx} fontFamily="Calibri (Body)" color="gray.900">
                {item.name}
              </Text>
            ))}
          </Box>
        </Card>
        <Card py={4} ps={12}>
          <Box
            flexDir="row"
            justifyContent="space-between"
            display="flex"
            flex="1"
          >
            <Text
              d="inline-block"
              color="brand.900"
              fontSize="24px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
            >
              <FormattedMessage id="purposes.values" defaultMessage="القيم" />
            </Text>
          </Box>
          <Box overflowY="auto" maxHeight={"150px"} css={scrollBarStyles}>
            {purpose!
              .selectedValues!.sort((a, b) => a.order - b.order)
              .slice(0, 3)
              .map((item) => (
                <Text
                  fontFamily="Calibri (Body)"
                  color="gray.900"
                  key={item.id}
                >
                  {item.name}
                </Text>
              ))}
          </Box>
        </Card>
      </Box>
      <Card
        minH={170}
        py={4}
        ps={12}
        onClick={() => {
          if (saved) setSaved(false);
        }}
      >
        <Box
          flexDir="row"
          justifyContent="space-between"
          display="flex"
          flex="1"
        >
          <Text
            d="inline-block"
            color="brand.900"
            fontSize="24px"
            fontWeight="bold"
            fontFamily="Calibri (Body)"
          >
            <FormattedMessage id="purposes.content" defaultMessage="الرسالة" />
          </Text>
          <IconButton
            color="brand.500"
            aria-label="edit"
            onClick={() => {
              saveNote(note);
              setSaved(true);
              setChanged(false);
            }}
            // fontSize="20px"
            icon={<MdSave />}
            variant="link"
            disabled={!changed || !note}
          />
        </Box>
        <Textarea
          variant="flushed"
          color={saved ? "gray.900" : "black"}
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
            setChanged(true);
          }}
          disabled={saved || !((Boolean(isSharedWithMe) && canUpdate) || !Boolean(isSharedWithMe))}
          cursor={saved ? "pointer" : "text"}
          maxHeight={"200px"}
          overflowY={"auto"}
          resize={"none"}
          css={scrollBarStyles}
        />
      </Card>
      <Box padding="10px 0"></Box>
      <ActionBar
        mt={8}
        onDelete={onDelete}
        textToCopy={note}
        onResort={restoreModal}
        title={formatMessage({ id: "print.purpose" }, { name: purpose.name })}
        keyTitle="purpose"
        canRestart={!Boolean(isSharedWithMe)}
        canDelete={!Boolean(isSharedWithMe)}
      />
      <div style={{ display: "none" }}>
        <Box
          id="text-to-mail"
          padding="20px"
          data-subject={formatMessage({ id: "purposes" })}
        >
          <Box dir="auto">
            <Text
              style={{ fontWeight: "bold", padding: "10px", fontSize: "18px" }}
            >
              {formatMessage({ id: "purposes" })}: ({purpose.name})
            </Text>
          </Box>
          <Box
            textAlign="start"
            style={{ paddingTop: "20px", textAlign: "center" }}
            dir="auto"
          >
            <p>{note}</p>
          </Box>
        </Box>
      </div>
    </>
  );
};
export default StepFour;
