import React from "react";
import { Box, Link, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as ReactRouterLink } from "react-router-dom";
import { DESCRIPTION_ROUTE, FAQ_ROUTE } from "../../routes";
import SendNote from "../SendNote/SendNote";

const Footer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 465px)");
  // this to make عن on top of sal125 on mobile devices per client request
  const aboutSalArabicText = isMobile ? "عن SAL125" : "SAL125 عن";
  const toOrderSalMagic = isMobile ? "لطلب SAL Magic" : "SAL Magic لطلب";
  const { locale } = useIntl();
  return (
    <Box
      as="footer"
      dir="ltr"
      padding="60px 20px 10px 20px"
      textAlign="center"
      fontSize="13px"
    >
      {window.location.pathname !== "/login" && (
        <Box
          w="100%"
          d="flex"
          alignItems="center"
          justifyContent="center"
          mb="10"
        >
          <Link
            as="button"
            onClick={() => {
              onOpen();
            }}
            color="brand.900"
            textStyle="smallBold"
            px="5"
          >
            <FormattedMessage id="feedback" />
          </Link>
          <Link
            as={ReactRouterLink}
            to={FAQ_ROUTE}
            color="brand.900"
            textStyle="smallBold"
            px="5"
          >
            <FormattedMessage id="faq" />
          </Link>
          <Link
            as={ReactRouterLink}
            to={DESCRIPTION_ROUTE}
            color="brand.900"
            textStyle="smallBold"
            px="5"
          >
            {locale === "ar" ? aboutSalArabicText : "About SAL125"}
          </Link>
          <Link
            target={"_blank"}
            href={"https://t.me/SmartsWay125"}
            color="brand.900"
            textStyle="smallBold"
            px="5"
          >
            <FormattedMessage id="contactSupport" />
          </Link>

          <Link
            target={"_blank"}
            href={
              "https://shop.smartsway.com/index.php?route=product/product&path=63_105&product_id=785"
            }
            color="brand.900"
            textStyle="smallBold"
            px="5"
          >
            {locale === "ar" ? toOrderSalMagic : "To Order SAL Magic"}
          </Link>
        </Box>
      )}
      <strong style={{ display: "inline" }}>
        &copy; All Rights Reserved for SmartsWay
      </strong>
      <a
        style={{
          display: "inline",
          marginTop: "5px",
          marginLeft: 5,
        }}
        href="https://techhive.dev/"
      >
        <strong>Developed by Tech Hive Dev</strong>
      </a>
      <SendNote isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default Footer;
