import { useEffect, useState } from "react";
import { Vision, Wish } from "src/types";

export type ReturnUseWishes = {
  wishes: Wish[];
  top20: Wish[];
  top10: Wish[];
  addTo10: (idx: number) => void;
  addTo20: (idx: number) => void;
  returnToWishes: (idx: number) => void;
  returnTo20: (idx: number) => void;
  reset: () => void;
  step: number;
  setStep: (val: number) => void;
};

type UseTopWishesParams = {
  vision?: Vision;
  isOpen: boolean;
};
export const useWishes: (params: UseTopWishesParams) => ReturnUseWishes = ({
  vision,
  isOpen,
}) => {
  const [wishes, setWishes] = useState<Wish[]>([]);
  const [step, setStep] = useState(0);
  useEffect(() => {
    if (vision) setWishes(vision.wishes);
  }, [vision]);

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen]);
  const [top20, setTop20] = useState<Wish[]>([]);
  const [top10, setTop10] = useState<Wish[]>([]);
  const addTo20 = (idx: number) => {
    setTop20((old) => [...old, wishes[idx]]);
    setWishes((old) => [...old.slice(0, idx), ...old.slice(idx + 1)]);
  };
  const returnToWishes = (idx: number) => {
    setWishes((old) => [...old, top20[idx]]);
    setTop20((old) => [...old.slice(0, idx), ...old.slice(idx + 1)]);
  };
  const addTo10 = (idx: number) => {
    setTop10((old) => [...old, top20[idx]]);
    setTop20((old) => [...old.slice(0, idx), ...old.slice(idx + 1)]);
  };
  const returnTo20 = (idx: number) => {
    setTop20((old) => [...old, top10[idx]]);
    setTop10((old) => [...old.slice(0, idx), ...old.slice(idx + 1)]);
  };
  const reset = () => {
    setTop10([]);
    setTop20([]);
    setWishes([]);
    setStep(0);
  };
  return {
    addTo10,
    addTo20,
    returnToWishes,
    returnTo20,
    wishes,
    top10,
    top20,
    reset,
    step,
    setStep,
  };
};
