import { Link, useLocation } from "react-router-dom";
import { Box, IconButton, useDisclosure } from "@chakra-ui/react";
import React from "react";
import RatingStars from "src/components/Rating/RatingStars";
import RatingModal from "src/components/Rating/RatingModal";
import { IoMdCalendar } from "react-icons/io";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useGetAllWaves } from "src/newApi/hooks";
import StaticRatingStars from "src/components/Rating/StaticRatingStars";
import RatingModalV2 from "src/components/Rating/RatingModalV2";

export default function SingleWave(props: { wave: any; entityName: any }) {
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { refetch: refetchWaves } = useGetAllWaves();
  return (
    <Box
      // margin={{
      //   base: "10px 10px 30px 10px !important",
      //   lg: "15px 15px !important",
      //   xl: "30px 30px !important",
      // }}
      margin={["20px 10px", "20px 15px", "20px 15px"]}
      width={"150px"}
      height={"150px"}
    >
      <Link
        to={
          props?.wave?.waveCategory?.id
            ? `${pathname}/category/${props.wave?.waveCategory?.id}/wave/${props?.wave?.id}`
            : `${pathname}/category/${props.wave?.category?.id}/wave/${props?.wave?.id}`
        }
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
          }}
          src={props.wave.imageUrl}
          alt={"img"}
        />
      </Link>
      <Box
        d="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        px="4px"
      >
        {/* <RatingStars
          icon="star"
          scale={5}
          fillColor="gold"
          size={3}
          isDisabled
          initialRating={props.wave.rating} /> */}
        <StaticRatingStars rating={props.wave.rating} starSize={18} />
        <IconButton
          variant="button"
          color="gold"
          fontSize="28px"
          aria-label="add"
          onClick={onOpen}
          d="flex"
          justifyContent="flex-end"
          icon={<AddCircleOutlineIcon />}
        />
      </Box>
      <RatingModalV2
        isOpen={isOpen}
        onClose={onClose}
        entityId={props.wave.id}
        entityName={props.entityName}
        refetchRating={refetchWaves}
        currentUserRating={props.wave.myRating}
      />
    </Box>
  );
}
