export const englishList = [
  {
    label: "Abundance",
    value: "Abundance",
  },
  {
    label: "Acceptance",
    value: "Acceptance",
  },
  {
    label: "Accessibility",
    value: "Accessibility",
  },
  {
    label: "Accomplishment",
    value: "Accomplishment",
  },
  {
    label: "Accountability",
    value: "Accountability",
  },
  {
    label: "Accuracy",
    value: "Accuracy",
  },
  {
    label: "Achievement",
    value: "Achievement",
  },
  {
    label: "Acknowledgement",
    value: "Acknowledgement",
  },
  {
    label: "Activeness",
    value: "Activeness",
  },
  {
    label: "Adaptability",
    value: "Adaptability",
  },
  {
    label: "Adoration",
    value: "Adoration",
  },
  {
    label: "Adroitness",
    value: "Adroitness",
  },
  {
    label: "Advancement",
    value: "Advancement",
  },
  {
    label: "Adventure",
    value: "Adventure",
  },
  {
    label: "Affection",
    value: "Affection",
  },
  {
    label: "Affluence",
    value: "Affluence",
  },
  {
    label: "Aggressiveness",
    value: "Aggressiveness",
  },
  {
    label: "Agility",
    value: "Agility",
  },
  {
    label: "Alertness",
    value: "Alertness",
  },
  {
    label: "Altruism",
    value: "Altruism",
  },
  {
    label: "Amazement",
    value: "Amazement",
  },
  {
    label: "Ambition",
    value: "Ambition",
  },
  {
    label: "Amusement",
    value: "Amusement",
  },
  {
    label: "Anticipation",
    value: "Anticipation",
  },
  {
    label: "Appreciation",
    value: "Appreciation",
  },
  {
    label: "Approachability",
    value: "Approachability",
  },
  {
    label: "Approval",
    value: "Approval",
  },
  {
    label: "Art",
    value: "Art",
  },
  {
    label: "Articulacy",
    value: "Articulacy",
  },
  {
    label: "Artistry",
    value: "Artistry",
  },
  {
    label: "Assertiveness",
    value: "Assertiveness",
  },
  {
    label: "Assurance",
    value: "Assurance",
  },
  {
    label: "Attentiveness",
    value: "Attentiveness",
  },
  {
    label: "Attractiveness",
    value: "Attractiveness",
  },
  {
    label: "Audacity",
    value: "Audacity",
  },
  {
    label: "Availability",
    value: "Availability",
  },
  {
    label: "Awareness",
    value: "Awareness",
  },
  {
    label: "Awe",
    value: "Awe",
  },
  {
    label: "Balance",
    value: "Balance",
  },
  {
    label: "Beauty",
    value: "Beauty",
  },
  {
    label: "Being",
    value: "Being",
  },
  {
    label: "Belonging",
    value: "Belonging",
  },
  {
    label: "Benevolence",
    value: "Benevolence",
  },
  {
    label: "Bliss",
    value: "Bliss",
  },
  {
    label: "Boldness",
    value: "Boldness",
  },
  {
    label: "Bravery",
    value: "Bravery",
  },
  {
    label: "Brilliance",
    value: "Brilliance",
  },
  {
    label: "Buoyancy",
    value: "Buoyancy",
  },
  {
    label: "Calmness",
    value: "Calmness",
  },
  {
    label: "Camaraderie",
    value: "Camaraderie",
  },
  {
    label: "Candor",
    value: "Candor",
  },
  {
    label: "Capability",
    value: "Capability",
  },
  {
    label: "Care",
    value: "Care",
  },
  {
    label: "Carefulness",
    value: "Carefulness",
  },
  {
    label: "Celebrity",
    value: "Celebrity",
  },
  {
    label: "Certainty",
    value: "Certainty",
  },
  {
    label: "Challenge",
    value: "Challenge",
  },
  {
    label: "Change",
    value: "Change",
  },
  {
    label: "Charity",
    value: "Charity",
  },
  {
    label: "Charm",
    value: "Charm",
  },
  {
    label: "Chastity",
    value: "Chastity",
  },
  {
    label: "Cheerfulness",
    value: "Cheerfulness",
  },
  {
    label: "Clarity",
    value: "Clarity",
  },
  {
    label: "Cleanliness",
    value: "Cleanliness",
  },
  {
    label: "Clear-mindedness",
    value: "Clear-mindedness",
  },
  {
    label: "Cleverness",
    value: "Cleverness",
  },
  {
    label: "Closeness",
    value: "Closeness",
  },
  {
    label: "Comfort",
    value: "Comfort",
  },
  {
    label: "Commitment",
    value: "Commitment",
  },
  {
    label: "Community",
    value: "Community",
  },
  {
    label: "Compassion",
    value: "Compassion",
  },
  {
    label: "Competence",
    value: "Competence",
  },
  {
    label: "Competition",
    value: "Competition",
  },
  {
    label: "Completion",
    value: "Completion",
  },
  {
    label: "Composure",
    value: "Composure",
  },
  {
    label: "Concentration",
    value: "Concentration",
  },
  {
    label: "Confidence",
    value: "Confidence",
  },
  {
    label: "Conformity",
    value: "Conformity",
  },
  {
    label: "Congruency",
    value: "Congruency",
  },
  {
    label: "Connection",
    value: "Connection",
  },
  {
    label: "Consciousness",
    value: "Consciousness",
  },
  {
    label: "Conservation",
    value: "Conservation",
  },
  {
    label: "Consistency",
    value: "Consistency",
  },
  {
    label: "Contentment",
    value: "Contentment",
  },
  {
    label: "Continuity",
    value: "Continuity",
  },
  {
    label: "Contribution",
    value: "Contribution",
  },
  {
    label: "Control",
    value: "Control",
  },
  {
    label: "Conviction",
    value: "Conviction",
  },
  {
    label: "Conviviality",
    value: "Conviviality",
  },
  {
    label: "Coolness",
    value: "Coolness",
  },
  {
    label: "Cooperation",
    value: "Cooperation",
  },
  {
    label: "Cordiality",
    value: "Cordiality",
  },
  {
    label: "Correctness",
    value: "Correctness",
  },
  {
    label: "Country",
    value: "Country",
  },
  {
    label: "Courage",
    value: "Courage",
  },
  {
    label: "Courtesy",
    value: "Courtesy",
  },
  {
    label: "Craftiness",
    value: "Craftiness",
  },
  {
    label: "Creativity",
    value: "Creativity",
  },
  {
    label: "Credibility",
    value: "Credibility",
  },
  {
    label: "Cunning",
    value: "Cunning",
  },
  {
    label: "Curiosity",
    value: "Curiosity",
  },
  {
    label: "Daring",
    value: "Daring",
  },
  {
    label: "Decisiveness",
    value: "Decisiveness",
  },
  {
    label: "Decorum",
    value: "Decorum",
  },
  {
    label: "Deference",
    value: "Deference",
  },
  {
    label: "Delight",
    value: "Delight",
  },
  {
    label: "Dependability",
    value: "Dependability",
  },
  {
    label: "Depth",
    value: "Depth",
  },
  {
    label: "Desire",
    value: "Desire",
  },
  {
    label: "Determination",
    value: "Determination",
  },
  {
    label: "Devotion",
    value: "Devotion",
  },
  {
    label: "Devoutness",
    value: "Devoutness",
  },
  {
    label: "Dexterity",
    value: "Dexterity",
  },
  {
    label: "Difference",
    value: "Difference",
  },
  {
    label: "Dignity",
    value: "Dignity",
  },
  {
    label: "Diligence",
    value: "Diligence",
  },
  {
    label: "Direction",
    value: "Direction",
  },
  {
    label: "Directness",
    value: "Directness",
  },
  {
    label: "Discipline",
    value: "Discipline",
  },
  {
    label: "Discovery",
    value: "Discovery",
  },
  {
    label: "Discretion",
    value: "Discretion",
  },
  {
    label: "Diversity",
    value: "Diversity",
  },
  {
    label: "Dominance",
    value: "Dominance",
  },
  {
    label: "Dreaming",
    value: "Dreaming",
  },
  {
    label: "Drive",
    value: "Drive",
  },
  {
    label: "Duty",
    value: "Duty",
  },
  {
    label: "Dynamism",
    value: "Dynamism",
  },
  {
    label: "Eagerness",
    value: "Eagerness",
  },
  {
    label: "Ease",
    value: "Ease",
  },
  {
    label: "Economy",
    value: "Economy",
  },
  {
    label: "Ecstasy",
    value: "Ecstasy",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Effectiveness",
    value: "Effectiveness",
  },
  {
    label: "Efficiency",
    value: "Efficiency",
  },
  {
    label: "Elation",
    value: "Elation",
  },
  {
    label: "Elegance",
    value: "Elegance",
  },
  {
    label: "Empathy",
    value: "Empathy",
  },
  {
    label: "Encouragement",
    value: "Encouragement",
  },
  {
    label: "Endurance",
    value: "Endurance",
  },
  {
    label: "Energy",
    value: "Energy",
  },
  {
    label: "Enjoyment",
    value: "Enjoyment",
  },
  {
    label: "Entertainment",
    value: "Entertainment",
  },
  {
    label: "Enthusiasm",
    value: "Enthusiasm",
  },
  {
    label: "Environmentalism",
    value: "Environmentalism",
  },
  {
    label: "Ethics",
    value: "Ethics",
  },
  {
    label: "Euphoria",
    value: "Euphoria",
  },
  {
    label: "Excellence",
    value: "Excellence",
  },
  {
    label: "Excitement",
    value: "Excitement",
  },
  {
    label: "Exhilaration",
    value: "Exhilaration",
  },
  {
    label: "Expectancy",
    value: "Expectancy",
  },
  {
    label: "Expediency",
    value: "Expediency",
  },
  {
    label: "Experience",
    value: "Experience",
  },
  {
    label: "Expertise",
    value: "Expertise",
  },
  {
    label: "Exploration",
    value: "Exploration",
  },
  {
    label: "Expressiveness",
    value: "Expressiveness",
  },
  {
    label: "Extravagance",
    value: "Extravagance",
  },
  {
    label: "Extroversion",
    value: "Extroversion",
  },
  {
    label: "Exuberance",
    value: "Exuberance",
  },
  {
    label: "Fairness",
    value: "Fairness",
  },
  {
    label: "Faith",
    value: "Faith",
  },
  {
    label: "Fame",
    value: "Fame",
  },
  {
    label: "Family",
    value: "Family",
  },
  {
    label: "Fascination",
    value: "Fascination",
  },
  {
    label: "Fashion",
    value: "Fashion",
  },
  {
    label: "Fearlessness",
    value: "Fearlessness",
  },
  {
    label: "Ferocity",
    value: "Ferocity",
  },
  {
    label: "Fidelity",
    value: "Fidelity",
  },
  {
    label: "Fierceness",
    value: "Fierceness",
  },
  {
    label: "Financial",
    value: "Financial",
  },
  {
    label: "Firmness",
    value: "Firmness",
  },
  {
    label: "Fitness",
    value: "Fitness",
  },
  {
    label: "Flexibility",
    value: "Flexibility",
  },
  {
    label: "Flow",
    value: "Flow",
  },
  {
    label: "Fluency",
    value: "Fluency",
  },
  {
    label: "Focus",
    value: "Focus",
  },
  {
    label: "Fortitude",
    value: "Fortitude",
  },
  {
    label: "Frankness",
    value: "Frankness",
  },
  {
    label: "Freedom",
    value: "Freedom",
  },
  {
    label: "Friendliness",
    value: "Friendliness",
  },
  {
    label: "Friendship",
    value: "Friendship",
  },
  {
    label: "Frugality",
    value: "Frugality",
  },
  {
    label: "Fun",
    value: "Fun",
  },
  {
    label: "Gallantry",
    value: "Gallantry",
  },
  {
    label: "Generosity",
    value: "Generosity",
  },
  {
    label: "Gentility",
    value: "Gentility",
  },
  {
    label: "Giving",
    value: "Giving",
  },
  {
    label: "Grace",
    value: "Grace",
  },
  {
    label: "Gratitude",
    value: "Gratitude",
  },
  {
    label: "Gregariousness",
    value: "Gregariousness",
  },
  {
    label: "Growth",
    value: "Growth",
  },
  {
    label: "Guidance",
    value: "Guidance",
  },
  {
    label: "Happiness",
    value: "Happiness",
  },
  {
    label: "Harmony",
    value: "Harmony",
  },
  {
    label: "Health",
    value: "Health",
  },
  {
    label: "Heart",
    value: "Heart",
  },
  {
    label: "Helpfulness",
    value: "Helpfulness",
  },
  {
    label: "Heroism",
    value: "Heroism",
  },
  {
    label: "Holiness",
    value: "Holiness",
  },
  {
    label: "Honesty",
    value: "Honesty",
  },
  {
    label: "Honor",
    value: "Honor",
  },
  {
    label: "Hopefulness",
    value: "Hopefulness",
  },
  {
    label: "Hospitality",
    value: "Hospitality",
  },
  {
    label: "Humility",
    value: "Humility",
  },
  {
    label: "Humor",
    value: "Humor",
  },
  {
    label: "Hygiene",
    value: "Hygiene",
  },
  {
    label: "Imagination",
    value: "Imagination",
  },
  {
    label: "Impact",
    value: "Impact",
  },
  {
    label: "Impartiality",
    value: "Impartiality",
  },
  {
    label: "Independence",
    value: "Independence",
  },
  {
    label: "Individuality",
    value: "Individuality",
  },
  {
    label: "Industry",
    value: "Industry",
  },
  {
    label: "Influence",
    value: "Influence",
  },
  {
    label: "Ingenuity",
    value: "Ingenuity",
  },
  {
    label: "Inquisitiveness",
    value: "Inquisitiveness",
  },
  {
    label: "Insightfulness",
    value: "Insightfulness",
  },
  {
    label: "Inspiration",
    value: "Inspiration",
  },
  {
    label: "Integrity",
    value: "Integrity",
  },
  {
    label: "Intellect",
    value: "Intellect",
  },
  {
    label: "Intelligence",
    value: "Intelligence",
  },
  {
    label: "Intensity",
    value: "Intensity",
  },
  {
    label: "Intimacy",
    value: "Intimacy",
  },
  {
    label: "Intrepidness",
    value: "Intrepidness",
  },
  {
    label: "Introspection",
    value: "Introspection",
  },
  {
    label: "Introversion",
    value: "Introversion",
  },
  {
    label: "Intuition",
    value: "Intuition",
  },
  {
    label: "Intuitiveness",
    value: "Intuitiveness",
  },
  {
    label: "Inventiveness",
    value: "Inventiveness",
  },
  {
    label: "Investing",
    value: "Investing",
  },
  {
    label: "Involvement",
    value: "Involvement",
  },
  {
    label: "Joy",
    value: "Joy",
  },
  {
    label: "Judiciousness",
    value: "Judiciousness",
  },
  {
    label: "Justice",
    value: "Justice",
  },
  {
    label: "Keenness",
    value: "Keenness",
  },
  {
    label: "Kindness",
    value: "Kindness",
  },
  {
    label: "Knowledge",
    value: "Knowledge",
  },
  {
    label: "Leadership",
    value: "Leadership",
  },
  {
    label: "Learning",
    value: "Learning",
  },
  {
    label: "Liberation",
    value: "Liberation",
  },
  {
    label: "Liberty",
    value: "Liberty",
  },
  {
    label: "Lightness",
    value: "Lightness",
  },
  {
    label: "Liveliness",
    value: "Liveliness",
  },
  {
    label: "Logic",
    value: "Logic",
  },
  {
    label: "Longevity",
    value: "Longevity",
  },
  {
    label: "Love",
    value: "Love",
  },
  {
    label: "Loyalty",
    value: "Loyalty",
  },
  {
    label: "Majesty",
    value: "Majesty",
  },
  {
    label: "Making",
    value: "Making",
  },
  {
    label: "Marriage",
    value: "Marriage",
  },
  {
    label: "Mastery",
    value: "Mastery",
  },
  {
    label: "Maturity",
    value: "Maturity",
  },
  {
    label: "Meaning",
    value: "Meaning",
  },
  {
    label: "Meekness",
    value: "Meekness",
  },
  {
    label: "Mellowness",
    value: "Mellowness",
  },
  {
    label: "Meticulousness",
    value: "Meticulousness",
  },
  {
    label: "Mindfulness",
    value: "Mindfulness",
  },
  {
    label: "Modesty",
    value: "Modesty",
  },
  {
    label: "Motivation",
    value: "Motivation",
  },
  {
    label: "Mysteriousness",
    value: "Mysteriousness",
  },
  {
    label: "Nature",
    value: "Nature",
  },
  {
    label: "Neatness",
    value: "Neatness",
  },
  {
    label: "Nerve",
    value: "Nerve",
  },
  {
    label: "Nonconformity",
    value: "Nonconformity",
  },
  {
    label: "Obedience",
    value: "Obedience",
  },
  {
    label: "Open-mindedness",
    value: "Open-mindedness",
  },
  {
    label: "Openness",
    value: "Openness",
  },
  {
    label: "Optimism",
    value: "Optimism",
  },
  {
    label: "Order",
    value: "Order",
  },
  {
    label: "Organization",
    value: "Organization",
  },
  {
    label: "Originality",
    value: "Originality",
  },
  {
    label: "Outdoors",
    value: "Outdoors",
  },
  {
    label: "Outlandishness",
    value: "Outlandishness",
  },
  {
    label: "Outrageousness",
    value: "Outrageousness",
  },
  {
    label: "Partnership",
    value: "Partnership",
  },
  {
    label: "Passion",
    value: "Passion",
  },
  {
    label: "Patience",
    value: "Patience",
  },
  {
    label: "Peace",
    value: "Peace",
  },
  {
    label: "Perceptiveness",
    value: "Perceptiveness",
  },
  {
    label: "Perfection",
    value: "Perfection",
  },
  {
    label: "Perkiness",
    value: "Perkiness",
  },
  {
    label: "Perseverance",
    value: "Perseverance",
  },
  {
    label: "Persistence",
    value: "Persistence",
  },
  {
    label: "Persuasiveness",
    value: "Persuasiveness",
  },
  {
    label: "Philanthropy",
    value: "Philanthropy",
  },
  {
    label: "Piety",
    value: "Piety",
  },
  {
    label: "Playfulness",
    value: "Playfulness",
  },
  {
    label: "Pleasantness",
    value: "Pleasantness",
  },
  {
    label: "Pleasure",
    value: "Pleasure",
  },
  {
    label: "Poise",
    value: "Poise",
  },
  {
    label: "Polish",
    value: "Polish",
  },
  {
    label: "Popularity",
    value: "Popularity",
  },
  {
    label: "Potency",
    value: "Potency",
  },
  {
    label: "Power",
    value: "Power",
  },
  {
    label: "Practicality",
    value: "Practicality",
  },
  {
    label: "Pragmatism",
    value: "Pragmatism",
  },
  {
    label: "Precision",
    value: "Precision",
  },
  {
    label: "Preparedness",
    value: "Preparedness",
  },
  {
    label: "Presence",
    value: "Presence",
  },
  {
    label: "Pride",
    value: "Pride",
  },
  {
    label: "Privacy",
    value: "Privacy",
  },
  {
    label: "Proactivity",
    value: "Proactivity",
  },
  {
    label: "Professionalism",
    value: "Professionalism",
  },
  {
    label: "Prosperity",
    value: "Prosperity",
  },
  {
    label: "Prudence",
    value: "Prudence",
  },
  {
    label: "Punctuality",
    value: "Punctuality",
  },
  {
    label: "Purity",
    value: "Purity",
  },
  {
    label: "Rationality",
    value: "Rationality",
  },
  {
    label: "Realism",
    value: "Realism",
  },
  {
    label: "Reason",
    value: "Reason",
  },
  {
    label: "Reasonableness",
    value: "Reasonableness",
  },
  {
    label: "Recognition",
    value: "Recognition",
  },
  {
    label: "Recreation",
    value: "Recreation",
  },
  {
    label: "Refinement",
    value: "Refinement",
  },
  {
    label: "Reflection",
    value: "Reflection",
  },
  {
    label: "Relaxation",
    value: "Relaxation",
  },
  {
    label: "Reliability",
    value: "Reliability",
  },
  {
    label: "Relief",
    value: "Relief",
  },
  {
    label: "Religiousness",
    value: "Religiousness",
  },
  {
    label: "Reputation",
    value: "Reputation",
  },
  {
    label: "Resilience",
    value: "Resilience",
  },
  {
    label: "Resolution",
    value: "Resolution",
  },
  {
    label: "Resolve",
    value: "Resolve",
  },
  {
    label: "Resourcefulness",
    value: "Resourcefulness",
  },
  {
    label: "Respect",
    value: "Respect",
  },
  {
    label: "Responsibility",
    value: "Responsibility",
  },
  {
    label: "Rest",
    value: "Rest",
  },
  {
    label: "Restraint",
    value: "Restraint",
  },
  {
    label: "Reverence",
    value: "Reverence",
  },
  {
    label: "Richness",
    value: "Richness",
  },
  {
    label: "Rigor",
    value: "Rigor",
  },
  {
    label: "Sacredness",
    value: "Sacredness",
  },
  {
    label: "Sacrifice",
    value: "Sacrifice",
  },
  {
    label: "Sagacity",
    value: "Sagacity",
  },
  {
    label: "Saintliness",
    value: "Saintliness",
  },
  {
    label: "Sanguinity",
    value: "Sanguinity",
  },
  {
    label: "Satisfaction",
    value: "Satisfaction",
  },
  {
    label: "Science",
    value: "Science",
  },
  {
    label: "Security",
    value: "Security",
  },
  {
    label: "Self-control",
    value: "Self-control",
  },
  {
    label: "Self-reliance",
    value: "Self-reliance",
  },
  {
    label: "Self-respect",
    value: "Self-respect",
  },
  {
    label: "Selflessness",
    value: "Selflessness",
  },
  {
    label: "Sensitivity",
    value: "Sensitivity",
  },
  {
    label: "Sensuality",
    value: "Sensuality",
  },
  {
    label: "Serenity",
    value: "Serenity",
  },
  {
    label: "Service",
    value: "Service",
  },
  {
    label: "Sexiness",
    value: "Sexiness",
  },
  {
    label: "Sexuality",
    value: "Sexuality",
  },
  {
    label: "Sharing",
    value: "Sharing",
  },
  {
    label: "Shrewdness",
    value: "Shrewdness",
  },
  {
    label: "Significance",
    value: "Significance",
  },
  {
    label: "Silence",
    value: "Silence",
  },
  {
    label: "Silliness",
    value: "Silliness",
  },
  {
    label: "Simplicity",
    value: "Simplicity",
  },
  {
    label: "Sincerity",
    value: "Sincerity",
  },
  {
    label: "Skillfulness",
    value: "Skillfulness",
  },
  {
    label: "Solidarity",
    value: "Solidarity",
  },
  {
    label: "Solitude",
    value: "Solitude",
  },
  {
    label: "Sophistication",
    value: "Sophistication",
  },
  {
    label: "Soundness",
    value: "Soundness",
  },
  {
    label: "Speed",
    value: "Speed",
  },
  {
    label: "Spirit",
    value: "Spirit",
  },
  {
    label: "Spirituality",
    value: "Spirituality",
  },
  {
    label: "Spontaneity",
    value: "Spontaneity",
  },
  {
    label: "Spunk",
    value: "Spunk",
  },
  {
    label: "Stability",
    value: "Stability",
  },
  {
    label: "Status",
    value: "Status",
  },
  {
    label: "Stealth",
    value: "Stealth",
  },
  {
    label: "Stillness",
    value: "Stillness",
  },
  {
    label: "Strength",
    value: "Strength",
  },
  {
    label: "Structure",
    value: "Structure",
  },
  {
    label: "Success",
    value: "Success",
  },
  {
    label: "Support",
    value: "Support",
  },
  {
    label: "Supremacy",
    value: "Supremacy",
  },
  {
    label: "Surprise",
    value: "Surprise",
  },
  {
    label: "Sympathy",
    value: "Sympathy",
  },
  {
    label: "Synergy",
    value: "Synergy",
  },
  {
    label: "Teaching",
    value: "Teaching",
  },
  {
    label: "Teamwork",
    value: "Teamwork",
  },
  {
    label: "Temperance",
    value: "Temperance",
  },
  {
    label: "Thankfulness",
    value: "Thankfulness",
  },
  {
    label: "Thoroughness",
    value: "Thoroughness",
  },
  {
    label: "Thoughtfulness",
    value: "Thoughtfulness",
  },
  {
    label: "Thrift",
    value: "Thrift",
  },
  {
    label: "Tidiness",
    value: "Tidiness",
  },
  {
    label: "Timeliness",
    value: "Timeliness",
  },
  {
    label: "Traditionalism",
    value: "Traditionalism",
  },
  {
    label: "Tranquility",
    value: "Tranquility",
  },
  {
    label: "Transcendence",
    value: "Transcendence",
  },
  {
    label: "Trust",
    value: "Trust",
  },
  {
    label: "Trustworthiness",
    value: "Trustworthiness",
  },
  {
    label: "Truth",
    value: "Truth",
  },
  {
    label: "Understanding",
    value: "Understanding",
  },
  {
    label: "Unflappability",
    value: "Unflappability",
  },
  {
    label: "Uniqueness",
    value: "Uniqueness",
  },
  {
    label: "Unity",
    value: "Unity",
  },
  {
    label: "Usefulness",
    value: "Usefulness",
  },
  {
    label: "Utility",
    value: "Utility",
  },
  {
    label: "Valor",
    value: "Valor",
  },
  {
    label: "Variety",
    value: "Variety",
  },
  {
    label: "Victory",
    value: "Victory",
  },
  {
    label: "Vigor",
    value: "Vigor",
  },
  {
    label: "Virtue",
    value: "Virtue",
  },
  {
    label: "Vision",
    value: "Vision",
  },
  {
    label: "Vitality",
    value: "Vitality",
  },
  {
    label: "Vivacity",
    value: "Vivacity",
  },
  {
    label: "Volunteering",
    value: "Volunteering",
  },
  {
    label: "Warmheartedness",
    value: "Warmheartedness",
  },
  {
    label: "Warmth",
    value: "Warmth",
  },
  {
    label: "Watchfulness",
    value: "Watchfulness",
  },
  {
    label: "Wealth",
    value: "Wealth",
  },
  {
    label: "Willfulness",
    value: "Willfulness",
  },
  {
    label: "Willingness",
    value: "Willingness",
  },
  {
    label: "Winning",
    value: "Winning",
  },
  {
    label: "Wisdom",
    value: "Wisdom",
  },
  {
    label: "Wittiness",
    value: "Wittiness",
  },
  {
    label: "Wonder",
    value: "Wonder",
  },
  {
    label: "Worthiness",
    value: "Worthiness",
  },
  {
    label: "Youthfulness",
    value: "Youthfulness",
  },
  {
    label: "Zeal",
    value: "Zeal",
  },
];
export const arabicList = [
  {
    label: "آمن",
    value: "آمن",
  },
  {
    label: "أناقة",
    value: "أناقة",
  },
  {
    label: "إدراك",
    value: "إدراك",
  },
  {
    label: "ابتكار",
    value: "ابتكار",
  },
  {
    label: "ابتهاج",
    value: "ابتهاج",
  },
  {
    label: "ابداع",
    value: "ابداع",
  },
  {
    label: "اتساق",
    value: "اتساق",
  },
  {
    label: "اثارة",
    value: "اثارة",
  },
  {
    label: "اجتهاد",
    value: "اجتهاد",
  },
  {
    label: "احترافية",
    value: "احترافية",
  },
  {
    label: "احتمال",
    value: "احتمال",
  },
  {
    label: "احسان",
    value: "احسان",
  },
  {
    label: "اخلاص",
    value: "اخلاص",
  },
  {
    label: "إرضاء النفس",
    value: "إرضاء النفس",
  },
  {
    label: "ازدهار",
    value: "ازدهار",
  },
  {
    label: "استجمام",
    value: "استجمام",
  },
  {
    label: "استرخاء",
    value: "استرخاء",
  },
  {
    label: "استعداد",
    value: "استعداد",
  },
  {
    label: "استعداد للمساعدة",
    value: "استعداد للمساعدة",
  },
  {
    label: "استعراض",
    value: "استعراض",
  },
  {
    label: "استغراب",
    value: "استغراب",
  },
  {
    label: "استكشاف",
    value: "استكشاف",
  },
  {
    label: "استمرارية",
    value: "استمرارية",
  },
  {
    label: "اسراف",
    value: "اسراف",
  },
  {
    label: "اسهام",
    value: "اسهام",
  },
  {
    label: "اشراقة",
    value: "اشراقة",
  },
  {
    label: "اصالة",
    value: "اصالة",
  },
  {
    label: "اصرار",
    value: "اصرار",
  },
  {
    label: "اطمئنان",
    value: "اطمئنان",
  },
  {
    label: "اعتراف بالجميل",
    value: "اعتراف بالجميل",
  },
  {
    label: "اعتماد علي الذات",
    value: "اعتماد علي الذات",
  },
  {
    label: "اعمال الخيرية",
    value: "اعمال الخيرية",
  },
  {
    label: "اغراء",
    value: "اغراء",
  },
  {
    label: "افق مفتوح",
    value: "افق مفتوح",
  },
  {
    label: "اقتصاد",
    value: "اقتصاد",
  },
  {
    label: "اكتراث",
    value: "اكتراث",
  },
  {
    label: "اكتشاف",
    value: "اكتشاف",
  },
  {
    label: "اكتمال",
    value: "اكتمال",
  },
  {
    label: "التزام",
    value: "التزام",
  },
  {
    label: "الثقة",
    value: "الثقة",
  },
  {
    label: "الجنس",
    value: "الجنس",
  },
  {
    label: "الحكمة",
    value: "الحكمة",
  },
  {
    label: "الحيوية",
    value: "الحيوية",
  },
  {
    label: "الراحة",
    value: "الراحة",
  },
  {
    label: "الشجاعة",
    value: "الشجاعة",
  },
  {
    label: "العاطفة",
    value: "العاطفة",
  },
  {
    label: "العاطفي",
    value: "العاطفي",
  },
  {
    label: "العيش في للحظة",
    value: "العيش في للحظة",
  },
  {
    label: "الفاعلية",
    value: "الفاعلية",
  },
  {
    label: "الفة",
    value: "الفة",
  },
  {
    label: "الكمال",
    value: "الكمال",
  },
  {
    label: "الهام",
    value: "الهام",
  },
  {
    label: "امانة",
    value: "امانة",
  },
  {
    label: "امتياز",
    value: "امتياز",
  },
  {
    label: "امل",
    value: "امل",
  },
  {
    label: "اناقة",
    value: "اناقة",
  },
  {
    label: "انبساط",
    value: "انبساط",
  },
  {
    label: "انتباه",
    value: "انتباه",
  },
  {
    label: "انتماء",
    value: "انتماء",
  },
  {
    label: "انثوية",
    value: "انثوية",
  },
  {
    label: "انجاز",
    value: "انجاز",
  },
  {
    label: "انسجام",
    value: "انسجام",
  },
  {
    label: "انضباط",
    value: "انضباط",
  },
  {
    label: "انطلاقة",
    value: "انطلاقة",
  },
  {
    label: "انعكاس",
    value: "انعكاس",
  },
  {
    label: "انفتاح",
    value: "انفتاح",
  },
  {
    label: "اهمية",
    value: "اهمية",
  },
  {
    label: "ايمان",
    value: "ايمان",
  },
  {
    label: "بحبوحة",
    value: "بحبوحة",
  },
  {
    label: "بديهة",
    value: "بديهة",
  },
  {
    label: "بذخ",
    value: "بذخ",
  },
  {
    label: "براءة",
    value: "براءة",
  },
  {
    label: "براعة",
    value: "براعة",
  },
  {
    label: "بساطة",
    value: "بساطة",
  },
  {
    label: "بسالة",
    value: "بسالة",
  },
  {
    label: "بطولة",
    value: "بطولة",
  },
  {
    label: "بهجة",
    value: "بهجة",
  },
  {
    label: "تأثير",
    value: "تأثير",
  },
  {
    label: "تأزر",
    value: "تأزر",
  },
  {
    label: "تألق",
    value: "تألق",
  },
  {
    label: "تأهب",
    value: "تأهب",
  },
  {
    label: "تبجيل",
    value: "تبجيل",
  },
  {
    label: "تبصر",
    value: "تبصر",
  },
  {
    label: "تجاوز",
    value: "تجاوز",
  },
  {
    label: "تجربة",
    value: "تجربة",
  },
  {
    label: "تحرر",
    value: "تحرر",
  },
  {
    label: "تحقيق واجب",
    value: "تحقيق واجب",
  },
  {
    label: "ترتيب",
    value: "ترتيب",
  },
  {
    label: "ترف",
    value: "ترف",
  },
  {
    label: "تركيز",
    value: "تركيز",
  },
  {
    label: "تسلية",
    value: "تسلية",
  },
  {
    label: "تسهل",
    value: "تسهل",
  },
  {
    label: "تشجع",
    value: "تشجع",
  },
  {
    label: "تشكيلة",
    value: "تشكيلة",
  },
  {
    label: "تضامن",
    value: "تضامن",
  },
  {
    label: "تضحية",
    value: "تضحية",
  },
  {
    label: "تطابق",
    value: "تطابق",
  },
  {
    label: "تطبيق عملي",
    value: "تطبيق عملي",
  },
  {
    label: "تطور",
    value: "تطور",
  },
  {
    label: "تعاطف",
    value: "تعاطف",
  },
  {
    label: "تعاون",
    value: "تعاون",
  },
  {
    label: "تعبير",
    value: "تعبير",
  },
  {
    label: "تعلم",
    value: "تعلم",
  },
  {
    label: "تعمد",
    value: "تعمد",
  },
  {
    label: "تفاؤل",
    value: "تفاؤل",
  },
  {
    label: "تقدير",
    value: "تقدير",
  },
  {
    label: "تقديس",
    value: "تقديس",
  },
  {
    label: "تقليدية",
    value: "تقليدية",
  },
  {
    label: "تقمص",
    value: "تقمص",
  },
  {
    label: "تكلف",
    value: "تكلف",
  },
  {
    label: "تكيف",
    value: "تكيف",
  },
  {
    label: "تلذذ",
    value: "تلذذ",
  },
  {
    label: "تنظيم",
    value: "تنظيم",
  },
  {
    label: "تنوع",
    value: "تنوع",
  },
  {
    label: "تنوير",
    value: "تنوير",
  },
  {
    label: "تهذيب",
    value: "تهذيب",
  },
  {
    label: "توازن",
    value: "توازن",
  },
  {
    label: "تواضع",
    value: "تواضع",
  },
  {
    label: "توجيه",
    value: "توجيه",
  },
  {
    label: "توفر",
    value: "توفر",
  },
  {
    label: "توق",
    value: "توق",
  },
  {
    label: "توقع",
    value: "توقع",
  },
  {
    label: "توكيد",
    value: "توكيد",
  },
  {
    label: "ثبات",
    value: "ثبات",
  },
  {
    label: "ثروة",
    value: "ثروة",
  },
  {
    label: "ثقة",
    value: "ثقة",
  },
  {
    label: "جاذبية",
    value: "جاذبية",
  },
  {
    label: "جدارة",
    value: "جدارة",
  },
  {
    label: "جرأة",
    value: "جرأة",
  },
  {
    label: "جسارة",
    value: "جسارة",
  },
  {
    label: "جلد",
    value: "جلد",
  },
  {
    label: "جمال",
    value: "جمال",
  },
  {
    label: "حافزية",
    value: "حافزية",
  },
  {
    label: "حب",
    value: "حب",
  },
  {
    label: "حذر",
    value: "حذر",
  },
  {
    label: "حرص",
    value: "حرص",
  },
  {
    label: "حرية",
    value: "حرية",
  },
  {
    label: "حرية تصرف",
    value: "حرية تصرف",
  },
  {
    label: "حساسية",
    value: "حساسية",
  },
  {
    label: "حسم",
    value: "حسم",
  },
  {
    label: "حسن الضيافة",
    value: "حسن الضيافة",
  },
  {
    label: "حصافة",
    value: "حصافة",
  },
  {
    label: "حضور",
    value: "حضور",
  },
  {
    label: "حكمة",
    value: "حكمة",
  },
  {
    label: "حماسة",
    value: "حماسة",
  },
  {
    label: "حماسية",
    value: "حماسية",
  },
  {
    label: "حيوية",
    value: "حيوية",
  },
  {
    label: "خدمة",
    value: "خدمة",
  },
  {
    label: "خصوصية",
    value: "خصوصية",
  },
  {
    label: "خفة",
    value: "خفة",
  },
  {
    label: "خيال",
    value: "خيال",
  },
  {
    label: "خير",
    value: "خير",
  },
  {
    label: "دبلوماسية",
    value: "دبلوماسية",
  },
  {
    label: "دعم",
    value: "دعم",
  },
  {
    label: "دفء",
    value: "دفء",
  },
  {
    label: "دقة",
    value: "دقة",
  },
  {
    label: "دقة في تفاصيل",
    value: "دقة في تفاصيل",
  },
  {
    label: "دهاء",
    value: "دهاء",
  },
  {
    label: "ديناميكية",
    value: "ديناميكية",
  },
  {
    label: "ذكاء",
    value: "ذكاء",
  },
  {
    label: "رؤية",
    value: "رؤية",
  },
  {
    label: "رباطة الجأش",
    value: "رباطة الجأش",
  },
  {
    label: "رجولية",
    value: "رجولية",
  },
  {
    label: "رعاية",
    value: "رعاية",
  },
  {
    label: "رغبة",
    value: "رغبة",
  },
  {
    label: "روح المبادرة",
    value: "روح المبادرة",
  },
  {
    label: "روحانية",
    value: "روحانية",
  },
  {
    label: "زهد",
    value: "زهد",
  },
  {
    label: "سخاء",
    value: "سخاء",
  },
  {
    label: "سخافة",
    value: "سخافة",
  },
  {
    label: "سرعة",
    value: "سرعة",
  },
  {
    label: "سعادة",
    value: "سعادة",
  },
  {
    label: "سكون",
    value: "سكون",
  },
  {
    label: "سلامة",
    value: "سلامة",
  },
  {
    label: "سمعة",
    value: "سمعة",
  },
  {
    label: "سيادة",
    value: "سيادة",
  },
  {
    label: "سيطرة",
    value: "سيطرة",
  },
  {
    label: "سيطرة على نفس",
    value: "سيطرة على نفس",
  },
  {
    label: "سيولة",
    value: "سيولة",
  },
  {
    label: "شجاعة",
    value: "شجاعة",
  },
  {
    label: "شعبية",
    value: "شعبية",
  },
  {
    label: "شغف",
    value: "شغف",
  },
  {
    label: "شفقة",
    value: "شفقة",
  },
  {
    label: "شكر",
    value: "شكر",
  },
  {
    label: "شهرة",
    value: "شهرة",
  },
  {
    label: "صحة",
    value: "صحة",
  },
  {
    label: "صداقة",
    value: "صداقة",
  },
  {
    label: "صدق",
    value: "صدق",
  },
  {
    label: "صراحة",
    value: "صراحة",
  },
  {
    label: "صرامة",
    value: "صرامة",
  },
  {
    label: "صفاء",
    value: "صفاء",
  },
  {
    label: "صلابة",
    value: "صلابة",
  },
  {
    label: "صلة",
    value: "صلة",
  },
  {
    label: "صمت",
    value: "صمت",
  },
  {
    label: "صناعة",
    value: "صناعة",
  },
  {
    label: "طاعة",
    value: "طاعة",
  },
  {
    label: "طاقة",
    value: "طاقة",
  },
  {
    label: "طراوة",
    value: "طراوة",
  },
  {
    label: "طموح",
    value: "طموح",
  },
  {
    label: "طهارة",
    value: "طهارة",
  },
  {
    label: "طول العمر",
    value: "طول العمر",
  },
  {
    label: "ظرفية",
    value: "ظرفية",
  },
  {
    label: "عجب",
    value: "عجب",
  },
  {
    label: "عدل",
    value: "عدل",
  },
  {
    label: "عزلة",
    value: "عزلة",
  },
  {
    label: "عصمة من الأخطاء",
    value: "عصمة من الأخطاء",
  },
  {
    label: "عطاء",
    value: "عطاء",
  },
  {
    label: "عطلة",
    value: "عطلة",
  },
  {
    label: "عفة",
    value: "عفة",
  },
  {
    label: "عفوية",
    value: "عفوية",
  },
  {
    label: "عمق",
    value: "عمق",
  },
  {
    label: "عمل الجماعي",
    value: "عمل الجماعي",
  },
  {
    label: "غزارة",
    value: "غزارة",
  },
  {
    label: "غني",
    value: "غني",
  },
  {
    label: "فائدة",
    value: "فائدة",
  },
  {
    label: "فخر",
    value: "فخر",
  },
  {
    label: "فضول",
    value: "فضول",
  },
  {
    label: "فضيلة",
    value: "فضيلة",
  },
  {
    label: "فطرة",
    value: "فطرة",
  },
  {
    label: "فطنة",
    value: "فطنة",
  },
  {
    label: "فكاهة",
    value: "فكاهة",
  },
  {
    label: "فوز",
    value: "فوز",
  },
  {
    label: "قبول",
    value: "قبول",
  },
  {
    label: "قدرات الخارقة",
    value: "قدرات الخارقة",
  },
  {
    label: "قدرة",
    value: "قدرة",
  },
  {
    label: "قدرة على اعتراف",
    value: "قدرة على اعتراف",
  },
  {
    label: "قدرة على الاقناع",
    value: "قدرة على الاقناع",
  },
  {
    label: "قدرة على القرار",
    value: "قدرة على القرار",
  },
  {
    label: "قدرة على ايجاد حلول",
    value: "قدرة على ايجاد حلول",
  },
  {
    label: "قرب",
    value: "قرب",
  },
  {
    label: "قناعة",
    value: "قناعة",
  },
  {
    label: "قيادة",
    value: "قيادة",
  },
  {
    label: "كبح",
    value: "كبح",
  },
  {
    label: "كثافة",
    value: "كثافة",
  },
  {
    label: "كرامة",
    value: "كرامة",
  },
  {
    label: "كفاءة",
    value: "كفاءة",
  },
  {
    label: "كفاية",
    value: "كفاية",
  },
  {
    label: "لطف",
    value: "لطف",
  },
  {
    label: "لهو",
    value: "لهو",
  },
  {
    label: "لياقة",
    value: "لياقة",
  },
  {
    label: "لياقة بدنية",
    value: "لياقة بدنية",
  },
  {
    label: "مؤهل",
    value: "مؤهل",
  },
  {
    label: "متعة",
    value: "متعة",
  },
  {
    label: "مثابرة",
    value: "مثابرة",
  },
  {
    label: "مجاملة",
    value: "مجاملة",
  },
  {
    label: "مرونة",
    value: "مرونة",
  },
  {
    label: "مشاركة",
    value: "مشاركة",
  },
  {
    label: "مصداقية",
    value: "مصداقية",
  },
  {
    label: "معرفة",
    value: "معرفة",
  },
  {
    label: "مغامرة",
    value: "مغامرة",
  },
  {
    label: "مفاجأة",
    value: "مفاجأة",
  },
  {
    label: "مودة",
    value: "مودة",
  },
  {
    label: "موضة",
    value: "موضة",
  },
  {
    label: "نباهة",
    value: "نباهة",
  },
  {
    label: "نزاهة",
    value: "نزاهة",
  },
  {
    label: "نشوة",
    value: "نشوة",
  },
  {
    label: "نضج",
    value: "نضج",
  },
  {
    label: "نظافة",
    value: "نظافة",
  },
  {
    label: "نعيم",
    value: "نعيم",
  },
  {
    label: "نقاء",
    value: "نقاء",
  },
  {
    label: "نمو",
    value: "نمو",
  },
  {
    label: "هدوء",
    value: "هدوء",
  },
  {
    label: "واقعية",
    value: "واقعية",
  },
  {
    label: "وداعة",
    value: "وداعة",
  },
  {
    label: "ورع",
    value: "ورع",
  },
  {
    label: "وضوح",
    value: "وضوح",
  },
  {
    label: "وعي",
    value: "وعي",
  },
  {
    label: "وفرة",
    value: "وفرة",
  },
  {
    label: "وقاحة",
    value: "وقاحة",
  },
  {
    label: "ولاء",
    value: "ولاء",
  },
  {
    label: "يقظة",
    value: "يقظة",
  },
  {
    label: "يقين",
    value: "يقين",
  },
];
