import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { UserType } from "src/types";

const initialState = { user: undefined as UserType | undefined, loading: true };
type State = typeof initialState;
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(
      state: State,
      {
        payload,
      }: PayloadAction<{
        user: UserType;
        autoLoginToken?: string;
        redirectUrl?: string;
      }>
    ) {
      if (payload.autoLoginToken) {
        state.user = {
          ...payload.user,
          accessToken: payload.autoLoginToken,
        };
      } else {
        state.user = payload.user;
      }
      state.loading = false;
      if (payload.redirectUrl) {
        // alert(payload.redirectUrl);
        if (window.location.pathname !== payload.redirectUrl) {
          window.location.replace(payload.redirectUrl);
        }
      }
    },
    update(state, { payload }: PayloadAction<{ user: UserType }>) {
      if (!payload.user) return;
      state.user = { ...state.user, ...payload.user };
    },
    finishLoading(state) {
      state.loading = false;
    },
  },
});
//this so all imports regarding auth are from here but its reducer is in index, so it can reset the state
const logoutAction = createAction("auth/logout");
export const logout = () => logoutAction();
export const { login, update, finishLoading } = authSlice.actions;
export default authSlice.reducer;
