import { VStack, Box, Text } from "@chakra-ui/react";
import React from "react";
import { useIntl } from "react-intl";

import Message from "./Message";

interface Props {
  remove?: any;
  data?: any;
  onClick?: any;
  onBlur?: any;
  subliminalSpecial?: any;
  subliminalGeneral?: any;
  addInput?: any;
  onDelete?: any;

  deleteAction?: any;
  share?: () => void;
}
const MessagesCard: React.FC<Props> = ({
  deleteAction,
  subliminalSpecial,
  onDelete,
  addInput,
  remove,
  data,
  onClick,
  onBlur,
  subliminalGeneral,
  share
}) => {
  React.useEffect(() => { }, [deleteAction, data]);
  const intl = useIntl();

  return (
    <Box
      boxShadow={"0 3px 6px rgba(0, 0, 0, 0.16)"}
      height={"100%"}
      borderRadius={"20px"}
      backgroundColor={"gray.100"}
      width={"100%"}
      minWidth={"352px"}
      padding="10px 0"
    >
      <Text
        textStyle={"intentionMessageHeader"}
        textAlign={"left"}
        paddingLeft={subliminalGeneral || subliminalSpecial ? "0" : "1.5rem"}
        py={2}
        width={"100%"}
        fontWeight={"bold"}
        fontSize={"18px"}
        padding="10px 30px"
      >
        {subliminalSpecial &&
          intl.formatMessage({ id: "subliminal.personalmessage" })}
        {subliminalGeneral &&
          intl.formatMessage({ id: "subliminal.generalmessage" })}
        {!subliminalGeneral &&
          !subliminalSpecial &&
          intl.formatMessage({ id: "intention" })}
      </Text>
      <Box justifyContent={"center"} padding="0 10px">
        <VStack
          maxHeight={"234px"}
          spacing={0}
          overflowY={"auto"}
          padding="0 10px"
        >
          <Message
            deleteAction={deleteAction}
            deleteHandler={onDelete}
            subliminal={subliminalSpecial || subliminalGeneral}
            data={data}
            addInput
            onBlur={onBlur}
            onClick={onClick}
            remove={remove && true}
            share={share}
          />
        </VStack>
      </Box>
    </Box>
  );
};

export default MessagesCard;
