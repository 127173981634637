import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import IndepMediaPlayer from "src/components/IndepMediaPlayer";
import { useDispatch } from "src/store";
import { changeName } from "src/store/media";

//this should accept Data later
function SubliminalVideo({ videoList }) {
  const itemRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const enableSuBtn = () => dispatch(changeName({ value: "subliminal" }));
  const disableSuBtn = () => dispatch(changeName({}));

  return (
    <Accordion border={"none"} allowToggle allowMultiple={true}>
      {videoList.map(({ name, url }) =>
        name ? (
          <AccordionItem
            ref={itemRef}
            my={"1rem"}
            border={"none"}
            bg={"#f9f9f9"}
            boxShadow={"0 3px 6px 0 rgb(0 0 0 / 16%)"}
            borderRadius={"10px"}
            minH={"46px"}
            align={"center"}
          >
            <h2>
              <AccordionButton minH={"46px"}>
                <Box flex="1" textAlign="left">
                  {name}
                </Box>
                {/* //later add string interpolation */}
                <AccordionIcon id={"btn0"} color={"#1f3f93"} />
              </AccordionButton>
            </h2>
            <AccordionPanel border={"none"} pb={4}>
              <IndepMediaPlayer
                src={url}
                onPlay={enableSuBtn}
                onPause={disableSuBtn}
                onEnded={disableSuBtn}
              />
            </AccordionPanel>
          </AccordionItem>
        ) : null
      )}
    </Accordion>
  );
}

export default SubliminalVideo;
