import React from "react";
import { VStack, Box } from "@chakra-ui/react";
import WCode from "src/assets/images/woman-code.png";
import MCode from "src/assets/images/man-code.png";
import { DISEASE_ROUTE } from "src/routes";
import BaseView from "../BaseView";
import { Link } from "react-router-dom";

const Disease = () => {
  //Woman and Man codes
  const codes = [
    {
      image: MCode,
      link: `${DISEASE_ROUTE}/male`,
    },
    {
      image: WCode,
      link: `${DISEASE_ROUTE}/female`,
    },
  ];

  return (
    <BaseView>
      <VStack
        width="100%"
        paddingBottom="30px"
        display="flex"
        flexDir={{ base: "column", lg: "row" }}
        alignItems="center"
        marginTop={{ lg: "60px" }}
      >
        {codes.map((code, index) => (
          <Box
            position="relative"
            width={{ base: "250px" }}
            key={index}
            margin={{
              base: "60px 0",
              lg: "0 30px !important",
              xl: "0 80px !important",
            }}
          >
            <Link to={code.link}>
              <img
                style={{ width: "100%", height: "500px" }}
                src={code.image}
                alt={"disease"}
              />
            </Link>
          </Box>
        ))}
      </VStack>
    </BaseView>
  );
};

export default Disease;
