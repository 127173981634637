import { deleteMethod, post, put } from "src/api";
import { Purpose } from "src/types";

export const create: (name: string) => Promise<{ id: number }> = (name) =>
  post("/message", { name }, undefined) as unknown as Promise<{ id: number }>;

export const edit: (purpose: Partial<Purpose>) => Promise<Partial<Purpose>> = (
  purpose
) => {
  return put<Partial<Purpose>>(`/message/${purpose.id}`, purpose, undefined);
};

export const setValuesMatrix: (params: {
  id: number;
  matrixId: number;
}) => Promise<void> = async ({ id, matrixId }) =>
  put(
    `/message/${id}/values`,
    { id: matrixId },
    undefined
  ) as unknown as Promise<void>;

export const addTarget: (params: {
  name: string;
  order: number;
  aspectId: number;
}) => Promise<void> = ({ name, order, aspectId }) =>
  put(
    `/message/aspect/${aspectId}/target`,
    {
      name,
      order,
    },
    undefined
  ) as unknown as Promise<void>;

export const selectTarget = (params: { targetId: number }) =>
  post(
    `/message/aspect/target/${params.targetId}/selection/toggle`,
    {},
    undefined
  ) as unknown as Promise<void>;

export const createAspect = (params: { name: string; messageId: number }) =>
  put(
    `/message/${params.messageId}/aspect`,
    {
      name: params.name,
    },
    undefined
  ) as unknown as Promise<void>;

export const selectAspect = (params: { aspectId: number }) =>
  put(
    `/message/aspect/${params.aspectId}/select`,
    {},
    undefined
  ) as unknown as Promise<void>;

export const deselectAspect = (params: { aspectId: number }) =>
  put(
    `/message/aspect/${params.aspectId}/deselect`,
    {},
    undefined
  ) as unknown as Promise<void>;

export const deletePurpose: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/message/${id!}`, undefined);

//Bug Here on backend
export const resetPurpose = (params: { purposeId: number }) =>
  post(
    `/message/${params.purposeId}/reset`,
    {},
    undefined
  ) as unknown as Promise<void>;
