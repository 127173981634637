import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import PurposeBG from "src/assets/images/purpose.jpg";
import MissionBG from "src/assets/images/mission.png";

type PrintProps = {
  keyTitle?: string;
  textToCopy?: string;
  title?: string;
};

class PrintComponent extends React.Component<PrintProps, {}> {
  constructor(props: PrintProps) {
    super(props);

    this.state = {};
  }

  render() {
    const map = {
      mission: MissionBG,
      purpose: PurposeBG,
    };
    const key = this.props.keyTitle as keyof typeof map;
    if (this.props.keyTitle === "purpose") {
      return (
        <Box
          margin="150px auto"
          width="600px"
          borderRadius="10px"
          position="relative"
          textAlign="center"
          paddingTop="100px"
        >
          <Image
            src={map[key]}
            objectFit="cover"
            minW="100%"
            minH="100%"
            position="absolute"
            top={0}
            left={0}
            zIndex="-1"
          />
          <Text color="brand.900" fontWeight="bolder" fontSize={36}>
            <FormattedMessage id={`life.${this.props.keyTitle}`} />
          </Text>
          <Box
            border="1px solid silver"
            h="260px"
            w="55%"
            margin="20px auto"
            d="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Text fontSize={16}>{this.props.textToCopy}</Text>
          </Box>
        </Box>
      );
    }
    if (this.props.keyTitle === "mission") {
      return (
        <Box
          margin="150px auto"
          width="600px"
          borderRadius="10px"
          position="relative"
          textAlign="center"
          paddingTop="20px"
        >
          <Image
            src={map[key]}
            objectFit="cover"
            minW="100%"
            minH="100%"
            position="absolute"
            top={0}
            left={0}
            zIndex="-1"
          />
          <Text
            color="brand.900"
            padding="15px 0 10px 0"
            fontWeight="bold"
            fontSize={28}
          >
            {this.props.title}
          </Text>
          <Text padding="85px 60px 15px 40px" fontSize={16}>
            {this.props.textToCopy}
          </Text>
        </Box>
      );
    }
    return (
      <Box
        margin="150px auto"
        width="600px"
        backgroundImage="linear-gradient(-180deg, #f5f7fa 70%, #d5d9de)"
        borderRadius="10px"
      >
        <Box
          background="brand.900"
          padding="10px"
          width="90%"
          color="white"
          borderRadius="25px"
          pos="relative"
          top="-20px"
          margin="0 auto"
        >
          <Text fontWeight="bold" fontSize={32} textAlign="center">
            {this.props.title}
          </Text>
        </Box>
        <Box display="flex" flexDir="column" alignItems="stretch">
          {[
            " ",
            ...this.props.textToCopy?.split("\n").filter((text) => text)!,
          ].map((text, idx, arr) => (
            <Text
              key={idx}
              padding="10px"
              textAlign="center"
              borderBottom={
                idx !== arr.length - 1 ? "1px solid silver" : "none"
              }
            >
              {text}
            </Text>
          ))}
        </Box>
      </Box>
    );
  }
}

export default PrintComponent;
