import { Box, HStack } from "@chakra-ui/react";
import React, { useEffect, useContext, useState } from "react";
import { affirmationBg } from "src/assets/icons";
import BaseView from "src/views/Prepration/BaseView";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import SubliminalAudio from "./SubliminalAudio";
import SubliminalVideo from "./SubliminalVideo";
import GeneralIntentions from "../Intentions/GeneralIntentions";
import SpecialIntentions from "../Intentions/SpecialIntentions";
import ShowsubliminalForm from "./ShowsubliminalForm";
import { useSelector, useDispatch } from "src/store";
import { setValue } from "src/store/media";
import { LocaleContext } from "src/providers/LocaleProvider";
import { get } from "src/api";
import { useLocation } from "react-router-dom";
import AudioContainer from "./AudioDetail";

const Subliminal = () => {
  const value = useSelector((state) => state.media.value);
  const { locale } = useContext(LocaleContext);
  const dispatch = useDispatch();

  const handleClick = (e: any) => {
    dispatch(setValue({ value: e.target.value }));
  };
  const [audioSubliminal, setAudioSubliminal] = useState([]);
  const [videoSubliminal, setVideoSubliminal] = useState([]);

  // let audioSubliminal:any = []
  // let videoSubliminal:any = []

  useEffect(() => {
    // Get All subliminals
    get("/subliminal", locale).then((data: any) => {
      let audioList: any = [];
      let videoList: any = [];
      // category: "Audio"
      data.forEach((subliminal) => {
        if (subliminal.category === "Audio") {
          audioList.push(subliminal);
        }

        if (subliminal.category === "Video") {
          videoList.push(subliminal);
        }
      });

      setAudioSubliminal(audioList);
      setVideoSubliminal(videoList);
    });
    let inputs = [
      ...document.getElementsByClassName(
        "chakra-input message-Input css-ar-1gfg92o"
      ),
    ];
    inputs.forEach((input: any) => {
      if (input.value === value) {
        input.style.border = "1px solid #2895ff";
      } else {
        input.style.border = "1px solid #e6e6e6";
      }
    });
  }, [value, locale]);

  const location = useLocation();

  useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tabIdx = params.get('idx');
        setTabIndex(tabIdx !== null ? +tabIdx : 0);
  }, [location.search])
  


  const [tabIndex, setTabIndex] = useState(0)

  const handleTabsChange = (index) => {
    setTabIndex(index)
  }
  return (
    <Box>
      <BaseView imageSrc={affirmationBg} />

      <Tabs align={"center"} index={tabIndex} onChange={handleTabsChange} border={"none"}>
        <TabList border={"none"}>
          <Tab
            width={"47.5px"}
            color={"#8b8b8b"}
            fontFamily={"Calibri (Body)"}
            fontSize={"16px"}
            lineHeight={"1.38"}
            mx={"1rem"}
            _selected={{ color: "#2895ff", borderBottom: "1px solid #2895ff" }}
          >
            <FormattedMessage id="subliminal.audio" defaultMessage={"الصوتي"} />
          </Tab>
          <Tab
            width={"39px"}
            color={"#8b8b8b"}
            fontFamily={"Calibri (Body)"}
            fontSize={"16px"}
            lineHeight={"1.38"}
            mx={"1rem"}
            _selected={{ color: "#2895ff", borderBottom: "1px solid #2895ff" }}
          >
            <FormattedMessage id="subliminal.video" defaultMessage={"المرئي"} />
          </Tab>
          <Tab
            width={"62px"}
            color={"#8b8b8b"}
            fontFamily={"Calibri (Body)"}
            fontSize={"16px"}
            lineHeight={"1.38"}
            mx={"1rem"}
            _selected={{ color: "#2895ff", borderBottom: "1px solid #2895ff" }}
          >
            <FormattedMessage
              id="subliminal.electronic"
              defaultMessage={"الإلكتروني"}
            />
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <SubliminalAudio audioList={audioSubliminal} />
          </TabPanel>
          <TabPanel>
            <SubliminalVideo videoList={videoSubliminal} />
          </TabPanel>
          <TabPanel>
            <HStack
              flexWrap={["wrap", "wrap", "nowrap", "nowrap"]}
              alignItems={"flex-start"}
              maxWidth={"720px"}
              justify={["center", "center", "space-between", "space-between"]}
              me={["10px", "-54px", "-10px"]}
            >
              <div>
                <SpecialIntentions onClick={handleClick} subliminal />
              </div>
              <div>
                <GeneralIntentions onClick={handleClick} subliminal />
              </div>
            </HStack>
            <ShowsubliminalForm />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

Subliminal.AudioDetail = AudioContainer;
export default Subliminal;
