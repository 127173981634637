import React, { useState } from "react";
import BaseView from "../BaseView";
import { VStack, Box, Input, Spinner, Center } from "@chakra-ui/react";
import WaveDetail from "./WaveDetail";
import { Wave } from "src/types";
import { useIntl } from "react-intl";
import { useGetAllWaves, useGetSearchWaves } from "src/newApi/hooks";
import SingleWave from "./SingleWave";
import Categories from "src/components/Categories";
import Category from "src/components/Categories/Category";
import { WAVES_ROUTE } from "src/routes";
const ENTITY_NAME = "waves";

const Waves = () => {
  const { formatMessage } = useIntl();
  const [searchValue, setSearchValue] = useState("");
  const {
    isLoading: allWavesLoading,
    data: allWavesData,
    error: allWavesError,
  } = useGetAllWaves();
  const {
    isLoading: searchWavesLoading,
    data: searchWavesData,
    error: searchWavesError,
  } = useGetSearchWaves(searchValue);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  if ((searchValue && searchWavesError) || (!searchValue && allWavesError)) {
    return (
      <VStack padding={"20px"}>{formatMessage({ id: "fetchError" })}</VStack>
    );
  }
  if (allWavesLoading) {
    return <VStack padding={"20px"}>{formatMessage({ id: "loading" })}</VStack>;
  }
  return (
    <>
      <Box>
        <Input
          type="text"
          placeholder={formatMessage({ id: "searchWave" })}
          maxWidth="300px"
          variant={"flushed"}
          my="6"
          onChange={changeHandler}
        />
        <Box
          display={"flex"}
          justifyContent={["start", "start", "start"]}
          width="100%"
          flexDir="row"
          flexWrap={"wrap"}
        >
          {searchValue !== "" && searchWavesLoading ? (
            <Center minHeight={"100%"} minWidth={"100%"}>
              <Spinner size={"xl"} />
            </Center>
          ) : searchValue !== "" ? (
            searchWavesData
              ?.filter((wave) => wave?.category?.id && wave.imageUrl)
              ?.map((wave: Wave) => (
                <SingleWave
                  key={wave.id}
                  wave={wave}
                  entityName={ENTITY_NAME}
                />
              ))
          ) : null}
        </Box>
      </Box>
      {searchValue === "" ? (
        <Categories
          categories={allWavesData}
          filter={(category: any) => category.waves.length}
          element={(waveCategory, idx) => (
            <Category
              key={idx}
              category={waveCategory.waves.map((cat) => ({
                ...cat,
                name: waveCategory.name,
              }))}
              filter={(wave) => wave.imageUrl}
              element={(wave) => {
                return (
                  <SingleWave
                    key={wave.id}
                    wave={{ ...wave, category: waveCategory }}
                    entityName={ENTITY_NAME}
                  />
                );
              }}
            />
          )}
        />
      ) : null}
    </>
  );
};

const WavesContainer = () => {
  return (
    <BaseView breadTitle="waves" route={WAVES_ROUTE}>
      <Waves />
    </BaseView>
  );
};
WavesContainer.WaveDetail = WaveDetail;

export default WavesContainer;
