import React, { useState } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import ReactPlayer from "react-player";

const Videos = ({
  videos,
  center = true,
  responsive = false,
}: {
  videos: {
    url: string;
    title: string;
  }[];
  center?: boolean;
  responsive?: boolean;
}) => {
  const [pause, setPause] = useState<Array<boolean>>(videos.map(() => false));
  return (
    <HStack
      padding="30px 0"
      width="100%"
      display="flex"
      flexWrap="wrap"
      justifyContent={center ? "center" : "flex-start"}
    >
      {videos?.map((video, index) =>
        video.url ? (
          <Box
            width={
              responsive
                ? ["250px", "300px", "350px", "400px", "450px"]
                : "250px"
            }
            height={responsive ? ["150px", "200px", "250px"] : "150px"}
            key={index}
            margin="45px 10px !important"
          >
            <ReactPlayer
              preload="none"
              key={index}
              className="react-player fixed-bottom"
              url={video.url}
              controls
              width="100%"
              height="100%"
              style={{ minWidth: "100%", minHeight: "100%" }}
              playing={pause[index]}
              onPlay={() => {
                const newArr = pause.map(() => false);
                newArr[index] = !newArr[index];
                setPause(newArr);
              }}
            />
            <Text
              textAlign="center"
              fontWeight="bold"
              marginTop="3"
              fontSize="20px"
            >
              {video.title}
            </Text>
          </Box>
        ) : null
      )}
    </HStack>
  );
};

export default Videos;
