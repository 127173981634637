import React, { useState } from "react";
import {
  Box,
  VStack,
  HStack,
  useDisclosure,
  Input,
  Spinner,
  Button,
  Text,
  Center,
  Grid,
} from "@chakra-ui/react";
import Paper from "@material-ui/core/Paper";
import SliderComponent from "src/components/Slider/SliderComponent";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ImageBox from "src/components/ImageBox/ImageBox";
import { useIntl } from "react-intl";
import * as mutation from "src/api/prepration/focusPoster/mutation";
import { get } from "src/api";
import PosterTemplates from "./PosterTemplates";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import BaseView from "../BaseView";
import { focusPoster } from "src/types";
import { useParams, useHistory } from "react-router-dom";

interface EditInterface {
  title?: string;
  color?: string;
  contentColor?: string;
  titleSize?: string;
  contentSize?: string;
  vAlign?: string;
  hAlign?: string;
}
const PosterContainer = () => {
  const { id: posterId } = useParams<any>();
  const { location: { state: posterDetails } } = useHistory<any>()
  const canEdit = !Boolean(posterDetails.shared) || (Boolean(posterDetails.shared) && Boolean(posterDetails.shared.permission === "update"))
  const {
    data: poster,
    isLoading,
    error,
  } = useQuery("poster", () =>
    posterId ? get<focusPoster>(`/poster/${posterId}`) : Promise.reject(null)
  );
  const client = useQueryClient();
  const { mutateAsync: editMutation } = useMutation(mutation.edit, {
    onSuccess: () => client.invalidateQueries("poster"),
  });
  const [color, setColor] = useState("");
  const [titleSize, setTitleSize] = useState("");
  const { formatMessage, locale } = useIntl();
  const [selectedCard, setSelectedCard] = useState(-1);
  const [title, setTitle] = useState("");
  const { onOpen: openUnsplashModal } = useDisclosure();

  const editHandler = ({
    title,
    color,
    contentColor,
    titleSize,
    contentSize,
    vAlign,
    hAlign,
  }: EditInterface) => {
    editMutation({
      name: title ?? poster?.name,
      nameColor: color || poster?.nameColor,
      contentColor: contentColor || poster?.contentColor,
      nameSize: titleSize || poster?.nameSize,
      contentSize: contentSize || poster?.contentSize,
      id: posterId,
      valign: vAlign || poster?.valign,
      halign: hAlign || poster?.halign,
    });
  };

  if (error) return <p>Error fetching poster</p>;
  if (isLoading)
    return (
      <Center w="100%" h="80vh">
        <Spinner />
      </Center>
    );

  return (
    <React.Fragment key={posterId}>
      {poster ? (
        <BaseView>
          <VStack
            w={["90vw", "70vw"]}
            paddingTop={["30px", "30px", "30px", "30px", "30px"]}
            flexDir={["column", "column", "row"]}
            alignItems={["center", "flex-start"]}
            mr={["0", "0", "auto"]}
            style={{
              position: "relative",
            }}
          >
            {posterId ? (
              <Grid
                columns={1}
                rows={1}
                w={"100%"}
                mr={["0", "0", "8"]}
                className="neomorphism"
              >
                <ImageBox
                  cardId={posterId}
                  selectedCard={selectedCard}
                  titleColor={color}
                  titleSize={titleSize}
                  contentColor={""}
                  contentSize={""}
                  onCheck={() => setSelectedCard(-1)}
                  unsplashDisclosureProps={openUnsplashModal}
                  vAlign={poster?.valign}
                  hAlign={poster?.halign}
                  canEdit={canEdit}
                ></ImageBox>
              </Grid>
            ) : (
              <Box width={"100%"}>
                <Paper
                  elevation={4}
                  style={{
                    backgroundImage:
                      "url('https://filestore.community.support.microsoft.com/api/images/72e3f188-79a1-465f-90ca-27262d769841')",
                    width: "100%",
                    height: "842px",
                    backgroundSize: "cover",
                    borderRadius: "30px",
                    backgroundPosition: "center",
                  }}
                />
              </Box>
            )}

            {canEdit ? <HStack
              display="flex"
              alignItems={["center"]}
              flexDir={["column", "column", "column", "column"]}
              paddingBottom="30px"
              mt={["0", "0", "8"]}
              justifyContent="center"
              gridGap={"10px"}
            >
              <Input
                type="text"
                value={title}
                placeholder={formatMessage({
                  id: "changeTitle",
                  defaultMessage: "تغيير العنوان",
                })}
                mb="4"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <HStack w="100%" my="8">
                <Button
                  onClick={() => editMutation({ ...poster, name: title })}
                >
                  {formatMessage({ id: "apply" })}
                </Button>
                <Button
                  onClick={() => {
                    setTitle("");
                    editHandler({ title: "" });
                  }}
                >
                  {formatMessage({ id: "removeTitle" })}
                </Button>
              </HStack>
              <Center
                w="100%"
                sx={{ gap: "10px" }}
                flexDirection={locale === "en" ? "row" : "row-reverse"}
              >
                <Button
                  onClick={() => {
                    editHandler({ hAlign: "left" });
                  }}
                >
                  <FormatAlignLeftIcon />
                </Button>
                <Button
                  onClick={() => {
                    editHandler({ hAlign: "center" });
                  }}
                >
                  <FormatAlignCenterIcon />
                </Button>
                <Button
                  onClick={() => {
                    editHandler({ hAlign: "right" });
                  }}
                >
                  <FormatAlignRightIcon />
                </Button>
              </Center>

              <Center w="100%" sx={{ gap: "10px" }}>
                <Button
                  onClick={() => {
                    editHandler({ vAlign: "flex-start" });
                  }}
                >
                  <AlignVerticalTopIcon />
                </Button>
                <Button
                  onClick={() => {
                    editHandler({ vAlign: "center" });
                  }}
                >
                  <AlignVerticalCenterIcon />
                </Button>
                <Button
                  onClick={() => {
                    editHandler({ vAlign: "flex-end" });
                  }}
                >
                  <AlignVerticalBottomIcon />
                </Button>
              </Center>
              <SliderComponent
                name="title"
                title={formatMessage({
                  id: "focusPoster.title.fontsize",
                  defaultMessage: "حجم خط العنوان",
                })}
                content={formatMessage({
                  id: "focusPoster.title.color",
                  defaultMessage: "لون العنوان",
                })}
                onChangeColor={(newColor: any) => {
                  setColor(newColor);
                  editHandler({ color: newColor });
                }}
                onChangeSize={(newSize: any) => {
                  setTitleSize(newSize);
                  editHandler({ titleSize: newSize });
                }}
              />
            </HStack> : null}
          </VStack>
          {canEdit ? <Box>
            <Text mt="4" fontSize="xl" fontWeight={"bold"}>
              {formatMessage({ id: "chooseTemplate" })}
            </Text>
            <VStack>
              <PosterTemplates
                poster={poster}
                update={editMutation}
                resetTitle={setTitle}
              />
            </VStack>
          </Box> : null}
        </BaseView>
      ) : null}
    </React.Fragment>
  );
};
const PosterDetails = () => {
  const { id: posterId } = useParams();
  return <PosterContainer key={posterId} />;
};

export default PosterDetails;
