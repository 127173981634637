import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import AddPlaylist from './AddPlaylist'
const AddPlaylistModal = ({ isOpen, onClose }) => {
    return (
        <div>
            <Modal
                isCentered
                onClose={onClose}
                isOpen={isOpen}
                motionPreset='slideInBottom'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <FormattedMessage id="createPlaylist" />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AddPlaylist isOpen={isOpen} onClose={onClose} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default AddPlaylistModal