import React, { useState, useEffect, useCallback } from "react";
import MaleHead from "src/assets/images/man-head.png";
import MaleAbs from "src/assets/images/man-abbs.png";
import MaleLegs from "src/assets/images/man-legs.png";
import FemaleHead from "src/assets/images/woman-head.png";
import FemaleAbs from "src/assets/images/woman-abbs.png";
import FemaleLegs from "src/assets/images/woman-legs.png";
import { Box } from "@chakra-ui/react";
import BaseView from "../BaseView";
import { Link, useLocation } from "react-router-dom";
import { get } from "src/api/index";
import useLocale from "src/providers/useLocale";
const map = {
  male: {
    head: MaleHead,
    abdomen: MaleAbs,
    limps: MaleLegs,
  },
  female: {
    head: FemaleHead,
    abdomen: FemaleAbs,
    limps: FemaleLegs,
  },
};
const Inner = () => {
  //function to obtain the gender and body section
  const getSlugs = useCallback(() => {
    const slugs = window.location.pathname.split("/");
    const gender = slugs[4] as unknown as keyof typeof map;
    const section = slugs[5] as unknown as keyof typeof map.male;
    return {
      gender,
      section,
    };
  }, []);

  const locale = useLocale()[0];

  //count of organs for current section
  const [organsCount, setOrgansCount] = useState<any>({});

  useEffect(() => {
    //function to fetch organs count
    const countOrgans = async () => {
      const { gender, section } = getSlugs();
      const response = await get(`/healing/${gender}/${section}`);
      if (response) setOrgansCount(response);
    };
    countOrgans();
  }, [getSlugs, locale]);

  //relative url
  const { pathname } = useLocation();

  //function to return the map for section parts
  const getImageMap = () => {
    if (!organsCount) return;
    const section = getSlugs().section;
    //Depending on the section we return the map for it's corresponding parts
    switch (section) {
      case "head":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.forehead ? "#" : `${pathname}/forehead`}
              position="absolute"
              width="80%"
              height="100px"
              top="0"
              left="50%"
              transform="translate(-50%, 10px)"
            />
            <Box
              as={Link}
              to={!organsCount.eyes ? "#" : `${pathname}/eyes`}
              position="absolute"
              width="80%"
              height="40px"
              top="0"
              left="50%"
              transform="translate(-50%, 120px)"
            />
            <Box
              as={Link}
              to={!organsCount.nose ? "#" : `${pathname}/nose`}
              position="absolute"
              width="20%"
              height="60px"
              top="0"
              left="50%"
              transform="translate(-50%, 145px)"
            />
            <Box
              as={Link}
              to={!organsCount.mouth ? "#" : `${pathname}/mouth`}
              position="absolute"
              width="30%"
              height="40px"
              top="0"
              left="50%"
              transform="translate(-50%, 215px)"
            />
            <Box
              as={Link}
              to={!organsCount.cheeks ? "#" : `${pathname}/cheeks`}
              position="absolute"
              width="20%"
              height="80px"
              top="0"
              left="0"
              transform="translate(50px , 180px)"
            />
            <Box
              as={Link}
              to={!organsCount.cheeks ? "#" : `${pathname}/cheeks`}
              position="absolute"
              width="20%"
              height="80px"
              top="0"
              right="0"
              transform="translate(-50px, 180px)"
            />
            <Box
              as={Link}
              to={!organsCount.ears ? "#" : `${pathname}/ears`}
              position="absolute"
              width="10%"
              height="60px"
              top="0"
              left="0"
              transform="translate(0px, 140px)"
            />
            <Box
              as={Link}
              to={!organsCount.ears ? "#" : `${pathname}/ears`}
              position="absolute"
              width="10%"
              height="60px"
              top="0"
              right="0"
              transform="translate(-0px, 140px)"
            />
            <Box
              as={Link}
              to={!organsCount.neck ? "#" : `${pathname}/neck`}
              position="absolute"
              width="70%"
              height="60px"
              top="0"
              left="50%"
              transform="translate(-50%, 290px)"
            />
          </>
        );
      case "abdomen":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.chest ? "#" : `${pathname}/chest`}
              position="absolute"
              width="100%"
              height="140px"
              top="0"
              left="50%"
              transform="translate(-50%, 30px)"
            />
            <Box
              as={Link}
              to={!organsCount.belly ? "#" : `${pathname}/belly`}
              position="absolute"
              width="80%"
              height="180px"
              top="0"
              left="50%"
              transform="translate(-50%, 170px)"
            />
          </>
        );
      case "limps":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.arms ? "#" : `${pathname}/arms`}
              position="absolute"
              width="10%"
              height="100px"
              top="0"
              left="0"
              transform="translate(60px, -15px) rotate(30deg)"
            />
            <Box
              as={Link}
              to={!organsCount.arms ? "#" : `${pathname}/arms`}
              position="absolute"
              width="10%"
              height="100px"
              top="0"
              right="0"
              transform="translate(-60px, -15px) rotate(-30deg)"
            />
            <Box
              as={Link}
              to={!organsCount.hands ? "#" : `${pathname}/hands`}
              position="absolute"
              width="10%"
              height="30px"
              top="0"
              left="0"
              transform="translate(10px, 100px) rotate(30deg)"
            />
            <Box
              as={Link}
              to={!organsCount.hands ? "#" : `${pathname}/hands`}
              position="absolute"
              width="10%"
              height="30px"
              top="0"
              right="0"
              transform="translate(-10px, 100px) rotate(-30deg)"
            />
            <Box
              as={Link}
              to={!organsCount.legs ? "#" : `${pathname}/upperLegs`}
              position="absolute"
              width="40%"
              height="150px"
              top="0"
              left="50%"
              transform="translate(-50%, 100px)"
            />
            <Box
              as={Link}
              to={!organsCount.feet ? "#" : `${pathname}/lowerLegs`}
              position="absolute"
              width="40%"
              height="100px"
              top="0"
              left="50%"
              transform="translate(-50%, 200px)"
            />
          </>
        );
    }
  };

  return (
    <BaseView>
      <Box position="relative" w="300px" h="350px" margin="80px auto">
        <img
          style={{ width: "100%", height: "100%" }}
          src={map[getSlugs().gender][getSlugs().section]}
          alt={"inner body part"}
        />
        {getImageMap()}
      </Box>
    </BaseView>
  );
};

export default Inner;
