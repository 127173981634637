import { Box, BoxProps } from "@chakra-ui/react";
import React, { useState } from "react";

type SelectItemProps = {
  selected?: boolean;
  onSelect?: () => Promise<void>;
} & BoxProps;
const SelectItem: React.FC<SelectItemProps> = ({
  children,
  selected,
  onSelect,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Box
      minH="32px"
      maxW="300px"
      // h="xl"
      px={5}
      onClick={async () => {
        try {
          setLoading(true);
          await onSelect?.();
          setLoading(false);
        } catch {
          setLoading(false);
        }
      }}
      backgroundColor={loading ? "brand.400" : selected ? "brand.800" : "white"}
      color={selected ? "white" : "brand.800"}
      borderColor="brand.800"
      borderWidth="1px"
      borderRadius="16px"
      justifyContent="center"
      alignItems="center"
      fontFamily="Calibri (Body)"
      d="flex"
      {...props}
    >
      {children}
    </Box>
  );
};
export default SelectItem;
