import React from "react";
import { Flex, Avatar, Box, Text, IconButton } from "@chakra-ui/react";
import { BASE_MEDIA } from "src/configs/config";
import RatingStars from "src/components/Rating/RatingStars";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

const SingleReview = ({
  id,
  user,
  rating,
  description,
  date,
  canEdit = true,
  canDelete = true,
  openModal,
  deleteRating,
}) => {
  return (
    <Flex alignItems={"center"} mb="2">
      <Avatar
        src={user?.picture ? `${BASE_MEDIA}/${user?.picture}` : undefined}
        mr="3"
      />
      <Box w={"100%"}>
        <Text fontSize={"small"} color={"#b3b1ad"}>
          {date?.split("T")[0].split("-").reverse().join("-")}
        </Text>
        <Flex alignItems={"center"} justifyContent="space-between" gap={3}>
          <Flex gap={3} alignItems="center">
            <h3>{user?.name}</h3>
            <RatingStars
              icon="star"
              scale={5}
              fillColor="gold"
              isDisabled
              initialRating={rating}
              size={3}
            />
          </Flex>
          <Box>
            {canEdit ? (
              <IconButton
                color="brand.900"
                icon={<FaEdit />}
                size={"sm"}
                variant="ghost"
                aria-label="icon-button"
                onClick={() => {
                  openModal();
                }}
              />
            ) : null}
            {canDelete ? (
              <IconButton
                color="#e97575"
                icon={<RiDeleteBin6Line />}
                size={"sm"}
                variant="ghost"
                aria-label="icon-button"
                onClick={() => {
                  deleteRating(id);
                }}
              />
            ) : null}
          </Box>
        </Flex>

        <Box>
          <p>{description}</p>
        </Box>
      </Box>
    </Flex>
  );
};

export default SingleReview;
