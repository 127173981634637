import React, { useEffect, useState } from "react";
import MessagesCard from "./MessagesCard";
import { get } from "src/api/index";
import { Disease } from "src/types";
import useLocale from "src/providers/useLocale";

interface Props {
  state?: any;
  data?: any;
  subliminal?: any;
  onClick?: any;
}
const GeneralCards: React.FC<Props> = ({ state, onClick, subliminal }) => {
  const [locale] = useLocale();
  const [messages, setMessages] = useState<{ id: number; name: string }[]>([]);

  //function to check for a query param symptoms in case the user
  //is redirected from healing page
  const checkSym = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const symptom = searchParams.get("sym");
    if (symptom) {
      const response: Disease = await get(`/healing/${symptom}`);
      if (Array.isArray(response?.affirmations)) {
        //Statements need to adapt to the intention statement schema
        const affs: { id: number; name: string }[] = [];
        for (let i = 0; i < response.affirmations.length; i++) {
          affs.push({ id: i, name: response.affirmations[i] });
        }
        setMessages(affs);
      }
    }
    //If the parameter doesn't exist just do nothing
  };

  useEffect(() => {
    checkSym();
  }, []);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!state) return;
      const response = await get(
        `/${subliminal ? "subliminal" : "intention"}?categoryId=${state.id}`
      );
      if (Array.isArray(response)) {
        setMessages(response);
      }
    };
    fetchMessages();
  }, [state, locale, subliminal]);

  return (
    <>
      <MessagesCard
        onClick={onClick}
        subliminalGeneral={subliminal}
        data={messages}
      />
    </>
  );
};

export default GeneralCards;
