import {
  Link as ChakraLink,
  LinkProps,
  Button,
  ButtonProps,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { Link as ReachLink } from "react-router-dom";

const Link = ({
  asBtn,
  to,
  isSecondary,
  children,
  onClick,
  disabled,
  ...props
}: {
  to?: string;
  isSecondary?: boolean;
  onClick?: any;
  asBtn?: boolean;
} & LinkProps &
  ButtonProps) => {
  React.useEffect(() => {}, [disabled]);
  return !asBtn ? (
    <ChakraLink
      onClick={onClick}
      as={to ? ReachLink : Box}
      to={to || null}
      bg={isSecondary ? "white" : disabled ? "gray.200" : "#2895ff"}
      cursor={disabled ? "default" : "pointer"}
      fontSize="16px"
      textColor={isSecondary ? "#2895ff" : "white"}
      textAlign="center"
      w="168px"
      py={3}
      marginBlock={{ base: "10px", sm: "0" }}
      borderRadius={5}
      border="1px solid"
      borderColor={isSecondary ? "#2895ff" : "white"}
      _hover={{ textDecoration: "none" }}
      {...props}
    >
      {children}
    </ChakraLink>
  ) : (
    <Button
      disabled={disabled}
      onClick={onClick}
      backgroundColor={isSecondary ? "white" : "#2895ff"}
      fontSize="16px"
      color={isSecondary ? "#2895ff" : "white"}
      textAlign="center"
      w="168px"
      py={3}
      borderRadius={5}
      border="1px solid"
      borderColor={isSecondary ? "#2895ff" : "white"}
      _hover={{ textDecoration: "none" }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default Link;
