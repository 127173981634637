import { Box, Center, Input, Text } from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import InputWithAdd from "src/components/InputWithAdd";
import PrimaryButton from "src/components/PrimaryButton";
import { Skill } from "src/types";
import SkillItem from "./SkilItem";
import { ReturnUseMission } from "./useMission";
type Step2Props = {
  nextStep: () => void;
} & ReturnUseMission;
const Step2: React.FC<Step2Props> = ({
  addSkill,
  editSkill,
  deleteSkill,
  mission: { skills },
  nextStep,
}) => {
  const { formatMessage } = useIntl();
  const [skill, setSkill] = useState<Skill>({ name: "", property: "" });
  const onClick = useCallback(() => {
    nextStep();
  }, [nextStep]);

  const submitListener = useCallback(
    (e: any) => {
      if (skills.length === 5 && e.keyCode === 13) onClick();
    },
    [onClick, skills.length]
  );

  useEffect(() => {
    window.addEventListener("keydown", submitListener);
    return () => {
      window.removeEventListener("keydown", submitListener);
    };
  }, [submitListener]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Box>
          <Text
            d="inline-block"
            color="brand.900"
            fontSize="18px"
            fontWeight="bold"
            fontFamily="Calibri (Body)"
            padding={"20px 0"}
          >
            <FormattedMessage
              id="missions.create.step2.title"
              defaultMessage="قم بإدخال صفة تتميز بها والعمل الذي تتحقق من خلاله"
            />
          </Text>{" "}
          <Text
            d="inline-block"
            color="gray.900"
            fontFamily="Calibri (Body)"
          >{`${skills.length}/5`}</Text>
        </Box>
        <Input
          disabled={skills.length === 5}
          onKeyDown={(evt: any) => {
            if (evt.keyCode === 13) {
              document.getElementById("inputAddSkill")?.focus();
            }
          }}
          id={"SkillInput0"}
          variant="flushed"
          mb={"16px"}
          placeholder={formatMessage({
            id: "mission.create.step2.skill",
            defaultMessage: "الصفة",
          })}
          value={skill.name}
          onChange={(e) =>
            setSkill((old) => {
              return { ...old, name: e.target.value };
            })
          }
        />
      </form>

      <InputWithAdd
        inputDisabled={skills.length === 5}
        id={"inputAddSkill"}
        onKeyDown={(evt: any) => {
          if (evt.keyCode === 13) {
            document.getElementById("SkillInput0")?.focus();

            if (!skill.property || !skill.name) return;
            addSkill(skill);
            setSkill({ name: "", property: "" });
          }
        }}
        disabled={
          skills.length === 5 ||
          skill.name.trim() === "" ||
          skill.property.trim() === ""
        }
        placeholder={formatMessage({
          id: "mission.create.step2.property",
          defaultMessage: "العمل",
        })}
        onClick={() => {
          if (!skill.property || !skill.name) return;
          document.getElementById("SkillInput0")?.focus();
          addSkill(skill);

          setSkill({ name: "", property: "" });
        }}
        value={skill.property}
        onChange={(e: any) =>
          setSkill((old) => {
            return { ...old, property: e.target.value };
          })
        }
        mb={"24.5px"}
      />

      {/* List */}
      {skills.map((skill, idx) => (
        <SkillItem
          key={idx}
          text={formatMessage(
            {
              id: "missions.step2.by",
              defaultMessage: "{name} من خلال {property}",
            },
            { name: skill.name, property: skill.property }
          )}
          edit={(skill) => editSkill(idx, skill)}
          remove={() => {
            deleteSkill(idx);
          }}
          skill={skill}
        />
      ))}

      <Center my={"32px"}>
        <PrimaryButton onClick={onClick} disabled={skills.length < 5}>
          <FormattedMessage id="next" defaultMessage="التالي" />
        </PrimaryButton>
      </Center>
    </>
  );
};
export default Step2;
