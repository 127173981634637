import { Box, Spinner, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { get } from "src/api";
import { useDispatch } from "src/store";
import { login } from "src/store/auth";
import { UserType } from "src/types";

const AutoLogin = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [text, setText] = useState("");

  const validateToken = useCallback(
    async (token) => {
      if (token) {
        //validate the token if found
        const response: UserType = await get("/user/me", undefined, token);
        if (response?.id) {
          const redirectUrl =
            response.subscriptionStatus === "approved" ? "/" : "/subscription";
          window.localStorage.setItem("__sal_auth", token);
          // Send the authorization token to the service worker
          if (navigator.serviceWorker.controller) {
            const message = window.localStorage.getItem("__sal_auth");
            navigator.serviceWorker.controller.postMessage({
              type: "fromPage",
              message: message,
            });
          }
          dispatch(login({ user: response, autoLoginToken: token }));
          history.push(redirectUrl);
        } else {
          setText("Invalid Link");
        }
      }
    },
    [history]
  );

  useEffect(() => {
    window.localStorage.getItem("__sal_auth");
    window.localStorage.removeItem("unloadFlag");
    const allqueries = location.search
      .substring(1)
      .split("&")
      .map((query) => {
        const qOpject = {};
        const pair = query.split("=");
        qOpject[pair[0]] = pair[1];
        return qOpject;
      });

    const token = allqueries.find((query) => query.token)?.token;

    if (token) {
      window.localStorage.setItem("__sal_auth", token);
      // Send the authorization token to the service worker
      if (navigator.serviceWorker.controller) {
        const message = window.localStorage.getItem("__sal_auth");
        navigator.serviceWorker.controller.postMessage({
          type: "fromPage",
          message: message,
        });
      }
      window.localStorage.removeItem("unloadFlag");
      validateToken(token);
    } else {
      setText("Invalid Link");
    }
  }, [history, location.search, validateToken]);

  return (
    <Box
      p="20"
      w="100%"
      d="flex"
      alignItems={"center"}
      justifyContent={"center"}
      flexDir={"column"}
    >
      {!text && <Spinner m="10" />}
      <Text>{text}</Text>
    </Box>
  );
};

export default AutoLogin;
