import { deleteMethod, post, put } from "src/api";

// =====================================================

export const create: (props: {
  name: string;
  entityName?: string;
  entityId?: number;
  trackType?: string;
}) => Promise<any> = (props: {
  name: string;
  entityName?: string;
  entityId?: number;
  trackType?: string;
}) => {
  const body =
    props.entityId && props.entityName
      ? {
          name: props.name,
          tracks: [
            {
              entityName: props.entityName,
              entityId: props.entityId,
              trackType: props.trackType,
            },
          ],
        }
      : {
          name: props.name,
        };
  return post("/playlist", body, undefined) as unknown as Promise<any>;
};

// =====================================================

export const deletePlaylist: (id: number) => Promise<any> = (id) =>
  deleteMethod(`/playlist/${id!}`, undefined);

export const deleteTrack: (trackId: number) => Promise<any> = (trackId) =>
  deleteMethod(`/playlist/track/${trackId!}`, undefined);

// =====================================================

export const edit: (playlist: any) => Promise<any> = (playlist) => {
  return playlist?.id
    ? put<any>(`/playlist/${playlist.id}`, playlist, undefined)
    : Promise.reject(null);
};

export const addToPlaylist: (params: {
  playlistId: any;
  entityName: any;
  entityId: any;
  trackType?: string;
}) => Promise<any> = (params: {
  playlistId: any;
  entityName: any;
  entityId: any;
  trackType?: string;
}) => {
  return params.entityId
    ? post<any>(
        `/playlist/${params.playlistId}`,
        {
          entityName: params.entityName,
          entityId: params.entityId,
          trackType: params.trackType,
        },
        undefined
      )
    : Promise.reject(null);
};

export const reorderPlayList = (params: {
  playlistId: string;
  body: { newOrder: string[] };
}) => {
  return put<any>(
    `/playlist/${params.playlistId}/reorder`,
    params.body,
    undefined
  );
};
