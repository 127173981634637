import { Box, Center, Text } from "@chakra-ui/react";
import React from "react";
import { FormattedMessage } from "react-intl";
import Card from "src/components/Card";
import PrimaryButton from "src/components/PrimaryButton";
import { ReturnUseWishes } from "./useWishes";
import WishItem from "./WishItem";
import { scrollBarStyles } from "src/layout/ScrollBar";

type WishesStepOneProps = ReturnUseWishes & { setStep: () => void };
const WishesStepOne: React.FC<WishesStepOneProps> = ({
  wishes,
  top20,
  addTo20,
  returnToWishes,
  setStep,
}) => {
  
  return (
    <Box flex="1">
      <Text
        marginStart="30px"
        d="inline-block"
        color="brand.900"
        fontSize="18px"
        fontWeight="bold"
        fontFamily="Calibri (Body)"
        mt={"22px"}
        mb={"30px"}
      >
        <FormattedMessage
          id="vision.step1.create.title"
          defaultMessage="قم باختيار أهم 20 أمنية"
        />
      </Text>
      <Box d="flex" flexDir="row" flex="1" className="here">
        <Card
          me={6}
          pt={"16px"}
          ps={"46px"}
          pe={"30px"}
          d="flex"
          flexDir="column"
          h="600px"
          // flex="1"
        >
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="brand.900"
            fontFamily="Calibri (Body)"
            lineHeight="36px"
            mb="16px"
          >
            <FormattedMessage
              id="vision.wishes.step1.card1.title"
              defaultMessage="جميع الامنيات"
            />
          </Text>
          <Box overflowY="scroll" maxH={"544px"} flex="1" css={scrollBarStyles}>
            <Box>
              {wishes.map((w, idx) => (
                <WishItem
                  key={w.id}
                  addHandler={() => {
                    top20.length !== 20 && addTo20(idx);
                  }}
                  mb={2}
                >
                  {w.name} 
                </WishItem>
              ))}
            </Box>
          </Box>
        </Card>
        <Card
          me={6}
          pt={"16px"}
          ps={"46px"}
          pe={"30px"}
          d="flex"
          flexDir="column"
          h="600px"
        >
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="brand.900"
            fontFamily="Calibri (Body)"
            lineHeight="36px"
            mb="16px"
          >
            <FormattedMessage
              id="vision.wishes.step1.card2.title"
              defaultMessage="أهم 20 أمنية"
            />
          </Text>
          <Box overflowY={"auto"} maxH={"544px"} flex="1" css={scrollBarStyles}>
            <Box id={"scrollableList"}>
              {top20.map((w, idx) => {
                return (
                  <WishItem
                    key={w.id}
                    removeHandler={() => {
                      returnToWishes(idx);
                    }}
                    mb={2}
                  >
                    {w.name}
                  </WishItem>
                );
              })}
            </Box>
          </Box>
        </Card>
      </Box>
      <Center mt={8} mb={10}>
        <PrimaryButton onClick={setStep} disabled={top20.length !== 20}>
          <FormattedMessage id="next" defaultMessage="التالي" />
        </PrimaryButton>
      </Center>
    </Box>
  );
};
export default WishesStepOne;
