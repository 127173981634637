import { post } from "src/api";
import { OpenAiMessage } from "src/types";

const sections = {
  preparation: "Preparation",
  affirmation: "Affirmation",
  gate: "Portals",
  communication: "Communication",
  frequency: "Frequency",
};

export const submitMessage: (
  message: string,
  role: "user" | "assistant",
  focusProgram: string
) => Promise<OpenAiMessage> = (message, role = "user", focusProgram) => {
  const section =
    focusProgram && sections[focusProgram] ? sections[focusProgram] : "Unknown";

  return post(
    "/sal-assistant/submit-message",
    { message, role, focusProgram: section },
    undefined
  ) as unknown as Promise<OpenAiMessage>;
};
