import React, { createContext, useState } from "react";

type ContextValue = {
  excutor?: string;
  startDate?: any;
  endDate?: any;
  setExcutor?: React.Dispatch<React.SetStateAction<string>>;
  setStartDate?: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate?: React.Dispatch<React.SetStateAction<Date>>;
  isFilterEnabled?: boolean;
  toggleFilter?: () => void;
  resetFilter?: () => void;
};

export const FiltersContext = createContext<ContextValue>({});

const FiltersProvider: React.FC = ({ children }) => {
  const [excutor, setExcutor] = useState("");
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [isFilterEnabled, setIsFilterEnabled] = useState<Boolean>(false);

  const toggleFilter = () => {
    setIsFilterEnabled((current) => !current);
  };

  const resetFilter = () => {
    setExcutor("");
    setStartDate("");
    setEndDate("");
    setIsFilterEnabled(false); //i'll use it to toggle filter visibility on the screen if needed
  };
  const values = {
    excutor,
    startDate,
    endDate,
    setExcutor,
    setStartDate,
    setEndDate,
    isFilterEnabled,
    toggleFilter,
    resetFilter,
  };

  return (
    // @ts-ignore
    <FiltersContext.Provider value={values}>{children}</FiltersContext.Provider>
  );
};

export default FiltersProvider;
