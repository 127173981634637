import React from "react";
import { Text, Center, Spinner } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useGetTraining } from "src/newApi/hooks";
import { Heading, Box } from "@chakra-ui/react";
import TrainingVideos from "./components/TrainingVideos";
const Learning = () => {
  const { isLoading, error, data: videos } = useGetTraining();

  if (isLoading)
    return (
      <Center minHeight={"70vh"}>
        <Spinner size="xl" />
      </Center>
    );
  if (error || videos === undefined || videos?.length === 0 || videos === null)
    return (
      <div>
        <FormattedMessage id={"fetchError"} />
      </div>
    );
  return (
    <>
      <Heading as="h2" textStyle={"h2"} my="2" textTransform={"capitalize"}>
        <FormattedMessage id={"trainingVideos"} />
      </Heading>
      <Box mb="4">
        <Text color="grey" paddingTop={"10px"} fontSize="18px">
          <FormattedMessage id={"trainingDescription"} />
        </Text>
      </Box>
      <TrainingVideos videos={videos} />
    </>
  );
};

export default Learning;
