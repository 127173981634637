import {
  Box,
  Button,
  Input,
  useToast,
  Text,
  RadioGroup,
  Stack,
  Radio,
  Center,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import usePlaylists from "./usePlaylists";
import { setValue } from "src/store/media";
// eslint-disable-next-line react-hooks/rules-of-hooks
const AddPlaylist = ({ isOpen, onClose, onSubmit, entityName, track }: any) => {
  const hasPureAndMusic = track?.withMusicUrl && track?.musicFreeUrl;
  const [name, setName] = useState("");
  const [submittedName, setSubmittedName] = useState("");
  const { createMutation } = usePlaylists();
  const [trackType, setTrackType] = useState("music");
  const [duplicateNameErr, setDuplicateNameErr] = useState("");
  const toast = useToast();
  const { formatMessage } = useIntl();
  const createPlaylist = useCallback(
    async (name: string, entityName?: string, entityId?: number) => {
      setSubmittedName(name);
      const res = await createMutation({
        name,
        entityName,
        entityId,
        trackType,
      });

      if (res?.id) {
        toast({
          title: formatMessage({ id: "saved" }),
          status: "success",
          isClosable: true,
        });
        onClose && onClose();
      } else if (res?.error === "Conflict") {
        // toast({
        //     title: formatMessage({ id: "duplicate" }),
        //     status: "error",
        //     isClosable: true,
        // });
        setDuplicateNameErr(formatMessage({ id: "duplicate" }));
      } else {
        toast({
          title: formatMessage({ id: "wentWrong" }),
          status: "error",
          isClosable: true,
        });
      }
    },
    [createMutation, formatMessage, onClose, toast, track, trackType]
  );
  useEffect(() => {
    if (!isOpen) {
      setName("");
    }
  }, [isOpen]);
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createPlaylist(name, entityName, track?.entityId);
          onSubmit && onSubmit();
        }}
      >
        <Input
          placeholder={formatMessage({ id: "playlistName" })}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        {duplicateNameErr && submittedName === name ? (
          <Text px="2" fontSize={"sm"} color="crimson">
            {duplicateNameErr}
          </Text>
        ) : null}

        {entityName === "waves" && hasPureAndMusic ? (
          <Center marginTop={5}>
            <RadioGroup
              onChange={(nextValue) => {
                console.log("this is the next value", nextValue);
                setTrackType(nextValue);
              }}
              value={trackType}
            >
              <Stack direction="row">
                <Radio value="pure">
                  <FormattedMessage id="Pure Tones" />
                </Radio>
                <Radio value="music">
                  <FormattedMessage id="Frequencies with music" />
                </Radio>
              </Stack>
            </RadioGroup>
          </Center>
        ) : (
          <></>
        )}

        <Box my="5" d="flex" alignItems="center" justifyContent="center">
          <Button disabled={!name} type="submit">
            <FormattedMessage id="save" />
          </Button>
        </Box>
      </form>
    </>
  );
};

export default AddPlaylist;
