import { Box, Text } from "@chakra-ui/react";
import React from "react";
type ThanosGemProps = {
  text: string;
  color?: string;
  textColor?: string;
};

const LightGem: React.FC<ThanosGemProps> = ({ text, color, textColor }) => {
  return (
    <Box
      bg={color}
      borderRadius="5px"
      w="32px"
      h="32px"
      alignItems="center"
      textAlign="center"
    >
      <Text
        cursor="default"
        verticalAlign="center"
        my="auto"
        color={textColor}
        lineHeight="2"
      >
        {text}
      </Text>
    </Box>
  );
};
export default LightGem;
