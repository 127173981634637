import React, { useState, useEffect } from "react";
import { userType } from "src/providers/AuthProvider";
import { Box, Text, Button } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { SUBSCRIPTION_ROUTE } from "src/routes";



type SubscriptionDetailsProps = {
  user: userType;
};

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({ user }) => {
  //formatting messages
  const { formatMessage } = useIntl();
  
  //date remaining calculated
  const initialState =
  new Date(user.subscription?.endDate).getTime() -
  new Date(Date.now()).getTime();
  const [remaining, setRemaining] = useState(initialState);
  
  const history = useHistory();

  // const isEligibleForOffer =
  // remaining > 30 * 24 * 60 * 60 * 1000 &&
  // remaining < 6 * 30 * 24 * 60 * 60 * 1000;

  const remainingDays = Math.floor(remaining / (1000 * 60 * 60 * 24));

  // Offer period
  const currentDate = new Date();
  const targetDate = new Date('2024-03-01');
  const july31 = new Date(currentDate.getFullYear(), 6, 31);

  //dynamic timer
  useEffect(() => {
    const interval = setInterval(() => {
      //Decrement a second for each second's passed
      setRemaining((rem) => rem - 1000);
    }, 1000);

    //remove the interval upon unmounting the component
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      marginTop={{ base: "50px" }}
      padding="30px"
      backgroundColor="gray.100"
      width="90%"
      maxWidth="500px"
      borderRadius="25px"
      boxShadow="3px 3px 5px 1px silver"
    >
      <Text color="gray.500" fontSize={20} textAlign="start" fontWeight="bold">
        {formatMessage({ id: "registerData" })}
      </Text>
      <Text
        fontSize={18}
        textAlign="center"
        fontWeight="bold"
        paddingTop="15px"
      >
        {formatMessage({ id: "subscription.remaining" })}
      </Text>
      <Box
        d="flex"
        fontWeight="bold"
        justifyContent="space-around"
        alignItems="center"
        paddingTop="25px"
      >
        <Text color={remainingDays <= 10 ? "red" : "#2bb0d9"}>
          {formatMessage({ id: "day" })} (
          {Math.floor(remaining / (1000 * 60 * 60 * 24))})
        </Text>
        <Text color={remainingDays <= 10 ? "red" : "#2bb0d9"}>
          {formatMessage({ id: "hour" })} (
          {Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))})
        </Text>
        <Text color={remainingDays <= 10 ? "red" : "#2bb0d9"}>
          {formatMessage({ id: "minute" })} (
          {Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60))})
        </Text>
        <Text color={remainingDays <= 10 ? "red" : "#2bb0d9"}>
          {formatMessage({ id: "second" })} (
          {Math.floor((remaining % (1000 * 60)) / 1000)})
        </Text>
      </Box>
      <Box
      d="flex"
      fontWeight="bold"
      alignItems="center"
      justifyContent="right"
      marginTop="25px">
      {(remainingDays > 10 && new Date(user.subscription?.endDate) <= july31 && currentDate <= targetDate) && (
        <Button onClick={() => {
          history.push(SUBSCRIPTION_ROUTE, { offer: true })
        }}
          colorScheme={remainingDays <= 10 ? "red" : "blue"}
          variant="solid"
          size="md"
        >
          {formatMessage({ id: "subscription.oneYearOffer" })}
        </Button>
      )}
      {(remainingDays <= 10) && (
        <Button onClick={() => {
          history.push(SUBSCRIPTION_ROUTE, { renew: true })
        }}
          colorScheme={remainingDays <= 10 ? "red" : "blue"}
          variant="solid"
          size="md"
        >
          {formatMessage({ id: "subscription.renewNow" })}
        </Button>
      )}
      </Box>
    </Box>
  );
};

export default SubscriptionDetails;
