import React, { useState, useEffect } from "react";
import BaseView from "../BaseView";
import { Box, HStack, Text } from "@chakra-ui/react";
import { get } from "src/api/index";
import useLocale from "src/providers/useLocale";
import { Manifestation } from "src/types";
import { FormattedMessage } from "react-intl";
import { FaPlayCircle, FaStopCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "src/store";
import { playMedia, stopMedia } from "src/store/media";

const ManifestationGate = () => {
  //current locale
  const [locale] = useLocale();

  //data to display
  const [data, setData] = useState<Array<Manifestation>>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  //background media play function
  const dispatch = useDispatch();
  const id = useSelector((state) => state.media.id);
  const closeMedia = () => dispatch(stopMedia());

  //fetch audios upon mounting component
  useEffect(() => {
    const fetchData = async () => {
      const response = await get<Array<Manifestation>>(
        "/manifestation",
        locale === "ar" || locale === "en" ? locale : undefined
      );
      setLoading(false);
      if (response && Array.isArray(response)) {
        setData(response);
      } else setError("Failed to fetch data!");
    };
    fetchData();
  }, [locale]);

  const handleClick = (url: string, idArg: number) => {
    if (id === `manifestation-${idArg}`) {
      closeMedia();
    } else {
      dispatch(
        playMedia({
          source: url,
          name: "manifestation",
          type: "audio",
          id: `manifestation-${idArg}`,
        })
      );
    }
  };

  return (
    <BaseView>
      <HStack
        paddingBottom={"50px"}
        display={"flex"}
        flexDir={"column"}
        alignItems="flex-start"
        paddingTop={"30px"}
      >
        {loading || error ? (
          <div style={{ padding: "30px", textAlign: "center" }}>
            {loading && "Loading..."}
            {error && error}
          </div>
        ) : (
          <>
            <Box
              display={"flex"}
              flexDir="column"
              alignItems="center"
              width={"100%"}
              maxWidth={"1200px"}
              paddingTop={"30px"}
              dir="auto"
            >
              {data.map(
                (item) =>
                  item.name &&
                  item.description && (
                    <Box
                      width={{ base: "90%", md: "100%" }}
                      borderRadius={"10px"}
                      background={"#fcfafa"}
                      padding={"15px"}
                      display={"flex"}
                      flexDir="column"
                      alignItems={"flex-start"}
                      justifyContent="space-between"
                      margin={"10px 0"}
                      boxShadow={"-3px 3px 5px silver"}
                      border="2px solid transparent"
                      key={item.id!}
                      minHeight="200px"
                    >
                      <Box>
                        <Text
                          fontSize={"14px"}
                          color="#c4c0c0"
                          fontWeight="bold"
                        >
                          {item.name}
                        </Text>
                        <Text color="grey" paddingTop={"10px"}>
                          {item.description}
                        </Text>
                      </Box>
                      <Box
                        width={"100%"}
                        display="flex"
                        justifyContent="center"
                      >
                        <Box
                          as="button"
                          background={"#2895ff"}
                          minWidth={{ base: "100px", xl: "150px" }}
                          padding={"7px"}
                          borderRadius="5px"
                          display={"flex"}
                          justifyContent="center"
                          alignItems="center"
                          transition={"250ms ease-out"}
                          _hover={{ background: "#9fb9e0" }}
                          onClick={() => handleClick(item.mediaUrl!, item.id!)}
                        >
                          {id === `manifestation-${item.id}` ? (
                            <>
                              <FaStopCircle size={18} color="white" />
                              <Text color="white" margin={"0 5px"}>
                                <FormattedMessage id="stop" />
                              </Text>
                            </>
                          ) : (
                            <>
                              <FaPlayCircle
                                size={18}
                                color="white"
                                cursor="pointer"
                              />
                              <Text color="white" margin={"0 5px"}>
                                <FormattedMessage id="play" />
                              </Text>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )
              )}
            </Box>
          </>
        )}
      </HStack>
    </BaseView>
  );
};

export default ManifestationGate;
