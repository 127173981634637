import React from "react";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);

export default function IconLabelButtons(props: any) {
  const classes = useStyles();

  return (
    <Button
      style={{
        borderRadius: "50%",
        position: "relative",
        // top: "-7px",
        width: "2px",
        height: "27px",
        minWidth: "29px",
        background: "white",
        right: "2.4em",
        margin: "0 auto",
        top: "-2em",
        ...props.styles,
      }}
      variant="contained"
      size="small"
      className={classes.button}
      startIcon={
        <CameraAltIcon
          style={{
            color: "#2895ff",
            position: "relative",
            left: "5px",
          }}
        />
      }
    ></Button>
  );
}
