import React from "react";
import { Button, Box, VStack } from "@chakra-ui/react";
import { useSelector, useDispatch } from "src/store";
import { toggleArea, setMaximize } from "src/store/media";
import IndepMediaPlayer from "src/components/IndepMediaPlayer";
import { useIntl } from "react-intl";
const videos = {
  ar: {
    clear: "https://www.youtube.com/watch?v=l-BrCAHSRuo",
    protect: "https://www.youtube.com/watch?v=kWr_MYdR7no",
  },
  en: {
    clear: "https://vimeo.com/744155737",
    protect: "https://vimeo.com/744319775",
  },
};
const VideoModal = () => {
  const { display, maximize, clear } = useSelector((state) => ({
    display: state.media.display,
    maximize: state.media.maximize,
    clear: state.media.clear,
  }));
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const property = clear ? "clear" : "protect";
  const videoUrl = videos[locale][property];
  return (
    <Box>
      <VStack>
        {maximize ? (
          <Box
            onClick={() => dispatch(setMaximize({ value: false }))}
            bg={"rgba(0,0,0,.9)"}
            position={"absolute"}
            top={"0"}
            bottom={"0"}
            left={"0"}
            right={"0"}
            width={"100%"}
            height={"100%"}
            zIndex={"100"}
          />
        ) : null}
        <Box
          style={
            maximize
              ? { zIndex: 101, maxWidth: "960px", margin: "auto" }
              : {
                  borderRadius: "30px",
                  maxWidth: "250px",
                  border: "none",
                  left: "10px",
                  outline: "none",
                  bottom: "0",
                  position: "absolute",
                }
          }
        >
          {display ? (
            <>
              <Button
                className={"modalXBtn"}
                onClick={() => {
                  dispatch(toggleArea({ value: false }));
                }}
                style={
                  maximize
                    ? { width: "100%", color: "white" }
                    : { position: "absolute", left: "200px", zIndex: 105 }
                }
                bg={"transparent"}
                maxWidth={"30px"}
                fontSize={"15px"}
                maxHeight={"20px"}
              >
                X
              </Button>
              {!maximize ? (
                <Button
                  className={"modalXBtn"}
                  onClick={() => {
                    dispatch(setMaximize({ value: true }));
                  }}
                  style={
                    maximize
                      ? { width: "100%", color: "white" }
                      : {
                          top: "1px",
                          position: "absolute",
                          left: "177px",
                          zIndex: 105,
                        }
                  }
                  bg={"transparent"}
                  maxWidth={"30px"}
                  top={"-3px"}
                  right={"18px"}
                  fontSize={"10px"}
                  maxHeight={"15px"}
                >
                  [-]
                </Button>
              ) : null}
              {maximize ? (
                <Button
                  className={"modalXBtn"}
                  onClick={() => {
                    dispatch(setMaximize({ value: false }));
                  }}
                  style={
                    maximize
                      ? { width: "100%", color: "white" }
                      : { position: "absolute", left: "170px", zIndex: 105 }
                  }
                  bg={"transparent"}
                  maxWidth={"30px"}
                  top={"-3px"}
                  right={"10px"}
                  fontSize={"27px"}
                  maxHeight={"15px"}
                >
                  -
                </Button>
              ) : null}
              <Box
                width={"fit-content"}
                height={"fit-content"}
                backgroundColor={"white"}
              >
                <IndepMediaPlayer
                  src={videoUrl}
                  disableSeeking={true}
                  autoPlay={true}
                  loop={false}
                />
              </Box>
            </>
          ) : null}
        </Box>
      </VStack>
    </Box>
  );
};

export default VideoModal;
