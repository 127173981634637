import React, { useMemo, useState } from "react";
import ItemCard from "src/components/ItemCard";

import { Purpose, PurposeListItem } from "src/types";
import PurposeItem from "./PurposeItem";
import { PurposesTypes } from "./purposesTypes";

type PurposeListProps = {
  completedList: PurposeListItem[];
  archiveList: PurposeListItem[];
  notCompletedList: PurposeListItem[];
  loading: boolean;
  error: Error | null;
  currentType: PurposesTypes;
  listActions: {
    removePurposeHandler: (id: number) => Promise<void>;
    incompleteItemOnClick: (id: number) => void;
    completeArchiveItemOnClick: (id: number) => void;
    archive: (id: number) => Promise<Partial<Purpose>>;
    unarchive: (id: number) => Promise<Partial<Purpose>>;
    reset: (id: number) => Promise<void>;
  };
  onShare?: any;
  setSharingProps?: any
};
const PurposeList: React.FC<PurposeListProps> = ({
  loading,
  archiveList,
  completedList,
  error,
  notCompletedList,
  currentType,
  listActions,
  onShare,
  setSharingProps
}) => {
  const list = useMemo(
    () =>
      currentType === PurposesTypes.ARCHIVE ? (
        <>
          {archiveList.sort((item1, item2) => Number(item2.createdAt) - Number(item1.createdAt)).map((item: any) => (
            <ItemCard
              cursor="pointer"
              key={item.id}
              mb="1rem"
              remove={() => {
                listActions.removePurposeHandler(item.id);
              }}
              unarchive={() => {
                listActions.unarchive(item.id);
              }}
              onClick={(e) => { e.stopPropagation(); listActions.completeArchiveItemOnClick(item.id); setSharingProps(item) }}
              share={() => onShare({ id: item.id, name: "message" })}
              isSharedWithMe={item.isShared}
            >
              {item.name}
            </ItemCard>
          ))}
        </>
      ) : (
        <>
          {completedList.sort((item1, item2) => Number(item2.createdAt) - Number(item1.createdAt)).map((item: any) => (
            <ItemCard
              cursor="pointer"
              key={item.id}
              mb="1rem"
              remove={() => {
                listActions.removePurposeHandler(item.id);
              }}
              onClick={(e) => { e.stopPropagation(); listActions.completeArchiveItemOnClick(item.id); setSharingProps(item) }}
              // archive={() => listActions.archive(item.id)}
              resort={!item.isShared ? () => {
                return listActions.reset(item.id).then(() => {
                  listActions.completeArchiveItemOnClick(item.id);
                })
              } : undefined}
              share={() => onShare({ id: item.id, name: "message" })}
              isSharedWithMe={item.isShared}
              permission={item.shared?.permission}
            >
              {item.name}
            </ItemCard>
          ))}
          <hr style={{ marginBottom: "1rem" }} />
          {notCompletedList.sort((item1, item2) => Number(item2.createdAt) - Number(item1.createdAt)).map((item) => (
            <PurposeItem
              cursor="pointer"
              key={item.id}
              id={item.id}
              mb="1rem"
              // share={() => onShare({ id: item.id, name: "message" })}
              remove={() => {
                listActions.removePurposeHandler(item.id);
              }}
              onClick={(e) => { e.stopPropagation(); listActions.incompleteItemOnClick(item.id); }}
            // isSharedWithMe={item.isShared}
            // permission={item.shared?.permission}
            // douplicate={() => { console.log('doublicate purpose') }}
            >
              {item.name}
            </PurposeItem>
          ))}

        </>
      ),
    [archiveList, completedList, currentType, listActions, notCompletedList, onShare]
  );

  if (loading) return <>Loading...</>;
  if (error) return <>{error.message}</>;
  return list;
};
export default PurposeList;
