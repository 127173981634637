import React, { useState } from "react";
import { Box, Center, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import SelectItem from "src/components/SelectItem";
import PrimaryButton from "src/components/PrimaryButton";
import SecondaryButton from "src/components/SecondaryButton";
import { Target } from "src/types";
import { scrollBarStyles } from "src/layout/ScrollBar";

export type StepTwoProps = {
  nextStep: (step?: number) => void;
  targets?: Target[];
  selectTarget: (targetId: number) => Promise<void>;
};
const StepTwo: React.FC<
  StepTwoProps & {
    nextLocalStep: () => void;
    closeModal: () => void;
  }
> = ({ nextStep, targets, selectTarget, nextLocalStep, closeModal }) => {
  const nextStepEnabled = React.useCallback(() => {
    if (!targets) return false;
    let selectionCount = 0;
    for (const target of targets) {
      if (target.cached) return false;
      if (target.selected) selectionCount += 1;
    }
    return selectionCount >= 1;
  }, [targets]);

  const [selecting, setSelecting] = useState(false);
  return (
    <>
      <Box>
        <Text
          d="inline-block"
          color="brand.900"
          fontSize="18px"
          fontWeight="bold"
          fontFamily="Calibri (Body)"
        >
          <FormattedMessage
            id="purpose.create.step2_title"
            defaultMessage="اختار من قائمة الصفات الاتية أهمها"
          />
        </Text>{" "}
        {/* <Text
          d="inline-block"
          color="gray.900"
          fontSize="16px"
          //   fontWeight="bold"
          fontFamily="Calibri (Body)">
          <FormattedMessage
            id="purpose.create.fromto"
            defaultMessage="من {from} ل {to} غايات"
            values={{ from: 3, to: 6 }}
          />
        </Text> */}
      </Box>
      <Box
        mb={"5px"}
        overflowY={"scroll"}
        maxHeight={"200px"}
        padding={"0 20px"}
        css={scrollBarStyles}
      >
        {targets?.map((target) => {
          return (
            <SelectItem
              mb={4}
              cursor={"pointer"}
              key={target.id}
              selected={target.selected}
              onSelect={async () => {
                if (!selecting) {
                  // setSelecting(true);
                  await selectTarget(target.id);
                  // setSelecting(false);
                }
              }}
            >
              {target.name}
            </SelectItem>
          );
        })}
      </Box>
      <Center mt={"40px"} padding={"0"}>
        <PrimaryButton
          margin={"0 5px"}
          onClick={() => {
            nextStep && nextStep(2);
            // nextLocalStep();
          }}
          disabled={!nextStepEnabled()}
        >
          <FormattedMessage id="next" defaultMessage="التالي" />
        </PrimaryButton>
        <SecondaryButton
          margin={"0 5px"}
          borderRadius="5px"
          h="40px"
          w="168px"
          onClick={() => {
            nextStep && nextStep(1);
            closeModal();
          }}
        >
          <FormattedMessage
            id="purposes.create.later"
            defaultMessage="استكمال لاحقا"
          />
        </SecondaryButton>
      </Center>
    </>
  );
};
export default StepTwo;
