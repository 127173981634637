import React from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import WishesStepOne from "./WishesStepOne";
import { useWishes } from "./useWishes";
import WishesStepTwo from "./WishesStepTwo";
import { DetailsDisclosureProps } from "../Missions/useUIState";
import { Vision, Wish } from "src/types";
import { useIntl } from "react-intl";
import AccessPermission from "src/components/AccessPermission/AccessPermission";
type WishesProps = {
  disclosureProps: DetailsDisclosureProps;
  vision?: Vision;
  submitTopTen: (wishes: [Wish]) => Promise<Vision>;
  isSharedWithMe?: boolean;
  canUpdate?: boolean;
  owner?: any;
};
const TopWishesModal: React.FC<
  WishesProps & { openPreview: (modalId: number) => void }
> = ({ disclosureProps, vision, submitTopTen, openPreview, isSharedWithMe,
  canUpdate,
  owner }) => {
    //TODO: map vision to this view
    const wishesProps = useWishes({ vision, isOpen: disclosureProps.isOpen, });
    const { formatMessage } = useIntl();
    return (
      <Modal {...disclosureProps}>
        <ModalOverlay />
        <ModalContent
          px={"45px"}
          borderRadius="30px"
          maxW="50rem"
          d="flex"
          minH="calc(100% - 8.5rem)"
        >
          <ModalCloseButton />
          <ModalBody d="flex" flex="1" flexDir="column" px={0}>
            {isSharedWithMe ? <Box mb="4">
              <Box>
                <div>
                  <p>
                    <span>{formatMessage({ id: "sharedBy" })}</span> <span>{owner?.name}</span>
                  </p>
                  <small>{owner?.email}</small>
                </div>
                <AccessPermission permission={canUpdate ? "update" : "read"} />
              </Box>
            </Box> : null}
            {wishesProps.step === 0 ? (
              <WishesStepOne
                {...wishesProps}
                setStep={() => wishesProps.setStep(1)}
              />
            ) : (
              <WishesStepTwo
                {...wishesProps}
                submitTopTen={submitTopTen}
                openPreview={openPreview}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };
export default TopWishesModal;
