import { Box } from "@chakra-ui/react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "src/routes";

const Content = () => {
  return (
    <Box
      flex="1"
      ms={["10px", "54px", "108px"]}
      me={["10px", "54px", "143px"]}
      mt="24px"
    >
      <Switch>
        {routes.map((route, idx) => (
          <Route path={route.path} key={idx} component={route.component} />
        ))}
      </Switch>
    </Box>
  );
};
export default Content;
