import React, { useEffect } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  Heading,
  Flex,
  useMediaQuery,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Tag,
  TagLeftIcon,
} from "@chakra-ui/react";
import { FaEllipsisV, FaPlayCircle } from "react-icons/fa";
import { MdShare } from "react-icons/md";
import { BiTime, BiCalendar } from "react-icons/bi";
import { RiShareBoxLine } from "react-icons/ri";
import { motion } from "framer-motion";
import DeleteButton from "src/components/DeleteModel";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useIntl } from "react-intl";
import SharingModal from "src/components/SharingModal/SharingModal";
import { FocusFilm } from "src/types";
import { BASE_MEDIA } from "src/api";

const MotionBox = motion(Box);

const FilmList = ({
  ownedFilms = [],
  sharedFilms = [],
  locale,
  onFilmPlay,
  onDelete,
}) => {
  const [isLargeScreen] = useMediaQuery("(min-width: 36em)");
  const { onOpen } = useDisclosure();
  const { formatMessage } = useIntl();

  const {
    isOpen: isFilmSharingModalOpen,
    onClose: onFilmSharingModalClose,
    onOpen: onFilmSharingModalOpen,
  } = useDisclosure();

  if (
    (!Array.isArray(ownedFilms) || ownedFilms.length === 0) &&
    (!Array.isArray(sharedFilms) || sharedFilms.length === 0)
  ) {
    return null;
  }

  const allFilms: FocusFilm[] = [...ownedFilms, ...sharedFilms];

  // Sort the films by createdAt
  allFilms.sort((a, b) => {
    // Ensure that createdAt is Date objects
    const createdAtA = new Date(a.createdAt);
    const createdAtB = new Date(b.createdAt);
    // Compare createdAt timestamps
    return createdAtB - createdAtA; // Descending order
  });

  return allFilms.map((film, index) =>
    isLargeScreen ? (
      <Flex align="start">
        <Flex key={film.id} align="start" justify="start" mb={5}>
          <Box position="relative">
            <Box
              position="relative"
              overflow="hidden"
              borderRadius="md"
              maxW="200px"
              minW="200px"
              maxH="110px"
              minH="110px"
              // mx="auto"
            >
              <Image
                src={
                  film.posters[0].coverUrl?.startsWith("media/")
                    ? `${BASE_MEDIA}/${film.posters[0].coverUrl}`
                    : film.posters[0].coverUrl
                }
                alt={`poster-${index}`}
                boxSize="100%"
                objectFit="cover"
                borderRadius="md"
                overflow="hidden"
              />
              <MotionBox
                position="absolute"
                top="0"
                left="0"
                transform="translate(-50%, -50%)"
                backgroundColor="rgba(0, 0, 0, 0.6)"
                height="100%"
                width="100%"
                borderRadius="md"
                opacity="0.8"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                initial={{ scale: 1 }}
                whileHover={{
                  scale: [1, 1.5],
                  transition: { duration: 0.3 },
                }}
                onClick={() => onFilmPlay(film)}
              >
                <FaPlayCircle size={40} color="white" />
              </MotionBox>
              <Text
                fontSize="sm"
                color="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="absolute"
                bottom={0}
                left={0}
              >
                <BiTime
                  color="gray.400"
                  style={{ marginRight: "2", marginLeft: "4" }}
                />{" "}
                {film.duration}
              </Text>
            </Box>
          </Box>
          <VStack ml={4} align="start" w="100%" h="100%">
            <Heading
              as="h3"
              size="md"
              cursor="pointer"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              maxW="16rem"
              minW="16rem"
              onClick={() => onFilmPlay(film)}
            >
              {film.title}
            </Heading>
            <Text
              fontSize="sm"
              color="gray.500"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              maxW="15rem"
            >
              {film.audioName}
            </Text>
            {film.description && (
              <Text
                fontSize="sm"
                color="gray.500"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                maxW="14rem"
              >
                {film.description}
              </Text>
            )}
            {!film.isShared && (
              <Text
                fontSize="sm"
                color="gray.500"
                display="flex"
                alignItems="center"
                justifyContent="start"
                w="5rem"
              >
                <BiCalendar
                  color="gray.400"
                  style={{
                    marginRight: locale === "ar" ? "0" : "2",
                    marginLeft: locale === "ar" ? "2" : "0",
                  }}
                />{" "}
                {new Intl.DateTimeFormat(locale, {
                  day: "numeric",
                  month: "short",
                  year: "2-digit",
                }).format(new Date(film.createdAt))}
              </Text>
            )}
            {film.isShared && (
              <Tag
                size="sm"
                variant="subtle"
                colorScheme="blue"
                borderRadius="full"
                px="2"
                py="1"
                fontFamily="Montserrat, sans-serif"
                fontWeight="bold"
                display="flex"
                alignItems="center"
              >
                <TagLeftIcon as={MdShare} />
                <Text mr="1">{formatMessage({ id: "focusFilm.shared" })}</Text>
              </Tag>
            )}
          </VStack>
        </Flex>
        {!film.isShared && (
          <Box w="4rem">
            <DeleteButton remove={() => onDelete(film.id)} />
            <IconButton
              mx={2}
              me="1"
              minW={1}
              onClick={() => {
                onFilmSharingModalOpen();
              }}
              color="brand.800"
              variant="link"
              aria-label="re-answer"
              fontSize="23px"
              icon={<RiShareBoxLine color="#718096" />}
              h="5"
            />
          </Box>
        )}
        {!film.isShared && (
          <SharingModal
            isOpen={isFilmSharingModalOpen}
            onClose={onFilmSharingModalClose}
            sharedEntity={{ name: "film", id: film.id }}
            showOptions={false}
          />
        )}
      </Flex>
    ) : (
      <Flex key={film.id} align="start" direction="column" mb={5}>
        <Box position="relative" mb={4} w="100%">
          <Box
            position="relative"
            overflow="hidden"
            borderRadius="md"
            maxW="100%"
            maxH="14rem"
            mx="auto"
          >
            <Image
              src={
                film.posters[0].coverUrl?.startsWith("media/")
                  ? `${BASE_MEDIA}/${film.posters[0].coverUrl}`
                  : film.posters[0].coverUrl
              }
              alt={`poster-${index}`}
              boxSize="100%"
              objectFit="cover"
              borderRadius="md"
              overflow="hidden"
            />

            <MotionBox
              position="absolute"
              top="0"
              left="0"
              transform="translate(-50%, -50%)"
              backgroundColor="rgba(0, 0, 0, 0.6)"
              height="100%"
              width="100%"
              borderRadius="md"
              opacity="0.8"
              display="flex"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              initial={{ scale: 1 }}
              whileHover={{
                scale: [1, 1.5],
                transition: { duration: 0.3 },
              }}
              onClick={() => onFilmPlay(film)}
            >
              <FaPlayCircle size={40} color="white" />
            </MotionBox>
            <Text
              fontSize="sm"
              color="white"
              display="flex"
              alignItems="center"
              position="absolute"
              bottom={0}
              left={0}
            >
              <BiTime
                color="gray.400"
                style={{ marginRight: "2", marginLeft: "4" }}
              />{" "}
              {film.duration}
            </Text>
            {/* Options button for small screens */}
            {!film.isShared && (
              <Box position="absolute" top="0" right="0">
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<FaEllipsisV style={{ color: "white" }} />}
                    variant="ghost" // Use "ghost" variant for a button with no background or border
                    colorScheme="white"
                    size="sm"
                    onClick={onOpen}
                    _focus={{ outline: "none" }} // Add this line to remove focus outline
                  />
                  <MenuList>
                    <MenuItem onClick={() => onDelete(film.id)}>
                      <RiDeleteBin6Line color="red" />
                      <Text m="0 4px">
                        {formatMessage({ id: "focusFilm.delete" })}
                      </Text>
                    </MenuItem>
                    <MenuItem onClick={() => onFilmSharingModalOpen()}>
                      <RiShareBoxLine color="#718096" />
                      <Text m="0 4px">
                        {formatMessage({ id: "focusFilm.share" })}
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            )}
          </Box>
        </Box>

        <VStack ml={{ base: 0, md: 4 }} align="start" w="100%" h="100%">
          <Flex direction="column" w="100%" align="center">
            <Flex align="center" justify="space-between" w="100%">
              <Heading
                as="h3"
                size="md"
                cursor="pointer"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                maxW={{ base: "100%", md: "200px" }}
                onClick={() => onFilmPlay(film)}
              >
                {film.title}
              </Heading>

              {/* Display createdAt beside the name for small screens */}
              {!film.isShared && (
                <Text
                  fontSize="sm"
                  color="gray.500"
                  display={{ base: "flex", md: "none" }}
                  alignItems="center"
                  justifyContent="end"
                  w="5rem"
                >
                  <BiCalendar
                    color="gray.400"
                    style={{
                      marginRight: locale === "ar" ? "0" : "2",
                      marginLeft: locale === "ar" ? "2" : "0",
                    }}
                  />{" "}
                  {new Intl.DateTimeFormat(locale, {
                    day: "numeric",
                    month: "short",
                    year: "2-digit",
                  }).format(new Date(film.createdAt))}
                </Text>
              )}
              {film.isShared && (
                <Tag
                  size="sm" // Adjust size as needed
                  variant="subtle"
                  colorScheme="blue"
                  borderRadius="full"
                  px="2"
                  py="1"
                  fontFamily="Montserrat, sans-serif"
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                >
                  <TagLeftIcon as={MdShare} />
                  <Text mr="1">
                    {formatMessage({ id: "focusFilm.shared" })}
                  </Text>
                </Tag>
              )}
            </Flex>
          </Flex>

          <Text
            fontSize="sm"
            color="gray.500"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            maxW="20rem"
          >
            {film.audioName}
          </Text>

          {film.description && (
            <Text fontSize="sm" color="gray.500" overflow="hidden">
              {film.description}
            </Text>
          )}
        </VStack>

        {/* Options button for larger screens */}
        {/* <Box display={{ base: "none", md: "block" }}>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FaEllipsisV />}
              variant="outline"
              size="xs"
              onClick={onOpen}
            />
            <MenuList>
              <MenuItem onClick={() => onDelete([film.id])}>Delete</MenuItem>
            </MenuList>
          </Menu>
        </Box> */}

        {!film.isShared && (
          <SharingModal
            isOpen={isFilmSharingModalOpen}
            onClose={onFilmSharingModalClose}
            sharedEntity={{ name: "film", id: film.id }}
            showOptions={false}
          />
        )}
      </Flex>
    )
  );
};

export default FilmList;
